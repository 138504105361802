import React from "react";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

export const HomepageXai: React.FC = () => {

	return (
		<section className="homepage-xai-clip">
			<div className="homepage-xai">
				<div className="homepage-xai_container">
					<div className="homepage-xai_container_content">
						<div className="homepage-xai_container_content_header">
							<img
								src={"https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto:best/v1686070078/website/images/homepage/xai_logo_glowing_bvayb4.svg"}
								alt={"Xai Logo"}
							/>
						</div>
						<h2 className="homepage-xai_container_content_title">
							Ex Populus games are published by the Xai Foundation
						</h2>
						<span className="homepage-xai_container_content_subtitle">
							The Xai Foundation is the steward of the Xai Blockchain and serves as a gaming publisher for the game developers deploying games to the Xai Blockchain. Powered by Offchain Labs’ Arbitrum Technology with games from Ex Populus, Xai is set to transform the gaming industry.
						</span>

						<ExPopulusButton
							color="dark-on-white"
							className="homepage-xai_container_content_button"
							href={"https://xai.games/"}
						>
							Learn More
						</ExPopulusButton>
					</div>

				</div>
				<div className="homepage-xai_gradient"/>

				<div className="homepage-xai_video">
					<MediaAssetViewer
						asset={new URL("https://res.cloudinary.com/ex-populus/video/upload/v1685483386/website/images/homepage/sai-bg_ahoazu.mp4")}
						contentType={"video"}
						videoAutoPlay={true}
						videoControls={false}
						videoMuted={true}
						videoLoop={true}
						elementWidth={{
							desktopWidth: 1920,
							mobileWidth: 800,
						}}
					/>
				</div>
			</div>
		</section>
	)
}
