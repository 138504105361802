"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationTypeToJSON = exports.VerificationTypeFromJSONTyped = exports.VerificationTypeFromJSON = exports.VerificationType = void 0;
/**
 *
 * @export
 */
exports.VerificationType = {
    PASSWORD_RESET: 'PASSWORD_RESET',
    EMAIL: 'EMAIL',
    PHONE_NUMBER: 'PHONE_NUMBER'
};
function VerificationTypeFromJSON(json) {
    return VerificationTypeFromJSONTyped(json, false);
}
exports.VerificationTypeFromJSON = VerificationTypeFromJSON;
function VerificationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.VerificationTypeFromJSONTyped = VerificationTypeFromJSONTyped;
function VerificationTypeToJSON(value) {
    return value;
}
exports.VerificationTypeToJSON = VerificationTypeToJSON;
