import React, {HTMLAttributes} from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {ReactComponent as ArrowRightIcon} from "../../svgs/arrow-right.svg";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

interface IProps extends HTMLAttributes<HTMLElement> {
	id: string;
	className?: string;
}

const OurGames: React.FC<IProps> = (props) => {

	return (
		<section
			className={classNames("our-games2", props.className)}
			id={props.id}
		>
			<Container>
				<div className={"info"}>
					<header>
						<h3>Check Out Our Titles</h3>
						<img src={"https://storage.googleapis.com/ex-populus-marketplace_static/about-page/updated_titles/games_svg.svg"} alt={"Games"} />
					</header>
					<p>Just like traditional video game companies (think Valve or Epic), some of the games made available by Ex Populus are developed by third party studios, and some are made in the Ex Populus studio.</p>
					<p>Click the button below to see a full list of games and their developers.</p>

					<ExPopulusButton
						to="/games"
						color="purple"
						rightIcon={ArrowRightIcon}
						className="btn-see-games"
					>
						See Our Games
					</ExPopulusButton>
				</div>

				<div className="image">
					<MediaAssetViewer
						asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/our-games-arrangement-v2_ht570l")}
						imageAltText="Games Arrangement"
						contentType={"image"}
					/>
				</div>

			</Container>
		</section>
	);
}

export default OurGames;
