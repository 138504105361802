import React from "react";
import classNames from "classnames";
import classes from "../../style/index.module.scss";

interface IScrollerProps {
    duration?: number;
    clones?: number;
    bannerClass?: string;
    scrollerClass?: string;
}

/**
 * ScrollerLeftToRight
 * Roughly, will scroll the provided children infinitely
 *
 * children: the elements to scroll
 * duration: the time it takes to fully scroll all the children once ("speed" of the scroll is a function of the width of the children and the duration)
 * clones: the number of "banner clones", defaults to 2 and this should usually be fine unless you provide too few children
 * bannerClass: style overrides for the item "banners"
 * scrollerClass: style overrides for the scroller container element
 *
 */
const ScrollerLeftToRight: React.FC<IScrollerProps> = (props) => {
    const {children, duration, clones, bannerClass, scrollerClass} = props;

    const createBanner = (bannerItems, i) => (
        <div key={`left-to-right-banner-items_${i}`}
             className={classNames("banner", bannerClass)}>{bannerItems}</div>
    );

    const createScrollerBanners = (count, items) => Array.from({length: count}, (x, i) => (
        createBanner(items, i)
    ));

    return (
        <div className={"scroller-container"}>
            <div className={classNames("scroller", "leftToRight", scrollerClass)}
                 style={{animationDuration: `${duration}s`}}>
                {createScrollerBanners(clones, children)}
            </div>
        </div>
    );
}

ScrollerLeftToRight.defaultProps = {
    duration: 10,
    clones: 2,
    bannerClass: "",
    scrollerClass: "",
};

export {ScrollerLeftToRight};
