import React, {ReactElement} from "react";
import {ReactComponent as Lightning} from "../../svgs/lightning.svg";
import {ReactComponent as Thumbs} from "../../svgs/thumbs.svg";
import {ReactComponent as Smile} from "../../svgs/smile.svg";
import classNames from "classnames";

interface CardElementData {
	icon: ReactElement,
	header: string;
	subheader: string;
	className?: string;
}

interface ComponentContent {
	bullets: Array<CardElementData>;
}

const componentContent = {
	bullets: [
		{
			icon: <Lightning width={22} height={22}/>,
			header: "Seamless Wallets",
			subheader: "Don't want to hassle with wallets, staking and other obtuse interactions? Our seamless wallets built in partnership with MetaFab will make your gaming experience completely seamless.",
		},
		{
			icon: <Thumbs width={22} height={22}/>,
			header: "Marketplace Integrations",
			subheader: "Ex Populus works to integrate seamlessly with various marketplaces and exchanges to bring a fully diegetic trading experience to its games.  This means a more immersive gaming experience and less headaches for players.",
		},
		{
			icon: <Smile width={22} height={22}/>,
			header: "Anti-Cheat and Compliance",
			subheader: "Ex Populus has integrated with industry leading technologies that help maintain multi-jurisdictional gaming compliance and minimizing the impact of cheaters in its extended gaming ecosystem.",
			className: "homepage-labs_container_content_bullet-container_item_lg",
		},
	],
} satisfies ComponentContent;

export const HomepageLabs: React.FC = () => {

	const bulletItem = (item: CardElementData, index: number) => {
		return (
			<div
				key={`bullet-item-${index}`}
				 className={classNames("homepage-labs_container_content_bullet-container_item", item.className)}
			>

				<div className="homepage-labs_container_content_bullet-container_item_body">
					{item.icon}
					<p>{item.header}</p>
					<span>{item.subheader}</span>
				</div>
			</div>
		);
	}

	return (
		<section className="homepage-labs-clip">
			<div className="homepage-labs">
				<div className="homepage-labs_container">
					<div className="homepage-labs_container_content">
						<h2 className="homepage-labs_container_content_title">
							<strong>Tools & Services</strong>
						</h2>

						<span className="homepage-labs_container_content_subtitle">
							In addition to games, Ex Populus builds a variety of technology to improve your overall gaming experience.
						</span>

						<div className="homepage-labs_container_content_bullet-container">
							{componentContent.bullets.map(bulletItem)}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
