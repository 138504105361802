import React, {ReactNode} from "react";
import GameCardLogo2 from "./GameCardLogo2";
import {Link} from "react-router-dom";
import classNames from "classnames";
import { GameCardTag, GameStatusTag, IGameCardTagType } from "./GameCardTag";

export interface IGameCardProps {
	id: string;
	to?: string; // used for turning card into a local link & applying hover effect
	keyArtPath: string;
	logo: string;
	title: string;
	desc: string;
	tags?: Array<IGameCardTagType>;
}

const GameCard: React.FC<IGameCardProps> = (props) => {

	const gameCardContent: ReactNode = (
		<React.Fragment>
			{props.to && (
				<div className="game-card_underlay"/>
			)}

			<div
				className={classNames("game-card_content", {
					"game-card_content_clickable": props.to,
				})}
			>
				<div className="key-art">
					<img src={props.keyArtPath}/>
				</div>

				<div className="game-info">
					<div className="heading">
						<GameCardLogo2 logo={props.logo}/>
						<div className="title">{props.title}</div>
					</div>

					<div className="description">{props.desc}</div>
					<div className="tags">
						{
							props.tags?.map(({ status, text }, i) => (status
								? <GameStatusTag key={`tag-${i}`} status={status} />
								: <GameCardTag key={`tag-${i}`} text={text} />)
							)
						}
					</div>
				</div>
			</div>

		</React.Fragment>
	);

	if (props.to) {
		return (
			<Link
				to={props.to}
				className="game-card"
			>
				{gameCardContent}
			</Link>
		);
	}

	return (
		<div className="game-card">
			{gameCardContent}
		</div>
	);
}

export default GameCard;
