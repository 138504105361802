import { CountryCode } from "libphonenumber-js";
import React from "react";

interface IProps {
	onClick: Function;
	countryCode: CountryCode;
	dialCodeNumber: string;
}

const CountryCodeLabel: React.FC<IProps> = ({ onClick, countryCode, dialCodeNumber }) => countryCode
	? (<div className={"country-code-label"} onClick={() => onClick()}>
			<img src={`${process.env.PUBLIC_URL}/icons/flags/4x3/${countryCode.toLowerCase()}.svg`} />
			<span>{dialCodeNumber}</span>
		</div>)
	: null;

export default CountryCodeLabel;