import React from "react";
import PlayFreeButton from "../../PlayFreeButton";
import {Carousel} from "react-responsive-carousel";

interface CardElementData {
	image: string,
	title: string,
	text: string,
}

interface ComponentContent {
	bullets: Array<CardElementData>;
}

const componentContent = {
	bullets: [
		{
			image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967066/website/images/final-form/3._hireling_1_wkpdkl",
			title: "Win Streaks",
			text: "As you rack up wins, grow your card inventory to become more-and-more powerful with each match. You’ll also win Chromos, the in-game fuel for Evolution.",
		},
		{
			image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967066/website/images/final-form/3._hireling_2_hsyglp",
			title: "Lose It All",
			text: "Taking inspiration from Rogue-Lite and Souls-Like games, loss in Final Form is severe, losing all but your Limited Edition cards and starting over completely.",
		},
	],
} satisfies ComponentContent;

export const FinalFormDeckBuilding: React.FC = () => {
	const bulletItem = (item: CardElementData, index: number) => {
		return (
			<div className="final-form-deck-building_container_content_wrapper"
				 key={`final-form-deck-building_content-${index}`}>
				<img src={item.image} alt={"image"}/>
				<h4>{item.title}</h4>
				<span>{item.text}</span>
			</div>
		);
	}

	return (
		<section className="final-form-deck-clip">
			<div className="final-form-deck-building">
				<div className="final-form-deck-building_container">

					<div className="final-form-deck-building_container_header">
						<h2 className="final-form-deck-building_container_header_title">
							<span>Tactical deck building</span>
							leads to domination
						</h2>
						<p className="final-form-deck-building_container_header_subtitle">
							Build a new deck every match and watch as your choices unfold in a cinematic auto-battling
							experience.
						</p>
						<PlayFreeButton
							className="final-form-deck-building_container_header_button"
							buttonColor="dark-on-white"
						/>
					</div>
					<div className="final-form-deck-building_container_content">
						<div className="final-form-deck-building_container_content_desktop">
							{componentContent.bullets.map(bulletItem)}
						</div>

						<div className="final-form-deck-building_container_content_mobile">
							<Carousel
								showStatus={false}
								showArrows={false}
								showThumbs={false}
								showIndicators={true}
								swipeScrollTolerance={32}
							>
								{componentContent.bullets.map(bulletItem)}
							</Carousel>
						</div>


					</div>
				</div>
			</div>
		</section>
	);
};
