"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTypeToJSON = exports.ErrorTypeFromJSONTyped = exports.ErrorTypeFromJSON = exports.ErrorType = void 0;
/**
 *
 * @export
 */
exports.ErrorType = {
    APP: 'APP',
    API: 'API',
    FORM: 'FORM'
};
function ErrorTypeFromJSON(json) {
    return ErrorTypeFromJSONTyped(json, false);
}
exports.ErrorTypeFromJSON = ErrorTypeFromJSON;
function ErrorTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ErrorTypeFromJSONTyped = ErrorTypeFromJSONTyped;
function ErrorTypeToJSON(value) {
    return value;
}
exports.ErrorTypeToJSON = ErrorTypeToJSON;
