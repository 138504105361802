import React, {useState} from "react";
import {Asset} from "client/marketplace";
import {Container, Modal} from "reactstrap";
import {FaPlay} from "react-icons/fa";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {VideoCard} from "../VideoCard";
import {fileIsVideo} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

const dataElement = {
    videoLength: "0:46",
    asset: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/dag_header.mp4",
        contentType: "video",
        cloudinary: {
            publicId: "website/videos/dag_header_wcanq5",
            actions: {
                desktop: ["f_auto", "q_80:qmax_80"],
                mobile: ["f_auto", "q_80:qmax_50"]
            },
        },
    } as Asset,
    thumbnail: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/video/DAG_thumbnail.png",
        contentType: "image",
        cloudinary: {
            publicId: "website/images/dag/video/DAG_thumbnail_yqd4mj",
            actions: {
                desktop: ["f_auto", "q_auto"],
                mobile: ["f_auto", "q_auto"]
            },
        },
    } as Asset,
}

const DagHomepageVideo: React.FC = () => {
    const [showVideo, setShowVideo] = useState<boolean>(false);

    const playVideoHandler = () => setShowVideo(!showVideo);

    const {asset, thumbnail, videoLength} = dataElement;

    return (
        <section className={"homepage-template-video"}>
            <Modal
                className={"homepage-template-video_modal"}
                contentClassName={"homepage-template-video_modal-content"}
                centered={true}
                fade={true}
                isOpen={showVideo}
                toggle={playVideoHandler}
                size="xl"
            >
                <VideoCard
                    asset={asset}
                    title={"DAG Promotional Video"}
                    controls={true}
                    containerClassName={"homepage-template-video_player"}
                />
            </Modal>

            <Container className={"homepage-template-video_container"}>
                <div className={"homepage-template-video_header"}>
                    Watch the video
                </div>

                <div className={"homepage-template-video_container"}>
                    <div className={"homepage-template-video_overlay"}/>
                    <div className={"homepage-template-video_thumbnail"}>
                        <MediaAssetViewer
                            asset={thumbnail}
                            imageAltText={"DAG Video Thumbnail"}
                            contentType={fileIsVideo(thumbnail) ? "video" : "image"}
                            elementWidth={{
                                desktopWidth: 1037,
                                mobileWidth: 557,
                            }}
                        />
                    </div>

                    <ExPopulusButton
                        color="white-outline"
                        leftIcon={FaPlay}
                        onClick={playVideoHandler}
                        forwardProps={{type: "button"}}
                        className={"homepage-template-video_button"}
                    >
                        Play video
                    </ExPopulusButton>
                </div>

                <div className={"homepage-template-video_description"}>
                    <p className={"homepage-template-video_text"}>
                        DAG Promotional Video
                    </p>
                    <p className={"homepage-template-video_time"}>
                        {videoLength}
                    </p>
                </div>
            </Container>
        </section>
    );
};

export {DagHomepageVideo};
