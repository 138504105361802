"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSaleInfoAllOfToJSON = exports.TokenSaleInfoAllOfFromJSONTyped = exports.TokenSaleInfoAllOfFromJSON = exports.instanceOfTokenSaleInfoAllOf = void 0;
var User_1 = require("./User");
/**
 * Check if a given object implements the TokenSaleInfoAllOf interface.
 */
function instanceOfTokenSaleInfoAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "tokenType" in value;
    isInstance = isInstance && "saleType" in value;
    isInstance = isInstance && "owner" in value;
    return isInstance;
}
exports.instanceOfTokenSaleInfoAllOf = instanceOfTokenSaleInfoAllOf;
function TokenSaleInfoAllOfFromJSON(json) {
    return TokenSaleInfoAllOfFromJSONTyped(json, false);
}
exports.TokenSaleInfoAllOfFromJSON = TokenSaleInfoAllOfFromJSON;
function TokenSaleInfoAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tokenType': json['tokenType'],
        'saleType': json['saleType'],
        'owner': (0, User_1.UserFromJSON)(json['owner']),
    };
}
exports.TokenSaleInfoAllOfFromJSONTyped = TokenSaleInfoAllOfFromJSONTyped;
function TokenSaleInfoAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tokenType': value.tokenType,
        'saleType': value.saleType,
        'owner': (0, User_1.UserToJSON)(value.owner),
    };
}
exports.TokenSaleInfoAllOfToJSON = TokenSaleInfoAllOfToJSON;
