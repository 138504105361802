"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestSendDownloadLinkBodyToJSON = exports.RequestSendDownloadLinkBodyFromJSONTyped = exports.RequestSendDownloadLinkBodyFromJSON = exports.instanceOfRequestSendDownloadLinkBody = void 0;
/**
 * Check if a given object implements the RequestSendDownloadLinkBody interface.
 */
function instanceOfRequestSendDownloadLinkBody(value) {
    var isInstance = true;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
exports.instanceOfRequestSendDownloadLinkBody = instanceOfRequestSendDownloadLinkBody;
function RequestSendDownloadLinkBodyFromJSON(json) {
    return RequestSendDownloadLinkBodyFromJSONTyped(json, false);
}
exports.RequestSendDownloadLinkBodyFromJSON = RequestSendDownloadLinkBodyFromJSON;
function RequestSendDownloadLinkBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
    };
}
exports.RequestSendDownloadLinkBodyFromJSONTyped = RequestSendDownloadLinkBodyFromJSONTyped;
function RequestSendDownloadLinkBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
    };
}
exports.RequestSendDownloadLinkBodyToJSON = RequestSendDownloadLinkBodyToJSON;
