import {Listing} from "client/marketplace";

/**
 * Generate the "x of y" message given a listing.
 *
 * @param listing
 */
export function generateSupplyMessage(listing: Listing): string {
	if (!listing?.totalSupply || !listing.maximumSupply) {
		return undefined;
	}

	let message: string = "";

	if (listing?.supplyPrefix?.length > 0) {
		message += listing.supplyPrefix + ": ";
	}

	message += listing?.totalSupply + " of " + listing?.maximumSupply;

	return message;
}
