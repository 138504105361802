
export interface IExPopulusUnifiedLinkDispatcher {
	to?: string;
	href?: string;
}

// todo, at this point this should be combined with the ascertainLocalLinks, after the April refactor they basically do the same thing...
/**
 * Determine if the props for this nav item should have a "to" or an "href" based on if the local parameter is true.
 *
 * @param implementingSiteURL
 * @param to
 */
export function exPopulusUnifiedLinkDispatcher(implementingSiteURL: string, to: string): IExPopulusUnifiedLinkDispatcher {
	const local: boolean = to && to.includes(implementingSiteURL);

	return {
		to: implementingSiteURL === to ? "/" : (local ? to.slice(to.indexOf("/", 10)) : undefined),
		href: !local ? to : undefined,
	}
}
