import React, {HTMLAttributes} from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import {ReactComponent as ArrowRightIcon} from "../../svgs/arrow-right.svg";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

interface IProps extends HTMLAttributes<HTMLElement> {
    id: string;
    className?: string;
}

const OurVision: React.FC<IProps> = (props) => {

    return (
        <section
            className={classNames("vision", props.className)}
            id={props.id}
        >
            <Container>
                <div className={"info"}>
                    <header>
                        <h3>About</h3>
                        <img
                            src={"https://storage.googleapis.com/ex-populus-marketplace_static/about-page/updated_titles/vision_svg.svg"}
                            alt={"Vision"}/>
                    </header>
                    <p>The <span className={"purple-text"}>Ex Populus Vision</span> is to bring joy to people around the
                        globe with the world&apos;s most compelling games and entertainment at the highest level
                        possible.</p>
                    <p><span className={"purple-text"}>Ex Populus&apos;s mission</span> is to work with the next
                        generation of elite content creators and bring their games to Web3 and the Metaverse.</p>

                    <ExPopulusButton
                        to="/contact"
                        color="purple"
                        rightIcon={ArrowRightIcon}
                        className="btn-contact-us"
                    >
                        Contact Us
                    </ExPopulusButton>
                </div>

                <div className="image">
                    <MediaAssetViewer
                        asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/our-vision-cutout_2x_sjiht7")}
                        imageAltText="Vision Cutout"
                        contentType={"image"}
                    />
                </div>

                <div className={"vision-bg-gradient"}></div>

            </Container>

            <div className="vision-bg-image">
                <MediaAssetViewer
                    asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/vision_bg_2x_a6dkbf")}
                    imageAltText="Vision Background"
                    contentType={"image"}
                />
            </div>
        </section>
    );
}

export default OurVision;
