import React, {HTMLAttributes} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

interface IProps extends HTMLAttributes<HTMLElement> {
}

const WeAreHiring: React.FC<IProps> = (props) => {

	const modifiedProps: Omit<IProps, "className"> = props;

	return (
		<section
			{...modifiedProps}
			className={classNames("we-are-hiring", props.className)}
		>
			<Container>
				<div className="we-are-hiring-container">
					<div>
						<h2 className="we-are-hiring-container__header">We're hiring!</h2>
					</div>

					<div className="we-are-hiring-container__body">

						<div className="we-are-hiring-container__body-element">
							<img src="/images/contact/we-are-hiring-fade.png" alt="walking fade"/>
						</div>

						<div className="we-are-hiring-container__body-element body">
							<div className="content">
								<h5 className="we-are-hiring-container__body-element__header">Work from anywhere!</h5>
								<span className="we-are-hiring-container__body-element__subheader">Remote option available for all positions</span>
							</div>

							<div className="content">
								<ExPopulusButton
									<React.AnchorHTMLAttributes<HTMLAnchorElement>>
									color="pink"
									href="https://jobs.lever.co/Expopulus"
									forwardProps={{
										target: "_blank",
									}}
								>
									APPLY NOW
								</ExPopulusButton>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
}

export default WeAreHiring;
