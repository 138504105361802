"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatabaseCollectionToJSON = exports.DatabaseCollectionFromJSONTyped = exports.DatabaseCollectionFromJSON = exports.DatabaseCollection = void 0;
/**
 *
 * @export
 */
exports.DatabaseCollection = {
    ASSETS: 'ASSETS',
    CAROUSEL_SLIDES: 'CAROUSEL_SLIDES',
    COLLECTIONS: 'COLLECTIONS',
    CREATORS: 'CREATORS',
    LISTINGS: 'LISTINGS',
    PROJECTS: 'PROJECTS',
    SUBSCRIBERS: 'SUBSCRIBERS',
    TOKENS: 'TOKENS',
    USERS: 'USERS',
    VERIFICATIONS: 'VERIFICATIONS',
    WALLETS: 'WALLETS'
};
function DatabaseCollectionFromJSON(json) {
    return DatabaseCollectionFromJSONTyped(json, false);
}
exports.DatabaseCollectionFromJSON = DatabaseCollectionFromJSON;
function DatabaseCollectionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DatabaseCollectionFromJSONTyped = DatabaseCollectionFromJSONTyped;
function DatabaseCollectionToJSON(value) {
    return value;
}
exports.DatabaseCollectionToJSON = DatabaseCollectionToJSON;
