import React, {ReactNode} from "react";
import {Asset} from "client/marketplace";
import {Link} from "react-router-dom";
import {fileIsImage} from "../../utils/fileTypeChecks";
import EthIconPriceLabel from "../EthIconPriceLabel";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

export interface IProps {
    asset: Asset;
    collection: string;
    price: string | number;
    soldOut?: boolean;
    name: string;
    subtitle: string;
    link?: string;
}

const ItemCard: React.FC<IProps> = ({
                                        asset,
                                        collection,
                                        price,
                                        soldOut,
                                        name,
                                        subtitle,
                                        link,
                                    }) => {
    const preview: ReactNode = (
        <div className="item-card">
            <div className="preview">
                <MediaAssetViewer
                    asset={asset}
                    imageAltText={asset?.name || "Collectible Preview"}
                    contentType={fileIsImage(asset) ? "image" : "video"}
                    videoAutoPlay={true}
                    videoMuted={true}
                    videoLoop={true}
                    videoPlaysInline={true}
                    videoControls={false}
                    elementWidth={{
                        desktopWidth: 520,
                        mobileWidth: 520,
                    }}
                />
            </div>

            <div className={"info-container"}>
                <div className={"name-price-info"}>
                    <div className={"title-widget"}>
                        <div className={"label"}>{collection}</div>
                        <div className={"title"}>{name}</div>
                    </div>

                    <div className={"price-widget"}>
                        <div className={"label"}>Price</div>
                        {
                            soldOut
                                ? (<div className={"sold-out"}>Sold Out</div>)
                                : (<EthIconPriceLabel price={price}/>)
                        }
                    </div>
                </div>
                {subtitle && (
                    <div className="rarity">
                        {subtitle}
                    </div>
                )}
            </div>

            <div className="overlay"></div>
        </div>
    );

    return (
        <div className="item-preview-extended-container">
            {link ? (
                <Link to={link}>
                    {preview}
                </Link>
            ) : preview}
        </div>
    );
}

export default ItemCard;
