import React from "react";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";

const PrivacyPolicyPage: React.FC = () => {

	return (
		<section className="legal-text">
			<div className="text-container">
				<div className="page-content">
					<div className="page-title">
						<h1 className="text-center">Ex Populus Privacy Policy</h1>
						<p className="upcase text-center">Last Updated: 12/01/2021</p>
					</div>

					<div className="mb-4">
						<p>
							This Privacy Policy explains how Ex Populus, Inc. and its subsidiaries and affiliated
							companies (“<strong><i>Ex Populus</i></strong>,” “<strong><i>we</i></strong>,” or
							“<strong><i>us</i></strong>”) collects, uses, and discloses information about you. This
							Privacy Policy applies when you use our website (our “<strong><i>Website</i></strong>”),
							engage with
							us on social media, or otherwise interact with us.

							We may change this Privacy Policy from time to time. If we make changes, we will notify you
							by revising the date at the top of this policy and, in some cases, we may provide you with
							additional notice (such as adding a statement to our website or sending you a notification).
							We encourage you to review this Privacy Policy regularly to stay informed about our
							information
							practices and the choices available to you.
						</p>
					</div>
				</div>
			</div>

			<div className="toc-container">
				<div className="text-container">
					<h3>Table of Contents</h3>

					<div className="toc-container_body">
						<a href="#collection-of-information">Collection of Information</a>
						<a href="#use-of-information">Use of Information</a>
						<a href="#sharing-of-information">Sharing of Information</a>
						<a href="#analytics">Analytics</a>
						<a href="#transfer-information-usa-and-other-countries">Transfer of Information to the United States
							and Other Countries</a>
						<a href="#your-choices">Your Choices</a>
						<a href="#your-california-privacy-rights">Your California Privacy Rights</a>
						<a href="#additional-disclosures-for-individuals-in-europe">Additional Disclosures for Individuals
							in Europe</a>
					</div>
				</div>
			</div>

			<div className="text-container">
				<div className="page-content">
					<a id="collection-of-information"/>
					<h3>Collection Of Information</h3>

					<h4>Information You Provide to Us</h4>
					<p>
						We collect information you provide directly to us. For example, you share information
						directly with us when you create an account, sign up for drop updates, or otherwise communicate
						with
						us. The types of personal information we may collect include your name, email address, phone
						number, and any other information you choose to provide. To process transactions, we collect
						payment
						information, and to send you your purchased NFT, we collect your public digital wallet
						address.
					</p>

					<h4>Information We Collect Automatically When You Interact with Us</h4>
					<p>
						When you access or use our Website or otherwise interact with us, we automatically collect
						certain information, including:
					</p>
					<ul>
						<li>
							<strong>Transactional Information:</strong> When you make a purchase, we collect
							information about the transaction, such as product details, purchase price, and the date of
							the
							transaction.
						</li>
						<li>
							<strong>Device and Usage Information:</strong> We collect information about how you
							access our Website, including data about the device and network you use, such as your
							hardware
							model, operating system version, mobile network, IP address, unique device identifiers,
							and browser type. We also collect information about your activity on our Website, such as
							access times, pages viewed, links clicked, and the page you visited before navigating to our
							Website.
						</li>
						<li>
							<strong>Information Collected by Cookies and Similar Tracking Technologies:</strong> We
							(and our service providers) use tracking technologies, such as cookies and web beacons, to
							collect information about you. Cookies are small data files stored on your hard drive or
							in device memory that help us improve our Website and your experience, see which areas and
							features of our Website are popular, and count visits. Web beacons (also known as “pixel
							tags” or “clear GIFs”) are electronic images that we use on our Website and in our
							emails to help deliver cookies, count visits, and understand usage and campaign
							effectiveness.
							For
							more information about cookies and how to disable them, see the <a href="#your-choices">Your
							Choices</a> section below.
						</li>
					</ul>

					<h4>Information We Collect from Other Sources</h4>
					<p>
						We obtain information from third-party sources. For example, we may collect information
						about you from Social Networks, Ad Networks, SSPs, DSPs, DMPs, DFPs and other ad and data
						related
						partners .
					</p>

					<h4>Information We Derive</h4>
					<p>
						We may derive information or draw inferences about you based on the information we collect.
						For example, we may make inferences about your location based on your IP address or infer that
						you are on a specific device based on your user agent.
					</p>

					<a id="use-of-information"/>
					<h3>Use Of Information</h3>
					<p>
						We use the information we collect to provide our Website and administer your account. We
						also use the information we collect to:
					</p>

					<ul>
						<li>
							Ex Populus uses information for any of the following purposes: Advertising (either
							personalized advertising on other sites or on the Ex Populus website itself), Contests,
							sweepstakes, promotions, or similar Loyalty / points program
						</li>
						<li>
							Provide, maintain, and improve our products and services;
						</li>
						<li>
							Process transactions and send you related information, including confirmations,
							receipts, invoices, and customer experience surveys,;
						</li>
						<li>
							Personalize and improve your experience on our Website;
						</li>
						<li>
							Send you technical notices, security alerts, and support and administrative messages;
						</li>
						<li>
							Respond to your comments and questions and provide customer service;
						</li>
						<li>
							Communicate with you about products, services, and events offered by Ex Populus and
							others and provide news and information that we think will interest you (see the <a
							href="#your-choices">Your Choices</a> section below for information about how to opt out
							of these communications at any time);
						</li>
						<li>
							Monitor and analyze trends, usage, and activities in connection with our Website;
						</li>
						<li>
							Detect, investigate, and prevent security incidents and other malicious, deceptive,
							fraudulent, or illegal activity and protect the rights and property of Ex Populus and
							others;
						</li>
						<li>
							Debug to identify and repair errors in our Website;
						</li>
						<li>
							Comply with our legal and financial obligations; and
						</li>
						<li>
							Carry out any other purpose described to you at the time the information was collected.
						</li>
					</ul>

					<a id="sharing-of-information"/>
					<h3>Sharing Of Information</h3>
					<p>
						We share personal information in the following circumstances or as otherwise described in
						this policy:
					</p>

					<div>
						<ul>
							<li>
								We share personal information with vendors, service providers, and consultants that need
								access to personal information in order to perform services for us, such as companies
								that assist us with web hosting, fraud prevention, customer service, and marketing.
							</li>
							<li>
								We may disclose personal information if we believe that disclosure is in accordance
								with, or required by, any applicable law or legal process, including lawful requests by
								public
								authorities to meet national security or law enforcement requirements.
							</li>
							<li>
								We may share personal information if we believe that your actions are inconsistent with
								our user agreements or policies, if we believe that you have violated the law, or if we
								believe it is necessary to protect the rights, property, and safety of Ex Populus, our
								users,
								the public, or others.
							</li>
							<li>
								We share personal information with our lawyers and other professional advisors where
								necessary to obtain advice or otherwise protect and manage our business interests.
							</li>
							<li>
								We may share personal information in connection with, or during negotiations concerning,
								any merger, sale of company assets, financing, or acquisition of all or a portion of our
								business by another company.
							</li>
							<li>
								Personal information is shared between and among Ex Populus and our current and future
								parents, affiliates, and subsidiaries and other companies under common control and
								ownership.
							</li>
							<li>
								We share personal information with your consent or at your direction.
							</li>
						</ul>
					</div>

					<p>
						We also share aggregated or de-identified information that cannot reasonably be used to
						identify you.
					</p>

					<a id="analytics"/>
					<h3>Analytics</h3>
					<p>
						We allow others to provide analytics services on our behalf across the web. These entities
						may use cookies, web beacons, device identifiers, and other technologies to collect information
						about your use of our Website, including your IP address, web browser, pages viewed, time
						spent on pages, links clicked, and conversion information. This information may be used by Ex
						Populus and others to, among other things, analyze and track data, determine the popularity of
						certain content, and better understand your online activity.
					</p>

					<a id="transfer-information-usa-and-other-countries"/>
					<h3>Transfer Of Information To The United States And Other Countries</h3>
					<p>
						Ex Populus is headquartered in the United States, and we have operations and service
						providers in the United States and other countries. Please note that we and our service
						providers
						may
						transfer your personal information to, or store or access it in, jurisdictions that may not
						provide levels of data protection that are equivalent to those of your home jurisdiction.
					</p>

					<a id="your-choices"/>
					<h3>Your Choices</h3>
					<h4>Account Information</h4>
					<p>
						You may update and correct certain account information at any time by logging into your
						account. If you wish to delete your account, please email us at hello@expopulus.com, but note
						that
						we
						may retain certain information as required by law or for our legitimate business purposes.
					</p>

					<h4>Cookies</h4>
					<p>
						Most web browsers are set to accept cookies by default. If you prefer, you can usually
						adjust your browser settings to remove or reject browser cookies. Please note that removing or
						rejecting cookies could affect the availability and functionality of our Website.
					</p>

					<h4>Communications Preferences</h4>
					<p>
						You may opt out of receiving text messages or promotional emails from Ex Populus by
						following the instructions in those communications. If you opt out, we may still send you
						non-promotional emails, such as those about your account or our ongoing business relations.
					</p>

					<a id="your-california-privacy-rights"/>
					<h3>Your California Privacy Rights</h3>
					<h4>California Shine the Light Law</h4>
					<p>
						California’s Shine the Light law (Cal. Civ. Code § 1798.83) permits residents of California
						to request certain details about how their information is shared with third parties for direct
						marketing purposes. If you are a California resident and would like to make such a request,
						please contact us at hello@expopulus.com.
					</p>

					<a id="additional-disclosures-for-individuals-in-europe"/>
					<h3>
						Additional Disclosures For Individuals In Europe
					</h3>
					<p>
						If you are located in the European Economic Area (EEA), the United Kingdom, or Switzerland,
						you have certain rights and protections under the law regarding the processing of your personal
						data, and this section applies to you.
					</p>

					<h4>Legal Basis for Processing</h4>
					<p>
						When we process your personal data, we will do so in reliance on the following lawful bases:
					</p>

					<ul>
						<li>
							To perform our responsibilities under our contract with you (e.g., providing the
							products and services you requested).
						</li>
						<li>
							When we have a legitimate interest in processing your personal data to operate our
							business or protect our interests (e.g., to provide, maintain, and improve our products and
							services, conduct data analytics, and communicate with you).
						</li>
						<li>
							To comply with our legal obligations (e.g., to maintain a record of your consents and
							track those who have opted out of marketing communications).
						</li>
						<li>
							When we have your consent to do so (e.g., when you opt in to receive marketing
							communications from us). When consent is the legal basis for our processing your
							personal data, you may withdraw such consent at any time.
						</li>
					</ul>

					<h4>Data Retention</h4>
					<p>
						We store personal data for as long as necessary to carry out the purposes for which we
						originally collected it and for other legitimate business purposes, including to meet our legal,
						regulatory, or other compliance obligations.
					</p>

					<h4>Data Subject Requests</h4>
					<p>
						Subject to certain limitations, you have the right to request access to the personal data we
						hold about you and to receive your data in a portable format, the right to ask that your
						personal data be corrected or erased, and the right to object to, or request that we
						restrict, certain processing. If you would like to exercise any of these rights, email us at
						hello@expopulus.com.
					</p>

					<h4>Questions or Complaints</h4>
					<p>
						If you have a concern about our processing of personal data that we are not able to resolve,
						you have the right to lodge a complaint with the Data Protection Authority where you reside.
						Contact details for your Data Protection Authority can be found using the links below:
					</p>

					<div className={"info-link"}>
						For individuals in the EEA:
						<a
							href="https://edpb.europa.eu/about-edpb/board/members_en"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://edpb.europa.eu/about-edpb/board/members_en
						</a>
					</div>
					<div className={"info-link"}>
						For individuals in the UK:
						<a
							href="https://ico.org.uk/global/contact-us/"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://ico.org.uk/global/contact-us/
						</a>
					</div>
					<div className={"info-link"}>
						For individuals in Switzerland:
						<a
							href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
						</a>
					</div>

					<a id="contact-us"/>
					<h3>Contact Us</h3>
					<p>
						If you have any questions about this Privacy Policy, please contact us at
						hello@expopulus.com.
					</p>

					<ExPopulusButton
						to="/contact"
						color="pink"
					>
						Contact Us
					</ExPopulusButton>
				</div>
			</div>
		</section>
	);
};

export default PrivacyPolicyPage;
