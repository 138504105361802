import React from "react";
import colors from "../style/colors";
import {ExPopulusButton, ExPopulusButtonColor} from "../components/buttons/ExPopulusButton";

const exPopulusButtonColors = Object.values<ExPopulusButtonColor>({
	pink: "pink",
	"white-on-pink": "white-on-pink",
	"pink-outline": "pink-outline",
	"pink-gradient": "pink-gradient",
	purple: "purple",
	success: "success",
	cancel: "cancel",
	error: "error",
	"dark-on-white": "dark-on-white",
	"white-on-dark": "white-on-dark",
	white: "white",
	"white-outline": "white-outline",
	"pink-on-gray": "pink-on-gray",
});

export const ExPopulusStyleGuide: React.FC = () => {
	return (
		<section className="ex-populus-style-guide">
			<h1>ExPopulus Style Guide</h1>

			<div className="ex-populus-style-guide_colors">
				<h2>Colors</h2>
				<br/>

				<div className="ex-populus-style-guide_colors_list">
					<h3>Primary</h3>
					<div>
						{Object.entries(colors).map((item, index) => {
							if (index < 1) {
								return (
									<div key={`${item[0]}`}>
										<div style={{
											border: "1px solid white",
											backgroundColor: item[1],
											width: "130px",
											height: "130px"
										}}/>
										<p>{item[0]}</p>
									</div>
								)
							}
						})}
					</div>

					<h3>Secondary</h3>
					<div>
						{Object.entries(colors).map((item, index) => {
							if (index >= 1 && index < 7) {
								return (
									<div key={`${item[0]}`}>
										<div style={{
											border: "1px solid white",
											backgroundColor: item[1],
											width: "130px",
											height: "130px"
										}}/>
										<p>{item[0]}</p>
									</div>
								)
							}
						})}
					</div>

					<h3>Tertiary</h3>
					<div>
						{Object.entries(colors).map((item, index) => {
							if (index >= 7) {
								return (
									<div key={`${item[0]}`}>
										<div style={{
											border: "1px solid white",
											backgroundColor: item[1],
											width: "130px",
											height: "130px"
										}}/>
										<p>{item[0]}</p>
									</div>
								)
							}
						})}
					</div>
				</div>
			</div>

			<div>
				<h2><u>Headings</u></h2>

				<br/>

				<h1>Heading 1</h1>
				<h2>Heading 2</h2>
				<h3>Heading 3</h3>
				<h4>Heading 4</h4>
				<h5>Heading 5</h5>
				<h6>Heading 6</h6>
				<br/>
				<p>Paragraph</p>
				<span>Span</span>
			</div>

			<div>
				<h2><u>Fonts</u></h2>

				<br/>

				<h3 className="good-headline-web-pro-wide_news">Good Headline Web Pro Wide News</h3>
				<h3 className="good-headline-web-pro-wide_bold">Good Headline Web Pro Wide Bold</h3>
				<h3 className="good-headline-web-pro-wide_black">Good Headline Web Pro Wide Black</h3>

				<br/>

				<h3 className="good-pro-wide">Good Pro Wide</h3>
				<h3 className="good-pro-wide_news">Good Pro Wide News</h3>
				<h3 className="good-pro-wide_regular">Good Pro Wide Regular</h3>
				<h3 className="good-pro-wide_medium">Good Pro Wide Medium</h3>
				<h3 className="good-pro-wide_bold">Good Pro Wide Bold</h3>
				<h3 className="good-pro-wide_black">Good Pro Wide Black</h3>

				<br/>

				<h3 className="good-pro-base">Good Pro</h3>
				<h3 className="good-pro_news">Good Pro News</h3>
				<h3 className="good-pro_bold">Good Pro Bold</h3>
				<h3 className="good-pro_black">Good Pro Black</h3>

				<br/>

				<h3 className="blank-river">Blank River</h3>
			</div>
			<hr/>

			<div className="ex-populus-style-guide_buttons">
				<h2><u>Buttons</u></h2>

				<div>
					{exPopulusButtonColors.map((item) => {
						return (
							<ExPopulusButton
								key={`button-${item}`}
								color={item as ExPopulusButtonColor}
								onClick={() => alert(`${item} button clicked`)}
							>
								{item}
							</ExPopulusButton>
						);
					})}
				</div>

				<h3><u>Buttons: disabled</u></h3>

				<div>
					{exPopulusButtonColors.map((item) => {
						return (
							<ExPopulusButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								key={`button-disabled-${item}`}
								color={item as ExPopulusButtonColor}
								forwardProps={{disabled: true}}
							>
								{item}
							</ExPopulusButton>
						);
					})}
				</div>
			</div>
		</section>
	);
}

