"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetAllOfToJSON = exports.AssetAllOfFromJSONTyped = exports.AssetAllOfFromJSON = exports.instanceOfAssetAllOf = void 0;
var runtime_1 = require("../runtime");
var CloudinaryInfo_1 = require("./CloudinaryInfo");
/**
 * Check if a given object implements the AssetAllOf interface.
 */
function instanceOfAssetAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "fileName" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "pipeUrl" in value;
    isInstance = isInstance && "sourcePipeUrl" in value;
    isInstance = isInstance && "redirectUrl" in value;
    isInstance = isInstance && "sourceRedirectUrl" in value;
    isInstance = isInstance && "base64Url" in value;
    isInstance = isInstance && "sourceBase64Url" in value;
    isInstance = isInstance && "cacheUrl" in value;
    isInstance = isInstance && "sourceCacheUrl" in value;
    return isInstance;
}
exports.instanceOfAssetAllOf = instanceOfAssetAllOf;
function AssetAllOfFromJSON(json) {
    return AssetAllOfFromJSONTyped(json, false);
}
exports.AssetAllOfFromJSON = AssetAllOfFromJSON;
function AssetAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fileName': json['fileName'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'contentType': json['contentType'],
        'size': json['size'],
        'url': json['url'],
        'pipeUrl': json['pipeUrl'],
        'sourcePipeUrl': json['sourcePipeUrl'],
        'redirectUrl': json['redirectUrl'],
        'sourceRedirectUrl': json['sourceRedirectUrl'],
        'base64Url': json['base64Url'],
        'sourceBase64Url': json['sourceBase64Url'],
        'cacheUrl': json['cacheUrl'],
        'sourceCacheUrl': json['sourceCacheUrl'],
        'cloudinary': !(0, runtime_1.exists)(json, 'cloudinary') ? undefined : (0, CloudinaryInfo_1.CloudinaryInfoFromJSON)(json['cloudinary']),
    };
}
exports.AssetAllOfFromJSONTyped = AssetAllOfFromJSONTyped;
function AssetAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fileName': value.fileName,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
        'size': value.size,
        'url': value.url,
        'pipeUrl': value.pipeUrl,
        'sourcePipeUrl': value.sourcePipeUrl,
        'redirectUrl': value.redirectUrl,
        'sourceRedirectUrl': value.sourceRedirectUrl,
        'base64Url': value.base64Url,
        'sourceBase64Url': value.sourceBase64Url,
        'cacheUrl': value.cacheUrl,
        'sourceCacheUrl': value.sourceCacheUrl,
        'cloudinary': (0, CloudinaryInfo_1.CloudinaryInfoToJSON)(value.cloudinary),
    };
}
exports.AssetAllOfToJSON = AssetAllOfToJSON;
