"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomLinkToJSON = exports.CustomLinkFromJSONTyped = exports.CustomLinkFromJSON = exports.instanceOfCustomLink = void 0;
/**
 * Check if a given object implements the CustomLink interface.
 */
function instanceOfCustomLink(value) {
    var isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
exports.instanceOfCustomLink = instanceOfCustomLink;
function CustomLinkFromJSON(json) {
    return CustomLinkFromJSONTyped(json, false);
}
exports.CustomLinkFromJSON = CustomLinkFromJSON;
function CustomLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'text': json['text'],
        'url': json['url'],
    };
}
exports.CustomLinkFromJSONTyped = CustomLinkFromJSONTyped;
function CustomLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'text': value.text,
        'url': value.url,
    };
}
exports.CustomLinkToJSON = CustomLinkToJSON;
