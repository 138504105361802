"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagToJSON = exports.FeatureFlagFromJSONTyped = exports.FeatureFlagFromJSON = exports.instanceOfFeatureFlag = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the FeatureFlag interface.
 */
function instanceOfFeatureFlag(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
exports.instanceOfFeatureFlag = instanceOfFeatureFlag;
function FeatureFlagFromJSON(json) {
    return FeatureFlagFromJSONTyped(json, false);
}
exports.FeatureFlagFromJSON = FeatureFlagFromJSON;
function FeatureFlagFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'key': json['key'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'status': json['status'],
    };
}
exports.FeatureFlagFromJSONTyped = FeatureFlagFromJSONTyped;
function FeatureFlagToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'key': value.key,
        'description': value.description,
        'status': value.status,
    };
}
exports.FeatureFlagToJSON = FeatureFlagToJSON;
