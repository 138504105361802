import {ApiError} from "client/cs";
import React, {ReactNode} from "react";
import {removeError} from "../../../redux/meta/MetaActions";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";

interface IProps {
    error: ApiError;
    index: number;
}

const ErrorModal: React.FC<ErrorModalProps> = (props: ErrorModalProps) => {

    function dismiss(): void {
        props.dispatch(removeError(props.index));
    }

    async function createErrors(_error: ApiError, i: number): Promise<ReactNode> {
        return (
            <li key={`error_${i}`}>
                {_error.message}
                {Array.isArray(_error.errors) && (
                    <ul>
                        {_error.errors.map(createErrors)}
                    </ul>
                )}
            </li>
        );
    }

    return (
        <Modal
            isOpen={true}
            toggle={dismiss}
        >
            <ModalHeader
                title="Error"
                toggle={dismiss}
            >
                Error
            </ModalHeader>

            <ModalBody>
                <b>{props.error.message}</b>
                {props.error.errors && props.error.errors.length > 0 && (
                    <ul>
                        {props.error.errors.map(createErrors)}
                    </ul>
                )}
            </ModalBody>

            <ModalFooter>
                <ExPopulusButton
                    color="pink"
                    onClick={dismiss}
                    className="w-100"
                >
                    Dismiss
                </ExPopulusButton>
            </ModalFooter>
        </Modal>
    );
};

const connector = connect((store: IStore, props: IProps) => props);

type ErrorModalProps = ConnectedProps<typeof connector>;

export default connector(ErrorModal);
