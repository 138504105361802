import React from "react";
import {User} from "client/cs";
import {GAMESIGHT_EVENTS} from "shared";
import {TokenType} from "../utils/TokenType";
import {sendEvent} from "../utils/event-tracking";

export enum MarketName {
	MAGIC_EDEN = "magicEden",
	OPENSEA = "opensea",
}

const markets = {
	[MarketName.MAGIC_EDEN]: {
		name: "Magic Eden",
		event: GAMESIGHT_EVENTS.DAG_WEB_SECONDARY_MARKET_MAGICEDEN,
		logo: {
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/buy%20now/MagicEden%402x.jpg",
			altText: "Magic Eden",
		},
	},
	[MarketName.OPENSEA]: {
		name: "OpenSea",
		event: GAMESIGHT_EVENTS.DAG_WEB_SECONDARY_MARKET_OPENSEA,
		logo: {
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/buy%20now/OpenSea%402x.jpg",
			altText: "OpenSea",
		},
	},
};

export interface IMarketButton {
	currentUser?: User;
	tokenType?: TokenType;
	marketName: MarketName,
	url: URL,
	onClickCallback?: () => void,
}

const MarketButton: React.FC<IMarketButton> = (props) => {
	const market = markets[props.marketName]
	if (!market) {
		return null;
	}

	const onClick = (marketEvent: GAMESIGHT_EVENTS) => () => {
		// always send the generic secondary market event
		sendEvent({
			eventName: GAMESIGHT_EVENTS.DAG_WEB_SECONDARY_MARKET,
			user: props.currentUser,
		});

		// then send the specific market event
		sendEvent({ eventName: marketEvent, user: props.currentUser });

		// then run any callback code
		props.onClickCallback();
	}

	return (
		<a
			href={props.url.href}
			target={"_blank"}
			rel={"noopener noreferrer"}
			className={"market-button"}
			onClick={onClick(market.event)}
		>
			<div className={"logo"}>
				<img src={market.logo.url} alt={market.logo.altText}/>
			</div>

			<div className={"name"}>{market.name}</div>
		</a>
	);
}

MarketButton.defaultProps = {
	onClickCallback: () => {},
}

export { MarketButton };
