import React from "react";

interface CardElementData {
	title: string,
	text: string,
}

interface ComponentContent {
	bullets: Array<CardElementData>;
}

const componentContent = {
	bullets: [
		{
			title: "Battle Enemies",
			text: "Players will use free limited edition cards, summoned companions, mutates and relics to build increasingly powerful battle decks. The best players will dominate the competition.",
		},
		// {
		// 	title: "Win Cash Prizes",
		// 	text: "When you evolve to your Final Form, you join the _______ Guild, and receive prizes from the _______ Guild hoard.",
		// },
		{
			title: "Evolve Cards",
			text: "Players will use Chromos — a reward from their battles — to fuel card evolution to Legendary Rarity. Evolution demands sacrifice, creating scarcity as players burn cards to evolve.",
		},
	],
} satisfies ComponentContent;

export const FinalFormOverview: React.FC = () => {
	const bulletItem = (item: CardElementData, index: number) => {
		return (
			<div className="final-form-overview_container_content_wrapper" key={`final-form-overview_content-${index}`}>
				<h4>{item.title}</h4>
				<span>{item.text}</span>
			</div>
		);
	}

	return (
		<section className="final-form-overview">
			<div className="final-form-overview_footer-bg">
				<img
					src="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967066/website/images/final-form/2._pledge_bg_y6vpva.png"
					alt="footer image"
				/>
			</div>

			<div className="final-form-overview_card">
				<img
					src="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967066/website/images/final-form/2._card_glow_wn5u3y"
					alt="card"
				/>
			</div>

			<div className="final-form-overview_container">

				<h2 className="final-form-overview_container_title">
					A Rogue-Lite Auto-Battler for
					<span>Card Traders</span>
				</h2>
				<div className="final-form-overview_container_content">
					{componentContent.bullets.map(bulletItem)}
				</div>
			</div>
		</section>
	);
};
