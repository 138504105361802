import React from "react";
import {Container} from "reactstrap";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

const dataElement = {
    logoDesktop: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565482/website/images/dag/hero/dag_header_1_bzrrjp.svg"),
    logoMobile: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565482/website/images/dag/hero/dag_header_2_vyzbpr.svg"),
    cards: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565483/website/images/dag/hero/dag-cards_2x_tytist.png"),
    imageLeft: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565483/website/images/dag/hero/left-dag_2x_bqlfyr.png"),
    imageCenter: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565482/website/images/dag/hero/main-dag_2x_skbbr1.png"),
    imageRight: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1659565483/website/images/dag/hero/right-dag_2x_mgqbzc.png"),
}

const DagHomepageHero: React.FC = () => {
    return (
        <section className={"homepage-template-hero"}>
            <div className={"homepage-template-hero_darkOverlay"}/>
            <div className={"homepage-template-hero_purpleOverlay"}/>

            <Container className={"container"}>
                <div className={"homepage-template-hero_container"}>
                    <div className={"homepage-template-hero_logo"}>
                        <div className={"homepage-template-hero_logo_mobile"}>
                            <div className={"homepage-template-hero_header text-center position-relative"}>
                                <MediaAssetViewer
                                    asset={dataElement.logoMobile}
                                    imageAltText={"Hero Logo"}
                                    contentType={"image"}
                                    elementWidth={{
                                        desktopWidth: 837,
                                        mobileWidth: 696,
                                    }}
                                />
                            </div>
                        </div>

                        <div className={"homepage-template-hero_logo_desktop"}>
                            <div className={"homepage-template-hero_header text-center position-relative"}>
                                <MediaAssetViewer
                                    asset={dataElement.logoDesktop}
                                    imageAltText={"Hero Logo"}
                                    contentType={"image"}
                                    elementWidth={{
                                        desktopWidth: 837,
                                        mobileWidth: 696,
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"homepage-template-hero_container_cards"}>
                        <MediaAssetViewer
                            asset={dataElement.cards}
                            imageAltText={"Hero Cards"}
                            contentType={"image"}
                            elementWidth={{
                                desktopWidth: 1416,
                                mobileWidth: 696,
                            }}
                        />
                    </div>

                    <div className={"homepage-template-hero_container_dag-1"}>
                        <MediaAssetViewer
                            asset={dataElement.imageLeft}
                            imageAltText={"Hero Image Left"}
                            contentType={"image"}
                            elementWidth={{
                                desktopWidth: 820,
                                mobileWidth: 500,
                            }}
                        />
                    </div>

                    <div className={"homepage-template-hero_container_dag-2"}>
                        <MediaAssetViewer
                            asset={dataElement.imageCenter}
                            imageAltText={"Hero Image Center"}
                            contentType={"image"}
                            elementWidth={{
                                desktopWidth: 1091,
                                mobileWidth: 696,
                            }}
                        />
                    </div>

                    <div className={"homepage-template-hero_container_dag-3"}>
                        <MediaAssetViewer
                            asset={dataElement.imageRight}
                            imageAltText={"Hero Image Right"}
                            contentType={"image"}
                            elementWidth={{
                                desktopWidth: 820,
                                mobileWidth: 500,
                            }}
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export {DagHomepageHero};
