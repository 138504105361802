"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TcgEditionToJSON = exports.TcgEditionFromJSONTyped = exports.TcgEditionFromJSON = exports.TcgEdition = void 0;
/**
 *
 * @export
 */
exports.TcgEdition = {
    _1st_Edition: '1st Edition'
};
function TcgEditionFromJSON(json) {
    return TcgEditionFromJSONTyped(json, false);
}
exports.TcgEditionFromJSON = TcgEditionFromJSON;
function TcgEditionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TcgEditionFromJSONTyped = TcgEditionFromJSONTyped;
function TcgEditionToJSON(value) {
    return value;
}
exports.TcgEditionToJSON = TcgEditionToJSON;
