import React from "react";
import {Link} from "react-router-dom";
import {ExPopulusButton} from "../buttons/ExPopulusButton";


interface IProps {
	agreedCookies?: boolean;
	onAgree: () => void;
	implementingSiteURL: string;
}

export const AgreeCookiePrompt: React.FC<IProps> = (props) => {

	if (props.agreedCookies) {
		return null;
	}

	return (
		<div className="agree-cookie-prompt">
			<div className="agree-cookie-prompt_about">
				This website uses cookies for the best user experience.
				{props.implementingSiteURL === process.env.REACT_APP_WEBSITE_URL ? (
					<Link to="/privacy">Privacy Policy</Link>
				) : (
					<a href={process.env.REACT_APP_WEBSITE_URL + "/privacy"}>
						Privacy Policy
					</a>
				)}
			</div>

			<ExPopulusButton
				color="dark-on-white"
				onClick={props.onAgree}
				className="agree-cookie-prompt_button"
			>
				I AGREE
			</ExPopulusButton>
		</div>
	);
};
