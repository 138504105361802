"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolanaPayTransactionAllOfToJSON = exports.SolanaPayTransactionAllOfFromJSONTyped = exports.SolanaPayTransactionAllOfFromJSON = exports.instanceOfSolanaPayTransactionAllOf = void 0;
var runtime_1 = require("../runtime");
var SolanaPayTransactionOrigin_1 = require("./SolanaPayTransactionOrigin");
/**
 * Check if a given object implements the SolanaPayTransactionAllOf interface.
 */
function instanceOfSolanaPayTransactionAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "isScanned" in value;
    isInstance = isInstance && "identifier" in value;
    return isInstance;
}
exports.instanceOfSolanaPayTransactionAllOf = instanceOfSolanaPayTransactionAllOf;
function SolanaPayTransactionAllOfFromJSON(json) {
    return SolanaPayTransactionAllOfFromJSONTyped(json, false);
}
exports.SolanaPayTransactionAllOfFromJSON = SolanaPayTransactionAllOfFromJSON;
function SolanaPayTransactionAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origin': (0, SolanaPayTransactionOrigin_1.SolanaPayTransactionOriginFromJSON)(json['origin']),
        'isScanned': json['isScanned'],
        'identifier': json['identifier'],
        'references': !(0, runtime_1.exists)(json, 'references') ? undefined : json['references'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
    };
}
exports.SolanaPayTransactionAllOfFromJSONTyped = SolanaPayTransactionAllOfFromJSONTyped;
function SolanaPayTransactionAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origin': (0, SolanaPayTransactionOrigin_1.SolanaPayTransactionOriginToJSON)(value.origin),
        'isScanned': value.isScanned,
        'identifier': value.identifier,
        'references': value.references,
        'data': value.data,
    };
}
exports.SolanaPayTransactionAllOfToJSON = SolanaPayTransactionAllOfToJSON;
