import {gsghtSetExternalUserId} from "shared";
import {MetaAction} from "./MetaReducer";
import {IAction} from "../IAction";
import {ApiError, Token, User} from "client/cs";
import {isAPIError} from "../../utils/isAPIError";

export function login(fullToken: Token): IAction<MetaAction.LOGIN, Token> {
	return {
		type: MetaAction.LOGIN,
		payload: fullToken,
	}
}

export function logout(): IAction<MetaAction.LOGOUT> {

	return {
		type: MetaAction.LOGOUT,
	}
}

export function incrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: 1,
	}
}

export function decrementLoading(): IAction<MetaAction.LOADING, number> {
	return {
		type: MetaAction.LOADING,
		payload: -1,
	}
}

const makeDefaultError: () => ApiError = () => ({type: "APP", message: "An unknown error has occurred. Please contact support or try your request again."});

export async function generateFrontendError(error?: {json: () => Promise<ApiError>} | ApiError): Promise<any> {
	let _error: ApiError;

	try {
		if (isAPIError(error)) {
			_error = error as ApiError;
		} else {
			try {
				const jsonResponse = await (error as {json: () => Promise<ApiError>}).json();
				if (isAPIError(jsonResponse)) {
					_error = jsonResponse
				} else {
					_error = makeDefaultError()
				}
			} catch (e) {
				_error = makeDefaultError();
			}
		}
	} catch (e) {
		_error = makeDefaultError();
	}

	return _error;
}

export async function addError(error?: {json: () => Promise<ApiError>} | ApiError): Promise<IAction<MetaAction.ADD_ERROR, any>> {
	return {
		type: MetaAction.ADD_ERROR,
		payload: await generateFrontendError(error),
	}
}

export function removeError(i: number = 0): IAction<MetaAction.REMOVE_ERROR, number> {
	return {
		type: MetaAction.REMOVE_ERROR,
		payload: i,
	}
}

export function toggleSideBar(open: boolean): IAction<MetaAction.SIDEBAR, boolean> {
	return {
		type: MetaAction.SIDEBAR,
		payload: open,
	}
}

export function setCurrentUser(user: User): IAction<MetaAction.UPDATE_CURRENT_USER, User> {
	if (user?.id) {
		gsghtSetExternalUserId({
			type: "mongo_id",
			id: user.id,
		});
	}

	return {
		type: MetaAction.UPDATE_CURRENT_USER,
		payload: user,
	}
}

export function setAgreedCookies(): IAction<MetaAction.AGREE_COOKIES> {
	return {
		type: MetaAction.AGREE_COOKIES,
	}
}


export function toggleShowSuccessfulPasswordResetModal(show: boolean): IAction<MetaAction.PASSWORD_RESET_MODAL_SUCCESS, boolean> {
	return {
		type: MetaAction.PASSWORD_RESET_MODAL_SUCCESS,
		payload: show,
	}
}
