import React, {useEffect} from "react";
import {Container} from "reactstrap";
import GameCard, {IGameCardProps} from "../components/gameCard/GameCard";
import hardcodedGameDetails from "../utils/hardcoded";

const GamesListPage: React.FC = () => {
	// TODO: remove once the container template implements the dark theme
	useEffect(() => {
		document.body.classList.add("page-gradient");

		return () => {
			document.body.classList.remove("page-gradient");
		};
	});

	const gameCards: Array<IGameCardProps> = [
		hardcodedGameDetails["final-form"],
		hardcodedGameDetails["lamoverse"],
		hardcodedGameDetails["carnivale"],
	];

	return (
		<Container className="games-list-page">
			<div className="title">Our Games</div>

			<div className="cards-container">
				{gameCards.map((card) => (<GameCard key={card.id} {...card} />))}
			</div>
		</Container>
	);
}

export default GamesListPage;
