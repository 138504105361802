import React from "react";
import GameDetailsList, {IGameDetail} from "./GameDetailsList";

export interface IProps {
    price?: string;
    playNow?: boolean;
    description: string;
    details: Array<IGameDetail>;
}

const GameDetailsInfoCard: React.FC<IProps> = (props) => (
    <div className="game-details-info-card">
        {props.price && (
            <div className="game-details-info-card_price-section">
                <p className="game-details-info-card_price-section_label">
                    CURRENT PRICE
                </p>

                <p className="game-details-info-card_price-section_price">
                    {props.price}
                </p>
            </div>
        )}

        <div className="game-details-info-card_description-section">
            <p className="game-details-info-card_description-section_label">
                DESCRIPTION
            </p>
            <p className="game-details-info-card_description-section_description">
                {props.description}
            </p>
        </div>

        <div className="game-details-info-card_details">
            <GameDetailsList details={props.details}/>
        </div>
    </div>
);

export default GameDetailsInfoCard;
