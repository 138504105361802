import {Container, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";
import {IFormState} from "../../../utils/formState";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";

interface IProps {
	formState: IFormState;
	onSubmit: () => void;
	toggle: () => void;
	resend: () => void;
}

const FinalFormWindowDownloadConfirmationModal: React.FC<FinalFormWindowDownloadConfirmationModalProps> = (props: FinalFormWindowDownloadConfirmationModalProps) => {

	function _onSubmitHelper(e?): void {
		if (e) {
			e.preventDefault();
		}
		return props.onSubmit();
	}

	return (
		<Container>
			<ModalHeader toggle={props.toggle as any}>
				Check email
			</ModalHeader>

			<ModalBody>
				<p>Please check your email (<span className="text-raz">{`${props.currentUser?.email || props.formState.email?.value}`}</span>) to download Final Form on your PC.</p>

				<ExPopulusButton
					<React.ButtonHTMLAttributes<HTMLButtonElement>>
					color="white-on-pink"

					className="final-form-window-download-modal_button"
					onClick={_onSubmitHelper}
				>
					CONTINUE
				</ExPopulusButton>
			</ModalBody>

			<ModalFooter className="d-flex w-100 justify-content-center">
				<p className="text-center text-black">Didn't get an email? <a className="text-raz" onClick={props.resend}>Resend link</a></p>
			</ModalFooter>
		</Container>
	);
}

const connector = connect((store: IStore, props: IProps) => {
	return {
		currentUser: store.metaStore.currentUser,
		...props,
	}
});

type FinalFormWindowDownloadConfirmationModalProps = ConnectedProps<typeof connector>;

export default connector(FinalFormWindowDownloadConfirmationModal);
