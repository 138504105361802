import React from "react";
import {ExPopulusUniversalNavigationItemFooter} from "../headerManager/universalNavigationLookup";
import ExPopulusFooterNav from "./ExPopulusFooterNav";
import ExPopulusFooterCopyright from "./ExPopulusFooterCopyright";
import ExPopulusFooterSubscribe from "./ExPopulusFooterSubscribe";

interface IProps {
	showSubscribe?: boolean;
	navigationUseLocalLinks?: boolean;
	showNavigation?: boolean;
	workLinks?: Array<ExPopulusUniversalNavigationItemFooter>;
	showCopyright?: boolean;
	onGoToTop?: () => void;
}

const ExPopulusFooterComplete: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			{props.showSubscribe && (
				<ExPopulusFooterSubscribe/>
			)}

			{props.showNavigation && (
				<ExPopulusFooterNav
					useLocalLinks={props.navigationUseLocalLinks}
					workLinks={props.workLinks}
				/>
			)}

			{props.showCopyright && (
				<ExPopulusFooterCopyright onGoToTop={props.onGoToTop}/>
			)}
		</React.Fragment>
	);
};

ExPopulusFooterComplete.defaultProps = {
	showSubscribe: true,
	showNavigation: true,
	showCopyright: true,
};

export {ExPopulusFooterComplete};

