import React, {useEffect, useState} from "react";
import {toggleSideBar} from "../../redux/meta/MetaActions";
import {useLocation} from "react-router";
import {ExPopulusHeader, HeaderBehaviorType} from "./ExPopulusHeader";
import {AccountButtonConfig} from "shared";
import {ExPopulusSideBarManager} from "./ExPopulusSideBarManager";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";

const APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com";

export const hideHeaderFooterOn = [];

const scrollHeaderPaths = [
	"/dags",
	"/ironpigeons",
];

export const HeaderManager: React.FC = () => {
	const location = useLocation();
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const sideBarOpen = useSelector((store: IStore) => store.metaStore.sidebarVisible);
	const dispatch = useDispatch();

	const [headerBehavior, setHeaderBehavior] = useState(HeaderBehaviorType.STICKY);

	useEffect(() => {
		// remove any trailing space for comparison
		const pathname = location.pathname.replace(/\/$/, "");

		// set to STICKY by default
		let behavior = HeaderBehaviorType.STICKY;

		if (hideHeaderFooterOn.includes(pathname)) {
			behavior = HeaderBehaviorType.HIDDEN;
		}

		// Removes sticky setting from the HeaderManager
		if (!!scrollHeaderPaths.find((p) => pathname.startsWith(p))) {
			behavior = HeaderBehaviorType.SCROLL;
		}

		// only update if different to avoid re-renders
		if (behavior !== headerBehavior) {
			setHeaderBehavior(behavior);
		}
	}, [location.pathname.replace(/\/$/, "")]);

	if (headerBehavior === HeaderBehaviorType.HIDDEN) {
		return null;
	}

	function toggleSideBarHelper(): void {
		dispatch(toggleSideBar(!sideBarOpen));
	}

	return (
		<React.Fragment>
			<ExPopulusHeader
				id={"ex-populus-header"}
				implementingSiteURL={APP_WEBSITE_URL}
				onHamburgerClick={toggleSideBarHelper}
				accountButtonConfig={{accountButton: fullToken ? AccountButtonConfig.ACCOUNT : AccountButtonConfig.SIGN_UP}}
				behavior={headerBehavior}
			/>

			<ExPopulusSideBarManager
				sideBarOpen={sideBarOpen}
				toggleSideBar={toggleSideBarHelper}
				sideBarInnerProps={{
					...ExPopulusSideBarManager.defaultProps.sideBarInnerProps,
					implementingSiteURL: APP_WEBSITE_URL,
					accountButtonConfig: {accountButton: fullToken ? AccountButtonConfig.ACCOUNT : AccountButtonConfig.SIGN_UP},
				}}
			/>
		</React.Fragment>
	);
}

HeaderManager.defaultProps = {
	sideBarOpen: false,
};
