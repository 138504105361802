import React, {ReactNode} from "react";
import classNames from "classnames";
import {Link, LinkProps} from "react-router-dom";
import {isWindows} from "react-device-detect";

export type ExPopulusButtonType =
	LinkProps |
	React.AnchorHTMLAttributes<HTMLAnchorElement> |
	React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ExPopulusButtonColor =
	"pink"
	| "white-on-pink"
	| "pink-outline"
	| "pink-gradient"
	| "purple"
	| "success"
	| "cancel"
	| "error"
	| "dark-on-white"
	| "white-on-dark"
	| "white"
	| "white-outline"
	| "pink-on-gray"
	| "white-on-transparent";

interface IProps<T extends ExPopulusButtonType> {
	children?: any;

	color: ExPopulusButtonColor;
	leftIcon?: React.FC;
	rightIcon?: React.FC;
	className?: string;

	to?: string; // When this prop is present, this component will render the <Link/> component from react-router-dom. Takes precedence over `href` prop.
	href?: string; // When this prop is present, this component will render an <a> tag and link to this href.
	onClick?: () => void; // Implement this prop & not `to` or `href` when you want a standard button rendered.
	forwardProps?: Omit<T, "onClick" | "className">;
}

export function ExPopulusButton<T extends ExPopulusButtonType>(props: IProps<T>): JSX.Element {

	const LeftIcon: any = props.leftIcon;
	const RightIcon: any = props.rightIcon;

	function onClickHandler(): void {
		props.onClick?.();
	}

	const dynamicClassNames: string = classNames(
		props.className,
		"exp-button",
		`exp-button_color_${props.color}`,
		{"exp-button_adjust-for-windows": isWindows,}
	);

	const buttonContent: ReactNode = (
		<React.Fragment>
			{props.leftIcon && (
				<LeftIcon className="exp-button_icon"/>
			)}

			{props.children && (
				<span>
					{props.children}
				</span>
			)}

			{props.rightIcon && (
				<RightIcon className="exp-button_icon"/>
			)}
		</React.Fragment>
	);

	if (props.to) {
		return (
			<Link
				{...props.forwardProps as unknown as LinkProps}
				to={props.to}
				onClick={onClickHandler}
				className={dynamicClassNames}
			>
				{buttonContent}
			</Link>
		);
	} else if (props.href) {
		return (
			<a
				{...props.forwardProps as React.AnchorHTMLAttributes<HTMLAnchorElement>}
				href={props.href}
				rel="noopener noreferrer"
				onClick={onClickHandler}
				className={dynamicClassNames}
			>
				<div className="exp-button_link-adjuster">
					{buttonContent}
				</div>
			</a>
		);
	} else {
		return (
			<button
				{...props.forwardProps as React.ButtonHTMLAttributes<HTMLButtonElement>}
				onClick={onClickHandler}
				className={dynamicClassNames}
			>
				{buttonContent}
			</button>
		);
	}
}
