import React, {useEffect} from "react";
import {Container} from "reactstrap";
import { ReactComponent as ArrowIcon } from "../svgs/arrow-right.svg";
import OurBackground from "../components/aboutUs/OurBackground";
import OurPressCoverage from "../components/aboutUs/OurPressCoverage/OurPressCoverage";
import OurStore from "../components/aboutUs/OurStore";
import OurGames from "../components/aboutUs/OurGames";
import AboutHero from "../components/aboutUs/AboutHero";
import OurVision from "../components/aboutUs/OurVision";
import OurInvestors from "../components/aboutUs/OurInvestors";
import OurAccolades from "../components/aboutUs/OurAccolades";
import OurCommunity from "../components/aboutUs/OurCommunity";


const AboutPage: React.FC = () => {

	function scrollToSection(sectionName: string): () => void {
		return () => {
			const section = document.getElementById(sectionName);
			if (section) {
				window.scrollTo(0, section.offsetTop);
			}
		}
	}

	function nextSectionScroll(onClick: () => void): JSX.Element {
		return (
			<div className="anchor-link" onClick={onClick}>
				<div className="anchor-text">
					<span>Next</span>
					<span className="anchor-caret">
						<ArrowIcon />
					</span>
				</div>
			</div>
		);
	}

	return (
		<Container className="about-page">
			<AboutHero id={"hero"} nextSectionScroll={nextSectionScroll(scrollToSection("vision"))} />

			<OurVision id={"vision"} />

			<OurBackground id={"our-background"}/>

			<OurInvestors id={"our-investors"} />

			{/* Waiting for actual copy for this section */}
			{/* <OurAccolades id={"our-accolades"} />*/}

			<OurGames id={"our-games"} />

			<OurStore id={"our-store"} />

			<OurCommunity id={"our-community"} />

			<OurPressCoverage id={"our-press-coverage"} />
		</Container>
	);
}

export default AboutPage;
