import React, {ReactNode, useMemo} from "react";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui";
import {WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {clusterApiUrl} from "@solana/web3.js";
import {getPhantomWallet} from "@solana/wallet-adapter-wallets";

interface IProps {
	children: ReactNode;
}

const PhantomProviderHelper: React.FC<IProps> = (props) => {

	const network: WalletAdapterNetwork = WalletAdapterNetwork.Mainnet;
	const endpoint = useMemo(() => clusterApiUrl(network), [network]);
	const wallets = useMemo(() => [getPhantomWallet()], [network]);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets} autoConnect={false}>
				<WalletModalProvider>
					{props.children}
				</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
};

export default PhantomProviderHelper;
