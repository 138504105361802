"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckIpBodyToJSON = exports.CheckIpBodyFromJSONTyped = exports.CheckIpBodyFromJSON = exports.instanceOfCheckIpBody = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CheckIpBody interface.
 */
function instanceOfCheckIpBody(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCheckIpBody = instanceOfCheckIpBody;
function CheckIpBodyFromJSON(json) {
    return CheckIpBodyFromJSONTyped(json, false);
}
exports.CheckIpBodyFromJSON = CheckIpBodyFromJSON;
function CheckIpBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ip': !(0, runtime_1.exists)(json, 'ip') ? undefined : json['ip'],
    };
}
exports.CheckIpBodyFromJSONTyped = CheckIpBodyFromJSONTyped;
function CheckIpBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ip': value.ip,
    };
}
exports.CheckIpBodyToJSON = CheckIpBodyToJSON;
