import React from "react";
import { User } from "client/cs";
import { MarketButton, MarketName } from "./MarketButton";
import { MarketButtonsContainer } from "./MarketButtonsContainer";
import { TokenType } from "../utils/TokenType";

interface IProps {
  header: string;
  collectionName: string;
  collectionNamePlural: string;
  currentUser?: User;
  disabled?: boolean;
  rarity?: string;
  onClickMarketButton?: () => void;
  marketButtons?: Array<{ marketName: string; url: string }>;
  tokenType?: TokenType;
}

const TemplateCollectionSoldOut: React.FC<IProps> = (props) => (
  <div style={{ width: "100%" }}>
    <h3 className={"homepage-template-buy-now_container_CTA_header"}>
      {props.header}
    </h3>

    <div>
      <div
        className={"homepage-template-buy-now_container_CTA_body-2_sold-out"}
      >
        All {props.rarity} {props.collectionNamePlural} have been sold out!
      </div>

      <div className={"homepage-template-buy-now_container_CTA_body-2"}>
        <p>
          For more information on the next drop, visit our{" "}
          <a
            href="https://discord.gg/xaigames"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
          . Want {props.collectionNamePlural} now? They are available on these
          marketplaces:
        </p>
      </div>
    </div>

    <MarketButtonsContainer>
      {props.marketButtons.map(({ marketName, url }, index) => (
        <MarketButton
          key={`market-button_${index}`}
          marketName={marketName as MarketName}
          url={new URL(url)}
          currentUser={props.currentUser}
          tokenType={props.tokenType}
          onClickCallback={props.onClickMarketButton}
        />
      ))}
    </MarketButtonsContainer>
  </div>
);

TemplateCollectionSoldOut.defaultProps = {
  rarity: "",
  marketButtons: [],
  disabled: true,
  onClickMarketButton: () => {},
};

export { TemplateCollectionSoldOut };
