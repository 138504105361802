"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCollectionsResponseAllOfToJSON = exports.GetCollectionsResponseAllOfFromJSONTyped = exports.GetCollectionsResponseAllOfFromJSON = exports.instanceOfGetCollectionsResponseAllOf = void 0;
var Collection_1 = require("./Collection");
/**
 * Check if a given object implements the GetCollectionsResponseAllOf interface.
 */
function instanceOfGetCollectionsResponseAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "collections" in value;
    return isInstance;
}
exports.instanceOfGetCollectionsResponseAllOf = instanceOfGetCollectionsResponseAllOf;
function GetCollectionsResponseAllOfFromJSON(json) {
    return GetCollectionsResponseAllOfFromJSONTyped(json, false);
}
exports.GetCollectionsResponseAllOfFromJSON = GetCollectionsResponseAllOfFromJSON;
function GetCollectionsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'collections': (json['collections'].map(Collection_1.CollectionFromJSON)),
    };
}
exports.GetCollectionsResponseAllOfFromJSONTyped = GetCollectionsResponseAllOfFromJSONTyped;
function GetCollectionsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'collections': (value.collections.map(Collection_1.CollectionToJSON)),
    };
}
exports.GetCollectionsResponseAllOfToJSON = GetCollectionsResponseAllOfToJSON;
