"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateNftSaleBodyToJSON = exports.CreateNftSaleBodyFromJSONTyped = exports.CreateNftSaleBodyFromJSON = exports.instanceOfCreateNftSaleBody = void 0;
var runtime_1 = require("../runtime");
var DagDropType_1 = require("./DagDropType");
var NftType_1 = require("./NftType");
var WalletType_1 = require("./WalletType");
/**
 * Check if a given object implements the CreateNftSaleBody interface.
 */
function instanceOfCreateNftSaleBody(value) {
    var isInstance = true;
    isInstance = isInstance && "nftType" in value;
    isInstance = isInstance && "walletType" in value;
    isInstance = isInstance && "walletAddress" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
exports.instanceOfCreateNftSaleBody = instanceOfCreateNftSaleBody;
function CreateNftSaleBodyFromJSON(json) {
    return CreateNftSaleBodyFromJSONTyped(json, false);
}
exports.CreateNftSaleBodyFromJSON = CreateNftSaleBodyFromJSON;
function CreateNftSaleBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftType': (0, NftType_1.NftTypeFromJSON)(json['nftType']),
        'dagDropType': !(0, runtime_1.exists)(json, 'dagDropType') ? undefined : (0, DagDropType_1.DagDropTypeFromJSON)(json['dagDropType']),
        'walletType': (0, WalletType_1.WalletTypeFromJSON)(json['walletType']),
        'walletAddress': json['walletAddress'],
        'whitelistNftUuid': !(0, runtime_1.exists)(json, 'whitelistNftUuid') ? undefined : json['whitelistNftUuid'],
        'txHash': !(0, runtime_1.exists)(json, 'txHash') ? undefined : json['txHash'],
        'quantity': json['quantity'],
    };
}
exports.CreateNftSaleBodyFromJSONTyped = CreateNftSaleBodyFromJSONTyped;
function CreateNftSaleBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftType': (0, NftType_1.NftTypeToJSON)(value.nftType),
        'dagDropType': (0, DagDropType_1.DagDropTypeToJSON)(value.dagDropType),
        'walletType': (0, WalletType_1.WalletTypeToJSON)(value.walletType),
        'walletAddress': value.walletAddress,
        'whitelistNftUuid': value.whitelistNftUuid,
        'txHash': value.txHash,
        'quantity': value.quantity,
    };
}
exports.CreateNftSaleBodyToJSON = CreateNftSaleBodyToJSON;
