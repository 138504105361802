import {applyMiddleware, combineReducers, createStore, Reducer, Store, StoreEnhancer} from "redux";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import {persistReducer, persistStore} from "redux-persist";
import promiseMiddleware from "redux-promise";
import MetaReducer from "./meta/MetaReducer";
import defaultStore, {IStore} from "./defaultStore";
import WalletReducer from "./wallets/WalletReducer";
import {IAction} from "./IAction";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "cookies-js";

/**
 * Create Persist Configuration.
 */
const metaStorePersistConfig = {
    key: "meta",
    storage: new CookieStorage(Cookies, {
        setCookieOptions: {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
        }
    }),
    whitelist: ["fullToken", "currentUser", "agreedCookies"],
};

const walletStorePersistConfig = {
    key: "walletStore",
    storage,
    whitelist: ["autoConnectPhantom"],
};

/**
 * Combine Reducers
 */
const reducers: Reducer<IStore, IAction> = combineReducers({
    metaStore: persistReducer(metaStorePersistConfig, MetaReducer),
    walletStore: persistReducer(walletStorePersistConfig, WalletReducer),
});

/**
 * Enable Middleware
 */
const middleWare: StoreEnhancer = applyMiddleware(
    // logger,
    promiseMiddleware,
);

export const store: Store<IStore> = createStore(reducers, defaultStore, middleWare);
export const persistor = persistStore(store);
