"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationToJSON = exports.LocationFromJSONTyped = exports.LocationFromJSON = exports.instanceOfLocation = void 0;
/**
 * Check if a given object implements the Location interface.
 */
function instanceOfLocation(value) {
    var isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfLocation = instanceOfLocation;
function LocationFromJSON(json) {
    return LocationFromJSONTyped(json, false);
}
exports.LocationFromJSON = LocationFromJSON;
function LocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'code': json['code'],
        'name': json['name'],
    };
}
exports.LocationFromJSONTyped = LocationFromJSONTyped;
function LocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'code': value.code,
        'name': value.name,
    };
}
exports.LocationToJSON = LocationToJSON;
