import React from "react";
import MediaAssetViewer from "../../mediaAssetViewer/MediaAssetViewer";

export const FinalFormHero: React.FC = () => {
	return (
		<div className="final-form-hero">
			<div className="final-form-hero_container">
				<MediaAssetViewer
					asset={new URL("https://res.cloudinary.com/ex-populus/video/upload/f_auto/q_100/v1682967069/website/images/final-form/1._ff_trailer_viz7lr.mp4")}
					imageAltText={"Preview"}
					videoControls={false}
					videoAutoPlay={true}
					videoMuted={true}
					videoPlaysInline={true}
					videoLoop={true}
					elementWidth={{
						desktopWidth: 1920,
						mobileWidth: 1920,
					}}
				/>
			</div>
		</div>
	)
}
