import colors from "./colors.module.scss";

interface IColors {
	black: string;
	raz: string;
	whitesmoke: string;
	silver: string;
	deepSpace: string;
	offBlack: string;
	deepBlack: string;
	fadedDeepSpace: string;
	lightDeepSpace: string;
	darkGrey: string;
	fadedBlue: string;
	purple: string;
	lightBlueGray: string;
	formFieldError: string;
	formFieldOk: string;
}

export default colors as unknown as IColors;
