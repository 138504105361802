import React from "react";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";

interface FinalFormBurnToEvolveProps {
	onClick: () => void;
}

export const FinalFormBurnToEvolve: React.FC<FinalFormBurnToEvolveProps> = (props: FinalFormBurnToEvolveProps) => {
	return (
		<section className="final-form-burn-to-evolve">
			<div className="final-form-burn-to-evolve_container">
				<div className="final-form-burn-to-evolve_container_body">
					<h2>Burn-To-Evolve</h2>
					<p>
						Upgrade your cards through evolution by combining Chromos and lower-tier cards to acquire higher
						powered cards. As card supplies dwindle and rarity rises, collectors and traders will find their
						cards more valuable in the dynamic gaming economy.
					</p>
					<div className="final-form-burn-to-evolve_container_body_button">
						<ExPopulusButton
							color="white"
							onClick={props.onClick}
						>
							GET CARDS
						</ExPopulusButton>
					</div>
				</div>

				<div className="final-form-burn-to-evolve_container_content">
					<div className="final-form-burn-to-evolve_container_content_image">
						<picture className="final-form-burn-to-evolve_container_content_image_style">
							<source
								srcSet="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683659729/website/images/final-form/5._evolve_image_alt_ot6tzu"
								media="(min-width:992px)"
							/>
							<img
								src="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683659730/website/images/final-form/5._evolve_image_mobile_alt_u01egt"
								alt="burn to evolve"
								draggable={false}
							/>
						</picture>
					</div>

				</div>
			</div>
		</section>
	)
}
