import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {WalletType, WalletsApi} from "client/cs";
import classNames from "classnames";
import {IoMdRefresh} from "react-icons/all";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getCsConfig from "../utils/getCsConfig";
import {useWallet} from "@solana/wallet-adapter-react";
import {WalletName} from "@solana/wallet-adapter-wallets";
import TagManager from "react-gtm-module";
import {GoogleTagManagerEvents} from "../utils/GoogleTagManagerEvents";
import HeaderContainer from "./containers/HeaderContainer";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

const PhantomLinkedIndicator: React.FC = () => {
	const walletContextState = useWallet(); //Phantom
	const [runActivatePhantom, setRunActivatePhantom] = useState(false);
	const [noPhantomLock, setNoPhantomLock] = useState(false);
	const [walletIsLinked, setWalletIsLinked] = useState<{ [key: string]: boolean }>({});
	const [phantomWalletForceKey, setPhantomWalletForceKey] = useState(0);
	const [localLoading, setLocalLoading] = useState(false);
	const address = useMemo(() => walletContextState?.publicKey?.toBase58(), [walletContextState]);
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const dispatch = useDispatch();

	/**
	 * Listens for changes to phantomWalletForceKey, which,
	 * as the name implies, is a variable we increment
	 * just to force re-renders & to check if the
	 * activatePhantom function should run again in
	 * combination with runActivatePhantom.
	 *
	 */
	useEffect(() => {
		if (phantomWalletForceKey !== 0 && runActivatePhantom && !noPhantomLock) {
			activatePhantom().catch((e) => console.error(e));
		} else if (noPhantomLock) {
			setNoPhantomLock(false);
		}
	}, [phantomWalletForceKey]);

	/**
	 * Once an address is present, we want to check
	 * our backend to see if it is connected.
	 *
	 */
	useEffect(() => {
		if (address && typeof address === "string" && address?.length > 0) {
			validateWalletIsConnected().catch((e) => console.error(e));
		}
	}, [address]);

	/**
	 * Try to activate the Phantom prompt for the user to connect their wallet.
	 * The connect() function fails every other time due to the library's handling of the "ready" status,
	 * so we enter the first catch & update setPhantomWalletForceKey to force a re-render so the function
	 * runs again properly.
	 *
	 */
	async function activatePhantom(): Promise<void> {
		setLocalLoading(true);

		try {
			setRunActivatePhantom(true);
			await walletContextState.select(WalletName.Phantom);

			try {
				await walletContextState.connect();

				TagManager.dataLayer({
					dataLayer: {
						event: GoogleTagManagerEvents.CONNECT_PHANTOM,
					},
				});
			} catch (err) {
				if (!("solana" in window)) {
					setPhantomWalletForceKey(phantomWalletForceKey + 1);
					setNoPhantomLock(true);
				} else if (!walletContextState.ready) {
					setPhantomWalletForceKey(phantomWalletForceKey + 1);
				} else {
					await dispatch(addError({type: "APP", message: "You must connect a web3 provider in order to do this. Try googling Phantom!"}));
				}
			} finally {
				setRunActivatePhantom(false);
			}
		} catch (e) {
			await dispatch(addError({type: "APP", message: "You must connect a web3 provider in order to do this. Try googling Phantom!"}));
		}

		setLocalLoading(false);
	}

	/**
	 * Used to refresh the phantom wallet status / address,
	 * as the library does no provide live-updating hooks for us as of yet.
	 *
	 */
	async function reactivatePhantom(): Promise<void> {
		if ("solana" in window) {
			try {
				await walletContextState.disconnect();
				setRunActivatePhantom(true);
				setPhantomWalletForceKey(phantomWalletForceKey + 1)
			} catch (e) {
			}
		} else {
			window.location.reload();
		}
	}

	/**
	 * Call our api to determine if their wallet is linked,
	 * save result to state variable.
	 *
	 */
	async function validateWalletIsConnected(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new WalletsApi(getCsConfig(fullToken)).isWalletLinked({
				isWalletLinkedBody: {
					address: address,
				},
			});

			setWalletIsLinked({
				...walletIsLinked,
				[address]: res.linked,
			});
		} catch (e) {
			await dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	/**
	 * Call api to link wallet.
	 *
	 */
	async function linkWallet(): Promise<void> {
		dispatch(incrementLoading());

		try {
			await new WalletsApi(getCsConfig(fullToken)).addWallet({
				addWalletBody: {
					type: WalletType.SOLANA,
					address,
				},
			});

			TagManager.dataLayer({
				dataLayer: {
					event: GoogleTagManagerEvents.LINK_PHANTOM,
					address,
				},
			});

			await validateWalletIsConnected();
		} catch (e) {
			await dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	function statusDisplayHelper(): "pending" | "ready" {
		if (!address) {
			return "pending"
		} else if (address && !walletIsLinked[address]) {
			return "pending"
		} else if (address && walletIsLinked[address]) {
			return "ready"
		} else {
			return "pending"
		}
	}

	return (
		<HeaderContainer
			title="Phantom"
			rounded={true}
			bgColor="darkGrey"
			status={statusDisplayHelper()}
		>
			<div className="account-page-linked">
				<div className="account-page-linked-indicator">
					<div className="account-page-linked-indicator_left">
						<div>
							<div className={classNames("account-page-linked-indicator_left_crypto-icon", {
								"account-page-linked-indicator_left_crypto-icon_linked": address,
								"account-page-linked-indicator_left_crypto-icon_unlinked": !address,
							})}>
								<img
									src={process.env.PUBLIC_URL + "/images/phantom-ghost-white.svg"}
									alt="phantom"
								/>
							</div>
						</div>
					</div>

					<div className="account-page-linked-indicator_right">
						<div>
							<span className="account-page-linked-indicator_right_title">
								Display Name
							</span>

							<p className="account-page-linked-indicator_right_address">
								{address || "(unknown)"}
							</p>
						</div>
					</div>
				</div>

				<hr/>

				<div className="account-page-linked-indicator_subheader">
					<span>Phantom is required for the following items:</span>
					<ul>
						<li>DAG</li>
						<li>Iron Pigeons</li>
					</ul>
				</div>

				<div className="account-page-linked-indicator_button-container">
					<div/>

					<div className={classNames({"account-page-linked-indicator_right_hidden": address && localLoading})}>
						<div className="account-page-linked-indicator_right_label-container">
							<ExPopulusButton
								color="pink"
								leftIcon={IoMdRefresh}
								onClick={reactivatePhantom}
							/>
							{!address && (
								<ExPopulusButton
									color="pink"
									onClick={activatePhantom}
								>
									CONNECT PHANTOM
								</ExPopulusButton>
							)}

							{address && !walletIsLinked[address] && (
								<ExPopulusButton
									color="pink"
									onClick={linkWallet}
								>
									Phantom connected - wallet unlinked
								</ExPopulusButton>
							)}

							{address && walletIsLinked[address] && (
								<ExPopulusButton
									<React.ButtonHTMLAttributes<HTMLButtonElement>>
									color="pink"
									forwardProps={{
										disabled: true
									}}
								>
									LINKED
								</ExPopulusButton>
							)}

						</div>
					</div>

				</div>
			</div>
		</HeaderContainer>
	);
};

export default PhantomLinkedIndicator;
