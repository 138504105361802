import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import TagManager from "react-gtm-module";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {RouteProps} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {Container, Input} from "reactstrap";
import {DialCode, RegisterBody, UsersApi, UtilsApi} from "client/cs";
import {
    exPopulusRedirectMap,
    validateAndReturnRedirectQuery,
    validateRedirectRoute,
    XP_REDIRECTS
} from "shared";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";
import {addError, decrementLoading, incrementLoading, login, setCurrentUser} from "../redux/meta/MetaActions";
import getCsConfig from "../utils/getCsConfig";
import MarketplaceCheckbox from "../components/MarketplaceCheckbox";
import {IStore} from "../redux/defaultStore";
import {GoogleTagManagerEvents} from "../utils/GoogleTagManagerEvents";
import PhoneNumberWithCountryCode from "../components/phoneNumberWithCountryCode/PhoneNumberWithCountryCode";
import Tooltip, {DisplayBehavior} from "../components/tooltips/TooltipContainer";
import TooltipErrorMsg from "../components/tooltips/TooltipErrorMessage";
import TooltipErrorList from "../components/tooltips/TooltipErrorList";
import InputPassword from "../components/inputs/InputPassword";
import formStateManager, {defaultFormField, IFormState} from "../utils/formState";
import XpLogoLink from "../components/XpLogoLink";
import isValidEmail from "../utils/isValidEmail";

interface CountryCodeItem {
    code: string;
    dialCode: string;
    flag: string;
    name: string;
}

const defaultFormState: IFormState = {
    nationalNumber: {...defaultFormField},
    countryCode: {...defaultFormField},
    agreeEmailUpdates: {...defaultFormField, value: false},
    email: {
        ...defaultFormField,
        errors: [{isError: true, errorText: ""}],
        validate: (value) => ([{isError: !isValidEmail(value), errorText: "This is not a valid email address"}]),
    },
    password: {
        ...defaultFormField,
        displayBehavior: DisplayBehavior.ONFOCUS,
        errors: [{isError: true, errorText: ""}],
        validate: (value) => ([
            {isError: value?.length < 8, errorText: "Be at least 8 characters long"},
            {isError: !(/[A-Z]+/g.test(value)), errorText: "Contain at least 1 uppercase letter"},
            {isError: !(/[0-9]+/g.test(value)), errorText: "Contain at least 1 number"},
        ]),
    },
    confirmPassword: {
        ...defaultFormField,
        errors: [{isError: true, errorText: ""}],
        validate: (value, currentState) => ([{
            isError: !value || value !== currentState.password.value,
            errorText: "Passwords do not match"
        }]),
    },
};

interface IProps extends RouteProps {}

const RegisterPage: React.FC<IProps> = (props) => {
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    // pulls the specified redirect from the query if exists (i.e. being sent here from DAG, IP, etc. to login before being allowed to proceed with a CTA on the respective subdomain)
    const redirect: string = query.get("redirect");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [dialCodes, setDialCodes] = useState<Array<DialCode>>(undefined);

    // We use this to prevent showing an extra page on a redirected-signup - instead of sending to user to login page if token exists, we'll only do it if token exists and it is the first render.
    const [firstRender, setFirstRender] = useState(true);
    const [formOk, setFormOk] = useState(false);
    const [formState, setFormState] = useState<IFormState>(defaultFormState);
    const stateManager = useMemo(() => formStateManager(defaultFormState), []);
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const dispatch = useDispatch();

    /**
     * Retrieve and store dialCodes to be passed to children
     */
    useEffect(() => {
        if (!dialCodes) {
            getCountryCodes()
                .then(setDialCodes)
                .catch();
        }
    }, [dialCodes]);

    /**
     * sets formOk true when agreeTerms is true and
     * no form fields are in error state
     */
    useEffect(() => {
        setFormOk(agreeTerms && Object
            .keys(formState)
            .reduce((isOk, field) => isOk
                ? !formState[field]?.errors?.some(({isError}) => isError)
                : false, true));
    }, [formState, agreeTerms]);

    /**
     * If token exists when accessing this Register page, send to Login page, where a token expiration check is run.
     *
     */
    useEffect(() => {
        if (fullToken && firstRender) {
            history.replace("/log-in");
        }

        setFirstRender(false);
    }, [fullToken]);

    /**
     * Call api to get list of dial codes & save to state.
     *
     */
    async function getCountryCodes(): Promise<CountryCodeItem[]> {
        try {
            return new UtilsApi(getCsConfig()).getListOfDialCodes();
        } catch (e) {
            await dispatch(addError(e));
        }
    }

    /**
     * Updates the local form state based on the output of the form state manager
     * to accommodate error and tooltip states
     * @param field the field to handle
     * @returns function to handle the form state updates
     */
    const onEvent = (field: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => setFormState(stateManager.setValue(field, e.target));

    /**
     * Update the register form with the new dial code data
     * @param dialCode The new dial code
     */
    const dialCodeOnChange = (dialCode: DialCode): void => setFormState(stateManager.setValue("countryCode", dialCode?.code));

    /**
     * Wrapper to handle updating the phone number
     */
    const updatePhoneNumber = (value) => setFormState(stateManager.setValue("nationalNumber", value));

    /**
     * Toggle agreeing to the email updates.
     *
     */
    const toggleAgreeEmailUpdates = (): void => setFormState(stateManager.setValue("agreeEmailUpdates", !formState.agreeEmailUpdates.value));

    /**
     * Toggle agreeing to the terms & privacy policy.
     *
     */
    function toggleAgreeTerms(): void {
        setAgreeTerms(!agreeTerms);
    }

    /**
     * Call the api to submit the registration form,
     * Log the user in, save the token & user to redux,
     * then navigate to the /account-details page.
     *
     */
    async function submitSignup(e?): Promise<void> {
        e?.preventDefault();
        if (!formOk) {
            return;
        }

        dispatch(incrementLoading());

        const {
            email,
            countryCode,
            nationalNumber,
            password,
            confirmPassword,
            agreeEmailUpdates,
        } = formState;

        const registerBody: RegisterBody = {
            email: email.value as string || undefined,
            // don't submit countryCode without a nationalNumber
            phoneNumber: (nationalNumber.value && countryCode.value) ? {
                countryCode: countryCode.value as string,
                nationalNumber: nationalNumber.value as string,
            } : undefined,
            password: password.value as string || undefined,
            confirmPassword: confirmPassword.value as string || undefined,
            agreeEmailUpdates: agreeEmailUpdates.value as boolean,
        };

        try {
            await new UsersApi(getCsConfig()).register({registerBody});

            TagManager.dataLayer({
                dataLayer: {
                    event: GoogleTagManagerEvents.WHITELIST_SUCCESSFUL,
                    email: email.value,
                },
            });


            const token = await new UsersApi(getCsConfig()).login({
                loginBody: {
                    email: email.value as string,
                    password: password.value as string,
                },
            });

            const userResponse = await new UsersApi(getCsConfig(token)).getProfile();
            await dispatch(login(token));
            await dispatch(setCurrentUser(userResponse.user));

            if (validateRedirectRoute(redirect as XP_REDIRECTS)) {
                window.location.href = exPopulusRedirectMap[redirect]
                return;
            } else {
                history.push("/log-in");
            }

        } catch (e) {
            await dispatch(addError(e));

            TagManager.dataLayer({
                dataLayer: {
                    event: GoogleTagManagerEvents.WHITELIST_FAILURE,
                },
            });
        }

        dispatch(decrementLoading());
    }

    if (fullToken) {
        return null;
    }

    return (
        <Container className="register">
            <div className="register_content">
                <XpLogoLink className={"logo"}/>

                <div className="register_content_form-container">
                    <div className="register_content_form-container_header">
                        <div className="title">Create Account</div>
                        <div className="subtitle">Create your new Ex Populus Account</div>
                    </div>

                    <form onSubmit={submitSignup}>
                        <Tooltip
                            position="top"
                            trigger={() => formState.email.state.showTooltip}
                            tooltip={<TooltipErrorMsg errors={formState.email.errors} position={"top"}/>}
                        >
                            <Input
                                type={"email"}
                                placeholder={"Email address"}
                                value={formState.email.value as string}
                                onChange={onEvent("email")}
                                onFocus={onEvent("email")}
                                onBlur={onEvent("email")}
                                className={classNames({"invalid": formState.email.state.showErrors && !formState.email.state.isValid})}
                            />
                        </Tooltip>

                        <PhoneNumberWithCountryCode
                            updateFormField={updatePhoneNumber}
                            dialCodesList={dialCodes}
                            dialCodeOnChange={dialCodeOnChange}
                        />

                        <Tooltip
                            position="top"
                            trigger={() => formState.password.state.showTooltip}
                            tooltip={<TooltipErrorList title={"Your password must:"} errors={formState.password.errors} position={"top"}/>}
                        >
                            <InputPassword
                                name="password"
                                placeholder="Password"
                                value={formState.password.value as string}
                                onChange={onEvent("password")}
                                onFocus={onEvent("password")}
                                onBlur={onEvent("password")}
                                className={classNames({"invalid": formState.password.state.showErrors && !formState.password.state.isValid})}
                            />
                        </Tooltip>

                        <Tooltip
                            position="bottom"
                            trigger={() => formState.confirmPassword.state.showTooltip}
                            offsets={{bottom: -6}}
                            tooltip={<TooltipErrorMsg errors={formState.confirmPassword.errors} position={"bottom"}/>}
                        >
                            <InputPassword
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={formState.confirmPassword.value as string}
                                onChange={onEvent("confirmPassword")}
                                onFocus={onEvent("confirmPassword")}
                                onBlur={onEvent("confirmPassword")}
                                className={classNames({"invalid": formState.confirmPassword.state.showErrors && !formState.confirmPassword.state.isValid})}
                            />
                        </Tooltip>

                        <div className="register_content_form-container_checkboxes">
                            <MarketplaceCheckbox
                                checked={formState.agreeEmailUpdates.value as boolean}
                                onChange={toggleAgreeEmailUpdates}
                                className="register_content_form-container_checkboxes_box-item"
                            >
                                <p>
                                    I would like to receive SMS updates about Ex Populus drops
                                </p>
                            </MarketplaceCheckbox>

                            <MarketplaceCheckbox
                                checked={agreeTerms}
                                onChange={toggleAgreeTerms}
                                className="register_content_form-container_checkboxes_box-item"
                            >
                                <p>
                                    I agree to the Ex Populus <Link to="/terms" target="_blank"
                                                                    rel="noopener noreferrer">terms</Link> and
                                    acknowledge the <Link to="/privacy" target="_blank" rel="noopener noreferrer">privacy
                                    policy</Link>
                                </p>
                            </MarketplaceCheckbox>
                        </div>

                        <ExPopulusButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="pink"
                            forwardProps={{
                                type: "submit",
                                disabled: !formOk,
                            }}
                            className="register_content_form-container_submit-button"
                        >
                            CREATE ACCOUNT
                        </ExPopulusButton>
                    </form>

                    <div className="register_content_extras">
                        <p className="register_content_extras_login-message">
                            Already have an account?
                            <br/><Link to={validateAndReturnRedirectQuery("/log-in", redirect as XP_REDIRECTS)}>Sign
                            in here</Link>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default RegisterPage;
