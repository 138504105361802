import React from "react";
import {
	convertExPopulusProjectNameToNavFooterItem, ExPopulusProject,
	ExPopulusUniversalNavigationItemFooter
} from "../headerManager/universalNavigationLookup";
import ExPopulusFooterNavColumn from "./ExPopulusFooterNavColumn";
import ExPopulusFooterSocials from "./ExPopulusFooterSocials";
import {Link} from "react-router-dom";

interface IProps {
	useLocalLinks?: boolean;
	workLinks?: Array<ExPopulusUniversalNavigationItemFooter>;
}

const ExPopulusFooterNav: React.FC<IProps> = (props) => {

	return (
		<footer className={"ex-populus-footer"}>
			<div className={"ex-populus-footer_content"}>
				<div className="ex-populus-footer_content_logo-container">
					<Link
						to={process.env.REACT_APP_WEBSITE_URL}
						// target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="https://storage.googleapis.com/ex-populus-marketplace_static/xp-logo.svg"
							alt="Ex Populus"
						/>
					</Link>
					<div>
						<ExPopulusFooterSocials/>
					</div>
				</div>

				<div className="ex-populus-footer_content_categories">
					<ExPopulusFooterNavColumn
						title="Games"
						links={[
							{
								title: "Final Form™",
								to: "/games/final-form",
								href: process.env.REACT_APP_WEBSITE_URL + "/games/final-form",
								local: props.useLocalLinks,
							},
							{
								title: "LAMOverse",
								to: "/games/lamoverse",
								href: process.env.REACT_APP_WEBSITE_URL + "/games/lamoverse",
								local: props.useLocalLinks,
							},
							{
								title: "Coming Soon!",
								to: "/games/coming-soon",
								href: process.env.REACT_APP_WEBSITE_URL + "/games/coming-soon",
								local: props.useLocalLinks,
							},
						]}
					/>

					<ExPopulusFooterNavColumn
						title="Company"
						links={[
							{
								title: "About",
								to: "/about",
								href: process.env.REACT_APP_WEBSITE_URL + "/about",
								local: props.useLocalLinks,
							},
							{
								title: "Contact",
								to: "/contact",
								href: process.env.REACT_APP_WEBSITE_URL + "/contact",
								local: props.useLocalLinks,
							},
							{
								title: "Jobs",
								href: "https://jobs.lever.co/Expopulus",
							},
						]}
					/>

					<ExPopulusFooterNavColumn
						title="Legal"
						links={[
							{
								title: "Privacy Policy",
								to: "/privacy",
								href: process.env.REACT_APP_WEBSITE_URL + "/privacy",
								local: props.useLocalLinks,
							},
							{
								title: "Terms of Service",
								to: "/terms",
								href: process.env.REACT_APP_WEBSITE_URL + "/terms",
								local: props.useLocalLinks,
							},
							{
								title: "Digital Collectibles Terms",
								to: "/digitalcollectibleterms",
								href: process.env.REACT_APP_WEBSITE_URL + "/digitalcollectibleterms",
								local: props.useLocalLinks,
							},
							{
								title: "Final Form Official Rules",
								to: "/final_form_official_rules",
								href: process.env.REACT_APP_WEBSITE_URL + "/final_form_official_rules",
								local: props.useLocalLinks,
							},
						]}
					/>
				</div>
			</div>
		</footer>
	);
};

ExPopulusFooterNav.defaultProps = {
	useLocalLinks: false,
	workLinks: [
		{
			...convertExPopulusProjectNameToNavFooterItem(ExPopulusProject.FINAL_FORM),
			// extraLabel: "NEW!",			// Enable to show a red "NEW" banner beside the game.
		},
		{
			...convertExPopulusProjectNameToNavFooterItem(ExPopulusProject.LAMOVERSE),
		},
		{
			...convertExPopulusProjectNameToNavFooterItem(ExPopulusProject.COMING_SOON),
		},
	],
};

export default ExPopulusFooterNav;
