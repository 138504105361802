"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadSolanaPayTransactionPostResponseToJSON = exports.ReadSolanaPayTransactionPostResponseFromJSONTyped = exports.ReadSolanaPayTransactionPostResponseFromJSON = exports.instanceOfReadSolanaPayTransactionPostResponse = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ReadSolanaPayTransactionPostResponse interface.
 */
function instanceOfReadSolanaPayTransactionPostResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "transaction" in value;
    return isInstance;
}
exports.instanceOfReadSolanaPayTransactionPostResponse = instanceOfReadSolanaPayTransactionPostResponse;
function ReadSolanaPayTransactionPostResponseFromJSON(json) {
    return ReadSolanaPayTransactionPostResponseFromJSONTyped(json, false);
}
exports.ReadSolanaPayTransactionPostResponseFromJSON = ReadSolanaPayTransactionPostResponseFromJSON;
function ReadSolanaPayTransactionPostResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transaction': json['transaction'],
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.ReadSolanaPayTransactionPostResponseFromJSONTyped = ReadSolanaPayTransactionPostResponseFromJSONTyped;
function ReadSolanaPayTransactionPostResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transaction': value.transaction,
        'message': value.message,
    };
}
exports.ReadSolanaPayTransactionPostResponseToJSON = ReadSolanaPayTransactionPostResponseToJSON;
