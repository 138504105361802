"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateDagImagesBodyAllOfToJSON = exports.GenerateDagImagesBodyAllOfFromJSONTyped = exports.GenerateDagImagesBodyAllOfFromJSON = exports.instanceOfGenerateDagImagesBodyAllOf = void 0;
var Dag_1 = require("./Dag");
/**
 * Check if a given object implements the GenerateDagImagesBodyAllOf interface.
 */
function instanceOfGenerateDagImagesBodyAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "dag" in value;
    return isInstance;
}
exports.instanceOfGenerateDagImagesBodyAllOf = instanceOfGenerateDagImagesBodyAllOf;
function GenerateDagImagesBodyAllOfFromJSON(json) {
    return GenerateDagImagesBodyAllOfFromJSONTyped(json, false);
}
exports.GenerateDagImagesBodyAllOfFromJSON = GenerateDagImagesBodyAllOfFromJSON;
function GenerateDagImagesBodyAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dag': (0, Dag_1.DagFromJSON)(json['dag']),
    };
}
exports.GenerateDagImagesBodyAllOfFromJSONTyped = GenerateDagImagesBodyAllOfFromJSONTyped;
function GenerateDagImagesBodyAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dag': (0, Dag_1.DagToJSON)(value.dag),
    };
}
exports.GenerateDagImagesBodyAllOfToJSON = GenerateDagImagesBodyAllOfToJSON;
