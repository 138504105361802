"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DagDropTypeToJSON = exports.DagDropTypeFromJSONTyped = exports.DagDropTypeFromJSON = exports.DagDropType = void 0;
/**
 *
 * @export
 */
exports.DagDropType = {
    _1: 'DROP_1',
    _2: 'DROP_2',
    _3: 'DROP_3'
};
function DagDropTypeFromJSON(json) {
    return DagDropTypeFromJSONTyped(json, false);
}
exports.DagDropTypeFromJSON = DagDropTypeFromJSON;
function DagDropTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DagDropTypeFromJSONTyped = DagDropTypeFromJSONTyped;
function DagDropTypeToJSON(value) {
    return value;
}
exports.DagDropTypeToJSON = DagDropTypeToJSON;
