import React, {ChangeEvent, useEffect, useState} from "react";
import {find} from "lodash";
import {Input} from "reactstrap";
import classNames from "classnames";
import { GoTriangleDown } from "react-icons/all";
import {DialCode, UtilsApi} from "client/cs";
import getCsConfig from "../../utils/getCsConfig";

interface IProps {
	placeholder?: string;
	value: string;
	valueKey: keyof DialCode;
	onChange: (dialCodeField: string) => void;
	disabled?: boolean;
	className?: string;
}

const ExPopulusFooterSubscribeCountryCodeInput: React.FC<IProps> = (props) => {

	const [dialCodes, setDialCodes] = useState<Array<DialCode>>(undefined);

	useEffect(() => {
		fetchDialCodes().then().catch();
	}, []);

	/**
	 * Default select the US when dial codes are set, to avoid needing to show "Select" in the select input.
	 *
	 */
	useEffect(() => {
		if (dialCodes && Array.isArray(dialCodes) && dialCodes.length > 0) {
			props.onChange(find(dialCodes, ["code", "US"])?.[props.valueKey]);
		}
	}, [JSON.stringify(dialCodes)]);

	/**
	 * Get the list of dial codes from the backend & save to state.
	 * Don't show loading or error as to not ruin the UX, as this component will render somewhere and run this
	 * api seemingly unbeknownst to the user, so we don't want to show an inappropriate loading indicator.
	 *
	 */
	async function fetchDialCodes(): Promise<void> {
		try {
			const res = await new UtilsApi(getCsConfig()).getListOfDialCodes();
			setDialCodes(res);
		} catch (e) {

		}
	}

	/**
	 * Use lodash find() to get the correct code from the list, and pass the correct value to the onChange based
	 * on the specified valueKey prop.
	 *
	 * @param e
	 */
	function onChangeHelper(e: ChangeEvent<HTMLInputElement>): void {
		props.onChange(find(dialCodes, ["code", e.target.value])?.[props.valueKey]);
	}

	const selectedCountryCode: DialCode = find(dialCodes, [props.valueKey, props.value]);

	return (
		<div className={classNames("ex-populus-footer-subscribe-country-code-input", props.className)}>
			<div className={"ex-populus-footer-subscribe-country-code-input_flag-container"}>
				{props.value && (
					<img
						src={`https://flagcdn.com/w40/${selectedCountryCode.code?.toLowerCase()}.png`}
						alt={`${selectedCountryCode.code} flag`}
					/>
				)}
			</div>

			<Input
				type="select"
				placeholder={props.placeholder}
				value={props.value}
				onChange={onChangeHelper}
				disabled={props.disabled}
				className={classNames("ex-populus-footer-subscribe-country-code-input_drop-down", {
					"ex-populus-footer-subscribe-country-code-input_drop-down-selected": props.value !== "",
				})}
			>
				{(dialCodes === undefined || props.value === "") ? null : (
					<React.Fragment>
						<option
							selected={!props.value}
							disabled
							value=""
						>
							Select
						</option>

						{dialCodes.map((fullDial: DialCode, i: number) => {
							const selected: boolean = props.value === fullDial[props.valueKey];

							return (
								<option
									key={`dial-code-option_${i}`}
									value={fullDial.code}
								>
									{selected ? fullDial.dialCode : `${fullDial.name} (${fullDial.dialCode})`}
								</option>
							);
						})}
					</React.Fragment>
				)}
			</Input>

			<div className={"ex-populus-footer-subscribe-country-code-input_arrow"}>
				<GoTriangleDown/>
			</div>
		</div>
	);
};

export default ExPopulusFooterSubscribeCountryCodeInput;
