import React from "react";
import classNames from "classnames";


interface IProps {
    resendConfirmation: boolean;
}

const FinalFormWindowDownloadModal: React.FC<IProps> = (props) => {

    return (
        <React.Fragment>
            <div className={classNames("final-form-window-download-modal_resend", {
                "final-form-window-download-modal_resend_fade-in": props.resendConfirmation,
            })}>
					<span>
						<p>Email resent!</p>
					</span>
            </div>
        </React.Fragment>
    );
}

export default FinalFormWindowDownloadModal;
