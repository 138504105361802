import {useSelector} from "react-redux";
import React from "react";
import {IStore} from "../redux/defaultStore";
import {Redirect, Route, RouteProps} from "react-router";

interface IProps {
	redirectOverride?: string;
}

const AuthenticatedRoute: React.FC<RouteProps & IProps> = (props: RouteProps & IProps) => {
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const {component, ...rest} = props;
	const Component = component;

	if (!fullToken?.token) {
		// props.dispatch(addError({message: "You must be logged into a verified account to access this page."}));

		if (props.redirectOverride) {
			return <Redirect to={props.redirectOverride}/>
		}

		return <Redirect to="/"/>
	}

	return (
		<Route
			{...rest}
			render={props => <Component {...props}/>}
		/>
	)
};

export default AuthenticatedRoute;
