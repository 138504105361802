import { gsghtGet } from "./gsghtGet";
import { gsghtInit } from "./gsghtInit";
import { gsghtSend } from "./gsghtSend";
import { gsghtOnLoad } from "./gsghtOnLoad";
import { gsghtSet } from "./gsghtSet";
import { gsghtSetUserIDHelper } from "./gsghtSetUserIDHelper";
import { gsghtSetUserId, gsghtSetRootDomain, gsghtSetAcmEnabled, gsghtSetWarnings, gsghtSetFbEnabled, gsghtSetGaEnabled, gsghtSetExternalUserId, } from "./gsghtSet";
import { gsghtUpdate } from "./gsghtUpdate";
import InitGamesight from "./InitGamesight";
export var GAMESIGHT_EVENTS;
(function (GAMESIGHT_EVENTS) {
    GAMESIGHT_EVENTS["DAG_WEB_PAGEVIEW"] = "dag_web_pageview";
    GAMESIGHT_EVENTS["DAG_WEB_GET_CARDS"] = "dag_web_get_cards";
    GAMESIGHT_EVENTS["DAG_WEB_WALLET_CONNECTED"] = "dag_web_wallet_connected";
    GAMESIGHT_EVENTS["DAG_WEB_SECONDARY_MARKET"] = "dag_web_secondary_market";
    GAMESIGHT_EVENTS["DAG_WEB_SECONDARY_MARKET_OPENSEA"] = "dag_web_secondary_market_opensea";
    GAMESIGHT_EVENTS["DAG_WEB_SECONDARY_MARKET_MAGICEDEN"] = "dag_web_secondary_market_magiceden";
    GAMESIGHT_EVENTS["DAG_PURCHASE_SUCCESSFUL"] = "dag_purchase_successful";
    GAMESIGHT_EVENTS["DAG_FF_PLAY_FREE"] = "dag_ff_play_free";
    GAMESIGHT_EVENTS["FF_WEB_PAGEVIEW"] = "ff_web_pageview";
    GAMESIGHT_EVENTS["FF_WEB_LAUNCHER_DOWNLOAD"] = "ff_web_launcher_download";
    GAMESIGHT_EVENTS["FF_DAG_GET_CARDS"] = "ff_dag_get_cards";
    GAMESIGHT_EVENTS["FF_WEB_DOWNLOAD_LINK"] = "ff_web_download_link";
    GAMESIGHT_EVENTS["IP_WEB_PAGEVIEW"] = "ip_web_pageview";
    GAMESIGHT_EVENTS["IP_WEB_GET_PIGEONS"] = "ip_web_get_pigeons";
    GAMESIGHT_EVENTS["IP_WEB_WALLET_CONNECTED"] = "ip_web_wallet_connect";
    GAMESIGHT_EVENTS["IP_WEB_GET_CARDS"] = "ip_web_get_cards";
    GAMESIGHT_EVENTS["IP_WEB_SECONDARY_MARKET"] = "ip_web_secondary_market";
    GAMESIGHT_EVENTS["IP_WEB_SECONDARY_MARKET_OPENSEA"] = "ip_web_secondary_market_opensea";
    GAMESIGHT_EVENTS["IP_WEB_SECONDARY_MARKET_MAGICEDEN"] = "ip_web_secondary_market_magiceden";
    GAMESIGHT_EVENTS["IP_PURCHASE_SUCCESSFUL"] = "ip_purchase_successful";
    GAMESIGHT_EVENTS["IP_FF_PLAY_FREE"] = "ip_ff_play_free";
})(GAMESIGHT_EVENTS || (GAMESIGHT_EVENTS = {}));
export { gsghtGet, gsghtInit, gsghtSend, gsghtOnLoad, gsghtSet, gsghtSetUserIDHelper, gsghtSetUserId, gsghtSetFbEnabled, gsghtSetGaEnabled, gsghtSetAcmEnabled, gsghtSetWarnings, gsghtSetRootDomain, gsghtUpdate, gsghtSetExternalUserId, InitGamesight, };
