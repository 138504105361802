"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAssetsResponseAllOfToJSON = exports.GetAssetsResponseAllOfFromJSONTyped = exports.GetAssetsResponseAllOfFromJSON = exports.instanceOfGetAssetsResponseAllOf = void 0;
var Asset_1 = require("./Asset");
/**
 * Check if a given object implements the GetAssetsResponseAllOf interface.
 */
function instanceOfGetAssetsResponseAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "assets" in value;
    return isInstance;
}
exports.instanceOfGetAssetsResponseAllOf = instanceOfGetAssetsResponseAllOf;
function GetAssetsResponseAllOfFromJSON(json) {
    return GetAssetsResponseAllOfFromJSONTyped(json, false);
}
exports.GetAssetsResponseAllOfFromJSON = GetAssetsResponseAllOfFromJSON;
function GetAssetsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'assets': (json['assets'].map(Asset_1.AssetFromJSON)),
    };
}
exports.GetAssetsResponseAllOfFromJSONTyped = GetAssetsResponseAllOfFromJSONTyped;
function GetAssetsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'assets': (value.assets.map(Asset_1.AssetToJSON)),
    };
}
exports.GetAssetsResponseAllOfToJSON = GetAssetsResponseAllOfToJSON;
