"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrorAllOfToJSON = exports.FormErrorAllOfFromJSONTyped = exports.FormErrorAllOfFromJSON = exports.instanceOfFormErrorAllOf = void 0;
var runtime_1 = require("../runtime");
var ApiError_1 = require("./ApiError");
/**
 * Check if a given object implements the FormErrorAllOf interface.
 */
function instanceOfFormErrorAllOf(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfFormErrorAllOf = instanceOfFormErrorAllOf;
function FormErrorAllOfFromJSON(json) {
    return FormErrorAllOfFromJSONTyped(json, false);
}
exports.FormErrorAllOfFromJSON = FormErrorAllOfFromJSON;
function FormErrorAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formFieldErrors': !(0, runtime_1.exists)(json, 'formFieldErrors') ? undefined : ((0, runtime_1.mapValues)(json['formFieldErrors'], ApiError_1.ApiErrorFromJSON)),
    };
}
exports.FormErrorAllOfFromJSONTyped = FormErrorAllOfFromJSONTyped;
function FormErrorAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formFieldErrors': value.formFieldErrors === undefined ? undefined : ((0, runtime_1.mapValues)(value.formFieldErrors, ApiError_1.ApiErrorToJSON)),
    };
}
exports.FormErrorAllOfToJSON = FormErrorAllOfToJSON;
