"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignCloudinaryUploadResponseToJSON = exports.SignCloudinaryUploadResponseFromJSONTyped = exports.SignCloudinaryUploadResponseFromJSON = exports.instanceOfSignCloudinaryUploadResponse = void 0;
var CloudinaryRequestOptionsType_1 = require("./CloudinaryRequestOptionsType");
/**
 * Check if a given object implements the SignCloudinaryUploadResponse interface.
 */
function instanceOfSignCloudinaryUploadResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "_requestOptions" in value;
    return isInstance;
}
exports.instanceOfSignCloudinaryUploadResponse = instanceOfSignCloudinaryUploadResponse;
function SignCloudinaryUploadResponseFromJSON(json) {
    return SignCloudinaryUploadResponseFromJSONTyped(json, false);
}
exports.SignCloudinaryUploadResponseFromJSON = SignCloudinaryUploadResponseFromJSON;
function SignCloudinaryUploadResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'signature': json['signature'],
        '_requestOptions': (0, CloudinaryRequestOptionsType_1.CloudinaryRequestOptionsTypeFromJSON)(json['requestOptions']),
    };
}
exports.SignCloudinaryUploadResponseFromJSONTyped = SignCloudinaryUploadResponseFromJSONTyped;
function SignCloudinaryUploadResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'signature': value.signature,
        'requestOptions': (0, CloudinaryRequestOptionsType_1.CloudinaryRequestOptionsTypeToJSON)(value._requestOptions),
    };
}
exports.SignCloudinaryUploadResponseToJSON = SignCloudinaryUploadResponseToJSON;
