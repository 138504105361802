"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGamesResponseToJSON = exports.GetGamesResponseFromJSONTyped = exports.GetGamesResponseFromJSON = exports.instanceOfGetGamesResponse = void 0;
var Game_1 = require("./Game");
var PaginationInfo_1 = require("./PaginationInfo");
/**
 * Check if a given object implements the GetGamesResponse interface.
 */
function instanceOfGetGamesResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "games" in value;
    return isInstance;
}
exports.instanceOfGetGamesResponse = instanceOfGetGamesResponse;
function GetGamesResponseFromJSON(json) {
    return GetGamesResponseFromJSONTyped(json, false);
}
exports.GetGamesResponseFromJSON = GetGamesResponseFromJSON;
function GetGamesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoFromJSON)(json['paginationInfo']),
        'games': (json['games'].map(Game_1.GameFromJSON)),
    };
}
exports.GetGamesResponseFromJSONTyped = GetGamesResponseFromJSONTyped;
function GetGamesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoToJSON)(value.paginationInfo),
        'games': (value.games.map(Game_1.GameToJSON)),
    };
}
exports.GetGamesResponseToJSON = GetGamesResponseToJSON;
