import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {AgreeCookiePrompt} from "./components/banners/AgreeCookiePrompt";
import {Token, User} from "client/cs";
import {connect} from "react-redux";
import {IStore} from "./redux/defaultStore";
import {setAgreedCookies} from "./redux/meta/MetaActions";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import ScrollToTop from "./components/ScrollToTop";
import PasswordResetSuccessModalManager from "./components/PasswordResetSuccessModalManager";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AccountDetailsPage from "./pages/AccountDetailsPage";
import UpdateTracking from "./components/UpdateTracking";
import AboutPage from "./pages/AboutPage";
import CollectiblesPage from "./pages/CollectiblesPage";
import ContactPage from "./pages/ContactPage";
import DigitalCollectibleTermsPage from "./pages/DigitalCollectibleTermsPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ListingPageDk from "./pages/ListingPageDk";
import Login from "./pages/Login";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import FooterManager from "./components/footerManager/FooterManager";
import {HeaderManager, hideHeaderFooterOn} from "./components/headerManager/HeaderManager";
import GamesListPage from "./pages/GamesListPage";
import GameDetailsPage from "./pages/GameDetailsPage";
import FinalFormOfficialRules from "./pages/FinalFormOfficialRules";
import {DagHomepage} from "./components/dag/DagHomepage";
import {IronPigeonsHomepage} from "./components/ironPigeons/IronPigeonsHomepage";
import XPHelmet from "./components/XPHelmet";
import ErrorManager from "./components/modals/errors/ErrorManager";
import {ArbitrumBanner} from "./components/banners/ArbitrumBanner";
import {Homepage} from "./pages/Homepage";
import {FinalForm} from "./pages/gameDetails/FinalForm";
import {ExPopulusStyleGuide} from "./pages/ExPopulusStyleGuide";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	agreedCookies?: boolean;
	sideBarOpen?: boolean;
	currentUser?: User;
}

const APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com";

const App: React.FC<IProps> = (props) => {

	console.log("process.env.NODE_ENV", process.env.NODE_ENV);
	return (
		<BrowserRouter>
			<ScrollToTop/>
			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>
			<XPHelmet/>

			<PasswordResetSuccessModalManager/>

			<main className="mh-100 ">
				<ArbitrumBanner/>

				<HeaderManager />

				<Route path="/" component={UpdateTracking}/>


				<Switch>
					<Redirect from="/games/expopulus-trading-card-game" to="/games/final-form"/>

					{/* HIDE HEADER/FOOTER */}
					<Route exact path="/log-in" component={Login}/>
					<Route exact path="/register" component={RegisterPage}/>
					<Route exact path="/auth/forgot-password" component={ForgotPasswordPage}/>
					<Route exact path="/auth/reset-password" component={ResetPasswordPage}/>

					{/* SHOW HEADER/FOOTER */}
					<Route exact path="/" component={Homepage}/>

					<Route exact path="/dags"><DagHomepage/></Route>
					<Route exact path="/ironpigeons"><IronPigeonsHomepage/></Route>

					<Route exact path="/privacy" component={PrivacyPolicyPage}/>
					<Route exact path="/terms" component={TermsOfServicePage}/>
					<Route exact path="/digitalcollectibleterms" component={DigitalCollectibleTermsPage}/>
					<Route exact path="/final_form_official_rules" component={FinalFormOfficialRules}/>

					<Route exact path="/about" component={AboutPage}/>
					<Route exact path="/contact" component={ContactPage}/>

					<Route exact path="/collectibles" component={CollectiblesPage}/>
					<Route path="/:creatorName/:projectName/:collectionName/:listingName" component={ListingPageDk}/>

					<Route exact path="/games" component={GamesListPage}/>

					{/* TODO; Use exact routes for initial development/launch, switch to the /:id routing when these pages become dynamic */}
					<Route exact path="/games/final-form"><FinalForm gameSlug="final-form"/></Route>
					<Route exact path="/games/lamoverse"><GameDetailsPage gameSlug="lamoverse"/></Route>
					<Route exact path="/games/coming-soon"><GameDetailsPage gameSlug="carnivale"/></Route>

					<AuthenticatedRoute exact path="/account-details" component={AccountDetailsPage}
										redirectOverride="/log-in"/>

					{process.env.NODE_ENV === "development" && (
						<Route path="/demo" component={ExPopulusStyleGuide}/>
					)}

					<Redirect to="/"/>
				</Switch>

				<AgreeCookiePrompt
					agreedCookies={props.agreedCookies}
					onAgree={() => {
						props.dispatch(setAgreedCookies())
					}}
					implementingSiteURL={APP_WEBSITE_URL}
				/>
			</main>

			<FooterManager hiddenOn={hideHeaderFooterOn}/>
		</BrowserRouter>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		agreedCookies: store.metaStore.agreedCookies,
		sideBarOpen: store.metaStore.sidebarVisible,
		...props,
	}
})(App);
