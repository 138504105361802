import HeaderContainer from "./containers/HeaderContainer";
import MetaMaskLinkedIndicator from "./MetaMaskLinkedIndicator";
import PhantomLinkedIndicator from "./PhantomLinkedIndicator";
import React from "react";

interface IProps {
}

const AccountWallets: React.FC<IProps> = (props) => {

	return (
		<HeaderContainer title="Wallets">
			<div className="my-3">
				<MetaMaskLinkedIndicator/>
			</div>

			<div className="my-3">
				<PhantomLinkedIndicator/>
			</div>
		</HeaderContainer>
	);
}

export default AccountWallets;
