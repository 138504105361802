"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadSolanaPayTransactionBodyToJSON = exports.ReadSolanaPayTransactionBodyFromJSONTyped = exports.ReadSolanaPayTransactionBodyFromJSON = exports.instanceOfReadSolanaPayTransactionBody = void 0;
/**
 * Check if a given object implements the ReadSolanaPayTransactionBody interface.
 */
function instanceOfReadSolanaPayTransactionBody(value) {
    var isInstance = true;
    isInstance = isInstance && "account" in value;
    return isInstance;
}
exports.instanceOfReadSolanaPayTransactionBody = instanceOfReadSolanaPayTransactionBody;
function ReadSolanaPayTransactionBodyFromJSON(json) {
    return ReadSolanaPayTransactionBodyFromJSONTyped(json, false);
}
exports.ReadSolanaPayTransactionBodyFromJSON = ReadSolanaPayTransactionBodyFromJSON;
function ReadSolanaPayTransactionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'account': json['account'],
    };
}
exports.ReadSolanaPayTransactionBodyFromJSONTyped = ReadSolanaPayTransactionBodyFromJSONTyped;
function ReadSolanaPayTransactionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'account': value.account,
    };
}
exports.ReadSolanaPayTransactionBodyToJSON = ReadSolanaPayTransactionBodyToJSON;
