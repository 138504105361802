import React, {ChangeEvent, useState} from "react";
import {useDispatch} from "react-redux";
import {Container, Input} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {RequestPasswordResetBody, VerificationsApi} from "client/cs";
import getCsConfig from "../utils/getCsConfig";
import {Link, useHistory} from "react-router-dom";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";
import XpLogoLink from "../components/XpLogoLink";


enum ForgotPasswordPageFormState {
	INITIAL,
	SENT,
}

const defaultForgotPasswordForm: RequestPasswordResetBody = {
	email: "",
}

interface IProps {}

const ForgotPasswordPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [forgotPasswordForm, setForgotPasswordForm] = useState(defaultForgotPasswordForm);
	const dispatch = useDispatch();

	/**
	 * Dynamically on change for the text inputs.
	 *
	 */
	function dynamicOnChange(key: keyof RequestPasswordResetBody): (e: ChangeEvent<HTMLInputElement>) => void {
		return (e) => {
			setForgotPasswordForm({
				...forgotPasswordForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call the api to send the request for resetting a password,
	 * and send user to the reset page with the request id as a query param.
	 *
	 */
	async function submitForgotPasswordRequest(e?): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		try {
			const res = await new VerificationsApi(getCsConfig()).requestPasswordReset({
				requestPasswordResetBody: {
					email: forgotPasswordForm?.email,
				},
			});

			history.push(`/auth/reset-password?request=${res.passwordResetRequestId}`);
		} catch (e) {
			await dispatch(addError(e));
		} finally {
			dispatch(decrementLoading());
		}
	}

	return (
		<Container className="forgot-password">
			<div className="forgot-password_content">
				<XpLogoLink className={"logo"}/>

				<div className="forgot-password_content_form-container">
					<div className="forgot-password_content_form-container_header">
						<div className="title">
							Forgot password?
						</div>

						<p className="subtitle">
							Enter your email address. If an account exists, an email containing instructions for
							resetting your password will be sent.
						</p>
					</div>

					<form onSubmit={submitForgotPasswordRequest}>
						<Input
							type="email"
							placeholder="Email"
							value={forgotPasswordForm.email}
							onChange={dynamicOnChange("email")}
						/>

						<ExPopulusButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="pink"
							forwardProps={{
								type: "submit",
								disabled: forgotPasswordForm.email.length < 1,
							}}
							className="forgot-password_content_form-container_submit-button"
						>
							SEND EMAIL
						</ExPopulusButton>

						<div className="forgot-password_content_extras">
							<p className="forgot-password_content_extras_signup-message">
								Remember your password?
								<br/>
								<Link to="/log-in">Sign in here</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
};

export default ForgotPasswordPage;
