"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletAllOfToJSON = exports.WalletAllOfFromJSONTyped = exports.WalletAllOfFromJSON = exports.instanceOfWalletAllOf = void 0;
var WalletType_1 = require("./WalletType");
/**
 * Check if a given object implements the WalletAllOf interface.
 */
function instanceOfWalletAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "address" in value;
    return isInstance;
}
exports.instanceOfWalletAllOf = instanceOfWalletAllOf;
function WalletAllOfFromJSON(json) {
    return WalletAllOfFromJSONTyped(json, false);
}
exports.WalletAllOfFromJSON = WalletAllOfFromJSON;
function WalletAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, WalletType_1.WalletTypeFromJSON)(json['type']),
        'address': json['address'],
    };
}
exports.WalletAllOfFromJSONTyped = WalletAllOfFromJSONTyped;
function WalletAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, WalletType_1.WalletTypeToJSON)(value.type),
        'address': value.address,
    };
}
exports.WalletAllOfToJSON = WalletAllOfToJSON;
