import React from "react";
import classNames from "classnames";

interface IProps {
	children: string;
	className?: string;
}

const ExPopulusHeaderLabel: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("ex-populus-header-label", props.className)}>
			{props.children}
		</div>
	);
};

export default ExPopulusHeaderLabel;
