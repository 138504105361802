import React from "react";
import { useLocation } from "react-router-dom";
import {ExPopulusFooterComplete} from "../footer/ExPopulusFooterComplete";

interface IProps {
  hiddenOn: string[];
}

const FooterManager: React.FC<IProps> = ({ hiddenOn }) => {
  const location = useLocation();

  return hiddenOn.includes(location.pathname)
    ? null
    : (<ExPopulusFooterComplete navigationUseLocalLinks={true}/>)
}

FooterManager.defaultProps = {
  hiddenOn: [],
};

export default FooterManager;
