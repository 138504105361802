import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./style/index.scss";
import {persistor, store} from "./redux"
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {getLibrary} from "./utils/web3Connection";
import { Web3ReactProvider } from "@web3-react/core";
import PhantomProviderHelper from "./components/PhantomProviderHelper";
import ReactGA from 'react-ga4';
import TagManager from "react-gtm-module";

try {
	ReactGA.initialize(
		process.env.REACT_APP_XP_MARKETPLACE_GOOGLE_ANALYTICS_TRACKING_CODE,
		{
			// comment out this line when you want to see your Google Analytics events in develop
			// testMode: process.env.NODE_ENV === "development",
		}
	);
} catch (err) {
	console.error(err)
}

// Google Tag Manager Setup
const tagManagerArgs = {
	gtmId: process.env.REACT_APP_XP_MARKETPLACE_GTM_ID,
	auth: process.env.REACT_APP_XP_MARKETPLACE_GTM_AUTH,
	preview: process.env.REACT_APP_XP_MARKETPLACE_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor} loading={null}>
			<Web3ReactProvider getLibrary={getLibrary}>
				<PhantomProviderHelper>
					<App/>
				</PhantomProviderHelper>
			</Web3ReactProvider>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
