"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudinaryActionsToJSON = exports.CloudinaryActionsFromJSONTyped = exports.CloudinaryActionsFromJSON = exports.instanceOfCloudinaryActions = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CloudinaryActions interface.
 */
function instanceOfCloudinaryActions(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCloudinaryActions = instanceOfCloudinaryActions;
function CloudinaryActionsFromJSON(json) {
    return CloudinaryActionsFromJSONTyped(json, false);
}
exports.CloudinaryActionsFromJSON = CloudinaryActionsFromJSON;
function CloudinaryActionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mobile': !(0, runtime_1.exists)(json, 'mobile') ? undefined : json['mobile'],
        'tablet': !(0, runtime_1.exists)(json, 'tablet') ? undefined : json['tablet'],
        'desktop': !(0, runtime_1.exists)(json, 'desktop') ? undefined : json['desktop'],
    };
}
exports.CloudinaryActionsFromJSONTyped = CloudinaryActionsFromJSONTyped;
function CloudinaryActionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mobile': value.mobile,
        'tablet': value.tablet,
        'desktop': value.desktop,
    };
}
exports.CloudinaryActionsToJSON = CloudinaryActionsToJSON;
