import {Token, User} from "client/cs";


export interface IMetaStore {
    fullToken?: Token;
    loadingIncrement?: number;
    errors?: Array<any>;
    sidebarVisible?: boolean;
    currentUser?: User;
    agreedCookies: boolean;
    showSuccessfulPasswordResetModal: boolean;
}

export default {
    loadingIncrement: 0,
    errors: [],
    sidebarVisible: false,
    currentUser: undefined,
    agreedCookies: false,
    showSuccessfulPasswordResetModal: false,
} as IMetaStore;
