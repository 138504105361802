import React, {ReactNode} from "react";
import {Container} from "reactstrap";

interface XPLocation {
	img: string;
	alt: string;
	city: string;
	location: string;
}

const locations: XPLocation[] = [
	{
		img: "/images/contact/contact-berkeley.jpg",
		alt: "Berkeley Location",
		city: "Berkeley",
		location: "California, USA",
	},
	{
		img: "/images/contact/contact-vancouver.jpg",
		alt: "Vancouver Location",
		city: "Vancouver",
		location: "British Columbia, Canada",
	},
	{
		img: "/images/contact/contact-singapore.jpg",
		alt: "Singapore Location",
		city: "Singapore",
		location: "Singapore",
	},
]


const OurLocations: React.FC = () => {

	function createLocationCard(item: XPLocation, index): ReactNode {
		return (
			<div
				key={`location-card-${index}`}
				className="our-locations__container-element"
			>
				<div className="our-locations__container-element__image">
					<img
						src={item.img}
						alt={item.alt}
					/>
				</div>
				<div className="our-locations__container-element__body">
					<h5 className="our-locations__container-element__body__city">{item.city}</h5>
					<span className="our-locations__container-element__body__location">{item.location}</span>
				</div>
			</div>
		);
	}

	return (
		<section className="our-locations">
			<Container>
				<h5 className="our-locations__header">Our locations</h5>

				<div className="our-locations__container">
					{locations.map(createLocationCard)}
				</div>
			</Container>
		</section>
	);
}

export default OurLocations;
