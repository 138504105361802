"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorAllOfToJSON = exports.ApiErrorAllOfFromJSONTyped = exports.ApiErrorAllOfFromJSON = exports.instanceOfApiErrorAllOf = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ApiErrorAllOf interface.
 */
function instanceOfApiErrorAllOf(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfApiErrorAllOf = instanceOfApiErrorAllOf;
function ApiErrorAllOfFromJSON(json) {
    return ApiErrorAllOfFromJSONTyped(json, false);
}
exports.ApiErrorAllOfFromJSON = ApiErrorAllOfFromJSON;
function ApiErrorAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'httpStatus': !(0, runtime_1.exists)(json, 'httpStatus') ? undefined : json['httpStatus'],
        'httpMethod': !(0, runtime_1.exists)(json, 'httpMethod') ? undefined : json['httpMethod'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
    };
}
exports.ApiErrorAllOfFromJSONTyped = ApiErrorAllOfFromJSONTyped;
function ApiErrorAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'httpStatus': value.httpStatus,
        'httpMethod': value.httpMethod,
        'path': value.path,
    };
}
exports.ApiErrorAllOfToJSON = ApiErrorAllOfToJSON;
