import React from "react";
import classNames from "classnames";
import { ReactComponent as Icon } from "../svgs/eth-icon-purple.svg";

interface IProps {
	className?: string;
}

const EthIconPurple: React.FC<IProps> = ({ className }) => {
	return (
		<div className={classNames("eth-icon-purple", className)}>
			<Icon />
		</div>
	);
}

export default EthIconPurple;
