"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TcgSetToJSON = exports.TcgSetFromJSONTyped = exports.TcgSetFromJSON = exports.TcgSet = void 0;
/**
 *
 * @export
 */
exports.TcgSet = {
    DAGs: 'DAGs',
    Iron_Pigeon: 'Iron Pigeon'
};
function TcgSetFromJSON(json) {
    return TcgSetFromJSONTyped(json, false);
}
exports.TcgSetFromJSON = TcgSetFromJSON;
function TcgSetFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TcgSetFromJSONTyped = TcgSetFromJSONTyped;
function TcgSetToJSON(value) {
    return value;
}
exports.TcgSetToJSON = TcgSetToJSON;
