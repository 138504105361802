import {EVMChain, Listing} from "client/marketplace";

export function generateEtherscanLink(listing: Listing): string {
	switch (listing?.chain) {
		case EVMChain.ETHEREUM_MAINNET:
			return `https://etherscan.io/address/${listing?.smartContractAddress}`;
		case EVMChain.RINKEBY:
			return `https://rinkeby.etherscan.io/address/${listing?.smartContractAddress}`;
		case EVMChain.ROPSTEN:
			return `https://ropsten.etherscan.io/address/${listing?.smartContractAddress}`;
		case EVMChain.POLYGON:
			return `https://polygonscan.com/address/${listing?.smartContractAddress}`;
		case EVMChain.POLYGON_MUMBAI:
			return `https://mumbai.polygonscan.com/address/${listing?.smartContractAddress}`;
		case EVMChain.BINANCE_MAINNET:
			return `https://bscscan.com/address/${listing?.smartContractAddress}`;
		case EVMChain.BINANCE_TESTNET:
			return `https://testnet.bscscan.com/address/${listing?.smartContractAddress}`;
	}

	return "";
}
