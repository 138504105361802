import { GameSightType } from "./gsghtInterfaces";
export function gsghtSet(field, value) {
    return window.gsght(GameSightType.SET, field, value);
}
export function gsghtSetUserId(value) {
    return window.gsght(GameSightType.SET, "user_id", value);
}
export function gsghtSetRootDomain(value) {
    return window.gsght(GameSightType.SET, "root_domain", value);
}
export function gsghtSetWarnings(value) {
    return window.gsght(GameSightType.SET, "warnings", value);
}
export function gsghtSetGaEnabled(value) {
    return window.gsght(GameSightType.SET, "ga_enabled", value);
}
export function gsghtSetFbEnabled(value) {
    return window.gsght(GameSightType.SET, "fb_enabled", value);
}
export function gsghtSetAcmEnabled(value) {
    return window.gsght(GameSightType.SET, "acm_enabled", value);
}
export function gsghtSetExternalUserId(value) {
    var defaults = {
        scope: "org",
        scope_id: "1041"
    };
    var valueArray = (Array.isArray(value) ? value : [value])
        .map(function (item) { return Object.assign({}, defaults, item); });
    return gsghtSet("user_id", valueArray);
}
