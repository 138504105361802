"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IronPigeonAllOfToJSON = exports.IronPigeonAllOfFromJSONTyped = exports.IronPigeonAllOfFromJSON = exports.instanceOfIronPigeonAllOf = void 0;
var runtime_1 = require("../runtime");
var NftField_1 = require("./NftField");
/**
 * Check if a given object implements the IronPigeonAllOf interface.
 */
function instanceOfIronPigeonAllOf(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfIronPigeonAllOf = instanceOfIronPigeonAllOf;
function IronPigeonAllOfFromJSON(json) {
    return IronPigeonAllOfFromJSONTyped(json, false);
}
exports.IronPigeonAllOfFromJSON = IronPigeonAllOfFromJSON;
function IronPigeonAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'A_background': !(0, runtime_1.exists)(json, 'A_background') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['A_background']),
        'B_costume_back': !(0, runtime_1.exists)(json, 'B_costume_back') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['B_costume_back']),
        'C_body': !(0, runtime_1.exists)(json, 'C_body') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['C_body']),
        'D_details': !(0, runtime_1.exists)(json, 'D_details') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['D_details']),
        'E_eyes_back': !(0, runtime_1.exists)(json, 'E_eyes_back') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['E_eyes_back']),
        'F_beak': !(0, runtime_1.exists)(json, 'F_beak') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['F_beak']),
        'G_eyes_front': !(0, runtime_1.exists)(json, 'G_eyes_front') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['G_eyes_front']),
        'H_costume_front': !(0, runtime_1.exists)(json, 'H_costume_front') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['H_costume_front']),
    };
}
exports.IronPigeonAllOfFromJSONTyped = IronPigeonAllOfFromJSONTyped;
function IronPigeonAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'A_background': (0, NftField_1.NftFieldToJSON)(value.A_background),
        'B_costume_back': (0, NftField_1.NftFieldToJSON)(value.B_costume_back),
        'C_body': (0, NftField_1.NftFieldToJSON)(value.C_body),
        'D_details': (0, NftField_1.NftFieldToJSON)(value.D_details),
        'E_eyes_back': (0, NftField_1.NftFieldToJSON)(value.E_eyes_back),
        'F_beak': (0, NftField_1.NftFieldToJSON)(value.F_beak),
        'G_eyes_front': (0, NftField_1.NftFieldToJSON)(value.G_eyes_front),
        'H_costume_front': (0, NftField_1.NftFieldToJSON)(value.H_costume_front),
    };
}
exports.IronPigeonAllOfToJSON = IronPigeonAllOfToJSON;
