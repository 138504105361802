export enum OPERATING_SYSYEM {
  WINDOWS = "windows",
  MAC_OS = "mac os",
  LINUX = "linux",
  ANDROID = "android",
  IOS = "ios",
};

const downloadUrls = {
  [OPERATING_SYSYEM.WINDOWS]: "https://dl.patchkit.net/d/7444jab7oagel4bhhz3dx/direct",
};

const getGameDownloadURL = (os: string): URL | undefined => {
  try {
      return new URL(downloadUrls[os?.toLowerCase()])
  } catch(e) {
      console.warn(`Not a valid OS for Final Form game! (${os})`);
  }

  return undefined;
}

export default getGameDownloadURL;
