import React, {FC, ReactNode, useState} from "react";
import {IExPopulusHeaderNavItemProps} from "./ExPopulusHeaderNavItem";
import classNames from "classnames";
import ExPopulusHeaderLabel from "./ExPopulusHeaderLabel";
import {MdKeyboardArrowDown} from "react-icons/all";
import {omit} from "lodash";
import {Link} from "react-router-dom";
import {exPopulusUnifiedLinkDispatcher, IExPopulusUnifiedLinkDispatcher} from "./exPopulusUnifiedLinkDispatcher";

interface IProps extends IExPopulusHeaderNavItemProps {
	height: number;
	nested?: boolean;
}

const ExPopulusSidebarNavItem: React.FC<IProps> = (props) => {

	// Determine if this nav item has a sub menu by checking the subMenu prop.
	const hasSubItems: boolean = props.subMenu && Array.isArray(props.subMenu) && props.subMenu.length > 0;
	const [showSubItems, setShowSubItems] = useState(false);

	function onDropDownClickHelper(): void {
		if (hasSubItems) {
			setShowSubItems(!showSubItems);
		}
	}

	/**
	 * Renderer for each sub navigation item.
	 *
	 * @param subItem
	 * @param index
	 */
	function createSubMenuItems(subItem: IExPopulusHeaderNavItemProps, index: number): ReactNode {
		return (
			<ExPopulusSidebarNavItem
				key={`sidebar-nav-item_${index}`}
				{...omit(subItem, "children")}
				implementingSiteURL={props.implementingSiteURL}
				height={props.height}
				nested={true}
			>
				{subItem.children}
			</ExPopulusSidebarNavItem>
		);
	}

	/**
	 * Helper to render the icon.
	 *
	 * @param _icon
	 */
	function getIcon(_icon: FC): ReactNode {
		const Icon = _icon;

		return (
			<Icon/>
		);
	}

	const isCurrentRoute: boolean =
		props.implementingSiteURL + location.pathname === props.raw ||
		props.implementingSiteURL === props.raw ||
		(props.implementingSiteURL === process.env.REACT_APP_WEBSITE_URL && props.to === "/account-details" && location.pathname === "/account-details");
	const linkDeterminer: IExPopulusUnifiedLinkDispatcher = exPopulusUnifiedLinkDispatcher(props.implementingSiteURL, props.raw);

	const linkContent = (
		<React.Fragment>
			{props.extraLabel && (
				<ExPopulusHeaderLabel>
					{props.extraLabel}
				</ExPopulusHeaderLabel>
			)}

			<div>
				{props.children}
				{props.icon ? getIcon(props.icon) : hasSubItems && getIcon(MdKeyboardArrowDown)}
			</div>
		</React.Fragment>
	);

	const subItems = (
		<React.Fragment>
			{hasSubItems && (
				<div className={"ex-populus-sidebar-nav-item_sub-menu"}>
					{props.subMenu.map((item, index) => createSubMenuItems(item, index))}
				</div>
			)}
		</React.Fragment>
	);

	if (linkDeterminer.to) {
		return (
			<Link
				to={linkDeterminer.to}
				onClick={props.onClick}
				className={classNames("ex-populus-sidebar-nav-item", props.className, {
					"ex-populus-sidebar-nav-item_selected": isCurrentRoute,
					"ex-populus-sidebar-nav-item_clickable": true,
				})}
			>
				{linkContent}
			</Link>
		);
	}

	return (
		<React.Fragment>
			<a
				href={linkDeterminer.href}
				rel="noopener noreferrer"
				onClick={onDropDownClickHelper}
				className={classNames("ex-populus-sidebar-nav-item", props.className, {
					"ex-populus-sidebar-nav-item_selected": isCurrentRoute,
					"ex-populus-sidebar-nav-item_drop-down_open": showSubItems,
				})}
			>
				{linkContent}
			</a>

			{showSubItems && subItems}
		</React.Fragment>
	);
};

export default ExPopulusSidebarNavItem;
