"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudinaryInfoToJSON = exports.CloudinaryInfoFromJSONTyped = exports.CloudinaryInfoFromJSON = exports.instanceOfCloudinaryInfo = void 0;
var runtime_1 = require("../runtime");
var CloudinaryActions_1 = require("./CloudinaryActions");
/**
 * Check if a given object implements the CloudinaryInfo interface.
 */
function instanceOfCloudinaryInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "publicId" in value;
    return isInstance;
}
exports.instanceOfCloudinaryInfo = instanceOfCloudinaryInfo;
function CloudinaryInfoFromJSON(json) {
    return CloudinaryInfoFromJSONTyped(json, false);
}
exports.CloudinaryInfoFromJSON = CloudinaryInfoFromJSON;
function CloudinaryInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'publicId': json['publicId'],
        'actions': !(0, runtime_1.exists)(json, 'actions') ? undefined : (0, CloudinaryActions_1.CloudinaryActionsFromJSON)(json['actions']),
    };
}
exports.CloudinaryInfoFromJSONTyped = CloudinaryInfoFromJSONTyped;
function CloudinaryInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'publicId': value.publicId,
        'actions': (0, CloudinaryActions_1.CloudinaryActionsToJSON)(value.actions),
    };
}
exports.CloudinaryInfoToJSON = CloudinaryInfoToJSON;
