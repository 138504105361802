"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherToJSON = exports.PublisherFromJSONTyped = exports.PublisherFromJSON = exports.instanceOfPublisher = void 0;
var Creator_1 = require("./Creator");
var User_1 = require("./User");
/**
 * Check if a given object implements the Publisher interface.
 */
function instanceOfPublisher(value) {
    var isInstance = true;
    isInstance = isInstance && "creators" in value;
    return isInstance;
}
exports.instanceOfPublisher = instanceOfPublisher;
function PublisherFromJSON(json) {
    return PublisherFromJSONTyped(json, false);
}
exports.PublisherFromJSON = PublisherFromJSON;
function PublisherFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, User_1.UserFromJSONTyped)(json, ignoreDiscriminator)), { 'creators': (json['creators'].map(Creator_1.CreatorFromJSON)) });
}
exports.PublisherFromJSONTyped = PublisherFromJSONTyped;
function PublisherToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, User_1.UserToJSON)(value)), { 'creators': (value.creators.map(Creator_1.CreatorToJSON)) });
}
exports.PublisherToJSON = PublisherToJSON;
