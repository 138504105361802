import React from "react";
import MediaAssetViewer from "../../mediaAssetViewer/MediaAssetViewer";

export const FinalFormChromos: React.FC = () => {
	return (
		<section className="final-form-chromos">
			<div className="final-form-chromos_bg-video">
				<div className="final-form-chromos_bg-video_gradient"/>
				<MediaAssetViewer
					asset={new URL("https://res.cloudinary.com/ex-populus/video/upload/f_auto/q_80:qmax_80/v1683229624/website/images/final-form/expopulus_chromos_wENV2Blurred_v84_1_lx4zp4.mp4")}
					contentType={"video"}
					videoAutoPlay={true}
					videoControls={false}
					videoMuted={true}
					videoLoop={true}
					elementWidth={{
						desktopWidth: 1920,
						mobileWidth: 800,
					}}
				/>
			</div>

			<div className="final-form-chromos_container">
				<div className="final-form-chromos_container_body">
					<h2 className="final-form-chromos_container_body_title">
						<strong>Fuel your evolution with </strong><span>Chromos</span>
					</h2>
					<p>
						For each battle you win, you’ll be rewarded with Chromos, the in-game fuel for Evolution. The
						higher the rarity-tier of your cards, the more Chromos you are rewarded with when you
						battle.
					</p>
				</div>
			</div>
		</section>
	)
}
