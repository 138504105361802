"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GodAdminToJSON = exports.GodAdminFromJSONTyped = exports.GodAdminFromJSON = exports.instanceOfGodAdmin = void 0;
/**
 * Check if a given object implements the GodAdmin interface.
 */
function instanceOfGodAdmin(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfGodAdmin = instanceOfGodAdmin;
function GodAdminFromJSON(json) {
    return GodAdminFromJSONTyped(json, false);
}
exports.GodAdminFromJSON = GodAdminFromJSON;
function GodAdminFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GodAdminFromJSONTyped = GodAdminFromJSONTyped;
function GodAdminToJSON(value) {
    return value;
}
exports.GodAdminToJSON = GodAdminToJSON;
