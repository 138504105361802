"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagicEdenCollectionCacheToJSON = exports.MagicEdenCollectionCacheFromJSONTyped = exports.MagicEdenCollectionCacheFromJSON = exports.instanceOfMagicEdenCollectionCache = void 0;
/**
 * Check if a given object implements the MagicEdenCollectionCache interface.
 */
function instanceOfMagicEdenCollectionCache(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "latestBlockNumberFound" in value;
    return isInstance;
}
exports.instanceOfMagicEdenCollectionCache = instanceOfMagicEdenCollectionCache;
function MagicEdenCollectionCacheFromJSON(json) {
    return MagicEdenCollectionCacheFromJSONTyped(json, false);
}
exports.MagicEdenCollectionCacheFromJSON = MagicEdenCollectionCacheFromJSON;
function MagicEdenCollectionCacheFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'collection': json['collection'],
        'latestBlockNumberFound': json['latestBlockNumberFound'],
    };
}
exports.MagicEdenCollectionCacheFromJSONTyped = MagicEdenCollectionCacheFromJSONTyped;
function MagicEdenCollectionCacheToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'collection': value.collection,
        'latestBlockNumberFound': value.latestBlockNumberFound,
    };
}
exports.MagicEdenCollectionCacheToJSON = MagicEdenCollectionCacheToJSON;
