"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketConnectionToJSON = exports.SocketConnectionFromJSONTyped = exports.SocketConnectionFromJSON = exports.instanceOfSocketConnection = void 0;
var runtime_1 = require("../runtime");
var Token_1 = require("./Token");
var User_1 = require("./User");
/**
 * Check if a given object implements the SocketConnection interface.
 */
function instanceOfSocketConnection(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "socketId" in value;
    return isInstance;
}
exports.instanceOfSocketConnection = instanceOfSocketConnection;
function SocketConnectionFromJSON(json) {
    return SocketConnectionFromJSONTyped(json, false);
}
exports.SocketConnectionFromJSON = SocketConnectionFromJSON;
function SocketConnectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'socketId': json['socketId'],
        'token': !(0, runtime_1.exists)(json, 'token') ? undefined : (0, Token_1.TokenFromJSON)(json['token']),
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, User_1.UserFromJSON)(json['user']),
    };
}
exports.SocketConnectionFromJSONTyped = SocketConnectionFromJSONTyped;
function SocketConnectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'socketId': value.socketId,
        'token': (0, Token_1.TokenToJSON)(value.token),
        'user': (0, User_1.UserToJSON)(value.user),
    };
}
exports.SocketConnectionToJSON = SocketConnectionToJSON;
