import React from "react";

const FinalFormOfficialRules: React.FC = () => {

    return (
        <section className={"legal-text"}>
            <div className="text-container">
                <div className={"page-content"}>
                    <div className="page-title">
                        <h1 className="text-center">Final Form Official Rules</h1>
                        <p className="upcase text-center">Last Updated: 08/24/2022</p>
                    </div>

                    <p>
                        Please read these Final Form Official Rules (“<span
                        className={"bold-white-text"}>Official Rules</span>”) before participating in Final Form (the
                        “<span className={"bold-white-text"}>Game</span>”) by Ex Populus, Inc. (“<span
                        className={"bold-white-text"}>Ex Populus</span>”).
                    </p>
                    <p>
                        BY PARTICIPATING IN THIS GAME, YOU (“<span className={"bold-white-text"}>YOU</span>” OR “<span
                        className={"bold-white-text"}>PLAYER</span>”) AGREE TO BE BOUND BY THESE OFFICIAL RULES AND
                        REPRESENT THAT YOU SATISFY ALL OF THE ELIGIBILITY REQUIREMENTS BELOW. YOU FURTHER AGREE TO (1)
                        THE <a className="text-blue-600 underline" href="https://expopulus.com/terms" target="_blank"
                               rel="noreferrer">EX POPULUS TERMS OF SERVICE</a> AND (2) THE <a
                        className="text-blue-600 underline" href="https://expopulus.com/terms" target="_blank"
                        rel="noreferrer">EX POPULUS DIGITAL COLLECTIBLES TERMS,</a> INCLUDING THE MANDATORY ARBITRATION
                        PROVISION AND CLASS ACTION WAIVER IN SECTION 17 OF THE EX POPULUS TERMS OF SERVICE. You may
                        review these terms by clicking the hyperlinks above and on <a
                        className="text-blue-600 underline" href="https://expopulus.com/terms" target="_blank"
                        rel="noreferrer">https://expopulus.com.</a> If you do not agree to these terms, do not play the
                        Game.
                    </p>

                    <h4>Eligibility</h4>

                    <p>
                        The Game is open only to players who (1) are residents of the 50 United States and DC, (2) have
                        a Solana compatible wallet (“<span className={"bold-white-text"}>Wallet</span>”); (3) are 18
                        years of age or older; and (4) otherwise comply with the eligibility requirements set forth in
                        these Official Rules and the Ex Populus Terms of Service.
                    </p>

                    <h4>How to link your wallet to Play the Game</h4>

                    <p>
                        To link your wallet, complete the following steps:
                    </p>
                    <ol style={{listStyleType: "lower-alpha"}}>
                        <li>
                            Visit https://expopulus.com/games/final-form
                        </li>
                        <li>
                            Download and install the game to your compatible PC.
                        </li>
                        <li>
                            Open the game and when prompted, follow the instructions to link your Solana wallet.
                        </li>
                    </ol>

                    <p>
                        All wallets must be linked by the individual player. Multiple players are not permitted to share
                        the same wallet.
                    </p>

                    <h4>Payments</h4>

                    <p>
                        For any Game that requires any type of payment or transaction on the blockchain, you will pay
                        the applicable cost (“<span className={"bold-white-text"}>Payments</span>”) by using your linked
                        wallet with whichever asset is required to pay. Payment is subject to the payment terms in
                        Section 4 of the Ex Populus Terms of Service. Payments are not refundable.
                    </p>

                    <p>
                        <span className={"bold-white-text"}>Gameplay Instructions</span>: The Game will give players the
                        opportunity to pledge and evolve eligible digital collectibles, including digital trading cards
                        (“<span className={"bold-white-text"}>Digital Collectibles</span>”). When you pledge your
                        digital collectibles you will receive Chromos. Chromos is the substance required to evolve your
                        digital collectibles to a higher tier. The amount of Chromos you receive will vary depending on
                        the digital collectible you pledge and changes to the game by its developers. To evolve your
                        digital collectibles, you must burn one digital collectible from each house (Beast, Man,
                        Machine, God) of the same tier and the required amount of Chromos to produce one digital
                        collectible of the higher tier. Burned digital collectibles and burned Chromos are gone forever
                        and can’t be recreated or returned to you. Evolving and burning digital collectibles is an
                        irreversible, permanent action.
                    </p>

                    <p>
                        Gameplay and rules are subject to these Official Rules, Ex Populus Terms of Service, and any
                        other rules, requirements, and limitations for the Game available on <a
                        className="text-blue-600 underline" href="https://expopulus.com/terms" target="_blank"
                        rel="noreferrer">https://expopulus.com,</a> which rules Ex Populus may update or amend from time
                        to time in its sole discretion (collectively, the “<span className={"bold-white-text"}>Game Rules</span>”).
                    </p>

                    <h4>Receiving Digital Collectibles from the Game</h4>

                    <p>
                        Those who receive Digital Collectibles from the Game will be decided as set forth in the Game
                        Rules. To the fullest extent permitted by applicable law, Ex Populus may require each potential
                        play who may receive Digital Collectibles from the Game to attest to their eligibility to
                        participate in the Game. Ex Populus’s decisions are final and binding in all matters related to
                        the game. If, by reason of a computer glitch, the use or abuse of a smart contract or code in a
                        way other than as the Ex Populus intended, or other error, more Digital Collectibles are claimed
                        than set forth in the Game Rules, or Digital Collectibles are distributed to a player other than
                        as intended by the Ex Populus, Ex Populus may take any action it deems appropriate in its sole
                        discretion, including distributing, redistributing, minting, or issuing Digital Collectibles.
                        The Game does not limit the number of players who may receive Digital Collectibles. Ex Populus
                        may limit the number of players who receive Digital Collectibles in its sole discretion. ALL
                        FEDERAL, STATE AND LOCAL TAXES ASSOCIATED WITH THE RECEIPT OR USE OF ANY DIGITAL COLLECTIBLE ARE
                        THE SOLE RESPONSIBILITY OF THE PLAYER WHO RECEIVES THE DIGITAL COLLECTIBLE. Players may not
                        transfer or assign the right to collect a Digital Collectible from the Game. Ex Populus reserves
                        the right to substitute Digital Collectibles. Ex Populus will not replace any lost or stolen
                        Digital Collectibles.
                    </p>

                    <h4>Use of Name, Likeness, etc.; Intellectual Property:</h4>

                    <p>
                        To the fullest extent permitted by applicable law, entry into the Game constitutes permission for
                        Ex Populus and its parent companies, subsidiaries, affiliates, agents, and designees to broadcast, stream,
                        film, transmit, publicly display, record and/or take photos of gameplay during the Game, and Ex Populus
                        may use the resulting content, video, audio, footage, film, recordings or photos for a range of purposes,
                        including, without limitation, for marketing, advertising, and promoting Ex Populus and its respective
                        products and services. By participating in the Game, player (and if such player is a Minor, his or her
                        parent or legal guardian) hereby consents to having his or her gameplay broadcasted, streamed, filmed,
                        transmitted, publicly displayed, recorded or photographed, and grants Ex Populus and those acting under
                        its authority (and agrees to confirm that grant in writing) the royalty-free, perpetual, irrevocable, sub-
                        licensable, right and license to publish, reproduce, perform, distribute, publicly display, broadcast, stream,
                        transmit, adapt, edit, modify, translate, create derivative works based upon, and otherwise use and display
                        any or all of the content and details of such participation without additional compensation, including
                        player’s actual name, in-game name, team name, deck composition, photo, voice and/or likeness, and any
                        of the content, video, audio, footage, film, photos or recordings taken pursuant to this section without
                        further notice, review or approval, without territorial, time, media or medium or other limitations, for any
                        and all purposes, including, but not limited to, marketing, advertising, and promotional purposes. By
                        participating in the Game, you consent to being contacted by Ex Populus for any purpose in connection
                        with this Game. Nothing in the foregoing, limits or restricts Ex Populus’s rights and licenses set forth in
                        the Ex Populus Terms of Service.
                    </p>

                    <h4>Intellectual Property Notice</h4>

                    <p>
                        Ex Populus, Final Form, Decentralized Autonomous Gigaunits, and DAGs are trademarks of Ex
                        Populus, Inc. The Game and all accompanying materials are copyright © 2022 by Ex Populus, Inc. All
                        rights reserved.
                    </p>

                    <h4>Disputes</h4>

                    <p>
                        Any dispute arising from these Official Rules or your use of the Game will be governed by and
                        construed and enforced according the terms set forth in the Ex Populus Terms of Service. BY PLAYING
                        THE GAME, YOU AGREE TO BINDING ARBITRATION AND CLASS ACTION WAIVER.
                    </p>

                    <h4>General Conditions</h4>

                    <p>
                        Players agree to be bound by these Official Rules and by the decisions of Ex Populus, which are
                        final and binding in all respects. Ex Populus reserves the right to change these Official Rules at any time,
                        in its discretion, and to suspend or cancel the Game or any player’s participation in the Game. Players
                        who violate these Official Rules, violate any law, rule or regulation in connection with participation in the
                        Game, tamper with the operation of the Game or engage in any conduct that is detrimental or unfair to Ex
                        Populus, the Game or any other player (as determined in Ex Populus’s discretion) are subject to
                        disqualification from the Game. Ex Populus reserves the right to lock out persons whose eligibility is in
                        question or who have been disqualified or are otherwise ineligible to participate in the Game. Ex Populus
                        reserves the right to disqualify any registrations received that contain inappropriate or offensive content.
                        Ex Populus’s failure to enforce any term of these Official Rules will not constitute a waiver of that
                        provision. Any provision of these Official Rules deemed unenforceable will be enforced to the extent
                        permissible, and the remainder of these Official Rules will remain in effect. All players agree to behave
                        in an appropriate, sportsmanlike, and respectful manner towards other players and viewers.
                        Unsportsmanlike conduct may result in an immediate disqualification from the Game, in the discretion of
                        Ex Populus or its designee. The use of the terms “include” or “including” in these Official Rules is
                        illustrative and not limiting.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default FinalFormOfficialRules;
