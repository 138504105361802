import React, {ReactNode} from "react";
import {Asset} from "client/marketplace";
import GalleryItem from "./GalleryItem";

interface IProps {
	assets: Array<Asset>;
	selectedIndex: number;
	setSelected: (index: number) => void;
}

const GalleryItemList: React.FC<IProps> = (props) => {

	/**
	 * Render each of the assets.
	 *
	 * @param asset
	 * @param index
	 */
	function renderAssets(asset: Asset, index: number): ReactNode {
		function clickHandler(): void {
			props.setSelected(index);
		}

		return (
			<GalleryItem
				key={`${asset.name}_${index}_${Math.random()}`}
				asset={asset}
				selected={index === props.selectedIndex}
				onClick={clickHandler}
			/>
		);
	}

	return (
		<React.Fragment>
			{props.assets.map(renderAssets)}
		</React.Fragment>
	)
};

GalleryItemList.defaultProps = {
	assets: [],
	setSelected: () => {},
};

export default React.memo(GalleryItemList, () => true);
