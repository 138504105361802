"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagicEdenCollectionCacheAllOfToJSON = exports.MagicEdenCollectionCacheAllOfFromJSONTyped = exports.MagicEdenCollectionCacheAllOfFromJSON = exports.instanceOfMagicEdenCollectionCacheAllOf = void 0;
/**
 * Check if a given object implements the MagicEdenCollectionCacheAllOf interface.
 */
function instanceOfMagicEdenCollectionCacheAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "latestBlockNumberFound" in value;
    return isInstance;
}
exports.instanceOfMagicEdenCollectionCacheAllOf = instanceOfMagicEdenCollectionCacheAllOf;
function MagicEdenCollectionCacheAllOfFromJSON(json) {
    return MagicEdenCollectionCacheAllOfFromJSONTyped(json, false);
}
exports.MagicEdenCollectionCacheAllOfFromJSON = MagicEdenCollectionCacheAllOfFromJSON;
function MagicEdenCollectionCacheAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'collection': json['collection'],
        'latestBlockNumberFound': json['latestBlockNumberFound'],
    };
}
exports.MagicEdenCollectionCacheAllOfFromJSONTyped = MagicEdenCollectionCacheAllOfFromJSONTyped;
function MagicEdenCollectionCacheAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'collection': value.collection,
        'latestBlockNumberFound': value.latestBlockNumberFound,
    };
}
exports.MagicEdenCollectionCacheAllOfToJSON = MagicEdenCollectionCacheAllOfToJSON;
