import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { ExPopulusButton} from "../buttons/ExPopulusButton";
import { ReactComponent as ArrowRightIcon } from "../../svgs/arrow-right.svg";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

interface IProps extends HTMLAttributes<HTMLElement> {
    id: string;
    className?: string;
}

const OurStore: React.FC<IProps> = (props) => {

    return (
        <section className={classNames("our-store", props.className)} id={props.id}>

            <div className="our-store_overlay"/>

            <Container>
                <div className={"info"}>
                    <header>
                        <h3>Shop Ex Populus</h3>
                        <img
                            src={"https://storage.googleapis.com/ex-populus-marketplace_static/about-page/updated_titles/store_svg.svg"}
                            alt={"Store"}/>
                    </header>
                    <p>Right now you are at the Ex Populus store where games, digital goods and related items are
                        sold. Smart contracts are leveraged by the marketplace to help make the games and digital
                        goods interoperable with each other and even other unrelated games and projects.</p>

                    <ExPopulusButton
                        to="/collectibles"
                        color="purple"
                        rightIcon={ArrowRightIcon}
                        className="btn-visit-store"
                    >
                        Visit The Store
                    </ExPopulusButton>
                </div>

                <div className="store-bg-image">
                    <MediaAssetViewer
                        asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661294234/website/about-page/our-store-treasure-chest_2x_x1kmqm")}
                        imageAltText="Store Foreground"
                        contentType={"image"}
                    />
                </div>

            </Container>
        </section>
    );
}

export default OurStore;
