import React, {HTMLAttributes} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";

const investorLogos = [
	{
		order: 0,
		name: "Animoca Brands",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/animoca-logo_2x_tiutg1",
	},
	{
		order: 1,
		name: "CMS",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/cms-logo_2x_hfhrt9",
	},
	{
		order: 2,
		name: "Outlier Ventures",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/outlier-ventures_2x_kjvr3h",
	},
	{
		order: 3,
		name: "Headline",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/headline-logo_2x_c2ik8h",
	},
	{
		order: 4,
		name: "Com2us",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/com2us-logo_2x_fce6ls",
	},
	{
		order: 5,
		name: "Enjin",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/enjin-logo_2x_nrsgpj",
	},
	{
		order: 6,
		name: "Akatsuki",
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/akatsuki-logo_2x_ymouaa",
	},
];

interface IProps extends HTMLAttributes<HTMLElement> {
	id?: string;
	className?: string;
}

const OurInvestors: React.FC<IProps> = (props) => {

	return (
		<section
			className={classNames("our-investors", props.className)}
			id={props.id}
		>
			<Container>
				<header>
					<h3>Who We&apos;re Backed By</h3>
					<h2>Investors</h2>
				</header>

				<div className={"logos"}>
					{
						investorLogos
							.sort((a, b) => (a.order > b.order) ? 1 : -1)
							.map(({ name, imageUrl }) => (<div className={"logo"}><img src={imageUrl} alt={name}/></div>))
					}
				</div>
			</Container>
		</section>
	);
}

export default OurInvestors;
