import React from "react";

interface IProps {
	logo: string;
}

const GameCardLogo2: React.FC<IProps> = (props) => {

	return (
		<div className="game-card-logo2">
			<img
				src={props.logo}
				alt="icon"
			/>
		</div>
	);
};

export default GameCardLogo2;
