import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}

const PasswordChangeSuccessModal: React.FC<IProps> = (props) => {

	return (
		<Modal
			centered={true}
			fade={true}
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="md"
			contentClassName={"password_change_success_modal"}
		>
			<ModalHeader className="password_change_success_modal_title">
				Password Updated
			</ModalHeader>

			<ModalBody>
				<p className="password_change_success_modal_subtitle">
					Your password has been updated successfully, please use it to log in from now on.
				</p>

				<ExPopulusButton
					color="pink"
					onClick={props.onClose}
					className="w-100"
				>
					DISMISS
				</ExPopulusButton>
			</ModalBody>
		</Modal>
	);
};

export default PasswordChangeSuccessModal;
