"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsWalletLinkedResponseToJSON = exports.IsWalletLinkedResponseFromJSONTyped = exports.IsWalletLinkedResponseFromJSON = exports.instanceOfIsWalletLinkedResponse = void 0;
/**
 * Check if a given object implements the IsWalletLinkedResponse interface.
 */
function instanceOfIsWalletLinkedResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "linked" in value;
    return isInstance;
}
exports.instanceOfIsWalletLinkedResponse = instanceOfIsWalletLinkedResponse;
function IsWalletLinkedResponseFromJSON(json) {
    return IsWalletLinkedResponseFromJSONTyped(json, false);
}
exports.IsWalletLinkedResponseFromJSON = IsWalletLinkedResponseFromJSON;
function IsWalletLinkedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'linked': json['linked'],
    };
}
exports.IsWalletLinkedResponseFromJSONTyped = IsWalletLinkedResponseFromJSONTyped;
function IsWalletLinkedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'linked': value.linked,
    };
}
exports.IsWalletLinkedResponseToJSON = IsWalletLinkedResponseToJSON;
