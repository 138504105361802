"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpBanReasonToJSON = exports.IpBanReasonFromJSONTyped = exports.IpBanReasonFromJSON = exports.instanceOfIpBanReason = void 0;
var runtime_1 = require("../runtime");
var GeoBanType_1 = require("./GeoBanType");
var IpBanType_1 = require("./IpBanType");
/**
 * Check if a given object implements the IpBanReason interface.
 */
function instanceOfIpBanReason(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
exports.instanceOfIpBanReason = instanceOfIpBanReason;
function IpBanReasonFromJSON(json) {
    return IpBanReasonFromJSONTyped(json, false);
}
exports.IpBanReasonFromJSON = IpBanReasonFromJSON;
function IpBanReasonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, IpBanType_1.IpBanTypeFromJSON)(json['type']),
        'geoBanType': !(0, runtime_1.exists)(json, 'geoBanType') ? undefined : (0, GeoBanType_1.GeoBanTypeFromJSON)(json['geoBanType']),
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.IpBanReasonFromJSONTyped = IpBanReasonFromJSONTyped;
function IpBanReasonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, IpBanType_1.IpBanTypeToJSON)(value.type),
        'geoBanType': (0, GeoBanType_1.GeoBanTypeToJSON)(value.geoBanType),
        'message': value.message,
    };
}
exports.IpBanReasonToJSON = IpBanReasonToJSON;
