"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriberToJSON = exports.SubscriberFromJSONTyped = exports.SubscriberFromJSON = exports.instanceOfSubscriber = void 0;
var runtime_1 = require("../runtime");
var PhoneNumber_1 = require("./PhoneNumber");
var User_1 = require("./User");
/**
 * Check if a given object implements the Subscriber interface.
 */
function instanceOfSubscriber(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "verified" in value;
    return isInstance;
}
exports.instanceOfSubscriber = instanceOfSubscriber;
function SubscriberFromJSON(json) {
    return SubscriberFromJSONTyped(json, false);
}
exports.SubscriberFromJSON = SubscriberFromJSON;
function SubscriberFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'phoneNumber': (0, PhoneNumber_1.PhoneNumberFromJSON)(json['phoneNumber']),
        'verified': json['verified'],
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, User_1.UserFromJSON)(json['user']),
    };
}
exports.SubscriberFromJSONTyped = SubscriberFromJSONTyped;
function SubscriberToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'phoneNumber': (0, PhoneNumber_1.PhoneNumberToJSON)(value.phoneNumber),
        'verified': value.verified,
        'user': (0, User_1.UserToJSON)(value.user),
    };
}
exports.SubscriberToJSON = SubscriberToJSON;
