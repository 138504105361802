"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericIpfsUploadResponseBodyToJSON = exports.GenericIpfsUploadResponseBodyFromJSONTyped = exports.GenericIpfsUploadResponseBodyFromJSON = exports.instanceOfGenericIpfsUploadResponseBody = void 0;
/**
 * Check if a given object implements the GenericIpfsUploadResponseBody interface.
 */
function instanceOfGenericIpfsUploadResponseBody(value) {
    var isInstance = true;
    isInstance = isInstance && "path" in value;
    return isInstance;
}
exports.instanceOfGenericIpfsUploadResponseBody = instanceOfGenericIpfsUploadResponseBody;
function GenericIpfsUploadResponseBodyFromJSON(json) {
    return GenericIpfsUploadResponseBodyFromJSONTyped(json, false);
}
exports.GenericIpfsUploadResponseBodyFromJSON = GenericIpfsUploadResponseBodyFromJSON;
function GenericIpfsUploadResponseBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'path': json['path'],
    };
}
exports.GenericIpfsUploadResponseBodyFromJSONTyped = GenericIpfsUploadResponseBodyFromJSONTyped;
function GenericIpfsUploadResponseBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'path': value.path,
    };
}
exports.GenericIpfsUploadResponseBodyToJSON = GenericIpfsUploadResponseBodyToJSON;
