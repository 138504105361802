import React from "react";
import {ExPopulusButton} from "./buttons/ExPopulusButton";
import {TokenType} from "../utils/TokenType";
import openSecondaryMarketURL from "../utils/openSecondaryMarketURL";

interface IProps {
	title: string;
	tokenType: TokenType;
	ctaToLocation?: string;
	onClickGetCardsCallback?: () => void;
}

export const GetFinalFormCardsBanner: React.FC<IProps> = (props) => {
	const onClick = () => {
		openSecondaryMarketURL(props.tokenType);

		props.onClickGetCardsCallback();
	}

	return (
		<section className={"homepage-get-final-form-cards-banner"}>
			<div className={"homepage-get-final-form-cards-banner_text"}>
				<span className={"homepage-get-final-form-cards-banner_text_tag"}>NEW!</span>
				<p>{props.title} are now available to play in <a href={`${process.env.REACT_APP_WEBSITE_URL}/games/final-form`}>Final Form™</a></p>
			</div>

			<ExPopulusButton
				className={"homepage-get-final-form-cards-banner_text_button"}
				color="white-on-pink"
				to={props.ctaToLocation}
				onClick={onClick}
			>
				GET CARDS
			</ExPopulusButton>
		</section>
	);
}

GetFinalFormCardsBanner.defaultProps = {
	ctaToLocation: "#get-cards",
	onClickGetCardsCallback: () => {},
}
