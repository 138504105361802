"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureFlagAllOfToJSON = exports.FeatureFlagAllOfFromJSONTyped = exports.FeatureFlagAllOfFromJSON = exports.instanceOfFeatureFlagAllOf = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the FeatureFlagAllOf interface.
 */
function instanceOfFeatureFlagAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
exports.instanceOfFeatureFlagAllOf = instanceOfFeatureFlagAllOf;
function FeatureFlagAllOfFromJSON(json) {
    return FeatureFlagAllOfFromJSONTyped(json, false);
}
exports.FeatureFlagAllOfFromJSON = FeatureFlagAllOfFromJSON;
function FeatureFlagAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'key': json['key'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'status': json['status'],
    };
}
exports.FeatureFlagAllOfFromJSONTyped = FeatureFlagAllOfFromJSONTyped;
function FeatureFlagAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'key': value.key,
        'description': value.description,
        'status': value.status,
    };
}
exports.FeatureFlagAllOfToJSON = FeatureFlagAllOfToJSON;
