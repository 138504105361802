import React, { useEffect, useState } from "react";
import { ExPopulusButton } from "../buttons/ExPopulusButton";
import { FiX } from "react-icons/all";
import { atom, useAtom } from "jotai";
import { useLocation } from "react-router-dom";

export const dismissBannerAtom = atom(false);

export const ArbitrumBanner: React.FC = () => {
  const [buttonRoute, setButtonRoute] = useState<string>("/");
  const [dismissBanner, setDismissBanner] = useAtom(dismissBannerAtom);
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const bannerVal = window.localStorage.getItem("dismissBanner");
    if (bannerVal === "true" && isMounted) {
      setDismissBanner(true);
    }

    return () => {
      isMounted = false;
    };
  }, [dismissBanner]);

  // checks pathname to determine what the "Learn More" button should direct to
  useEffect(() => {
    if (!dismissBanner) {
      switch (location.pathname) {
        case "/ironpigeons/":
          setButtonRoute("/ironpigeons/#get-cards");
          break;
        case "/dags/":
          setButtonRoute("/dags/#get-cards");
          break;
        default:
          setButtonRoute("/dags");
      }
    }
  }, [location.pathname]);

  if (!!dismissBanner) {
    return null;
  }

  return (
    <div className="arbitrum-banner">
      <div className="arbitrum-banner_about">
        <span>
          <strong>New ∙ </strong>Final Form is coming soon to Xai.
        </span>
      </div>
      <div className="arbitrum-banner_button">
        <a
          href="https://xai.games/"
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Learn More
        </a>
      </div>
      <div
        onClick={() => {
          setDismissBanner(true);
          window.localStorage.setItem("dismissBanner", "true");
        }}
        className="arbitrum-banner_close"
      >
        <FiX />
      </div>
    </div>
  );
};
