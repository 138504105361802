"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatorAllOfToJSON = exports.CreatorAllOfFromJSONTyped = exports.CreatorAllOfFromJSON = exports.instanceOfCreatorAllOf = void 0;
/**
 * Check if a given object implements the CreatorAllOf interface.
 */
function instanceOfCreatorAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfCreatorAllOf = instanceOfCreatorAllOf;
function CreatorAllOfFromJSON(json) {
    return CreatorAllOfFromJSONTyped(json, false);
}
exports.CreatorAllOfFromJSON = CreatorAllOfFromJSON;
function CreatorAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
    };
}
exports.CreatorAllOfFromJSONTyped = CreatorAllOfFromJSONTyped;
function CreatorAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
    };
}
exports.CreatorAllOfToJSON = CreatorAllOfToJSON;
