import {formatEther} from "@ethersproject/units";

/**
 * Appends " ETH" to the end of the value returned
 * from the formatEther function.
 *
 * @param value
 */
export function formatEtherAppendLabel(value: string): string {
	return formatEther(value) + " ETH";
}
