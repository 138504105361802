import React from "react";
import classNames from "classnames";

export interface IProps {
	show?: boolean;
	message?: string;
	className?: string;
}

const FormFieldError: React.FC<IProps> = (props) => {
	if (!props.show) {
		return null;
	}

	return (
		<div className={classNames("form-field-error", props.className)}>
			{props.children || props.message}
		</div>
	);
};

FormFieldError.defaultProps = {
	show: false,
	message: "There was an error with the form value",
}

export default FormFieldError;
