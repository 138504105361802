import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {Token, UsersApi} from "client/cs";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import PasswordChangeSuccessModal from "./PasswordChangeSuccessModal";
import {ExPopulusButton} from "./buttons/ExPopulusButton";
import InputPassword from "./inputs/InputPassword";
import Tooltip, {DisplayBehavior} from "../components/tooltips/TooltipContainer";
import TooltipErrorMsg from "../components/tooltips/TooltipErrorMessage";
import TooltipErrorList from "../components/tooltips/TooltipErrorList";
import formStateManager, {defaultFormField, IFormState} from "../utils/formState";
import classNames from "classnames";
import getCsConfig from "../utils/getCsConfig";
import ErrorHandler from "../utils/ErrorHandler";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
}

const defaultFormState: IFormState = {
	oldPassword: {
		...defaultFormField,
	},
	newPassword: {
		...defaultFormField,
		displayBehavior: DisplayBehavior.ONFOCUS,
		errors: [{isError: true, errorText: ""}],
		validate: (value) => ([
			{isError: value?.length < 8, errorText: "Be at least 8 characters long"},
			{isError: !(/[A-Z]+/g.test(value)), errorText: "Contain at least 1 uppercase letter"},
			{isError: !(/[0-9]+/g.test(value)), errorText: "Contain at least 1 number"},
		]),
	},
	confirmPassword: {
		...defaultFormField,
		errors: [{isError: true, errorText: ""}],
		validate: (value, currentState) => ([{
			isError: !value || value !== currentState.newPassword.value,
			errorText: "Passwords do not match"
		}]),
	},
};

const UpdatePasswordModal: React.FC<IProps> = (props) => {
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [formOk, setFormOk] = useState(false);
	const [formState, setFormState] = useState<IFormState>(defaultFormState);

	/*
	*	We should aim to convert this from useMemo to useState
	*/
	const stateManager = useMemo(() => formStateManager(defaultFormState), []);


	/**
	 * sets formOk true when no form fields are in error state
	 */
	useEffect(() => {
		setFormOk(Object
			.keys(formState)
			.reduce((isOk, field) => isOk
				? !formState[field]?.errors?.some(({isError}) => isError)
				: false, true));
	}, [formState]);

	/**
	 *  Since the modal's tooltip only determines its position on modal load,
	 *  this useEffect will reset the modal content whenever isOpen changes.
	 */
	useEffect(() => {
		setFormState(stateManager.reset(defaultFormState));
	}, [props.isOpen]);

	/**
	 * Resets the form & closes the modal.
	 */
	function resetAndClose(): void {
		setFormState(stateManager.reset(defaultFormState));
		props.onClose();
	}

	/**
	 * Submit the api to update the user's password.
	 * Afterwards, show success modal.
	 *
	 * @param e
	 */
	async function submitPasswordChange(e?): Promise<void> {
		e?.preventDefault();
		if (!formOk) {
			return;
		}

		props.dispatch(incrementLoading());

		const {
			oldPassword,
			newPassword,
			confirmPassword,
		} = formState;

		try {
			await new UsersApi(getCsConfig(props.fullToken)).changePassword({
				changePasswordBody: {
					oldPassword: oldPassword.value as string || undefined,
					newPassword: newPassword.value as string || undefined,
					confirmPassword: confirmPassword.value as string || undefined,
				},
			});

			setShowSuccessModal(true);
		} catch (e) {
			props.dispatch(addError(await ErrorHandler.handleError(e)));
		}

		resetAndClose();

		props.dispatch(decrementLoading());
	}

	/**
	 * Close the success modal,
	 * reset the form & close the main modal.
	 */
	function closeSuccessModal(): void {
		setShowSuccessModal(false);
	}

	/**
	 * Updates the local form state based on the output of the form state manager
	 * to accomodate error and tooltip states
	 * @param field the field to handle
	 * @returns function to handle the form state updates
	 */

	function onEvent(field: keyof IFormState): (e: ChangeEvent<HTMLInputElement>) => void {
		return (e: ChangeEvent<HTMLInputElement>) => {
			setFormState(stateManager.setValue(field, e.target));
		}
	}

	return (
		<React.Fragment>
			<PasswordChangeSuccessModal
				isOpen={showSuccessModal}
				onClose={closeSuccessModal}
			/>

			<Modal
				centered={true}
				fade={true}
				isOpen={props.isOpen}
				toggle={resetAndClose}
				contentClassName={"update-password-modal"}
			>
				<ModalHeader
					className="update-password-modal_title"
					toggle={resetAndClose}
				>
					Change Password
				</ModalHeader>

				<ModalBody>
					<div>
						<p className="modal-title_subtitle">Enter a new password for your account</p>
					</div>

					<form onSubmit={submitPasswordChange}>
						<div className="mb-1">
							<Tooltip
								position="top"
								trigger={() => formState.oldPassword.state.showTooltip}
								tooltip={<TooltipErrorMsg errors={formState.oldPassword.errors} position={"top"}/>}
							>
								<InputPassword
									name="oldPassword"
									placeholder="Current Password"
									autoComplete="off"
									value={formState.oldPassword.value as string}
									onChange={onEvent("oldPassword")}
									onFocus={onEvent("oldPassword")}
									onBlur={onEvent("oldPassword")}
								/>
							</Tooltip>
						</div>

						<div className="mb-1">
							<Tooltip
								position="top"
								trigger={() => formState.newPassword.state.showTooltip}
								tooltip={<TooltipErrorList title={"Your password must:"}
														   errors={formState.newPassword.errors} position={"top"}/>}
							>
								<InputPassword
									name="newPassword"
									placeholder="New Password"
									autoComplete="off"
									value={formState.newPassword.value as string}
									onChange={onEvent("newPassword")}
									onFocus={onEvent("newPassword")}
									onBlur={onEvent("newPassword")}
									className={classNames({"invalid": formState.newPassword.state.showErrors && !formState.newPassword.state.isValid})}
								/>
							</Tooltip>
						</div>

						<div className="mb-1">
							<Tooltip
								position="top"
								trigger={() => formState.confirmPassword.state.showTooltip}
								tooltip={<TooltipErrorMsg errors={formState.confirmPassword.errors} position={"top"}/>}
							>
								<InputPassword
									name="confirmPassword"
									placeholder="Confirm Password"
									autoComplete="off"
									value={formState.confirmPassword.value as string}
									onChange={onEvent("confirmPassword")}
									onFocus={onEvent("confirmPassword")}
									onBlur={onEvent("confirmPassword")}
									className={classNames({"invalid": formState.confirmPassword.state.showErrors && !formState.confirmPassword.state.isValid})}
								/>
							</Tooltip>
						</div>

						<div className="update-password-modal_buttons">
							<ExPopulusButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="cancel"
								onClick={resetAndClose}
								forwardProps={{
									type: "button"
								}}
								className="w-100"
							>
								CANCEL
							</ExPopulusButton>

							<ExPopulusButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="pink"
								forwardProps={{
									type: "submit",
									disabled: !formOk
								}}
								className="w-100"
							>
								CONFIRM
							</ExPopulusButton>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(UpdatePasswordModal);
