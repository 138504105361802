import { Asset } from "client/marketplace";
import { HardCodedGameDetails } from ".";

const gameDetailsCarnivale: HardCodedGameDetails = {
	id: "xp-1",
	to: "/games/coming-soon",
	title: "Coming Soon!",
	socialIcons: false,
	playFree: false,
	desc: "A new open-world experience for players that takes place a century in the future and mankind’s chief struggle has become influence over and control of information.",
	keyArtPath: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661464976/website/images/coming-soon-2_ittw7h",
	logo: process.env.PUBLIC_URL + "/images/games/xp/xpLogo.png",
	carouselAssets: [
		{
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/coming-soon/coming-soon-2.jpeg",
			contentType: "image",
			cloudinary: {
				publicId: "website/images/coming-soon-2_ittw7h",
				actions: {
					desktop: ["f_auto", "q_auto"],
					mobile: ["f_auto", "q_auto"]
				},
			},
		} as unknown as Asset,
		{
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/coming-soon/coming-soon-1.png",
			contentType: "image",
			cloudinary: {
				publicId: "website/images/coming-soon-1_apglum",
				actions: {
					desktop: ["f_auto", "q_auto"],
					mobile: ["f_auto", "q_auto"]
				},
			},
		} as unknown as Asset,
	],
	details: {
		description: "A new open-world experience for players that takes place a century in the future and mankind’s chief struggle has become influence over and control of information.",
		details: [
			{
				label: "DEVELOPER",
				value: {display: "Ex Populus"},
			},
			{
				label: "GENRE",
				value: {display: "Action / Platformer"},
			},
			{
				label: "STATUS",
				value: {display: "Pre-Production"},
			},
			{
				label: "RELEASES",
				value: {display: "To Be Announced"},
			},
			{
				label: "PLATFORM",
				value: {display: "Windows"},
			},
		]
	},
	tags: [
		{ status: "Pre-Production" },
		{ text: "Action/Platformer" },
	],
	moreGames: [
		"lamoverse",
		"final-form",
	],
};

export default gameDetailsCarnivale;
