"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpfsHashResponseToJSON = exports.IpfsHashResponseFromJSONTyped = exports.IpfsHashResponseFromJSON = exports.instanceOfIpfsHashResponse = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the IpfsHashResponse interface.
 */
function instanceOfIpfsHashResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfIpfsHashResponse = instanceOfIpfsHashResponse;
function IpfsHashResponseFromJSON(json) {
    return IpfsHashResponseFromJSONTyped(json, false);
}
exports.IpfsHashResponseFromJSON = IpfsHashResponseFromJSON;
function IpfsHashResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ipfsHash': !(0, runtime_1.exists)(json, 'ipfsHash') ? undefined : json['ipfsHash'],
    };
}
exports.IpfsHashResponseFromJSONTyped = IpfsHashResponseFromJSONTyped;
function IpfsHashResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ipfsHash': value.ipfsHash,
    };
}
exports.IpfsHashResponseToJSON = IpfsHashResponseToJSON;
