import React, {ChangeEvent, useEffect, useState} from "react";
import {RouteProps} from "react-router";
import {Link, useHistory} from "react-router-dom";
import {Container, Input} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {ApiError, LoginBody, TokensApi, UsersApi} from "client/cs";
import {exPopulusRedirectMap, validateRedirectRoute, XP_REDIRECTS} from "shared";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";
import getCsConfig from "../utils/getCsConfig";
import InputPassword from "../components/inputs/InputPassword";
import FormFieldError from "../components/FormFieldError";
import {IStore} from "../redux/defaultStore";
import {updateUser} from "../utils/updateUserData";
import {addError, decrementLoading, incrementLoading, login, logout} from "../redux/meta/MetaActions";
import XpLogoLink from "../components/XpLogoLink";

const defaultLoginForm: LoginBody = {
	email: "",
	password: "",
}

interface IProps extends RouteProps {}

const Login: React.FC<IProps> = (props) => {
	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const redirect: string = query.get("redirect"); // pulls the specified redirect from the query if exists (i.e. being sent here from DAG, IP, etc. to login before being allowed to proceed with a CTA on the respective subdomain)
	const [loginForm, setLoginForm] = useState<LoginBody>(defaultLoginForm);
	const [firstRender, setFirstRender] = useState(true); // We use this to prevent sending the user to account details page for a brief second before a redirect (when applicable), Because we have a useEffect listening for token changing, we'll only let it run its logic on the first render, not after the token gets updated from login.
	const [signInEnabled, setSignInEnabled] = useState(false);
	const [formError, setFormError] = useState({ show: false, message: "The email and/or password is incorrect" });
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const dispatch = useDispatch();

	useEffect(() => {
		if (fullToken && firstRender) {
			verifyTokenExpiration().then().catch();
		}

		setFirstRender(false);
	}, [JSON.stringify(fullToken)]);

	useEffect(() => {
		setSignInEnabled(loginForm.email?.length > 0 && loginForm.password?.length > 0);
	}, [loginForm]);

	/**
	 * Checks if the existing token is already expired, and if it is, dispatch redux logout action with redirecting,
	 * as we're already on the login page so the user can log in again.
	 *
	 * If the token is still valid, then navigate to the account details page.
	 */
	async function verifyTokenExpiration(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new TokensApi(getCsConfig(fullToken)).checkTokenExpiration({
				tokenBody: {
					token: fullToken?.token,
				},
			});

			if (res.expired) {
				await dispatch(logout());
			} else if (validateRedirectRoute(redirect as XP_REDIRECTS)) {
				window.location.href = exPopulusRedirectMap[redirect]
			} else {
				history.replace("/account-details");
			}
		} catch (e) {
			await dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	/**
	 * Dynamically on change for the text inputs.
	 *
	 */
	function dynamicOnChange(key: keyof LoginBody): (e: ChangeEvent<HTMLInputElement>) => void {
		return (e) => {
			setLoginForm({
				...loginForm,
				[key]: e.target.value,
			});
		}
	}

	async function handleFormError(formErrorResponse?: {json: () => Promise<ApiError>}) {
		try {
			const json = await (formErrorResponse as {json: () => Promise<ApiError>}).json();
			setFormError({ ...formError, ...json, show: true });
		} catch (error) {
			setFormError({ ...formError, show: true});
		}
	}

	/**
	 * Call the api to submit the login form.
	 *
	 */
	async function submitLogin(e?): Promise<void> {
		e?.preventDefault();
		dispatch(incrementLoading());

		setFormError({ ...formError, show: false });

		try {
			const res = await new UsersApi(getCsConfig()).login({
				loginBody: {
					email: loginForm.email || undefined,
					password: loginForm.password || undefined,
				},
			});

			dispatch(login(res));
			await updateUser();

			if (validateRedirectRoute(redirect as XP_REDIRECTS)) {
				window.location.href = exPopulusRedirectMap[redirect];
				return;
			} else {
				history.push("/account-details");
			}

		} catch (error) {
			// reset the password field
			setLoginForm({ ...loginForm, password: "" });

			await handleFormError(error);
		}

		dispatch(decrementLoading());
	}

	return (
		<Container className="login">
			<div className="login_content">
				<XpLogoLink className={"logo"} />

				<div className="login_content_form-container">
					<div className="login_content_form-container_header">
						<div className="title">Welcome back!</div>
						<div className="subtitle">Sign in to your Ex Populus Account</div>
					</div>

					<form onSubmit={submitLogin}>
						<Input
							name="email"
							type="email"
							placeholder="Email"
							value={loginForm.email}
							onChange={dynamicOnChange("email")}
						/>

						<InputPassword
							name="password"
							placeholder="Password"
							value={loginForm.password}
							onChange={dynamicOnChange("password")}
						/>

						<FormFieldError { ...formError } />

						<div className="login_content_extras">
							<p className="login_content_extras_forgot-password">
								<Link to="/auth/forgot-password">
									Forgot Password?
								</Link>
							</p>
						</div>

						<ExPopulusButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="pink"
							forwardProps={{
								type: "submit",
								disabled: !signInEnabled,
							}}
							className="login_content_form-container_submit-button"
						>
							SIGN IN
						</ExPopulusButton>

						<div className="login_content_extras">
							<p className="login_content_extras_signup-message">
								Don't have an account?
								<br />
								<Link to="/register">Create account here</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
};

export default Login;
