import React from "react";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {ReactComponent as FFHeader} from "../../svgs/ff-header.svg";

export const HomepageIronPigeons: React.FC = () => {

	return (
		<section className="homepage-iron-pigeons">
			<div className="homepage-iron-pigeons_container">
				<div className="homepage-iron-pigeons_container_content">
					<div className="homepage-iron-pigeons_container_content_header">
						<img
							src={"https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1682534519/website/images/homepage/Iron-Pigeons-logo_hugpid"}
							alt={"Iron Pigeons"}
						/>
					</div>
					<span className="homepage-iron-pigeons_container_content_subtitle">
					The champ wanted to share his passion for collecting and raising Pigeons with you by making a unique set of pigeons for everyone to collect & enjoy in Final Form.
				</span>

					<ExPopulusButton
						color="dark-on-white"
						className="homepage-iron-pigeons_container_content_button"
						to="/ironpigeons"
					>
						Learn More
					</ExPopulusButton>
				</div>
			</div>

			<div className="homepage-iron-pigeons_gradient"/>
		</section>
	)
}
