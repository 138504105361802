import React from "react";
import {Container} from "reactstrap";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com";

const dataElement = {
    assetDesktop: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1666302300/website/images/dag/playFree/desktop_dag_srvzaj.jpg"),
    assetTablet: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1666302300/website/images/dag/playFree/tablet_dag_vkhzee.png"),
    assetMobile: new URL("https://res.cloudinary.com/ex-populus/image/upload/v1666302300/website/images/dag/playFree/mobile_dag_vlhlho.png"),
    imageAltText: "pledge",
    header: "Pledge your DAGs in the game Final Form™",
    body: "Final Form has been designed from the start as a card collector’s dream game. The game focuses on the evolution of player cards which has been a central theme of the trading card genre for decades.",
}

const DagHomepagePlayFree: React.FC = () => (
    <section className={"homepage-template-play-free"}>
        <div className={"homepage-template-play-free_overlay"}/>

        <div className={"homepage-template-play-free_parent-container_background-desktop"}>
            <MediaAssetViewer
                asset={dataElement.assetDesktop}
                imageAltText={dataElement.imageAltText}
                contentType={"image"}
                elementWidth={{
                    desktopWidth: 1920,
                    mobileWidth: 1920,
                }}
            />
        </div>

        <div className={"homepage-template-play-free_parent-container_background-tablet"}>
            <MediaAssetViewer
                asset={dataElement.assetDesktop}
                imageAltText={dataElement.imageAltText}
                contentType={"image"}
                elementWidth={{
                    desktopWidth: 1920,
                    mobileWidth: 1920,
                }}
            />
        </div>

        <div className={"homepage-template-play-free_parent-container_background-mobile"}>
            <MediaAssetViewer
                asset={dataElement.assetMobile}
                imageAltText={dataElement.imageAltText}
                contentType={"image"}
                elementWidth={{
                    desktopWidth: 576,
                    mobileWidth: 576,
                }}
            />
        </div>

        <Container className={"homepage-template-play-free_parent-container"}>
            <div className={"homepage-template-play-free_container"}>
                <div className={"homepage-template-play-free_container_text"}>
                    <div className={"homepage-template-play-free_container_text-container"}>
                        <div className={"homepage-template-play-free_container_text-container_header"}>
                            <h2>{dataElement.header}</h2>
                        </div>

                        <p className={"homepage-template-play-free_container_text-container_body"}>
                            {dataElement.body}
                        </p>

                        <ExPopulusButton
                            color="white"
                            href={`${WEBSITE_URL}/games/final-form`}
                            className={"homepage-template-play-free_container_button"}
                        >
                            Play Free
                        </ExPopulusButton>
                    </div>
                </div>
            </div>
        </Container>
    </section>
);

export {DagHomepagePlayFree};
