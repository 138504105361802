import React, {HTMLAttributes} from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import SocialMediaIcon, {IconType} from "./SocialMediaIcon";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

interface IProps extends HTMLAttributes<HTMLElement> {
    id: string;
    className?: string;
}

const OurCommunity: React.FC<IProps> = (props) => {

    return (
        <section
            className={classNames("our-community", props.className)}
            id={props.id}
        >
            <Container>
                <div className={"info"}>
                    <header>
                        <h3>Join The Ex Populus Movement</h3>
                        <img
                            src={"https://storage.googleapis.com/ex-populus-marketplace_static/about-page/updated_titles/community_svg.svg"}
                            alt={"Community"}/>
                    </header>
                    <p>The Ex Populus community is among the most loyal and dedicated in Web3. The community has grown
                        organically since 2021 into a base of thousands of fans passionate about gaming, web3, and
                        culture. Ex Populus invites you to join the official Discord or follow us on Twitter.</p>
                    <div className={"social-media-icons"}>
                        <SocialMediaIcon iconType={IconType.DISCORD}/>
                        <SocialMediaIcon iconType={IconType.TWITTER}/>
                        <SocialMediaIcon iconType={IconType.INSTAGRAM}/>
                    </div>
                </div>

                <div className={"community-bg-gradient"}></div>

                <div className="community-cutout-image">
                    <MediaAssetViewer
                        asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/our-community-cutout_2x_v68wn7")}
                        imageAltText="Community Cutout"
                        contentType={"image"}
                    />
                </div>

            </Container>
        </section>
    );
}

export default OurCommunity;
