import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface IProps {
    toUrl?: string;
    imgUrl?: string;
		altText?: string;
		className?: string;
}

const XpLogoLink: React.FC<IProps> = (props) => {
	return (
		<div className={classNames("xp-logo-link", props.className)}>
			<Link tabIndex={0} to={"/"} className={"link"}>
				<img src={props.imgUrl} alt={props.altText} />
			</Link>
		</div>
	);
};

XpLogoLink.defaultProps = {
	toUrl: "/",
	imgUrl: "https://storage.googleapis.com/ex-populus-marketplace_static/xp-logo.svg",
	altText: "Ex Populus",
}

export default XpLogoLink;
