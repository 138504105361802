import {MutableRefObject} from "react";

export enum ProjectPageSection {
	HOME = "HOME",
	PLAY_FREE = "PLAY_FREE",
	ABOUT = "ABOUT",
	GET_NOW = "GET_NOW",
	TRAITS_AND_RARITY = "TRAITS_AND_RARITY",
	SET = "SET",
	FAQ = "FAQ",
	TEAM = "TEAM",
}

export interface IProjectPageSectionConfig {
	label: string;
	route: string;
}

export type ProjectPageSectionConfigMap = {
	[key in ProjectPageSection]?: IProjectPageSectionConfig;
}

export type ProjectPageSectionRefMap = {
	[key in ProjectPageSection]?: MutableRefObject<HTMLDivElement>;
}

/**
 * Utility for the refactored sub-header.
 * This runs a loop to check if the specific scrollY position is less than the upcoming section, in which case
 * we will set the "current" scrolled section (the section that comes before the next one) as the selected section.
 *
 * @param scrollY
 * @param refList
 */
export function projectHeaderUtils(scrollY: number, refList: ProjectPageSectionRefMap = {}): ProjectPageSection {
	const refArrayTuples: Array<[string, MutableRefObject<HTMLDivElement>]> = Object.entries(refList);

	for (let i = 0; i < refArrayTuples.length; i += 1) {
		// First, check if we are iterating over the final item in the array, and if we are, we just want to check
		// if we're scrolled past it.
		const finalItem: [string, MutableRefObject<HTMLDivElement>] = refArrayTuples[refArrayTuples.length - 1];
		if (scrollY >= finalItem[1]?.current?.offsetTop) {
			return finalItem[0] as unknown as ProjectPageSection;
		}

		// If we're evaluating any item besides the final, we check if our current scrollY position is less than
		// the next section's ref.
		if (scrollY < refArrayTuples[i + 1]?.[1]?.current?.offsetTop) {
			return refArrayTuples[i]?.[0] as unknown as ProjectPageSection;
		}
	}
}
