"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationInfoToJSON = exports.PaginationInfoFromJSONTyped = exports.PaginationInfoFromJSON = exports.instanceOfPaginationInfo = void 0;
/**
 * Check if a given object implements the PaginationInfo interface.
 */
function instanceOfPaginationInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "enablePrevious" in value;
    isInstance = isInstance && "enableNext" in value;
    isInstance = isInstance && "lowerRange" in value;
    isInstance = isInstance && "upperRange" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "pages" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "offset" in value;
    return isInstance;
}
exports.instanceOfPaginationInfo = instanceOfPaginationInfo;
function PaginationInfoFromJSON(json) {
    return PaginationInfoFromJSONTyped(json, false);
}
exports.PaginationInfoFromJSON = PaginationInfoFromJSON;
function PaginationInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enablePrevious': json['enablePrevious'],
        'enableNext': json['enableNext'],
        'lowerRange': json['lowerRange'],
        'upperRange': json['upperRange'],
        'total': json['total'],
        'pages': json['pages'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}
exports.PaginationInfoFromJSONTyped = PaginationInfoFromJSONTyped;
function PaginationInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enablePrevious': value.enablePrevious,
        'enableNext': value.enableNext,
        'lowerRange': value.lowerRange,
        'upperRange': value.upperRange,
        'total': value.total,
        'pages': value.pages,
        'limit': value.limit,
        'offset': value.offset,
    };
}
exports.PaginationInfoToJSON = PaginationInfoToJSON;
