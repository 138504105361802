"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSubscribersResponseToJSON = exports.GetSubscribersResponseFromJSONTyped = exports.GetSubscribersResponseFromJSON = exports.instanceOfGetSubscribersResponse = void 0;
var PaginationInfo_1 = require("./PaginationInfo");
var Subscriber_1 = require("./Subscriber");
/**
 * Check if a given object implements the GetSubscribersResponse interface.
 */
function instanceOfGetSubscribersResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "subscribers" in value;
    return isInstance;
}
exports.instanceOfGetSubscribersResponse = instanceOfGetSubscribersResponse;
function GetSubscribersResponseFromJSON(json) {
    return GetSubscribersResponseFromJSONTyped(json, false);
}
exports.GetSubscribersResponseFromJSON = GetSubscribersResponseFromJSON;
function GetSubscribersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoFromJSON)(json['paginationInfo']),
        'subscribers': (json['subscribers'].map(Subscriber_1.SubscriberFromJSON)),
    };
}
exports.GetSubscribersResponseFromJSONTyped = GetSubscribersResponseFromJSONTyped;
function GetSubscribersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoToJSON)(value.paginationInfo),
        'subscribers': (value.subscribers.map(Subscriber_1.SubscriberToJSON)),
    };
}
exports.GetSubscribersResponseToJSON = GetSubscribersResponseToJSON;
