import { DisplayBehavior, ITooltipError } from "../components/tooltips/TooltipContainer";

export type FormFieldValue = string | number | boolean | undefined;

export interface IFormField {
	value: FormFieldValue
	displayBehavior: DisplayBehavior,
	validate(value: string, currentState?: IFormState): Array<ITooltipError>;
	state: {
		isFocused: boolean;
		isValid: boolean;
		showErrors: boolean;
		showTooltip: boolean;
	};
	errors: Array<ITooltipError>;
}

export interface IFormState {
	[key: string]: IFormField;
}

/**
 * Convenience definiton for common for field values and behaviors
 */
export const defaultFormField: IFormField = {
	value: "",
	displayBehavior: DisplayBehavior.ONERROR,
	validate: (value) => [{ isError: false, errorText: "" }],
	state: {
		isFocused: false,
		isValid: true,
		showErrors: false,
		showTooltip: false,
	},
	errors: [{ isError: false, errorText: "" }],
}

/**
 * 
 * @param initialState the IFormState object that contains default values and calculations
 * @returns the form state manipulation object
 */
const formState = (initialState: IFormState) => {
	type State = typeof initialState;
	type Field = keyof State;
	type SetValue = {
    (field: Field, value: HTMLInputElement): State;
    (field: Field, value: string | boolean | number): State;
	}

	let currentState: State = { ...initialState };

	const isShowTooltip = (behavior: DisplayBehavior, errors: boolean, focused: boolean) => {
		switch (behavior) {
			case DisplayBehavior.ALWAYS:
				return true;

			case DisplayBehavior.NEVER:
				return false;

			case DisplayBehavior.ONFOCUS:
				return focused;

			case DisplayBehavior.ONERROR:
				return focused && errors;
		
			default:
				return false;
		}
	};

	/**
	 * Reset the form state using either the provided new state or
	 * a copy of the original state
	 * @param newState The new form state to use, original state used if omitted
	 * @returns the reset form state
	 */
	const reset = (newState?: IFormState) => {
		currentState = newState ? { ...newState } : { ...initialState };
		return currentState;
	}

	/**
	 * @param field the field to get the value from
	 * @returns the value for the named field
	 */
	const getValue = (field: Field): FormFieldValue => currentState[field].value;

	/**
	 * Convienience methods to cast returned value
	 */
	const getValueAsString = (field: Field): string => getValue(field) as string;
	const getValueAsNumber = (field: Field): number => getValue(field) as number;
	const getValueAsBool = (field: Field): boolean => getValue(field) as boolean;
	
	/**
	 * 
	 * @param field the field to set the value for
	 * @param value the value to set. if an HTMLInputElement, use the element's value
	 * @returns the updated form state
	 */
	const setValue: SetValue = (field: keyof IFormField, value) => {
		if (value === undefined || value === null) {
			return currentState;
		}

		const fieldElement = value.value !== undefined ? value : undefined;
		const fieldValue = fieldElement ? fieldElement.value : value;
		const validationErrors = currentState[field].validate(fieldValue, currentState);
		const anyErrors = validationErrors.some(({ isError }) => isError);

		// if we weren't passed an element then we can't know if it is focused or not, so assume false
		const isFocused = fieldElement && fieldElement === document.activeElement;

		currentState = {
			...currentState,
			[field]: {
				...currentState[field],
				value: fieldValue,
				errors: validationErrors,
				state: {
					...currentState[field].state,
					showErrors: true,
					isValid: !anyErrors,
					showTooltip: isShowTooltip(currentState[field].displayBehavior, anyErrors, isFocused),
				},
			}
		};

		return currentState;
	};

	/**
	 * Convenience method to simply turn the tooltip on/off
	 * @param field the field to set the tooltip state on
	 * @param show true to show the tooltip, false to hide it
	 * @returns the updated form state
	 */
	const showTooltip = (field: Field, show: boolean): State => {
		currentState = {
			...currentState,
			[field]: {
				...currentState[field],
				state: {
					...currentState[field].state,
					showTooltip: show,
				}
			}
		};

		return currentState;
	}

	return {
		getValue,
		getValueAsString,
		getValueAsNumber,
		getValueAsBool,
		setValue,
		showTooltip,
		reset,
		getState: () => currentState,
	}
};

export default formState;