"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminAllOfToJSON = exports.AdminAllOfFromJSONTyped = exports.AdminAllOfFromJSON = exports.instanceOfAdminAllOf = void 0;
/**
 * Check if a given object implements the AdminAllOf interface.
 */
function instanceOfAdminAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "username" in value;
    return isInstance;
}
exports.instanceOfAdminAllOf = instanceOfAdminAllOf;
function AdminAllOfFromJSON(json) {
    return AdminAllOfFromJSONTyped(json, false);
}
exports.AdminAllOfFromJSON = AdminAllOfFromJSON;
function AdminAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': json['username'],
    };
}
exports.AdminAllOfFromJSONTyped = AdminAllOfFromJSONTyped;
function AdminAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
    };
}
exports.AdminAllOfToJSON = AdminAllOfToJSON;
