"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenToJSON = exports.TokenFromJSONTyped = exports.TokenFromJSON = exports.instanceOfToken = void 0;
var UserType_1 = require("./UserType");
/**
 * Check if a given object implements the Token interface.
 */
function instanceOfToken(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
exports.instanceOfToken = instanceOfToken;
function TokenFromJSON(json) {
    return TokenFromJSONTyped(json, false);
}
exports.TokenFromJSON = TokenFromJSON;
function TokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, UserType_1.UserTypeFromJSON)(json['type']),
        'token': json['token'],
    };
}
exports.TokenFromJSONTyped = TokenFromJSONTyped;
function TokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, UserType_1.UserTypeToJSON)(value.type),
        'token': value.token,
    };
}
exports.TokenToJSON = TokenToJSON;
