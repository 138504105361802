"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselSlideBodyToJSON = exports.CarouselSlideBodyFromJSONTyped = exports.CarouselSlideBodyFromJSON = exports.instanceOfCarouselSlideBody = void 0;
var runtime_1 = require("../runtime");
var CarouselCta_1 = require("./CarouselCta");
/**
 * Check if a given object implements the CarouselSlideBody interface.
 */
function instanceOfCarouselSlideBody(value) {
    var isInstance = true;
    isInstance = isInstance && "asset" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
exports.instanceOfCarouselSlideBody = instanceOfCarouselSlideBody;
function CarouselSlideBodyFromJSON(json) {
    return CarouselSlideBodyFromJSONTyped(json, false);
}
exports.CarouselSlideBodyFromJSON = CarouselSlideBodyFromJSON;
function CarouselSlideBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'asset': json['asset'],
        'title': json['title'],
        'description': json['description'],
        'detailsToggle': !(0, runtime_1.exists)(json, 'detailsToggle') ? undefined : json['detailsToggle'],
        'primaryButton': !(0, runtime_1.exists)(json, 'primaryButton') ? undefined : (0, CarouselCta_1.CarouselCtaFromJSON)(json['primaryButton']),
        'secondaryButton': !(0, runtime_1.exists)(json, 'secondaryButton') ? undefined : (0, CarouselCta_1.CarouselCtaFromJSON)(json['secondaryButton']),
    };
}
exports.CarouselSlideBodyFromJSONTyped = CarouselSlideBodyFromJSONTyped;
function CarouselSlideBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'asset': value.asset,
        'title': value.title,
        'description': value.description,
        'detailsToggle': value.detailsToggle,
        'primaryButton': (0, CarouselCta_1.CarouselCtaToJSON)(value.primaryButton),
        'secondaryButton': (0, CarouselCta_1.CarouselCtaToJSON)(value.secondaryButton),
    };
}
exports.CarouselSlideBodyToJSON = CarouselSlideBodyToJSON;
