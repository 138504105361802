import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import MarketplaceCheckbox from "./MarketplaceCheckbox";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

interface IProps {
    isOpen: boolean;
    isChecked: boolean;
    onClose: () => void;
    onConfirmClick: () => void;
}

const UpdateEmailPreferencesModal: React.FC<IProps> = (props) => {

    const [isChecked, setIsChecked] = useState(props.isChecked);
    const [confirmUpdate, setConfirmUpdate] = useState(false);

    function resetAndClose(): void {
        props.onClose();
        setConfirmUpdate(false)
    }

    function updateEmailPreferences(): void {
        setIsChecked(!isChecked);
        setConfirmUpdate(!confirmUpdate);
    }

    function submitEmailPreferenceChange(): void {
        props.onConfirmClick();
        resetAndClose();
    }

    return (
        <React.Fragment>
            <Modal
                centered={true}
                fade={true}
                isOpen={props.isOpen}
                toggle={resetAndClose}
                contentClassName={"update-email-preferences-modal"}
            >
                <ModalHeader
                    className="update-email-preferences-modal_title"
                    toggle={resetAndClose}
                >
                    Update Email Preferences
                </ModalHeader>

                <ModalBody>
                    <MarketplaceCheckbox
                        checked={isChecked}
                        onChange={updateEmailPreferences}
                        className="update-email-preferences-modal_checkboxes_box-item"
                    >
                        <p>I would like to receive SMS updates about Ex Populus drops</p>
                    </MarketplaceCheckbox>

                    <div className="update-email-preferences-modal_buttons">
                        <ExPopulusButton
                            color="cancel"
                            onClick={resetAndClose}
                            className="w-100"
                        >
                            CANCEL
                        </ExPopulusButton>

                        <ExPopulusButton
                            <React.ButtonHTMLAttributes<HTMLButtonElement>>
                            color="pink"
                            onClick={submitEmailPreferenceChange}
                            className="w-100"
                            forwardProps={{
                                type: "submit",
                                disabled: !confirmUpdate,
                            }}
                        >
                            CONFIRM
                        </ExPopulusButton>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default UpdateEmailPreferencesModal;
