"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetListingsResponseAllOfToJSON = exports.GetListingsResponseAllOfFromJSONTyped = exports.GetListingsResponseAllOfFromJSON = exports.instanceOfGetListingsResponseAllOf = void 0;
var Listing_1 = require("./Listing");
/**
 * Check if a given object implements the GetListingsResponseAllOf interface.
 */
function instanceOfGetListingsResponseAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "listings" in value;
    return isInstance;
}
exports.instanceOfGetListingsResponseAllOf = instanceOfGetListingsResponseAllOf;
function GetListingsResponseAllOfFromJSON(json) {
    return GetListingsResponseAllOfFromJSONTyped(json, false);
}
exports.GetListingsResponseAllOfFromJSON = GetListingsResponseAllOfFromJSON;
function GetListingsResponseAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'listings': (json['listings'].map(Listing_1.ListingFromJSON)),
    };
}
exports.GetListingsResponseAllOfFromJSONTyped = GetListingsResponseAllOfFromJSONTyped;
function GetListingsResponseAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'listings': (value.listings.map(Listing_1.ListingToJSON)),
    };
}
exports.GetListingsResponseAllOfToJSON = GetListingsResponseAllOfToJSON;
