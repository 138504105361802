import React from "react";
import classNames from "classnames";

interface IProps {
	children: string;
	className?: string;
}

const ExPopulusFooterLabel: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("ex-populus-footer-label", props.className)}>
			<h6 className="ex-populus-footer-label_title">
				{props.children}
			</h6>
		</div>
	);
};

export default ExPopulusFooterLabel;
