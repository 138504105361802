import React, {useEffect} from "react";
import {useLocation, withRouter} from "react-router-dom";

const ScrollToTop: React.FC = (props) => {

	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return null;
}

export default withRouter(ScrollToTop);
