"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DagToJSON = exports.DagFromJSONTyped = exports.DagFromJSON = exports.instanceOfDag = void 0;
var runtime_1 = require("../runtime");
var NftField_1 = require("./NftField");
/**
 * Check if a given object implements the Dag interface.
 */
function instanceOfDag(value) {
    var isInstance = true;
    isInstance = isInstance && "index" in value;
    return isInstance;
}
exports.instanceOfDag = instanceOfDag;
function DagFromJSON(json) {
    return DagFromJSONTyped(json, false);
}
exports.DagFromJSON = DagFromJSON;
function DagFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'index': json['index'],
        '_1_house': !(0, runtime_1.exists)(json, '1_house') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['1_house']),
        '_2_health': !(0, runtime_1.exists)(json, '2_health') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['2_health']),
        '_3_attack': !(0, runtime_1.exists)(json, '3_attack') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['3_attack']),
        '_4_ability_one': !(0, runtime_1.exists)(json, '4_ability_one') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['4_ability_one']),
        '_4_ability_two': !(0, runtime_1.exists)(json, '4_ability_two') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['4_ability_two']),
        '_4_ability_three': !(0, runtime_1.exists)(json, '4_ability_three') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['4_ability_three']),
        '_5_rarity': !(0, runtime_1.exists)(json, '5_rarity') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['5_rarity']),
        '_6_power': !(0, runtime_1.exists)(json, '6_power') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['6_power']),
        'Theme': !(0, runtime_1.exists)(json, 'Theme') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['Theme']),
        'A_background': !(0, runtime_1.exists)(json, 'A_background') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['A_background']),
        'B_costume_back': !(0, runtime_1.exists)(json, 'B_costume_back') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['B_costume_back']),
        'C_hairhat_back': !(0, runtime_1.exists)(json, 'C_hairhat_back') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['C_hairhat_back']),
        'D_body': !(0, runtime_1.exists)(json, 'D_body') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['D_body']),
        'E_head': !(0, runtime_1.exists)(json, 'E_head') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['E_head']),
        'F_costume_front': !(0, runtime_1.exists)(json, 'F_costume_front') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['F_costume_front']),
        'G_eyesnose_back': !(0, runtime_1.exists)(json, 'G_eyesnose_back') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['G_eyesnose_back']),
        'H_hairhat_mid': !(0, runtime_1.exists)(json, 'H_hairhat_mid') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['H_hairhat_mid']),
        'I_mouthchin': !(0, runtime_1.exists)(json, 'I_mouthchin') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['I_mouthchin']),
        'J_eyesnose_front': !(0, runtime_1.exists)(json, 'J_eyesnose_front') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['J_eyesnose_front']),
        'K_hairhat_front': !(0, runtime_1.exists)(json, 'K_hairhat_front') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['K_hairhat_front']),
        'L_helditem': !(0, runtime_1.exists)(json, 'L_helditem') ? undefined : (0, NftField_1.NftFieldFromJSON)(json['L_helditem']),
    };
}
exports.DagFromJSONTyped = DagFromJSONTyped;
function DagToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'index': value.index,
        '1_house': (0, NftField_1.NftFieldToJSON)(value._1_house),
        '2_health': (0, NftField_1.NftFieldToJSON)(value._2_health),
        '3_attack': (0, NftField_1.NftFieldToJSON)(value._3_attack),
        '4_ability_one': (0, NftField_1.NftFieldToJSON)(value._4_ability_one),
        '4_ability_two': (0, NftField_1.NftFieldToJSON)(value._4_ability_two),
        '4_ability_three': (0, NftField_1.NftFieldToJSON)(value._4_ability_three),
        '5_rarity': (0, NftField_1.NftFieldToJSON)(value._5_rarity),
        '6_power': (0, NftField_1.NftFieldToJSON)(value._6_power),
        'Theme': (0, NftField_1.NftFieldToJSON)(value.Theme),
        'A_background': (0, NftField_1.NftFieldToJSON)(value.A_background),
        'B_costume_back': (0, NftField_1.NftFieldToJSON)(value.B_costume_back),
        'C_hairhat_back': (0, NftField_1.NftFieldToJSON)(value.C_hairhat_back),
        'D_body': (0, NftField_1.NftFieldToJSON)(value.D_body),
        'E_head': (0, NftField_1.NftFieldToJSON)(value.E_head),
        'F_costume_front': (0, NftField_1.NftFieldToJSON)(value.F_costume_front),
        'G_eyesnose_back': (0, NftField_1.NftFieldToJSON)(value.G_eyesnose_back),
        'H_hairhat_mid': (0, NftField_1.NftFieldToJSON)(value.H_hairhat_mid),
        'I_mouthchin': (0, NftField_1.NftFieldToJSON)(value.I_mouthchin),
        'J_eyesnose_front': (0, NftField_1.NftFieldToJSON)(value.J_eyesnose_front),
        'K_hairhat_front': (0, NftField_1.NftFieldToJSON)(value.K_hairhat_front),
        'L_helditem': (0, NftField_1.NftFieldToJSON)(value.L_helditem),
    };
}
exports.DagToJSON = DagToJSON;
