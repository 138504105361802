import React from "react";
import classNames from "classnames";
import {Spinner} from "reactstrap";

interface IProps {
	className?: string;
}

const LocalLoader: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("local-loader", props.className)}>
			<Spinner color="darkGrey"/>
		</div>
	);
};

export default LocalLoader;
