import navigateToURL from "./navigateToURL";
import {TokenType} from "./TokenType";

const secondaryMarketURLs = {
  [TokenType.IRON_PIGEONS]: "https://magiceden.io/marketplace/iron_pigeons",
  [TokenType.DAG]: undefined,
};

/**
 * Opens the secondary market page for the given token type if one exists
 *
 * @param tokenType {TokenType} - The token type
 * @param newWindow {boolean} [true] - Forces the secondary market url to open in a new window
 * @returns {URL} The URL for the secondary market or undefined if no URL is created
 */
const openSecondaryMarketURL = (tokenType: TokenType, newWindow = true) => {
  let url: URL;
  try {
    url = new URL(secondaryMarketURLs[tokenType])
    navigateToURL(url.href, newWindow);
  } catch(e) {
    console.warn(`No secondary market URL for token type ${tokenType}`);
  } finally {
    return url;
  }
};

export default openSecondaryMarketURL;
