import React from "react";
import {Container} from "reactstrap";
import {Asset} from "client/marketplace";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {fileIsImage} from "../../utils/fileTypeChecks";
import openSecondaryMarketURL from "../../utils/openSecondaryMarketURL";
import {TokenType} from "../../utils/TokenType";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

const body: IAbout = {
    header: "Backstory",
    subheader: "GigaChad and Absolute Unit made sweet love and had a lot of babies...",
    body: "These DAGs are decentralized because they live on the blockchain. These DAGs are autonomous because let’s be honest, nobody tells a DAG what to do. Now these DAGs are a part of the Ex Populus trading card game!",
    cardImageAlt: "DAG Cards Stack",
    ctaText: (
        <p>
            DAGs are now available to play in <a
            href={`${process.env.REACT_APP_WEBSITE_URL}/games/final-form`} target="_blank"
            rel="noopener noreferrer">Final Form™</a>
        </p>
    ),
    asset: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/about/dag-cards-about.png",
        contentType: "image",
        cloudinary: {
            publicId: "website/images/dag-cards-about_o8jj2p",
            actions: {
                desktop: ["f_auto", "q_auto"],
                mobile: ["f_auto", "q_auto"]
            },
        },
    } as unknown as Asset,
}

interface IAbout {
    asset: Asset;
    cardImageAlt: string;
    header: string;
    subheader: string;
    body: string;
    ctaText?: string | JSX.Element;
}

interface IProps {
    tokenType: TokenType;
    onClickGetCardsCallback?: () => void;
}

const DagHomepageAbout: React.FC<IProps> = (props) => {

    const onClickGetCards = () => {
        openSecondaryMarketURL(props.tokenType);

        props.onClickGetCardsCallback();
    }

    return (
        <section className={"homepage-template-about"}>
            <Container className={"container"}>
                <div className={"homepage-template-about_container"}>

                    <div className={"homepage-template-about_container_text"}>
                        <div className={"homepage-template-about_container_text_header"}>
                            {body.header}
                        </div>
                        <div className={"homepage-template-about_container_text_subheader"}>
                            {body.subheader}
                        </div>
                        <p className={"homepage-template-about_container_text_body"}>
                            {body.body}
                        </p>


                        {body.ctaText && (
                            <div className={"homepage-template-about_container_text_cta"}>
                                <div className={"homepage-template-about_container_text_cta_text"}>
                                    {body.ctaText}
                                </div>

                                <ExPopulusButton
                                    color="white-on-pink"
                                    to="#get-cards"
                                    onClick={onClickGetCards}
                                    className={"homepage-template-about_container_text_cta_button"}
                                >
                                    Get Cards
                                </ExPopulusButton>
                            </div>
                        )}
                    </div>

                    <div className={"homepage-template-about_container_card"}>
                        <div className={"homepage-template-about_container_card_size"}>
                            <MediaAssetViewer
                                asset={body.asset}
                                imageAltText={body.cardImageAlt}
                                contentType={fileIsImage(body.asset) ? "image" : "video"}
                                elementWidth={{
                                    desktopWidth: 520,
                                    mobileWidth: 520,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

DagHomepageAbout.defaultProps = {
    onClickGetCardsCallback: () => {
    },
}

export {DagHomepageAbout};
