import React from "react";
import {HomepageHero} from "../components/homepage/HomepageHero";
import {HomepageInvestors} from "../components/homepage/HomepageInvestors";
import {HomepageLamo} from "../components/homepage/HomepageLamo";
import {HomepageFinalForm} from "../components/homepage/HomepageFinalForm";
import {HomepageIronPigeons} from "../components/homepage/HomepageIronPigeons";
import {HomepageLabs} from "../components/homepage/HomepageLabs";
import {HomepageNews} from "../components/homepage/HomepageNews";
import {HomepageCommunity} from "../components/homepage/HomepageCommunity";
import {HomepageXai} from "../components/homepage/HomepageXai";

export const Homepage: React.FC = () => {
	return (
		<section>
			<HomepageHero/>
			<HomepageInvestors/>
			<HomepageLamo/>
			<HomepageFinalForm/>
			<HomepageIronPigeons/>
			<HomepageLabs/>
			<HomepageXai/>
			<HomepageNews/>
			<HomepageCommunity/>
		</section>
	);
};
