import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { useRect } from "../../utils/hooks/useRect";
import {
	arrowBorderWidth,
	commonArrowStyles,
	defaultArrowPositionalStyles,
	IArrowPositionalStyles,
	ITooltipError,
	TooltipPosition,
} from "./TooltipContainer";

interface IProps {
	errors: Array<ITooltipError>,
	position: TooltipPosition,
	width?: number,
	theme?: "dark" | "light",
}

const TooltipErrorMsg: React.FC<IProps> = ({ errors, position, width , theme}) => {
	const [arrowPositionalStyles, setArrowPositionalStyles] = useState<IArrowPositionalStyles>(defaultArrowPositionalStyles);

	const ref = React.createRef<HTMLDivElement>();
	const rect = useRect(ref);

	useEffect(() => {
		const vCenter = `${rect.height / 2 - arrowBorderWidth}px`;
		const hCenter = `${rect.width / 2 - arrowBorderWidth}px`;

		setArrowPositionalStyles({
			top: {
				...arrowPositionalStyles.top,
				top: `${rect.height}px`,
				left: hCenter
			},
			bottom: {
				...arrowPositionalStyles.bottom,
				left: hCenter
			},
			left: {
				...arrowPositionalStyles.left,
				top: vCenter
			},
			right: {
				...arrowPositionalStyles.right,
				top: vCenter
			}
		});
	}, [rect, errors]);

	const tooltipStyles = {
		width: `${width}px`,
	};

	const toolTipError = errors?.[0];

  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z"/>
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"/>
    </svg>
  );

	const arrowClassnames = classNames("tt-arrow", {
		"tt-arrow_dark" : theme === "dark",
		"tt-arrow_light" : theme === "light",
		top: position.toLowerCase() === "top",
		bottom: position.toLowerCase() === "bottom",
		left: position.toLowerCase() === "left",
		right: position.toLowerCase() === "right",
	});

	return (
		<div ref={ref} className={classNames("message", {
			"dark": theme === "dark",
			"light": theme === "light",
			"message_drop-shadow": theme === "light",
		})}
		     style={tooltipStyles}
		>
			<div className={"icon"}>{icon}</div>
			<span>{toolTipError.errorText || "This is not a valid email address"}</span>
			<span
				className={arrowClassnames}
				style={{
					...commonArrowStyles,
					...arrowPositionalStyles[position]
				}}
			/>
		</div>
	);
};

TooltipErrorMsg.defaultProps = {
	width: 300,
	theme: "dark",
}

export default TooltipErrorMsg;
