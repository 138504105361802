import React, {useState} from "react";
import {Container} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {UsersApi} from "client/cs";
import UpdatePasswordModal from "../components/UpdatePasswordModal";
import UpdateEmailPreferencesModal from "../components/UpdateEmailPreferencesModal";
import {addError, decrementLoading, incrementLoading, logout} from "../redux/meta/MetaActions";
import {useHistory} from "react-router-dom";
import {AccountDetails} from "../components/AccountDetails";
import AccountWallets from "../components/AccountWallets";
import {updateUser} from "../utils/updateUserData";
import getCsConfig from "../utils/getCsConfig";
import isStandardUser from "../utils/isStandardUser";


const AccountDetailsPage: React.FC = () => {
    const history = useHistory();
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showEmailPreferencesModal, setShowEmailPreferencesModal] = useState(false);
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();

    let standardUser;

    if (isStandardUser(currentUser)) {
        standardUser = currentUser;
    }

    /*  Hide or show the change password modal.  */
    function toggleShowPasswordModal(): void {
        setShowPasswordModal(!showPasswordModal);
    }

    /*  Hide or show the email preferences modal.  */
    function toggleShowEmailPreferencesModal(): void {
        setShowEmailPreferencesModal(!showEmailPreferencesModal);
    }

    /*	Update user's email preferences.  */
    async function submitEmailPreferencesChange(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new UsersApi(getCsConfig(fullToken)).changeEmailPreferences({
                changeEmailPreferencesBody: {
                    agreeEmailUpdates: !standardUser?.agreeEmailUpdates
                },
            });
            await updateUser()
        } catch (e) {
            await dispatch(addError(e));
        }
        dispatch(decrementLoading());
    }

    /*	Clear token and go to homepage.	*/
    function logoutButton(e?): void {
        e?.preventDefault();
        dispatch(logout());
        new UsersApi(getCsConfig(fullToken)).logout().catch(err => dispatch(addError(err)));
        dispatch(logout());
        history.push("/");
    }

    return (
        <>
            <UpdatePasswordModal
                isOpen={showPasswordModal}
                onClose={toggleShowPasswordModal}
            />

            <UpdateEmailPreferencesModal
                isOpen={showEmailPreferencesModal}
                isChecked={standardUser?.agreeEmailUpdates}
                onConfirmClick={submitEmailPreferencesChange}
                onClose={toggleShowEmailPreferencesModal}
            />

            <Container className="account-details-page">
                <AccountWallets/>

                <AccountDetails
                    email={standardUser?.email}
                    onEditPassword={toggleShowPasswordModal}
                    onEditEmailPreferences={toggleShowEmailPreferencesModal}
                    onLogoutClick={logoutButton}
                />
            </Container>
        </>
    );
};

export default AccountDetailsPage;
