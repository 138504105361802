"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardUserToJSON = exports.StandardUserFromJSONTyped = exports.StandardUserFromJSON = exports.instanceOfStandardUser = void 0;
var User_1 = require("./User");
var Wallet_1 = require("./Wallet");
/**
 * Check if a given object implements the StandardUser interface.
 */
function instanceOfStandardUser(value) {
    var isInstance = true;
    isInstance = isInstance && "agreeEmailUpdates" in value;
    isInstance = isInstance && "wallets" in value;
    return isInstance;
}
exports.instanceOfStandardUser = instanceOfStandardUser;
function StandardUserFromJSON(json) {
    return StandardUserFromJSONTyped(json, false);
}
exports.StandardUserFromJSON = StandardUserFromJSON;
function StandardUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, User_1.UserFromJSONTyped)(json, ignoreDiscriminator)), { 'agreeEmailUpdates': json['agreeEmailUpdates'], 'wallets': (json['wallets'].map(Wallet_1.WalletFromJSON)) });
}
exports.StandardUserFromJSONTyped = StandardUserFromJSONTyped;
function StandardUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, User_1.UserToJSON)(value)), { 'agreeEmailUpdates': value.agreeEmailUpdates, 'wallets': (value.wallets.map(Wallet_1.WalletToJSON)) });
}
exports.StandardUserToJSON = StandardUserToJSON;
