import React, {ReactNode} from "react";
import { Link } from "react-router-dom";

export interface IGameDetail {
	label: string;
	value: {
		display: string;
		href?: string;
	}
}

interface IProps {
	details: Array<IGameDetail>;
}

const GameDetailsList: React.FC<IProps> = (props) => {

	function renderDetail(detail: IGameDetail, i: number): ReactNode {
		return (
			<React.Fragment key={`${detail.label}-${i}`}>
				<div className="game-details-list_entry">
					<p className="game-details-list_entry_label">
						{detail.label}
					</p>

					{detail.value.href ? (
						<Link to="/" className="game-details-list_entry_link">
							{detail.value.display}
						</Link>
					) : (
						<p className="game-details-list_entry_display">
							{detail.value.display}
						</p>
					)}
				</div>

				{i + 1 < props.details.length && (
					<div className="game-details-list_separator"/>
				)}
			</React.Fragment>
		);
	}

	return (
		<div className="game-details-list">
			{props.details.map(renderDetail)}
		</div>
	);
};

export default GameDetailsList;
