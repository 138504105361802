import React, {HTMLAttributes} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";
import PressArticle, { IArticle } from "./PressArticle";

interface IProps extends HTMLAttributes<HTMLElement> {
	className?: string;
}

const articles: Array<IArticle> = [
	{
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_422/f_auto/q_auto/v1661359374/website/about-page/article-1_2x_ump9qm",
		source: "CoinDesk",
		date: "14 Dec 2021",
		description: "Ex Populus Raises $8.5M Amid Fears Web 3 Gaming is Growing Frothy",
		link: "https://www.yahoo.com/now/ex-populus-raises-8-5m-185956009.html",
	},
	{
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_422/f_auto/q_auto/v1661359374/website/about-page/article-2_2x_qxojgi",
		source: "Business Wire",
		date: "28 Oct 2021",
		description: "New Indie Blockchain Game Publisher Ex Populus Announces $3M Seed Round",
		link: "https://www.businesswire.com/news/home/20211028005101/en/New-Indie-Blockchain-Game-Publisher-Ex-Populus-Announces-3M-Seed-Round-Led-by-Animoca-Brands",
	},
	{
		imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_422/f_auto/q_auto/v1661359374/website/about-page/article-3_2x_zdqfvc",
		source: "Essentially Sports",
		date: "18 Jan 2022",
		description: "Mike Tyson’s Pigeons Are Available for Sale as NFTs",
		link: "https://www.essentiallysports.com/boxing-news-mike-tysons-pigeons-are-available-for-sale-as-nfts/",
	},
]

const OurPressCoverage: React.FC<IProps> = (props) => {

	return (
		<section
			className={classNames("our-press-coverage", props.className)}
			{...props}
		>
			<Container>
				<header>
					<h3>Read About Us</h3>
					<h2>Press Coverage</h2>
				</header>

				<div className={"articles"}>
					{articles.map((article) => <PressArticle article={article} />)}
				</div>
			</Container>
		</section>
	);
}

export default OurPressCoverage;
