import React from "react";
import {Carousel} from "react-responsive-carousel";


export const FinalFormNextGen: React.FC = () => {

	const element1: JSX.Element = (
		<div className="final-form-next-gen_element">
			<h3>Open Source Gaming</h3>
			<p>Final Form’s game logic is powered by the cutting-edge technology of Arbitrum Nova, an open and
				decentralized blockchain. This groundbreaking integration ensures unparalleled transparency, allowing
				the game to thrive for generations to come, possibly even spanning across decades or centuries.</p>
		</div>
	);
	const element2: JSX.Element = (
		<div className="final-form-next-gen_element">
			<h3>Peer-to-Peer</h3>
			<p>Final Form enables players with unparalleled card mobility across digital environments, akin to
				Steam Community Market but with enhanced benefits. Every asset adheres to high ERC standards,
				ensuring seamless mobility and limitless possibilities. Join us to experience a game that
				redefines trading cards and elevates your gaming journey!</p>
		</div>
	);
	const element3: JSX.Element = (
		<div className="final-form-next-gen_element">
			<h3>Self-Organizing</h3>
			<p>Final Form and its extended trading card ecosystem has been built not just by Ex Populus, but by its fans
				around the world.</p>
		</div>
	);

	return (
		<section className="final-form-next-gen">
			<h2>NextGen Gaming</h2>

			<div className="final-form-next-gen_body">
				{[element1, element2, element3]}
			</div>

			<div className="final-form-next-gen_body_mobile">
				<Carousel
					showStatus={false}
					infiniteLoop={true}
					showArrows={false}
					showThumbs={false}
					swipeScrollTolerance={32}
					showIndicators={true}
					className="final-form-next-gen_body_mobile_carousel"
				>
					{[element1, element2, element3]}
				</Carousel>
			</div>
		</section>
	);
}
