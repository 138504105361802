"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateIronPigeonImagesBodyToJSON = exports.GenerateIronPigeonImagesBodyFromJSONTyped = exports.GenerateIronPigeonImagesBodyFromJSON = exports.instanceOfGenerateIronPigeonImagesBody = void 0;
var IronPigeon_1 = require("./IronPigeon");
/**
 * Check if a given object implements the GenerateIronPigeonImagesBody interface.
 */
function instanceOfGenerateIronPigeonImagesBody(value) {
    var isInstance = true;
    isInstance = isInstance && "uploadToIpfs" in value;
    isInstance = isInstance && "bucketFolderName" in value;
    isInstance = isInstance && "backendUrl" in value;
    isInstance = isInstance && "nftJson" in value;
    isInstance = isInstance && "indexOffset" in value;
    isInstance = isInstance && "setName" in value;
    isInstance = isInstance && "setNumber" in value;
    isInstance = isInstance && "edition" in value;
    isInstance = isInstance && "ironPigeon" in value;
    return isInstance;
}
exports.instanceOfGenerateIronPigeonImagesBody = instanceOfGenerateIronPigeonImagesBody;
function GenerateIronPigeonImagesBodyFromJSON(json) {
    return GenerateIronPigeonImagesBodyFromJSONTyped(json, false);
}
exports.GenerateIronPigeonImagesBodyFromJSON = GenerateIronPigeonImagesBodyFromJSON;
function GenerateIronPigeonImagesBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uploadToIpfs': json['uploadToIpfs'],
        'bucketFolderName': json['bucketFolderName'],
        'backendUrl': json['backendUrl'],
        'nftJson': json['nftJson'],
        'indexOffset': json['indexOffset'],
        'setName': json['setName'],
        'setNumber': json['setNumber'],
        'edition': json['edition'],
        'ironPigeon': (0, IronPigeon_1.IronPigeonFromJSON)(json['ironPigeon']),
    };
}
exports.GenerateIronPigeonImagesBodyFromJSONTyped = GenerateIronPigeonImagesBodyFromJSONTyped;
function GenerateIronPigeonImagesBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uploadToIpfs': value.uploadToIpfs,
        'bucketFolderName': value.bucketFolderName,
        'backendUrl': value.backendUrl,
        'nftJson': value.nftJson,
        'indexOffset': value.indexOffset,
        'setName': value.setName,
        'setNumber': value.setNumber,
        'edition': value.edition,
        'ironPigeon': (0, IronPigeon_1.IronPigeonToJSON)(value.ironPigeon),
    };
}
exports.GenerateIronPigeonImagesBodyToJSON = GenerateIronPigeonImagesBodyToJSON;
