"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftCollectionToJSON = exports.NftCollectionFromJSONTyped = exports.NftCollectionFromJSON = exports.instanceOfNftCollection = void 0;
/**
 * Check if a given object implements the NftCollection interface.
 */
function instanceOfNftCollection(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "family" in value;
    return isInstance;
}
exports.instanceOfNftCollection = instanceOfNftCollection;
function NftCollectionFromJSON(json) {
    return NftCollectionFromJSONTyped(json, false);
}
exports.NftCollectionFromJSON = NftCollectionFromJSON;
function NftCollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'family': json['family'],
    };
}
exports.NftCollectionFromJSONTyped = NftCollectionFromJSONTyped;
function NftCollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'family': value.family,
    };
}
exports.NftCollectionToJSON = NftCollectionToJSON;
