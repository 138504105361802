import React, {ReactNode} from "react";
import {Container} from "reactstrap";
import PlayFreeButton from "../PlayFreeButton";
import {User} from "client/cs";
import {useMediaQuery} from "react-responsive";
import variables from "../../style/variables";
import classNames from "classnames";
import {useAtom} from "jotai";
import {dismissBannerAtom} from "../banners/ArbitrumBanner";
import {IoSparklesSharp} from "react-icons/all";


export interface IProps {
    title: string;
    socialIcons: boolean;
    id?: string;
    playFree?: boolean;
    className?: string;
    user?: User;
}

// Any changes made to this component should also be made on the GameDetailsTitleBarMobileSticky.tsx component.
const GameDetailsTitleBar: React.FC<IProps> = (props) => {
    const isMobile = useMediaQuery({query: `(max-width: ${variables.gameDetailsCarouselMobileBreakpoint})`});
    const [dismissBanner] = useAtom(dismissBannerAtom);

    return (
        <div id={props.id}
             className={classNames("game-details-title-bar", {
                 "game-details-title-bar_sticky-banner": !dismissBanner,
             })}
        >

            <Container className="game-details-title-bar_container">
                <div className="game-details-title-bar_container_header">
                    <h1 className="game-details-title-bar_container_header_title">
                        {props.title}
                    </h1>
                </div>

                {props.socialIcons && (
                    <div className="game-details-title-bar_container_align">

                        <div className="game-details-title-bar_container_buttons">
                            {props.playFree && (
                                <PlayFreeButton className={"game-details-title-bar_container_buttons_style"}
                                                user={props.user}/>)}
                        </div>
                    </div>
                )}
            </Container>
        </div>
    )
};

GameDetailsTitleBar.defaultProps = {
    className: "",
    id: null,
    playFree: false,
};

export default GameDetailsTitleBar;
