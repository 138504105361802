"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublisherAllOfToJSON = exports.PublisherAllOfFromJSONTyped = exports.PublisherAllOfFromJSON = exports.instanceOfPublisherAllOf = void 0;
var Creator_1 = require("./Creator");
/**
 * Check if a given object implements the PublisherAllOf interface.
 */
function instanceOfPublisherAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "creators" in value;
    return isInstance;
}
exports.instanceOfPublisherAllOf = instanceOfPublisherAllOf;
function PublisherAllOfFromJSON(json) {
    return PublisherAllOfFromJSONTyped(json, false);
}
exports.PublisherAllOfFromJSON = PublisherAllOfFromJSON;
function PublisherAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'creators': (json['creators'].map(Creator_1.CreatorFromJSON)),
    };
}
exports.PublisherAllOfFromJSONTyped = PublisherAllOfFromJSONTyped;
function PublisherAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'creators': (value.creators.map(Creator_1.CreatorToJSON)),
    };
}
exports.PublisherAllOfToJSON = PublisherAllOfToJSON;
