import {isNil} from "lodash";
import {IStore} from "../redux/defaultStore";
import {GetUserResponse, UsersApi} from "client/cs";
import getCsConfig from "./getCsConfig";
import {store} from "../redux";
import {decrementLoading, incrementLoading, setCurrentUser} from "../redux/meta/MetaActions";


async function updateUser(showLoading: boolean = true): Promise<void> {

	if (showLoading) {
		store.dispatch(incrementLoading());
	}

	const _store: IStore = store.getState();
	const currentToken = _store?.metaStore?.fullToken?.token;

	// can't run without the token
	if (isNil(currentToken)) {
		store.dispatch(decrementLoading());
		throw {messages: ["Cannot get user's data without logging in."]};
	}

	// get data from the API
	const res: GetUserResponse = await new UsersApi(getCsConfig(_store.metaStore.fullToken)).getProfile();
	store.dispatch(setCurrentUser(res.user));

	if (showLoading) {
		store.dispatch(decrementLoading());
	}
}

export {
	updateUser,
}
