"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriberAllOfToJSON = exports.SubscriberAllOfFromJSONTyped = exports.SubscriberAllOfFromJSON = exports.instanceOfSubscriberAllOf = void 0;
var runtime_1 = require("../runtime");
var PhoneNumber_1 = require("./PhoneNumber");
var User_1 = require("./User");
/**
 * Check if a given object implements the SubscriberAllOf interface.
 */
function instanceOfSubscriberAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "verified" in value;
    return isInstance;
}
exports.instanceOfSubscriberAllOf = instanceOfSubscriberAllOf;
function SubscriberAllOfFromJSON(json) {
    return SubscriberAllOfFromJSONTyped(json, false);
}
exports.SubscriberAllOfFromJSON = SubscriberAllOfFromJSON;
function SubscriberAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'phoneNumber': (0, PhoneNumber_1.PhoneNumberFromJSON)(json['phoneNumber']),
        'verified': json['verified'],
        'user': !(0, runtime_1.exists)(json, 'user') ? undefined : (0, User_1.UserFromJSON)(json['user']),
    };
}
exports.SubscriberAllOfFromJSONTyped = SubscriberAllOfFromJSONTyped;
function SubscriberAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'phoneNumber': (0, PhoneNumber_1.PhoneNumberToJSON)(value.phoneNumber),
        'verified': value.verified,
        'user': (0, User_1.UserToJSON)(value.user),
    };
}
exports.SubscriberAllOfToJSON = SubscriberAllOfToJSON;
