"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselSlideToJSON = exports.CarouselSlideFromJSONTyped = exports.CarouselSlideFromJSON = exports.instanceOfCarouselSlide = void 0;
var runtime_1 = require("../runtime");
var Asset_1 = require("./Asset");
var CarouselCta_1 = require("./CarouselCta");
/**
 * Check if a given object implements the CarouselSlide interface.
 */
function instanceOfCarouselSlide(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "asset" in value;
    return isInstance;
}
exports.instanceOfCarouselSlide = instanceOfCarouselSlide;
function CarouselSlideFromJSON(json) {
    return CarouselSlideFromJSONTyped(json, false);
}
exports.CarouselSlideFromJSON = CarouselSlideFromJSON;
function CarouselSlideFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'asset': (0, Asset_1.AssetFromJSON)(json['asset']),
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'primaryButton': !(0, runtime_1.exists)(json, 'primaryButton') ? undefined : (0, CarouselCta_1.CarouselCtaFromJSON)(json['primaryButton']),
        'secondaryButton': !(0, runtime_1.exists)(json, 'secondaryButton') ? undefined : (0, CarouselCta_1.CarouselCtaFromJSON)(json['secondaryButton']),
        'priority': !(0, runtime_1.exists)(json, 'priority') ? undefined : json['priority'],
        'detailsToggle': !(0, runtime_1.exists)(json, 'detailsToggle') ? undefined : json['detailsToggle'],
    };
}
exports.CarouselSlideFromJSONTyped = CarouselSlideFromJSONTyped;
function CarouselSlideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'asset': (0, Asset_1.AssetToJSON)(value.asset),
        'title': value.title,
        'description': value.description,
        'primaryButton': (0, CarouselCta_1.CarouselCtaToJSON)(value.primaryButton),
        'secondaryButton': (0, CarouselCta_1.CarouselCtaToJSON)(value.secondaryButton),
        'priority': value.priority,
        'detailsToggle': value.detailsToggle,
    };
}
exports.CarouselSlideToJSON = CarouselSlideToJSON;
