import React from "react";
import classNames from "classnames";

interface IProps {
	title?: string;
	rounded?: boolean;
	bgColor?: "deepBlack" | "darkGrey";
	size?: "sm" | "md";
	status?: "pending" | "ready";
}

const HeaderContainer: React.FC<IProps> = (props) => {
	return (
		<aside
			className={
				classNames("header-container",
					`bg-${props.bgColor}`,
					{
						"rounded": props.rounded,
					},
				)
			}
		>
			<div className="header-container_header">
				<div
					className={classNames("header-container_header-circle", {
						"hide-element": !props.status,
						"bg-raz": props.status === "pending",
						"bg-mantis": props.status === "ready",
					})}
				/>
				<span
					className={classNames({
						"status-spacer": props.status,
					})}
				>
					{props?.title}
				</span>
			</div>

			<div className="header-container_body">
				{props.children}
			</div>
		</aside>
	);
}

HeaderContainer.defaultProps = {
	rounded: false,
	bgColor: "deepBlack",
	size: "md",
}

export default HeaderContainer;
