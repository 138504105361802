import React from "react";

interface IProps {
	onGoToTop?: () => void;
}

const ExPopulusFooterCopyright: React.FC<IProps> = (props) => {

	/**
	 * onClick for the "Back to top" anchor. Prevent the default link action, and scroll to top.
	 *
	 * @param e
	 */
	function toTop(e): void {
		e.preventDefault();
		props.onGoToTop();
	}

	return (
		<div className={"ex-populus-footer-copyright"}>
			<div className="ex-populus-footer-copyright_hr">
				<hr/>
			</div>
			<div className={"ex-populus-footer-copyright_content"}>
				<p className="ex-populus-footer-copyright_content_copyright-message">
					&copy; Copyright 2019 - {new Date().getFullYear()} Ex Populus. All Rights Reserved |

					<a
						className={"ex-populus-footer-copyright_content_copyright-message_back-to-top"}
						onClick={toTop}
					>
						Back to top
					</a>
				</p>
			</div>
		</div>
	);
};

ExPopulusFooterCopyright.defaultProps = {
	onGoToTop: () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}
};

export default ExPopulusFooterCopyright;
