"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiKeyTypeToJSON = exports.ApiKeyTypeFromJSONTyped = exports.ApiKeyTypeFromJSON = exports.ApiKeyType = void 0;
/**
 *
 * @export
 */
exports.ApiKeyType = {
    DATA_AGGREGATION_API_KEY: 'DATA_AGGREGATION_API_KEY',
    TCG_NFT_IMAGE_API_KEY: 'TCG_NFT_IMAGE_API_KEY'
};
function ApiKeyTypeFromJSON(json) {
    return ApiKeyTypeFromJSONTyped(json, false);
}
exports.ApiKeyTypeFromJSON = ApiKeyTypeFromJSON;
function ApiKeyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ApiKeyTypeFromJSONTyped = ApiKeyTypeFromJSONTyped;
function ApiKeyTypeToJSON(value) {
    return value;
}
exports.ApiKeyTypeToJSON = ApiKeyTypeToJSON;
