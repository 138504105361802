"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolanaPayTransactionOriginToJSON = exports.SolanaPayTransactionOriginFromJSONTyped = exports.SolanaPayTransactionOriginFromJSON = exports.SolanaPayTransactionOrigin = void 0;
/**
 *
 * @export
 */
exports.SolanaPayTransactionOrigin = {
    FINAL_FORM: 'FINAL_FORM'
};
function SolanaPayTransactionOriginFromJSON(json) {
    return SolanaPayTransactionOriginFromJSONTyped(json, false);
}
exports.SolanaPayTransactionOriginFromJSON = SolanaPayTransactionOriginFromJSON;
function SolanaPayTransactionOriginFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SolanaPayTransactionOriginFromJSONTyped = SolanaPayTransactionOriginFromJSONTyped;
function SolanaPayTransactionOriginToJSON(value) {
    return value;
}
exports.SolanaPayTransactionOriginToJSON = SolanaPayTransactionOriginToJSON;
