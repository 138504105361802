import {Configuration} from "client/marketplace";
import {Token} from "client/marketplace";

export default function getConfig(fullToken?: Token): Configuration {
	const headers = fullToken ? {headers: {authorization: `Bearer ${fullToken?.token}`}} : {};

	return new Configuration({
		...headers,
	});
}
