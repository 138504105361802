"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetCollectionsResponseToJSON = exports.GetCollectionsResponseFromJSONTyped = exports.GetCollectionsResponseFromJSON = exports.instanceOfGetCollectionsResponse = void 0;
var Collection_1 = require("./Collection");
var PaginationInfo_1 = require("./PaginationInfo");
/**
 * Check if a given object implements the GetCollectionsResponse interface.
 */
function instanceOfGetCollectionsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "collections" in value;
    return isInstance;
}
exports.instanceOfGetCollectionsResponse = instanceOfGetCollectionsResponse;
function GetCollectionsResponseFromJSON(json) {
    return GetCollectionsResponseFromJSONTyped(json, false);
}
exports.GetCollectionsResponseFromJSON = GetCollectionsResponseFromJSON;
function GetCollectionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoFromJSON)(json['paginationInfo']),
        'collections': (json['collections'].map(Collection_1.CollectionFromJSON)),
    };
}
exports.GetCollectionsResponseFromJSONTyped = GetCollectionsResponseFromJSONTyped;
function GetCollectionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoToJSON)(value.paginationInfo),
        'collections': (value.collections.map(Collection_1.CollectionToJSON)),
    };
}
exports.GetCollectionsResponseToJSON = GetCollectionsResponseToJSON;
