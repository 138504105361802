import React, { ReactNode } from "react";
import { BsTwitter, FiX, GrMedium, SiDiscord } from "react-icons/all";
import ExPopulusSidebarNavItem from "./ExPopulusSidebarNavItem";
import { Link } from "react-router-dom";

import { IExPopulusHeaderNavItemProps } from "./ExPopulusHeaderNavItem";
import {
  AccountButtonConfig,
  IExPopulusUnifiedSignUpButtonProps,
} from "./IExPopulusUnifiedSignUpButtonProps";
import {
  ascertainLocalLinks,
  isMainEXPSite,
} from "./universalNavigationLookup";
import { ExPopulusButton } from "../buttons/ExPopulusButton";
import ExPopulusHeaderSocialLink from "./ExPopulusHeaderSocialLink";

export interface IProps {
  implementingSiteURL: string;
  height: number; // height in px, should be getting inherited from parent
  onClose: (e) => void;
  links?: Array<IExPopulusHeaderNavItemProps>;
  accountButtonConfig: IExPopulusUnifiedSignUpButtonProps;
  showTwitter?: boolean;
  showMedium?: boolean;
  showDiscord?: boolean;
}

const ExPopulusSideBarInner: React.FC<IProps> = (props) => {
  // provide a reasonable default since we don't check if the env var is set
  const APP_WEBSITE_URL =
    process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com/";

  const mainEXPSite: boolean = isMainEXPSite(props.implementingSiteURL);

  // properly setup urls
  const appWebsiteUrl = new URL(APP_WEBSITE_URL);
  const loginUrl = new URL("/log-in", APP_WEBSITE_URL);
  const accountDetailsUrl = new URL("/account-details", APP_WEBSITE_URL);

  function renderSideBarNavItem(
    link: IExPopulusHeaderNavItemProps,
    i: number
  ): ReactNode {
    return (
      <ExPopulusSidebarNavItem
        key={`sidebar-child-nav-item_${i}`}
        {...link}
        implementingSiteURL={props.implementingSiteURL}
        height={props.height}
      >
        {link.children}
      </ExPopulusSidebarNavItem>
    );
  }

  /**
   * Helper to prevent the default link action and call supplied function to close the sidebar.
   *
   * @param e
   */
  function onCloseClickHelper(e): void {
    e.preventDefault();
    props.onClose(e);
  }

  /**
   * Don't want to "preventDefault" on React Router Dom's <Link> component.
   *
   */
  function localLinkCloseHelper(): void {
    props.onClose(undefined);
  }

  const logo = (
    <img
      src="https://storage.googleapis.com/ex-populus-marketplace_static/xp-logo.svg"
      alt="Ex Populus"
    />
  );

  const getExpopulusButtonPink = (buttonText) => (
    <ExPopulusButton
      color="pink"
      className={"ex-populus-sidebar-inner_bottom_login-cta-container_button"}
    >
      {buttonText}
    </ExPopulusButton>
  );

  const expopulusSignInButton = getExpopulusButtonPink("SIGN IN");
  const expopulusVerifyEmailButton = getExpopulusButtonPink("VERIFY EMAIL");

  return (
    <div className={"ex-populus-sidebar-inner"}>
      <div className={"ex-populus-sidebar-inner_top"}>
        <div
          className={"ex-populus-sidebar-inner_top_close-icon-spacer"}
          style={{ height: props.height }}
        >
          {mainEXPSite ? (
            <Link
              to={appWebsiteUrl.pathname}
              onClick={localLinkCloseHelper}
              className={
                "ex-populus-sidebar-inner_top_close-icon-spacer_logo-link"
              }
            >
              {logo}
            </Link>
          ) : (
            <a
              href={appWebsiteUrl.href}
              onClick={onCloseClickHelper}
              className={
                "ex-populus-sidebar-inner_top_close-icon-spacer_logo-link"
              }
            >
              {logo}
            </a>
          )}

          <a
            href="#"
            onClick={onCloseClickHelper}
            aria-label="Sidebar Close Icon"
            className={"ex-populus-sidebar-inner_top_close-icon-spacer_anchor"}
          >
            <FiX />
          </a>
        </div>

        <div className={"ex-populus-sidebar-inner_top_navigation-items"}>
          {props.links &&
            Array.isArray(props.links) &&
            props.links.length > 0 &&
            ascertainLocalLinks(props.links, props.implementingSiteURL).map(
              renderSideBarNavItem
            )}
        </div>
      </div>

      <div className={"ex-populus-sidebar-inner_bottom"}>
        {props.accountButtonConfig.accountButton ===
          AccountButtonConfig.SIGN_UP && (
          <div
            className={"ex-populus-sidebar-inner_bottom_login-cta-container"}
          >
            {mainEXPSite ? (
              <Link to={loginUrl.pathname} onClick={localLinkCloseHelper}>
                {expopulusSignInButton}
              </Link>
            ) : (
              <a href={loginUrl.href} onClick={localLinkCloseHelper}>
                {expopulusSignInButton}
              </a>
            )}
          </div>
        )}

        {props.accountButtonConfig.accountButton ===
          AccountButtonConfig.ACCOUNT && (
          <ExPopulusSidebarNavItem
            implementingSiteURL={props.implementingSiteURL}
            height={props.height}
            to={
              mainEXPSite ? accountDetailsUrl.pathname : accountDetailsUrl.href
            }
            raw={accountDetailsUrl.href}
            onClick={localLinkCloseHelper}
            className={"ex-populus-sidebar-inner_bottom_account-link"}
          >
            ACCOUNT
          </ExPopulusSidebarNavItem>
        )}

        {(props.showTwitter || props.showMedium || props.showDiscord) && (
          <div className={"ex-populus-sidebar-inner_bottom_socials"}>
            {props.showMedium && (
              <ExPopulusHeaderSocialLink
                icon={GrMedium}
                ariaLabel="Medium"
                href="https://expopulus.medium.com/"
                className={"ex-populus-sidebar-inner_bottom_socials_link"}
              />
            )}

            {props.showTwitter && (
              <ExPopulusHeaderSocialLink
                icon={BsTwitter}
                ariaLabel="Twitter"
                href="https://twitter.com/xai_games"
                className={"ex-populus-sidebar-inner_bottom_socials_link"}
              />
            )}

            {props.showDiscord && (
              <ExPopulusHeaderSocialLink
                icon={SiDiscord}
                ariaLabel="Discord"
                href="https://discord.gg/xaigames"
                className={"ex-populus-sidebar-inner_bottom_socials_link"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ExPopulusSideBarInner.defaultProps = {
  onClose: () => {},
  // Remainder of default props for this component can be found in ExPopulusSideBarManager.defaultProps, as
  // that component injects some important functionality, so it was easier to create the props there.
};

export default ExPopulusSideBarInner;
