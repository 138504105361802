import React from "react";
import classNames from "classnames";

interface IScrollerProps {
    duration?: number;
    clones?: number;
    bannerClass?: string;
    scrollerClass?: string;
}

/**
 * ScrollerRightToLeft
 * Roughly, will scroll the provided children infinitely
 *
 * children: the elements to scroll
 * duration: the time it takes to fully scroll all the children once ("speed" of the scroll is a function of the width of the children and the duration)
 * clones: the number of "banner clones", defaults to 2 and this should usually be fine unless you provide too few children
 * bannerClass: style overrides for the item "banners"
 * scrollerClass: style overrides for the scroller container element
 *
 */
const ScrollerRightToLeft: React.FC<IScrollerProps> = (props) => {
    const {children, duration, clones, bannerClass, scrollerClass} = props;

    const createBanner = (bannerItems, i) => (
        <div key={`right-to-left-banner-items_${i}`}
             className={classNames("banner", bannerClass)}>{bannerItems}</div>
    );

    const createScrollerBanners = (count, items) => Array.from({length: count}, (x, i) => (
        createBanner(items, i)
    ));

    return (
        <div className="scroller-container">
            <div className={classNames("scroller", "rightToLeft", scrollerClass)}
                 style={{animationDuration: `${duration}s`}}>
                {createScrollerBanners(clones, children)}
            </div>
        </div>
    );
}

ScrollerRightToLeft.defaultProps = {
    duration: 10,
    clones: 2,
    bannerClass: "",
    scrollerClass: "",
};

export {ScrollerRightToLeft};
