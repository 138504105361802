import React, {ReactNode, useEffect, useState} from "react";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError} from "../redux/meta/MetaActions";
import {GetListingsResponse, Listing, ListingsApi} from "client/marketplace";
import getConfig from "../utils/getConfig";
import {RouteComponentProps} from "react-router";
import {useHistory} from "react-router-dom";
import CollectiblesPaginator from "../components/CollectiblesPaginator";
import LocalLoader from "../components/LocalLoader";
import {generateSupplyMessage} from "../utils/generateSupplyMessage";
import {formatEtherAppendLabel} from "../utils/formatEtherAppendLabel";
import formatEtherRounded from "../utils/formatEtherRounded";
import {generateFrontendFullVanityURL} from "../utils/generateFrontendFullVanityURL";
import ItemCard from "../components/collectibles/ItemCard";

interface IProps extends RouteComponentProps {
	dispatch?: any;
}

const CollectiblesPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const offset: string = query.get("offset");

	const [collectibles, setCollectibles] = useState<GetListingsResponse>(undefined);
	const [localLoading, setLocalLoading] = useState(false);

	// TODO: remove once the container template implements the dark theme
	useEffect(() => {
		document.body.classList.add("page-gradient");

		return () => {
			document.body.classList.remove("page-gradient");
		};
	});

	/**
	 * Prompt api call on mount, and each time
	 * the frontend pagination is updated.
	 *
	 * Replace route if offset in query is not a satisfactory number.
	 */
	useEffect(() => {
		if (!new RegExp(/^\d*[1-9]\d*$/).test(offset)) {
			history.replace("/collectibles");
		}

		getCollectibles().then().catch();
	}, [offset]);

	/**
	 * Call the api with the latest frontend pagination to get
	 * a new list of collectibles.
	 *
	 */
	async function getCollectibles(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new ListingsApi(getConfig()).getListings({
				limit: 12,
				offset: !offset ? 0 : parseInt(offset),
			});

			setCollectibles(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		setLocalLoading(false);
	}

	/**
	 * Render each collectible.
	 *
	 * @param listing
	 * @param i
	 */
	function renderCollectible(listing: Listing, i: number): ReactNode {
		const soldOut = listing?.totalSupply == null || listing?.maximumSupply == null || listing.totalSupply >= listing.maximumSupply;

		return (
			<div
				key={`listing_${i}_${listing?.name}`}
				className="listings-container_item"
			>
				<ItemCard
					key={`featured-listing_${i}_${listing?.name}`}
					asset={listing?.assets?.[0]}
					collection={listing?.collection?.project?.name}
					price={listing?.price ? formatEtherAppendLabel(listing?.price) : undefined}
					// if we want to round the price to specific decimal places:
					// price={listing?.price ? formatEtherRounded(listing?.price) : undefined}
					soldOut={soldOut}
					name={listing?.name}
					subtitle={generateSupplyMessage(listing)}
					link={generateFrontendFullVanityURL(listing)}
				/>
			</div>
		);
	}

	function paginationChangeHelper(index: number): void {
		history.push(`/collectibles?offset=${index}`);
	}

	return (
		<Container className="collectibles-page page">
			<div className="title">Collectibles</div>

			<div className="collectibles-page_listings">
				<div className="listings-container">
					{localLoading ? (
						<LocalLoader/>
					) : collectibles?.listings?.map(renderCollectible)}
				</div>
			</div>

			{!localLoading && (
				<div className="collectibles-page_pagination-container">
					<CollectiblesPaginator
						paginationInfo={collectibles?.paginationInfo}
						onPageChangeHelper={paginationChangeHelper}
					/>
				</div>
			)}
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(CollectiblesPage);
