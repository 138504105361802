import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { GAMESIGHT_EVENTS, XP_REDIRECTS } from "shared";
import { sendEvent } from "../../utils/event-tracking";
import { DagHeader } from "./DagHeader";
import { DagHomepageHero } from "./DagHomepageHero";
import { GetFinalFormCardsBanner } from "../GetFinalFormCardsBanner";
import { DagHomepageAbout } from "./DagHomepageAbout";
import { DagHomepageVideo } from "./DagHomepageVideo";
import { DagHomepagePlayFree } from "./DagHomepagePlayFree";
import { DagHomepageBuyNow } from "./DagHomepageBuyNow";
import { DagHomepageTotalCount } from "./DagHomepageTotalCount";
import { DagHomepageTraitsAndRarity } from "./DagHomepageTraitsAndRarity";
import { DagHomepageSet } from "./DagHomepageSet";
import { DagHomepageFAQ } from "./DagHomepageFAQ";
import { SaleTypeEnum } from "../../utils/SaleTypeEnum";
import { ProjectPageSection } from "../../utils/projectHeaderUtils";
import { useSelector } from "react-redux";
import { IStore } from "../../redux/defaultStore";
import { ArbitrumComingSoon } from "./ArbitrumComingSoon";
import { TokenType } from "../../utils/TokenType";

const DagHomepage: React.FC = () => {
  const homepageHeroRef = useRef(null);
  const homepagePlayFreeRef = useRef(null);
  const homepageAboutRef = useRef(null);
  const homepageGetNowRef = useRef(null);
  const homepageTraitsAndRarityRef = useRef(null);
  const homepageSetRef = useRef(null);
  const homepageFAQRef = useRef(null);

  const history = useHistory();
  const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
  const currentUser = useSelector(
    (store: IStore) => store.metaStore.currentUser
  );

  useEffect(
    () =>
      sendEvent({
        eventName: GAMESIGHT_EVENTS.DAG_WEB_PAGEVIEW,
        user: currentUser,
      }),
    [currentUser]
  );

  useEffect(() => {
    if (!history.location.pathname.endsWith("/")) {
      history.push(`${history.location.pathname}/#`);
    }
  }, []);

  const trackGetCardsButtonClick = () =>
    sendEvent({
      eventName: GAMESIGHT_EVENTS.DAG_WEB_GET_CARDS,
      sendToGameSight: false,
    });

  return (
    <section>
      <DagHeader
        refList={{
          [ProjectPageSection.HOME]: homepageHeroRef,
          [ProjectPageSection.PLAY_FREE]: homepagePlayFreeRef,
          [ProjectPageSection.ABOUT]: homepageAboutRef,
          [ProjectPageSection.GET_NOW]: homepageGetNowRef,
          [ProjectPageSection.TRAITS_AND_RARITY]: homepageTraitsAndRarityRef,
          [ProjectPageSection.SET]: homepageSetRef,
          [ProjectPageSection.FAQ]: homepageFAQRef,
        }}
      />

      <div id="homepage" ref={homepageHeroRef}>
        <DagHomepageHero />
      </div>

      <GetFinalFormCardsBanner
        title="DAGs"
        tokenType={TokenType.DAG}
        onClickGetCardsCallback={trackGetCardsButtonClick}
      />

      <DagHomepageVideo />

      <div id="play-free" ref={homepagePlayFreeRef}>
        <DagHomepagePlayFree />
      </div>

      <div id="about" ref={homepageAboutRef}>
        <DagHomepageAbout
          tokenType={TokenType.DAG}
          onClickGetCardsCallback={trackGetCardsButtonClick}
        />
      </div>

      <div id={"get-cards"} ref={homepageGetNowRef}>
        <ArbitrumComingSoon />

        <DagHomepageBuyNow
          fullToken={fullToken}
          currentUser={currentUser}
          selectionHelper={() => {} /* NO BUY FLOW IMPLEMENTED HERE! */}
          saleType={
            SaleTypeEnum.RARE_SOLD_OUT /* ONLY SOLD OUT AVAILABLE HERE! */
          }
          tokenType={TokenType.DAG}
          redirect={XP_REDIRECTS.GET_DAGS}
        />
      </div>

      <DagHomepageTotalCount />

      <div id="traits-and-rarity" ref={homepageTraitsAndRarityRef}>
        <DagHomepageTraitsAndRarity
          onClickGetCardsCallback={trackGetCardsButtonClick}
        />
      </div>

      <div id="card-sets" ref={homepageSetRef}>
        <DagHomepageSet />
      </div>

      <div id="faq" ref={homepageFAQRef}>
        <DagHomepageFAQ />
      </div>
    </section>
  );
};

export { DagHomepage };
