"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeToJSON = exports.UserTypeFromJSONTyped = exports.UserTypeFromJSON = exports.UserType = void 0;
/**
 *
 * @export
 */
exports.UserType = {
    GOD_ADMIN: 'GOD_ADMIN',
    STANDARD_ADMIN: 'STANDARD_ADMIN',
    PUBLISHER: 'PUBLISHER',
    STANDARD_USER: 'STANDARD_USER'
};
function UserTypeFromJSON(json) {
    return UserTypeFromJSONTyped(json, false);
}
exports.UserTypeFromJSON = UserTypeFromJSON;
function UserTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.UserTypeFromJSONTyped = UserTypeFromJSONTyped;
function UserTypeToJSON(value) {
    return value;
}
exports.UserTypeToJSON = UserTypeToJSON;
