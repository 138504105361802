import React, { CSSProperties, useEffect, useState } from "react";
import { useRect } from "../../utils/hooks/useRect";
import classNames from "classnames";

export enum DisplayBehavior {
	ALWAYS,   // always displayed
	ONERROR,  // only displayed on focus when there is an error
	ONFOCUS,  // only displayed on focus no matter the error state
	NEVER,    // never displayed
}

export type TooltipPosition =  "top" | "bottom" | "left" | "right";

export interface ITooltipError {
	isError: boolean;
	errorText: string;
}

export interface IArrowPositionalStyles {
	[key: string]: CSSProperties,
}

export const arrowBorderWidth = 10;
export const commonArrowStyles = {
	borderWidth: `${arrowBorderWidth}px`,
} as CSSProperties;

export const defaultArrowPositionalStyles: IArrowPositionalStyles = {
	top: {
		top: "44px",
		left: "0",
	},
	bottom: {
		top: "-20px",
		left: "0",
	},
	left: {
		top: "0",
		left: "210px",
	},
	right: {
		top: "0",
		left: "-20px",
	}
}

interface IProps {
	children?: React.ReactNode;
	tooltip?: React.ReactNode | string | null;
	position?: TooltipPosition;
	offsets?: {
		top?: number;
		bottom?: number;
		left?: number;
		right?: number;
	};
	theme?: "dark" | "light";
	trigger(): boolean;
}

const Tooltip: React.FC<IProps> = ({
	children,
	tooltip,
	position,
	offsets,
	trigger,
}) => {
	const contentRef = React.createRef<HTMLDivElement>();
	const tooltipRef = React.createRef<HTMLDivElement>();

	const contentRect = useRect(contentRef);
	const tooltipRect = useRect(tooltipRef);

	const [showTooltip, setShowTooltip] = useState(true);
	const [tooltipPosition, setTooltipPosition] = useState({
		top: { bottom: 0, left: 0 },
		bottom: { top: 0, left: 0 },
		left: { top: 0, right: 0 },
		right: { top: 0, left: 0 }
	});

	useEffect(() => {
		setShowTooltip(trigger());
	}, [trigger]);

	useEffect(() => {
		const vLeft = contentRect.width / 2 - tooltipRect.width / 2;
		const vTop = offsets.top + contentRect.height;
		const vBottom = offsets.bottom + contentRect.height;
		const hTop = (contentRect.height - tooltipRect.height) / 2;
		const hLeft = offsets.left + contentRect.width;
		const hRight = offsets.right + contentRect.width;

		setTooltipPosition({
			top: { bottom: vTop, left: vLeft },
			bottom: { top: vBottom, left: vLeft },
			left: { top: hTop, right: hLeft },
			right: { top: hTop, left: hRight }
		});
	}, [contentRect, tooltipRect, offsets]);

	return (
		<div
			ref={contentRef}
			className={"tooltip-container"}
		>
			{children}

			{tooltip && showTooltip && (
				<div
					ref={tooltipRef}
					className="tooltip-container_element"
					style={{...tooltipPosition[position] }}
				>
					{tooltip}
				</div>
			)}
		</div>
	);
};

Tooltip.defaultProps = {
	tooltip: null,
	position: "top",
	offsets: {
		top: 12,
		bottom: 12,
		left: 12,
		right: 12,
	},
	theme: "dark",
};

export default Tooltip;