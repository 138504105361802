"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftTypeToJSON = exports.NftTypeFromJSONTyped = exports.NftTypeFromJSON = exports.NftType = void 0;
/**
 *
 * @export
 */
exports.NftType = {
    DAG: 'DAG',
    IP: 'IP'
};
function NftTypeFromJSON(json) {
    return NftTypeFromJSONTyped(json, false);
}
exports.NftTypeFromJSON = NftTypeFromJSON;
function NftTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.NftTypeFromJSONTyped = NftTypeFromJSONTyped;
function NftTypeToJSON(value) {
    return value;
}
exports.NftTypeToJSON = NftTypeToJSON;
