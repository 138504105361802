"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketEvToJSON = exports.SocketEvFromJSONTyped = exports.SocketEvFromJSON = exports.SocketEv = void 0;
/**
 * This enum defines all the room names (ev) that can be used in the socket.io server.
 * @export
 */
exports.SocketEv = {
    privilegedtoAll: 'privileged:toAll',
    privilegedtoSingle: 'privileged:toSingle',
    privilegedtoMany: 'privileged:toMany',
    privilegedjoinRoom: 'privileged:joinRoom',
    health: 'health'
};
function SocketEvFromJSON(json) {
    return SocketEvFromJSONTyped(json, false);
}
exports.SocketEvFromJSON = SocketEvFromJSON;
function SocketEvFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SocketEvFromJSONTyped = SocketEvFromJSONTyped;
function SocketEvToJSON(value) {
    return value;
}
exports.SocketEvToJSON = SocketEvToJSON;
