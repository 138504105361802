import React from "react";
import {useLocation} from "react-router";
import {Helmet} from "react-helmet";

export interface IMappingDetails { title: string, description?: string | null };
export interface IPathMappings { [path: string]: IMappingDetails }
const pathMappings: IPathMappings = {
  // "/": { title: "", description: null },
  // "/collectibles": { title: "", description: null },
  // "/log-in": { title: "", description: null },
  // "/register": { title: "", description: null },
  // "/contact": { title: "", description: null },
  // "/privacy": { title: "", description: null },
  // "/terms": { title: "", description: null },
  // "/about": { title: "", description: null },
  // "/games": {
  //   title: "Ex Populus Games Collection",
  //   description: "Explore how web3 collectibles can open new ways to play and enjoy these fun and exciting games.",
  // },
  // "/games/final-form": { title: "", description: null },
  // "/games/coming-soon": { title: "", description: null },
  // "/games/lamoverse": { title: "", description: null },
  "/ironpigeons": {
    title: "Iron Pigeons: A Web3 Trading Card Set For Final Form",
    description: null,
  },
  "/dags": {
    title: "DAGs: A Web3 Trading Card Set For Final Form",
    description: null,
  },
}

const XPHelmet: React.FC = () => {
	const location = useLocation();

  const getHelmet = (pathname: string) => {
    const defaultDetails = {
      title: "Ex Populus",
      description: null,
    };

    // util func to help ensure paths match as expected
    const removeTrailingSlash = (path: string) => path.length > 1 && path.endsWith("/")
      ? path.slice(0, -1)
      : path;

    // get title from pathMappings or use defaults is mapping is not found for path
    const { title } = pathMappings[removeTrailingSlash(pathname)] || defaultDetails;

    return (
      <Helmet>
        <title>{title}</title>
        {/* meta description not working atm */}
        {/* <meta name={"description"} content={description} /> */}
      </Helmet>
    )
  }

	return getHelmet(location.pathname);
}

export default XPHelmet;
