"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudinaryRequestOptionsTypeToJSON = exports.CloudinaryRequestOptionsTypeFromJSONTyped = exports.CloudinaryRequestOptionsTypeFromJSON = exports.instanceOfCloudinaryRequestOptionsType = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the CloudinaryRequestOptionsType interface.
 */
function instanceOfCloudinaryRequestOptionsType(value) {
    var isInstance = true;
    isInstance = isInstance && "timestamp" in value;
    return isInstance;
}
exports.instanceOfCloudinaryRequestOptionsType = instanceOfCloudinaryRequestOptionsType;
function CloudinaryRequestOptionsTypeFromJSON(json) {
    return CloudinaryRequestOptionsTypeFromJSONTyped(json, false);
}
exports.CloudinaryRequestOptionsTypeFromJSON = CloudinaryRequestOptionsTypeFromJSON;
function CloudinaryRequestOptionsTypeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'timestamp': json['timestamp'],
        'public_id': !(0, runtime_1.exists)(json, 'public_id') ? undefined : json['public_id'],
        'folder': !(0, runtime_1.exists)(json, 'folder') ? undefined : json['folder'],
    };
}
exports.CloudinaryRequestOptionsTypeFromJSONTyped = CloudinaryRequestOptionsTypeFromJSONTyped;
function CloudinaryRequestOptionsTypeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timestamp': value.timestamp,
        'public_id': value.public_id,
        'folder': value.folder,
    };
}
exports.CloudinaryRequestOptionsTypeToJSON = CloudinaryRequestOptionsTypeToJSON;
