import React from "react";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

export const HomepageLamo: React.FC = () => {

	return (
		<section className="homepage-lamo">
			<div className="homepage-lamo_container">
				<div className="homepage-lamo_container_content">
					<div className="homepage-lamo_container_content_header">
						<img
							src={"https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1682534509/website/images/homepage/LAMO-logo_njje2x"}
							alt={"LAMO"}
						/>
					</div>
					<h2 className="homepage-lamo_container_content_title">
						<strong>Third Person Shooter</strong>
					</h2>
					<span className="homepage-lamo_container_content_subtitle">
						Unleash chaos with your own limited edition LAMO. Blast enemies, shoot and loot vinyl collectible characters to grow your collection.
					</span>

					<ExPopulusButton
						color="dark-on-white"
						className="homepage-lamo_container_content_button"
						to="/games/lamoverse"
					>
						Learn More
					</ExPopulusButton>
				</div>

			</div>
			<div className="homepage-lamo_gradient"/>
		</section>
	)
}
