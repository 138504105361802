import React from "react";
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {BsTwitter, FiMenu, GrMedium, SiDiscord} from "react-icons/all";
import ExPopulusHeaderNavItem, {IExPopulusHeaderNavItemProps,} from "./ExPopulusHeaderNavItem";
import ExPopulusHeaderSocialLink from "./ExPopulusHeaderSocialLink";
import {AccountButtonConfig, IExPopulusUnifiedSignUpButtonProps,} from "./IExPopulusUnifiedSignUpButtonProps";
import {ascertainLocalLinks} from "./universalNavigationLookup";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import classNames from "classnames";
import {useAtom} from "jotai";
import {dismissBannerAtom} from "../banners/ArbitrumBanner";
import {ReactComponent as XPLogo} from "../../svgs/social-media-icons/xp-no-color.svg";

export const mainSiteHeaderHeight: number = 64;
export const altSiteHeaderHeight: number = 52;

export enum HeaderBehaviorType {
	STICKY = "sticky",
	SCROLL = "scroll",
	HIDDEN = "hidden",
}

const APP_WEBSITE_URL =
	process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com";
const APP_DAG_URL =
	process.env.REACT_APP_DAG_URL || "https://dag.expopulus.com";
const APP_IRON_PIGEONS_URL =
	process.env.REACT_APP_IRON_PIGEONS_URL || "https://ironpigeons.expopulus.com";

interface IProps {
	implementingSiteURL: string;
	accountButtonConfig: IExPopulusUnifiedSignUpButtonProps;
	id?: string;
	behavior?: HeaderBehaviorType;
	height?: number; // height in px
	showTwitter?: boolean;
	showMedium?: boolean;
	showDiscord?: boolean;
	className?: string;
	links?: Array<IExPopulusHeaderNavItemProps>;
	autoAdjustForCoveredStickyContent?: boolean;
	showFullNavMinimumBreakpoint?: number | string;
	onHamburgerClick?: () => void;
}

const ExPopulusHeader: React.FC<IProps> = (props) => {
	if (props.behavior === HeaderBehaviorType.HIDDEN) {
		return null;
	}

	const isSticky = props.behavior === HeaderBehaviorType.STICKY;
	const [dismissBanner] = useAtom(dismissBannerAtom);

	// JS breakpoint configured via props as to allow custom breakpoints per implementation.
	// Single value that acts as the minimum viewport width to switch from mobile (hamburger display) to full nav.
	const showDesktopNav: boolean = useMediaQuery({
		minWidth: props.showFullNavMinimumBreakpoint,
	});

	const logo = (
		<img
			src="https://storage.googleapis.com/ex-populus-marketplace_static/xp-logo.svg"
			alt="Ex Populus"
		/>
	);

	return (
		<React.Fragment>
			<header
				id={props.id}
				className={classNames("ex-populus-header", props.className, {
					"ex-populus-header_sticky": isSticky,
					"ex-populus-header_banner": !dismissBanner,
					"ex-populus-header_sticky-banner": !dismissBanner && isSticky,
				})}
			>
				<nav
					className={"ex-populus-header_navigation"}
					style={{height: props.height}}
				>
					<div className={"ex-populus-header_navigation_brand-and-nav"}>
						<div className="ex-populus-header_navigation_brand-and-nav_logo-container">
							<Link to="/">
								<div className="ex-populus-header_navigation_brand-and-nav_logo-container_logo">
									<XPLogo color="#FFF" width={22} height={22}/>
								</div>
								{logo}
							</Link>
						</div>

						<div
							className={classNames(
								"ex-populus-header_navigation_brand-and-nav_navigation-items",
								{
									"ex-populus-header_navigation_brand-and-nav_navigation-items-d-flex":
									showDesktopNav,
								}
							)}
						>
							{props.links &&
								Array.isArray(props.links) &&
								props.links.length > 0 &&
								ascertainLocalLinks(props.links, props.implementingSiteURL).map(
									(extraLink, i) => {
										return (
											<ExPopulusHeaderNavItem
												key={`header-nav-item_${i}`}
												implementingSiteURL={props.implementingSiteURL}
												{...extraLink}
											>
												{extraLink.children}
											</ExPopulusHeaderNavItem>
										);
									}
								)}
						</div>
					</div>

					<div className={"ex-populus-header_navigation_links-and-mobile"}>
						<div
							className={classNames(
								"ex-populus-header_navigation_links-and-mobile_other-links",
								{
									"ex-populus-header_navigation_links-and-mobile_other-links-d-flex":
									showDesktopNav,
								}
							)}
						>
							{props.showMedium && (
								<ExPopulusHeaderSocialLink
									icon={GrMedium}
									ariaLabel="Medium"
									href="https://expopulus.medium.com/"
									className="ex-populus-header_navigation_links-and-mobile_other-links_social-icon-link"
								/>
							)}

							{props.showTwitter && (
								<ExPopulusHeaderSocialLink
									icon={BsTwitter}
									ariaLabel="Twitter"
									href="https://twitter.com/xai_games"
									className={
										"ex-populus-header_navigation_links-and-mobile_other-links_social-icon-link"
									}
								/>
							)}

							{props.showDiscord && (
								<ExPopulusHeaderSocialLink
									icon={SiDiscord}
									ariaLabel="Discord"
									href="https://discord.gg/xaigames"
									className={
										"ex-populus-header_navigation_links-and-mobile_other-links_social-icon-link"
									}
								/>
							)}

							{props.accountButtonConfig.accountButton ===
								AccountButtonConfig.SIGN_UP && (
									<React.Fragment>
										<Link
											to="/log-in"
											className={
												"ex-populus-header_navigation_links-and-mobile_other-links-d-flex_cta-container"
											}
										>
											<ExPopulusButton
												color="white-on-pink"
												className="ex-populus-header_navigation_links-and-mobile_other-links-d-flex_cta-container_button"
											>
												SIGN IN
											</ExPopulusButton>
										</Link>
									</React.Fragment>
								)}

							{props.accountButtonConfig.accountButton ===
								AccountButtonConfig.ACCOUNT && (
									<ExPopulusHeaderNavItem
										implementingSiteURL={props.implementingSiteURL}
										to={"/account-details"}
										raw={`${APP_WEBSITE_URL}/account-details`}
										className={
											"ex-populus-header_navigation_links-and-mobile_other-links-d-flex_account-button"
										}
									>
										Account
									</ExPopulusHeaderNavItem>
								)}
						</div>

						<div
							className={classNames(
								"ex-populus-header_navigation_links-and-mobile_hamburger-container",
								{
									"ex-populus-header_navigation_links-and-mobile_hamburger-container-d-none":
									showDesktopNav,
								}
							)}
						>
							<div
								onClick={props.onHamburgerClick}
								className="ex-populus-header_navigation_links-and-mobile_hamburger-container_hamburger"
							>
								<FiMenu/>
							</div>
						</div>
					</div>
				</nav>
			</header>
		</React.Fragment>
	);
};

export const baseHeaderLinks: Array<IExPopulusHeaderNavItemProps> = [
	{
		children: "Store",
		to: `${APP_WEBSITE_URL}/collectibles`,
	},
	{
		children: "Games",
		to: `${APP_WEBSITE_URL}/games`,
	},
	// {
	//   children: "Trading Cards",
	//   subMenu: [
	//     {
	//       children: "DAGs",
	//       to: `${APP_WEBSITE_URL}/dags/#`,
	//     },
	//     {
	//       children: "Iron Pigeons",
	//       to: `${APP_WEBSITE_URL}/ironpigeons/#`,
	//     },
	//   ],
	// },
	{
		children: "About",
		to: `${APP_WEBSITE_URL}/about`,
	},
];

ExPopulusHeader.defaultProps = {
	implementingSiteURL: undefined,
	behavior: HeaderBehaviorType.STICKY,
	autoAdjustForCoveredStickyContent: true,
	height: mainSiteHeaderHeight,
	onHamburgerClick: () => {
	},

	// When specifying links, always use the process.env URL & the universal navigation logic
	// will determine if the links should be local or not by comparing to the implementingSiteURL prop.
	links: baseHeaderLinks,
	showFullNavMinimumBreakpoint: 1200, // default value set to the bootstrap default for xl
	showTwitter: true,
	showMedium: true,
	showDiscord: true,
};

export {ExPopulusHeader};
