import React, {useMemo} from "react";
import {ScrollerRightToLeft,} from "../scrollers";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {Asset} from "client/marketplace";

const body: IHomepageInvestors = {
	scrollerDuration: 84,
	scrollerItems: [
		//akatsuki
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/akatsuki-logo_2x_ymouaa",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/akatsuki-logo_2x_ymouaa",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//animoca
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/animoca-logo_2x_tiutg1",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/animoca-logo_2x_tiutg1",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//asymmetric
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/v1682467393/website/about-page/asymmetric_adcg30",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/asymmetric_adcg30",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//citizen x
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/v1682467393/website/about-page/citizenx_evgcxj",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/citizenx_evgcxj",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//cms
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/cms-logo_2x_hfhrt9",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/cms-logo_2x_hfhrt9",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//com2us
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/com2us-logo_2x_fce6ls",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/com2us-logo_2x_fce6ls",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//enjin
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/enjin-logo_2x_nrsgpj",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/enjin-logo_2x_nrsgpj",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//fisher8
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/v1682467393/website/about-page/fisher8_r8xdr4",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/fisher8_r8xdr4",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		//headline
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/headline-logo_2x_c2ik8h",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/headline-logo_2x_c2ik8h",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
		// //offchain
		// {
		// 	url: "https://res.cloudinary.com/ex-populus/image/upload/v1682467393/website/about-page/offchain_labs_y4cexm",
		// 	contentType: "image",
		// 	cloudinary: {
		// 		publicId: "website/about-page/offchain_labs_y4cexm",
		// 		actions: {
		// 			desktop: ["f_auto", "q_80"],
		// 			mobile: ["f_auto", "q_80"]
		// 		},
		// 	},
		// } as unknown as Asset,
		//outlier
		{
			url: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_80/v1661361001/website/about-page/outlier-ventures_2x_kjvr3h",
			contentType: "image",
			cloudinary: {
				publicId: "website/about-page/outlier-ventures_2x_kjvr3h",
				actions: {
					desktop: ["f_auto", "q_80"],
					mobile: ["f_auto", "q_80"]
				},
			},
		} as unknown as Asset,
	]
}

interface IHomepageInvestors {
	scrollerDuration: number;
	scrollerItems: Array<Asset>;
}

export const HomepageInvestors: React.FC = () => {
	const {scrollerDuration} = body;
	const scrollerItems = body.scrollerItems?.length ? body.scrollerItems : [];

	const listResults = useMemo(() =>
		scrollerItems.map((source, index) => (
			<MediaAssetViewer
				key={`scroller-left-${index}`}
				asset={source}
				contentType={fileIsImage(source) ? "image" : "video"}
				elementWidth={{
					desktopWidth: 336,
					mobileWidth: 336,
				}}
			/>
		)), [scrollerItems])

	return (
		<>
			<div className="homepage-investors">
				<div className="homepage-investors_gradient"/>
				{scrollerItems.length &&
                    <ScrollerRightToLeft duration={scrollerDuration}>
						{listResults}
                    </ScrollerRightToLeft>
				}
			</div>
		</>
	);
};
