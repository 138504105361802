import React from "react";

interface CardElementData {
	tag: string,
	title: string,
	body: string,
	date: string,
	image: string,
	url: string,
}

interface ComponentContent {
	bullets: Array<CardElementData>;
}

const componentContent = {
	bullets: [
		{
			tag: "ANNOUNCEMENT",
			title: "Ex Populus Acquires LAMO, Hires Veteran Game Director",
			body: "Ex Populus, the Web3 gaming ecosystem, has acquired LAMO, an expansive entertainment package that includes a PC game, Augmented Reality Experience, and toy line. LAMO made waves over the past few years as it licensed a slew of top tier gaming streamers to be playable characters in the PC game companion collectible interactive vinyl figures. Ex Populus will tie the mediums together with interoperable Web3 technology.",
			date: "Nov 22, 2022",
			image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683760573/website/images/homepage/LAMO-news-v2_kdrrms.png",
			url: "https://expopulus.medium.com/ex-populus-acquires-lamo-hires-veteran-game-director-a5aab614cc82",
		},
		{
			tag: "ANNOUNCEMENT",
			title: "Final Form Opens Its Doors Tomorrow",
			body: "On October 19th, to celebrate the 35th Anniversary of Mike Tyson becoming the undisputed heavyweight champion of the world, Mike Tyson’s Iron Pigeons will launch as a free claim on Magic Eden’s Launchpad. The Iron Pigeons will be immediately playable in the game Final Form. All LAMO and DAG collectors will be automatically allow listed for the first 5 hours of the drop.",
			date: "Oct 11, 2022",
			image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683760236/website/images/homepage/FF_news_jguzqf.png",
			url: "https://expopulus.medium.com/final-form-opens-its-doors-tomorrow-755d6732be08",
		},
		{
			tag: "ANNOUNCEMENT",
			title: "Ex Populus, the Web3 Game Studio, Join Forces With Deeze and Farokh, NFT Thought Leaders",
			body: "Ex Populus, the Web3 game developer, has announced the addition of two prominent creators in the NFT space, Deeze and Farokh, as key members of the Ex Populus advisory board. DeezFi and Farokh have both been long-time collaborators with Ex Populus co-founder Soban Saqib (@Sobylife) and have kept a close eye on Ex Populus developments over the past year.",
			date: "Aug 15, 2022",
			image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683760572/website/images/homepage/advisors-v3_uzegse.png",
			url: "https://expopulus.medium.com/ex-populus-the-web3-game-studio-join-forces-with-deeze-and-farokh-nft-thought-leaders-c7b99edb4f1e",
		},
	],
} satisfies ComponentContent;

export const HomepageNews: React.FC = () => {


	const bulletItem = (item: CardElementData, index: number) => {
		return (
			<div key={`testimonial-${index}`} className="homepage-news_container_items_container">
				<div
					className="homepage-news_container_items_container_image"
					onClick={() => window.open(item.url, "_blank", "noopener noreferrer")}
				>
					<img src={item.image} alt={"News image"}/>
				</div>

				<div className="homepage-news_container_items_container_content">
					<div className="homepage-news_container_items_container_content_tag">
						{item.tag}
					</div>
					<div
						className="homepage-news_container_items_container_content_title"
						onClick={() => window.open(item.url, "_blank", "noopener noreferrer")}
					>
						{item.title}
					</div>
					<div className="homepage-news_container_items_container_content_body">
						{item.body}
					</div>
					<div className="homepage-news_container_items_container_content_date">
						{item.date}
					</div>
				</div>
			</div>
		);
	}

	return (
		<section className="homepage-news">
			<div className="homepage-news_container">
				<h2 className="homepage-news_container_title">
					<strong>News</strong>
				</h2>

				<div className="homepage-news_container_items">
					{componentContent.bullets.map(bulletItem)}
				</div>
			</div>
		</section>
	)
}
