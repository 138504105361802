"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAssetUploadedBodyToJSON = exports.CreateAssetUploadedBodyFromJSONTyped = exports.CreateAssetUploadedBodyFromJSON = exports.instanceOfCreateAssetUploadedBody = void 0;
var runtime_1 = require("../runtime");
var AssetType_1 = require("./AssetType");
/**
 * Check if a given object implements the CreateAssetUploadedBody interface.
 */
function instanceOfCreateAssetUploadedBody(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "fileName" in value;
    return isInstance;
}
exports.instanceOfCreateAssetUploadedBody = instanceOfCreateAssetUploadedBody;
function CreateAssetUploadedBodyFromJSON(json) {
    return CreateAssetUploadedBodyFromJSONTyped(json, false);
}
exports.CreateAssetUploadedBodyFromJSON = CreateAssetUploadedBodyFromJSON;
function CreateAssetUploadedBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, AssetType_1.AssetTypeFromJSON)(json['type']),
        'fileName': json['fileName'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.CreateAssetUploadedBodyFromJSONTyped = CreateAssetUploadedBodyFromJSONTyped;
function CreateAssetUploadedBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, AssetType_1.AssetTypeToJSON)(value.type),
        'fileName': value.fileName,
        'name': value.name,
        'description': value.description,
    };
}
exports.CreateAssetUploadedBodyToJSON = CreateAssetUploadedBodyToJSON;
