import React, {HTMLAttributes} from "react";
import ContactFormComponent from "./ContactFormComponent";
import classNames from "classnames";
import {Container} from "reactstrap";

interface IProps extends HTMLAttributes<HTMLDivElement> {
}

const HowCanWeHelpSection: React.FC<IProps> = (props) => {

	return (
		<Container>
			<div
				{...props}
				className={classNames("how-can-we-help", props.className)}
			>
				<div>
					<div className="how-can-we-help__text">
						<h2 className="how-can-we-help__text-header">How can we help?</h2>
						<p className="how-can-we-help__text-body">If you are a developer looking for a publisher, you’re at the right place. Please fill out the form and we will get back to you soon.</p>
					</div>
				</div>

				<div>
					<ContactFormComponent/>
				</div>
			</div>
		</Container>
	);
}

export default HowCanWeHelpSection;
