import React from "react";

interface IGameStatusTag {
	status: GameStatus;
}

export type IGameCardTagType = { status: GameStatus, text?: never } | { status?: never, text: string };

export type GameStatus = "Pre-Production" | "In Development" | "Post-Production" | "Beta Testing";

export const GameCardTag = (props: { text: string }) => (
	<div className={"game-card-tag"}><span>{props.text}</span></div>
);

export const GameStatusTag = (props: IGameStatusTag) => (<GameCardTag text={props.status} />);
