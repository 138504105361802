import * as React from "react";
import {IDataElementArrayElement} from "./FinalFormTCS";
import classNames from "classnames";

interface IProps {
    data: Array<IDataElementArrayElement>
    selectedCarouselSlide: number;
    setSelectedCarouselSlide: (e: number) => void;
}

const GameDetailsTCSTabControl: React.FC<IProps> = (props) => {

    const updateSlide = (slideNumber) => () => {
        return props.setSelectedCarouselSlide(slideNumber)
    }

    return (
        <aside className="game-details-tcs-tab-control">
            <div className="game-details-tcs-tab-control_scroll">
                {props.data.map((item, index) => {
                    return (
                        <span
                            id={item.header}
                            key={`tcs-card-tab-control-${index}`}
                            onClick={updateSlide(index)}
                            className={classNames("game-details-tcs-tab-control_scroll_item", {
                                "game-details-tcs-tab-control_scroll_item_selected": props.selectedCarouselSlide === index,
                            })}
                        >
                        {item.header}
                    </span>
                    )
                })}
            </div>
        </aside>
    )
};

export default GameDetailsTCSTabControl;
