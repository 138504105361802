import React, {ChangeEvent, useState} from "react";
import {Input} from "reactstrap";
import {RiSendPlaneFill} from "react-icons/all";
import {ContactUsApi, RequestContactUsBody} from "client/marketplace";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {connect} from "react-redux";
import ContactUsSuccessModal from "../modals/ContactUsSuccessModal";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

const defaultRequestContactUsBody: RequestContactUsBody = {
	name: "",
	email: "",
	subject: "",
	body: "",
}

interface IProps {
	dispatch?: any;
}

const ContactFormComponent: React.FC<IProps> = (props) => {

	const [contactForm, setContactForm] = useState<RequestContactUsBody>(defaultRequestContactUsBody);
	const [contactSuccessModal, setContactSuccessModal] = useState<boolean>(false);

	/**
	 * Dynamically on change for the text inputs.
	 *
	 */
	function dynamicOnChange(key: keyof RequestContactUsBody): (e: ChangeEvent<HTMLInputElement>) => void {
		return (e) => {
			setContactForm({
				...contactForm,
				[key]: e.target.value,
			});
		}
	}

	async function submitContactUsForm(e?): Promise<void> {
		e?.preventDefault();

		props.dispatch(incrementLoading());

		try {
			await new ContactUsApi(getConfig()).requestContactUs({
				requestContactUsBody: {
					name: contactForm.name || undefined,
					email: contactForm.email || undefined,
					subject: contactForm.subject || undefined,
					body: contactForm.body || undefined,
				}
			});
			setContactSuccessModal(true);
		} catch (err) {
			props.dispatch(addError(err));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<section className="contact-form-component">
			<ContactUsSuccessModal
				isOpen={contactSuccessModal}
				onClose={setContactSuccessModal}
			/>

			<div className="contact-form-component-container">
				<div className="contact-form-component-container__header">
					<span className="contact-form-component-container__header_1">Send us a message!</span>

					<span className="contact-form-component-container__header_2">
						{"Or you can reach us via email at "}
						<a
							href="mailto:hello@expopulus.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							hello@expopulus.com
						</a>
					</span>
				</div>

				<div>
					<form className="" onSubmit={submitContactUsForm}>
						<Input
							name="name"
							placeholder="Name"
							value={contactForm.name}
							onChange={dynamicOnChange("name")}
						/>

						<Input
							name="email"
							type="email"
							placeholder="Your email"
							value={contactForm.email}
							onChange={dynamicOnChange("email")}
						/>

						<Input
							name="subject"
							placeholder="How can we help you?"
							value={contactForm.subject}
							onChange={dynamicOnChange("subject")}
						/>

						<Input
							type="textarea"
							placeholder="Message"
							value={contactForm.body}
							onChange={dynamicOnChange("body")}
							rows={10}
						/>
					</form>
				</div>

				<div className="contact-form-component-container__submit">
					<div/>

					<div>
						<ExPopulusButton
							color="pink"
							leftIcon={RiSendPlaneFill}
							onClick={submitContactUsForm}
						>
							SEND MESSAGE
						</ExPopulusButton>
					</div>
				</div>
			</div>
		</section>
	);
}

export default connect()(ContactFormComponent);
