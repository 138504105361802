import {CTAItemType, IBuyNowProperties, ICTACommonRare, ICTASoldOut} from "../components/dag/DagBuyNowCTA";
import { SaleTypeEnum } from "./SaleTypeEnum";

export const getCTAValue = (saleType: SaleTypeEnum, buyNowData: IBuyNowProperties): CTAItemType => {
	const ctaValues = {
		[SaleTypeEnum.COMMON]: buyNowData.CTACommonValue as ICTACommonRare,
		[SaleTypeEnum.RARE]: buyNowData.CTARareValue as ICTACommonRare,
		[SaleTypeEnum.RARE_SOLD_OUT]: buyNowData.CTARareSoldOut as ICTASoldOut,
	}

	return ctaValues[saleType] || ctaValues[SaleTypeEnum.RARE];
}
