import {Configuration} from "client/cs";
import {Token} from "client/cs";

export default function getCsConfig(fullToken?: Token): Configuration {
	const headers = fullToken ? {headers: {authorization: `Bearer ${fullToken?.token}`}} : {};

	return new Configuration({
		...headers,
	});
}
