import {Asset} from "client/marketplace";
import {GameStatus} from "../../components/gameCard/GameCardTag";
import {IProps as IAboutProps} from "../../components/gameDetails/GameDetailsAbout";
import {IProps as IDetailsProps} from "../../components/gameDetails/GameDetailsInfoCard";
import {IGameContentProps} from "../../components/gameDetails/GameDetailsInGameContent";
import {ISocialProof} from "../../components/SocialProof";

import gameDetailsCarnivale from "./gameDetailsCarnivale";
import gameDetailsFinalForm from "./gameDetailsFinalForm";
import gameDetailsLamoverse from "./gameDetailsLamoverse";

export type GameSlug = "final-form" | "lamoverse" | "carnivale";

export type IGameCardTagType = { status: GameStatus, text?: never } | { status?: never, text: string };

export interface ISystemRequirement {
    label: string;
    value: string;
}

export type SystemRequirementsType = {
    title: string;
    requirements: Array<ISystemRequirement>;
};

export type HardCodedGameDetails = {
    id: string;
    title: string;
    pageTitle?: string;
    socialIcons?: boolean;
    playFree: boolean;
    to: string;
    desc: string;
    keyArtPath: string;
    logo: string;
    carouselAssets: Array<Asset>;
    details: IDetailsProps;
    gameContent?: Array<IGameContentProps>;
    moreGames?: Array<GameSlug>;
    about?: IAboutProps;
    tags?: Array<IGameCardTagType>;
    systemRequirements?: Array<SystemRequirementsType>;
    social?: {
        proof?: Array<ISocialProof>;
    }
}

export {
    gameDetailsCarnivale,
    gameDetailsLamoverse,
    gameDetailsFinalForm,
};

export default {
    carnivale: gameDetailsCarnivale,
    lamoverse: gameDetailsLamoverse,
    "final-form": gameDetailsFinalForm,
};
