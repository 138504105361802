"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordBodyToJSON = exports.ChangePasswordBodyFromJSONTyped = exports.ChangePasswordBodyFromJSON = exports.instanceOfChangePasswordBody = void 0;
/**
 * Check if a given object implements the ChangePasswordBody interface.
 */
function instanceOfChangePasswordBody(value) {
    var isInstance = true;
    isInstance = isInstance && "oldPassword" in value;
    isInstance = isInstance && "newPassword" in value;
    isInstance = isInstance && "confirmPassword" in value;
    return isInstance;
}
exports.instanceOfChangePasswordBody = instanceOfChangePasswordBody;
function ChangePasswordBodyFromJSON(json) {
    return ChangePasswordBodyFromJSONTyped(json, false);
}
exports.ChangePasswordBodyFromJSON = ChangePasswordBodyFromJSON;
function ChangePasswordBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oldPassword': json['oldPassword'],
        'newPassword': json['newPassword'],
        'confirmPassword': json['confirmPassword'],
    };
}
exports.ChangePasswordBodyFromJSONTyped = ChangePasswordBodyFromJSONTyped;
function ChangePasswordBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}
exports.ChangePasswordBodyToJSON = ChangePasswordBodyToJSON;
