import React, {useEffect} from "react";

const DigitalCollectibleTermsPage: React.FC = () => {

    return (
        <section className={"legal-text"}>
            <div className="text-container">
                <div className={"page-content"}>
                    <div className="page-title">
                        <h1 className="text-center">Ex Populus Digital Collectibles Store Terms</h1>
                        <p className="upcase text-center">Last Updated: 1/6/2022</p>
                    </div>

                    <p>
                        By purchasing or otherwise obtaining a unique, non-fungible token created by Ex Populus, Inc.
                        (&ldquo;<span className={"bold-white-text"}>Ex Populus</span>&rdquo;) (&ldquo;<span
                        className={"bold-white-text"}>NFT</span>&rdquo;), either through an initial transfer from a
                        licensee of Ex Populus or a subsequent transfer from the prior owner of the Digital Collectible,
                        you agree to these Digital Collectible Terms by and between you and Ex Populus (these &ldquo;
                        <span className={"bold-white-text"}>Terms</span>&rdquo;).
                    </p>
                    <p>
                        The NFT is a limited-edition digital asset that is specifically associated with one or more
                        works of authorship owned or controlled by Ex Populus or its affiliates (the &ldquo;<span
                        className={"bold-white-text"}>Source Materials</span>&rdquo;). Although the NFT is sold to its
                        purchaser, the Source Materials are licensed and not sold to such purchaser. The Source
                        Materials are neither stored nor embedded in the NFT, but are accessible through the NFT. Ex
                        Populus hereby grants to the owner of the NFT a non-exclusive license, subject to these Terms,
                        to access, perform, display and develop creative works using the Source Materials, for the
                        owner&rsquo;s non-commercial purposes. Except for the license granted above, Ex Populus and its
                        affiliates retain all right, title and interest in and to the Source Materials. If you are the
                        owner of the NFT, you may transfer the NFT to a third party, but, after you do so, your license
                        to the Artwork will immediately terminate.
                    </p>
                    <p>
                        Ex Populus, in its sole discretion, may, at a time to be determined by Ex Populus, provide a
                        physical collectible (the &ldquo;<span
                        className={"bold-white-text"}>Physical Collectible</span>&rdquo;) to the then-current holder of
                        the NFT, subject to confirmation of the NFT holder&rsquo;s identity and mailing address.
                    </p>
                    <p className="bold-text">
                        THE DIGITAL COLLECTIBLE IS INTENDED FOR CONSUMER ENJOYMENT, USE AND CONSUMPTION ONLY. IT IS NOT
                        A &ldquo;SECURITY,&rdquo; AS DEFINED UNDER THE SECURITIES ACT OF 1933, AS AMENDED, THE
                        SECURITIES EXCHANGE ACT OF 1934, AS AMENDED, THE INVESTMENT COMPANY ACT OF 1940, AS AMENDED, OR
                        UNDER THE SECURITIES LAWS OF ANY U.S. STATE.
                    </p>
                    <p className="bold-text">
                        Ex Populus provides the NFT and Physical Collectible, and licenses the Source Materials to the
                        owner of the NFT, on an &ldquo;AS IS&rdquo; basis, and expressly disclaims any warranties or
                        conditions of any kind, either express or implied, including, without limitation, any warranties
                        or conditions of title, non-infringement, merchantability, or fitness for a particular purpose.
                        In no event will Ex Populus be liable for any indirect, incidental, consequential, or other
                        non-direct damages of any kind. The maximum aggregate liability of Ex Populus for all damages
                        and causes of action, whether in contract, tort (including negligence) or otherwise, shall be
                        $100. The laws of the United States of America and the State of New York govern all matters
                        arising out of or relating to these Terms without giving effect to any conflict of law
                        principles.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default DigitalCollectibleTermsPage;
