import { GameSightType } from "./gsghtInterfaces";
import { gsghtSetExternalUserId } from "./index";
/**
 * function for calling GameSight analytics.
 * call without event type or parameters for automatic click measurement.
 *
 * @param event_type
 * @param user
 * @param event_params
 *
 * example format: gsght("send", "[{event_type}]", [{event_params}])
 */
export function gsghtSend(event_type, user, event_params) {
    // when we have it, send the user's mongodb id to GameSight as an external id
    if (user === null || user === void 0 ? void 0 : user.id) {
        gsghtSetExternalUserId({
            type: "mongo_id",
            id: user.id,
        });
        window.gsght("send");
    }
    // passing undefined into the event params will break the gsght call
    if (event_params) {
        var formattedEventParams = Array.isArray(event_params) ? event_params : [event_params];
        return window.gsght(GameSightType.SEND, event_type, formattedEventParams);
    }
    return window.gsght(GameSightType.SEND, event_type);
}
