import React from "react";
import { Container } from "reactstrap";
import { Token, User } from "client/cs";
import { Asset } from "client/marketplace";
import { HomepageTemplateBuyNowCTA, IBuyNowProperties } from "./DagBuyNowCTA";
import { getCTAValue } from "../../utils/getCTAValue";
import { SaleTypeEnum } from "../../utils/SaleTypeEnum";
import { fileIsImage } from "../../utils/fileTypeChecks";
import { MarketName } from "../MarketButton";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import { ArbitrumComingSoon } from "./ArbitrumComingSoon";
import { TokenType } from "../../utils/TokenType";

const dataElement: IBuyNowProperties = {
  header: "Get Cards",
  subheader: "Get a DAG and evolve your way to legendary.",
  body1:
    "Each DAG is a trading card with a unique combination of visual traits and stats. In the Ex Populus trading card system, you will be able to leverage your unique card to compete against other collectors for status. Enjoy completing your sets with a card from each house. Every DAG card is fully compatible with other decks in the Ex Populus trading card system.",
  backgroundImage: {
    url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/buy%20now/buy-now_background%402x.png",
    contentType: "image",
    cloudinary: {
      publicId: "website/images/buy-now_background_2x_c99eaf",
      actions: {
        desktop: ["f_auto", "q_auto"],
        mobile: ["f_auto", "q_auto"],
      },
    },
  } as unknown as Asset,
  backgroundImageAlt: "Buy Now Background",

  //TODO: We need to create interfaces for our objects in order to enforce our templates.
  CTACommonValue: {
    saleType: SaleTypeEnum.COMMON,
    rarity: "Common",
    collectionName: "DAG",
    collectionNamePlural: "DAGs",
    header: "Common DAG Trading Card",
    subheader:
      "You will receive 1 Common DAG Trading Card for free with each order",
    price: 0,
    priceType: "Sol",
    cryptoIcon: "images/icons/solana-sol-logo.svg",
    buttonText: "GET CARDS",
    bullets: [
      {
        iconName: "trophy",
        text: "Mint now for a free common card! (while supplies last; gas additional)",
      },
      {
        iconName: "star",
        text: "Place up to 50 orders per wallet. You can collect and trade these cards with friends.",
      },
      {
        iconName: "info",
        text: (
          <p>
            Transactions may take up to a few minutes. Visit our{" "}
            <a
              href="https://discord.gg/xaigames"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>{" "}
            for support.
          </p>
        ),
      },
    ],
  },

  //TODO: We need to create interfaces for our objects in order to enforce our templates.
  CTARareValue: {
    saleType: SaleTypeEnum.RARE,
    rarity: "Rare",
    collectionName: "DAG",
    collectionNamePlural: "DAGs",
    header: "Rare DAG Trading Card",
    subheader: "You will receive 1 Rare DAG Trading Card with each purchase",
    price: 0.5,
    priceType: "Sol",
    cryptoIcon: "/images/icons/solana-sol-logo.svg",
    buttonText: "GET CARDS",
    bullets: [
      {
        iconName: "trophy",
        text: "Mint now for a rare card! (while supplies last; gas additional)",
      },
      {
        iconName: "star",
        text: "Place up to 50 orders per wallet. You can collect and trade these cards with friends.",
      },
      {
        iconName: "info",
        text: (
          <p>
            Transactions may take up to a few minutes. Visit our{" "}
            <a
              href="https://discord.gg/xaigames"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>{" "}
            for support.
          </p>
        ),
      },
    ],
  },

  CTARareSoldOut: {
    saleType: SaleTypeEnum.RARE_SOLD_OUT,
    rarity: "Rare",
    collectionName: "DAG",
    collectionNamePlural: "DAGs",
    header: "Rare DAG Trading Card",
    disabled: true,
    marketButtons: [
      {
        marketName: MarketName.MAGIC_EDEN,
        url: "https://magiceden.io/marketplace/decentralized_autonomous_gigaunits_dag",
      },
      {
        marketName: MarketName.OPENSEA,
        url: "https://opensea.io/collection/dags-evolved",
      },
    ],
  },
};

export interface IProps {
  saleType: SaleTypeEnum;
  tokenType: TokenType;
  fullToken?: Token;
  currentUser?: User;
  redirect?: string;
  selectionHelper: () => void;
  onClickMarketButton?: () => void;
}

const DagHomepageBuyNow: React.FC<IProps> = (props) => {
  const ctaValue = getCTAValue(props.saleType, dataElement);

  return (
    <section id={"homepage-buy-now"} className={"homepage-template-buy-now"}>
      <div className={"homepage-template-buy-now_overlay"} />

      {/*     TODO: Touch base with Spencer to understand why we are unable to set more than one class on ...     */}
      <Container className={"container"}>
        <div className={"homepage-template-buy-now_container"}>
          <div className={"homepage-template-buy-now_container_text"}>
            <div className={"homepage-template-buy-now_container_text_header"}>
              {dataElement.header}
            </div>
            <div
              className={"homepage-template-buy-now_container_text_subheader"}
            >
              {dataElement.subheader}
            </div>
            <p className={"homepage-template-buy-now_container_text_body"}>
              {dataElement.body1}
            </p>
            <p className={"homepage-template-buy-now_container_text_body"}>
              {dataElement.body2}
            </p>
          </div>

          <HomepageTemplateBuyNowCTA
            ctaValue={ctaValue}
            fullToken={props.fullToken}
            saleType={props.saleType as SaleTypeEnum}
            tokenType={props.tokenType}
            currentUser={props.currentUser}
            selectionHelper={props.selectionHelper}
            redirect={props.redirect}
            onClickMarketButton={props.onClickMarketButton}
          />
        </div>
      </Container>

      <div className={"homepage-template-buy-now_background"}>
        <MediaAssetViewer
          asset={dataElement.backgroundImage}
          imageAltText={dataElement.backgroundImageAlt}
          contentType={
            fileIsImage(dataElement.backgroundImage) ? "image" : "video"
          }
        />
      </div>
    </section>
  );
};

DagHomepageBuyNow.defaultProps = {
  onClickMarketButton: () => {},
};

export { DagHomepageBuyNow };
