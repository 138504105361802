"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftPropertiesToJSON = exports.NftPropertiesFromJSONTyped = exports.NftPropertiesFromJSON = exports.instanceOfNftProperties = void 0;
var runtime_1 = require("../runtime");
var NftCreator_1 = require("./NftCreator");
var NftFile_1 = require("./NftFile");
/**
 * Check if a given object implements the NftProperties interface.
 */
function instanceOfNftProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "creators" in value;
    return isInstance;
}
exports.instanceOfNftProperties = instanceOfNftProperties;
function NftPropertiesFromJSON(json) {
    return NftPropertiesFromJSONTyped(json, false);
}
exports.NftPropertiesFromJSON = NftPropertiesFromJSON;
function NftPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'creators': (json['creators'].map(NftCreator_1.NftCreatorFromJSON)),
        'files': !(0, runtime_1.exists)(json, 'files') ? undefined : (json['files'].map(NftFile_1.NftFileFromJSON)),
        'category': !(0, runtime_1.exists)(json, 'category') ? undefined : json['category'],
    };
}
exports.NftPropertiesFromJSONTyped = NftPropertiesFromJSONTyped;
function NftPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'creators': (value.creators.map(NftCreator_1.NftCreatorToJSON)),
        'files': value.files === undefined ? undefined : (value.files.map(NftFile_1.NftFileToJSON)),
        'category': value.category,
    };
}
exports.NftPropertiesToJSON = NftPropertiesToJSON;
