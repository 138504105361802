"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletToJSON = exports.WalletFromJSONTyped = exports.WalletFromJSON = exports.instanceOfWallet = void 0;
var WalletType_1 = require("./WalletType");
/**
 * Check if a given object implements the Wallet interface.
 */
function instanceOfWallet(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "address" in value;
    return isInstance;
}
exports.instanceOfWallet = instanceOfWallet;
function WalletFromJSON(json) {
    return WalletFromJSONTyped(json, false);
}
exports.WalletFromJSON = WalletFromJSON;
function WalletFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, WalletType_1.WalletTypeFromJSON)(json['type']),
        'address': json['address'],
    };
}
exports.WalletFromJSONTyped = WalletFromJSONTyped;
function WalletToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, WalletType_1.WalletTypeToJSON)(value.type),
        'address': value.address,
    };
}
exports.WalletToJSON = WalletToJSON;
