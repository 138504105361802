import React from "react";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {ReactComponent as FFHeader} from "../../svgs/ff-header.svg";

export const HomepageFinalForm: React.FC = () => {

	return (
		<section className="homepage-clip">
			<div className="homepage-final-form">
				<div className="homepage-final-form_container">
					<div className="homepage-final-form_container_content">
						<div className="homepage-final-form_container_content_header">
							<FFHeader/>
						</div>
						<h2 className="homepage-final-form_container_content_title">
							<strong>Rogue-Lite TCG Auto Battler</strong>
						</h2>
						<span className="homepage-final-form_container_content_subtitle">
							In Final Form, players will participate in high frequency deck building and auto-battles mashed-up with Rogue-Lite mechanics.
						</span>

						<ExPopulusButton
							color="dark-on-white"
							className="homepage-final-form_container_content_button"
							to="/games/final-form"
						>
							Play Free
						</ExPopulusButton>
					</div>

				</div>
				<div className="homepage-final-form_gradient"/>
			</div>
		</section>
	)
}
