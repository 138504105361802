"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoBanSortToJSON = exports.GeoBanSortFromJSONTyped = exports.GeoBanSortFromJSON = exports.GeoBanSort = void 0;
/**
 *
 * @export
 */
exports.GeoBanSort = (_a = {},
    _a["+type"] = '+type',
    _a["-type"] = '-type',
    _a["+createdAt"] = '+createdAt',
    _a["-createdAt"] = '-createdAt',
    _a["+updatedAt"] = '+updatedAt',
    _a["-updatedAt"] = '-updatedAt',
    _a);
function GeoBanSortFromJSON(json) {
    return GeoBanSortFromJSONTyped(json, false);
}
exports.GeoBanSortFromJSON = GeoBanSortFromJSON;
function GeoBanSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GeoBanSortFromJSONTyped = GeoBanSortFromJSONTyped;
function GeoBanSortToJSON(value) {
    return value;
}
exports.GeoBanSortToJSON = GeoBanSortToJSON;
