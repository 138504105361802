import React from "react";
import {Container} from "reactstrap";
import {Asset} from "client/marketplace";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import openSecondaryMarketURL from "../../utils/openSecondaryMarketURL";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {TokenType} from "../../utils/TokenType";

const body: ITraits = {
    header: "TRAITS & RARITY",
    subheader:
        (
            <React.Fragment>
                Every card completely<br/>unique.
            </React.Fragment>
        ),
    cardImageAlt: "Traits and Rarity",
    ctaText: (
        <p>
            Iron Pigeons are now available to play in <a
            href={`${process.env.REACT_APP_WEBSITE_URL}/games/final-form`} target="_blank"
            rel="noopener noreferrer">Final Form™</a>
        </p>
    ),
    asset: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/traits%20%26%20rarity/ip-card-blank-hires-sized.png",
        contentType: "image",
        cloudinary: {
            publicId: "website/images/ip-traits_n2qj6a",
            actions: {
                desktop: ["f_auto", "q_auto"],
                mobile: ["f_auto", "q_auto"]
            },
        },
    } as unknown as Asset,
    leftTraits: [
        {
            trait: "backgrounds",
            rarity: "12",
        },
        {
            trait: "beaks",
            rarity: "9",
        },
        {
            trait: "details",
            rarity: "4",
        },
    ],
    rightTraits: [
        {
            trait: "bodies",
            rarity: "37",
        },
        {
            trait: "costumes",
            rarity: "66",
        },
        {
            trait: "eyes",
            rarity: "20",
        },
    ],
}

interface ITraitElement {
    trait: string;
    rarity: string;
}

interface ITraits {
    asset: Asset;
    header: string;
    subheader: string | JSX.Element; // If the subheader uses anything besides the React.Fragment, we will run into nesting issues.
    ctaText?: string | JSX.Element;
    cardImageAlt: string;
    leftTraits: Array<ITraitElement>
    rightTraits: Array<ITraitElement>
}

const TraitLabel: React.FC<{ align: "left" | "right", label: ITraitElement }> = ({align, label: {trait, rarity}}) => {
    return (
        <div className={`${"trait-label"} ${align}`}>
            <div className={"rarity"}>{rarity}</div>
            <div className={"trait"}>{trait}</div>
        </div>
    );
}

const TraitsList: React.FC<{ align: "left" | "right", traits: Array<ITraitElement> }> = ({align, traits}) => {
    return (
        <div className={`${"trait-labels-list"} ${align}`}>
            {traits.map((trait, index) => (<TraitLabel key={`traits-${align}-${index}`} align={align} label={trait}/>))}
        </div>
    );
}

interface IProps {
    onClickGetCardsCallback?: () => void;
}

const IronPigeonsHomepageTraitsAndRarity: React.FC<IProps> = (props) => {
    const onClickGetCards = () => {
        openSecondaryMarketURL(TokenType.IRON_PIGEONS,);

        props.onClickGetCardsCallback();
    }

    return (
        <section className={"homepage-template-traits-and-rarity"}>
            <Container className={"container"}>
                <div className={"homepage-template-traits-and-rarity_container"}>
                    <div className={"homepage-template-traits-and-rarity_container_text"}>
                        <div className={"homepage-template-traits-and-rarity_container_text_header"}>
                            {body.header}
                        </div>
                        <div className={"homepage-template-traits-and-rarity_container_text_subheader"}>
                            {body.subheader}
                        </div>
                    </div>

                    <div className={"homepage-template-traits-and-rarity_container_traits"}>
                        <div className={"homepage-template-traits-and-rarity_container_card_mobile"}>
                            <MediaAssetViewer
                                asset={body.asset}
                                imageAltText={body.cardImageAlt}
                                contentType={fileIsImage(body.asset) ? "image" : "video"}
                            />
                        </div>
                        <div className={"homepage-template-traits-and-rarity_container_traits_align"}>
                            <div className={"homepage-template-traits-and-rarity_container_traits_left"}>
                                <TraitsList align={"left"} traits={body.leftTraits}/>
                            </div>
                            <div className={"homepage-template-traits-and-rarity_container_card_desktop"}>
                                <MediaAssetViewer
                                    asset={body.asset}
                                    imageAltText={body.cardImageAlt}
                                    contentType={fileIsImage(body.asset) ? "image" : "video"}
                                    elementWidth={{
                                        desktopWidth: 455,
                                        mobileWidth: 381,
                                    }}
                                />
                            </div>

                            <div className={"homepage-template-traits-and-rarity_container_traits_right"}>
                                <TraitsList align={"right"} traits={body.rightTraits}/>
                            </div>
                        </div>
                    </div>

                    {body.ctaText && (
                        <div className={"homepage-template-traits-and-rarity_container-2"}>
                            <div className={"homepage-template-traits-and-rarity_container-2_cta"}>
                                <div className={"homepage-template-traits-and-rarity_container-2_cta_text"}>
                                    {body.ctaText}
                                </div>

                                <ExPopulusButton
                                    color="white-on-pink"
                                    to="#get-cards"
                                    onClick={onClickGetCards}
                                    className={"homepage-template-traits-and-rarity_container-2_cta_button"}
                                >
                                    Get Cards
                                </ExPopulusButton>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </section>
    );
};

IronPigeonsHomepageTraitsAndRarity.defaultProps = {
    onClickGetCardsCallback: () => {
    },
}

export {IronPigeonsHomepageTraitsAndRarity};
