import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";
import {GAMESIGHT_EVENTS} from "shared";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {sendEvent} from "../../utils/event-tracking";
import hardcodedGameDetails, {GameSlug} from "../../utils/hardcoded";
import GameDetailsMoreGames from "../../components/gameDetails/GameDetailsMoreGames";
import SystemRequirements from "../../components/gameDetails/SystemRequirements";
import {FinalFormTCS} from "../../components/gameDetails/FinalFormTCS";
import GameDetailsSocialProofCarousel from "../../components/gameDetails/GameDetailsSocialProofCarousel";
import {decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {FinalFormHero} from "../../components/gameDetails/finalForm/FinalFormHero";
import {FinalFormOverview} from "../../components/gameDetails/finalForm/FinalFormOverview";
import {FinalFormDeckBuilding} from "../../components/gameDetails/finalForm/FinalFormDeckBuilding";
import {FinalFormBurnToEvolve} from "../../components/gameDetails/finalForm/FinalFormBurnToEvolve";
import {FinalFormChromos} from "../../components/gameDetails/finalForm/FinalFormChromos";
import {FinalFormMultiverse} from "../../components/gameDetails/finalForm/FinalFormMultiverse";
import {FinalFormInGameContent} from "../../components/gameDetails/finalForm/FinalFormInGameContent";
import {FinalFormTitleBar} from "../../components/gameDetails/finalForm/FinalFormTitleBar";
import {FinalFormNextGen} from "../../components/gameDetails/finalForm/FinalFormNextGen";
import {ExPopulusButton} from "../../components/buttons/ExPopulusButton";

interface GameDetailsPageProps {
	gameSlug: GameSlug;
}

export const FinalForm: React.FC<GameDetailsPageProps> = (props: GameDetailsPageProps) => {
	const history = useHistory();
	const currentUser = useSelector((store: IStore) => store.metaStore.currentUser)
	const dispatch = useDispatch();

	const gameDetails = hardcodedGameDetails[props.gameSlug];

	useEffect(() => {
		const getGameInfo = async (gameSlug: GameSlug) => {
			try {
				dispatch(incrementLoading());

			} catch (e) {
				console.error(e);

			} finally {
				dispatch(decrementLoading());
			}
		};

		void getGameInfo(props.gameSlug);
	}, [props.gameSlug]);

	/*
	 * Used to send GameSight data ONLY on Final Form page view.
	 */
	useEffect(() => {
		if (props.gameSlug !== "final-form") {
			return;
		}

		sendEvent({eventName: GAMESIGHT_EVENTS.FF_WEB_PAGEVIEW, user: currentUser});
		window.onpageshow = function (event) {
			if (event.persisted) {
				sendEvent({
					eventName: GAMESIGHT_EVENTS.FF_WEB_PAGEVIEW,
					user: currentUser,
				});
			}
		};

		return () => {
			window.onpageshow = undefined;
		}
	}, [props.gameSlug, history.location.pathname]);

	// Identifies if the game has Game Content and adds a button to scroll the user down to the section.
	const getCardsCTAButton = (onClick: () => void, className: string, color) => (
		gameDetails.gameContent
			? (
				<ExPopulusButton
					color={color}
					className={className}
					onClick={onClick}
				>
					Get Cards
				</ExPopulusButton>
			)
			: null
	);

	function scrollToSection(sectionName: string): () => void {
		return () => {
			const section = document.getElementById(sectionName);
			if (section) {
				window.scrollTo(0, section.offsetTop - 150);
			}
		}
	}

	return (
		<React.Fragment>
			{gameDetails.pageTitle && (
				<Helmet>
					<title>{gameDetails.pageTitle}</title>
				</Helmet>
			)}

			<section className="game-details-page-v2">
				<div className="game-details-page-v2_content">
					<FinalFormHero/>

					<FinalFormTitleBar
						id="final-form-title-bar"
						user={currentUser}
					/>

					<GameDetailsSocialProofCarousel
						proof={gameDetails.social?.proof}
					/>

					<FinalFormOverview/>
					<FinalFormDeckBuilding/>
					<FinalFormChromos/>
					<FinalFormBurnToEvolve
						onClick={scrollToSection("get-cards")}
					/>
					{/*<FinalFormGuild/>*/}
					<FinalFormTCS/>
					<FinalFormMultiverse
						onClick={scrollToSection("get-cards")}
					/>
					<FinalFormNextGen/>
					<FinalFormInGameContent
						id={"get-cards"}
					/>

					{gameDetails.systemRequirements && (
						<SystemRequirements requirements={gameDetails.systemRequirements}/>
					)}

					{gameDetails.moreGames.length && (
						<GameDetailsMoreGames
							games={gameDetails.moreGames.map((gameSlug) => hardcodedGameDetails[gameSlug])}
						/>
					)}

				</div>
			</section>
		</React.Fragment>
	);
};
