import React, {ReactNode} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {ExPopulusUniversalNavigationItemFooter} from "../headerManager/universalNavigationLookup";
import ExPopulusFooterLabel from "./ExPopulusFooterLabel";

interface IProps {
    title: string;
    links?: Array<ExPopulusUniversalNavigationItemFooter>;
    children?: ReactNode;
}

const ExPopulusFooterNavColumn: React.FC<IProps> = (props) => {

    /**
     * Renders each link item; includes handling with the "nested" parameter to know if this is a "sub" navigation item,
     * in which an additional className is applied to add indentation. Gets called recursively if there are more sub links.
     *
     * @param _link
     * @param nested
     * @param index
     */
    function createLinkItems(_link: ExPopulusUniversalNavigationItemFooter, index: number, nested: boolean = false): ReactNode {
        const hasLink: boolean = _link.href && _link.href?.length > 0;
        const key = `footer_content_categories_column_link-spacer_clickable_${index}`;

        const linkContent = (
            <React.Fragment>
                {_link.title}

                {_link.extraLabel && (
                    <div className={classNames("ex-populus-header-label ex-populus-footer_content_categories_column_link-spacer_extra-label")}>
                        {_link.extraLabel}
                    </div>
                )}
            </React.Fragment>
        );

        const generatedClassNames = classNames("ex-populus-footer_content_categories_column_link-spacer", {
            "ex-populus-footer_content_categories_column_link-spacer_nested": nested,
        });

        if (_link.local) {
            return (
                <div
                    key={key}
                    className={generatedClassNames}
                >
                    <Link
                        to={_link.to}
                        className={classNames({
                            "ex-populus-footer_content_categories_column_link-spacer_clickable": hasLink
                        })}
                    >
                        {linkContent}
                    </Link>

                    {_link.subLinks && Array.isArray(_link.subLinks) && _link.subLinks.length > 0 && _link.subLinks.map((link, i) => createLinkItems(link, i, true))}
                </div>
            );
        } else {
            return (
                <div
                    key={key}
                    className={generatedClassNames}
                >
                    <a
                        href={hasLink ? _link.href : undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames({
                            "ex-populus-footer_content_categories_column_link-spacer_clickable": hasLink
                        })}
                    >
                        {linkContent}
                    </a>

                    {_link.subLinks && Array.isArray(_link.subLinks) && _link.subLinks.length > 0 && _link.subLinks.map((link, i) => createLinkItems(link, i, true))}
                </div>
            );
        }
    }

    return (
        <div className={"ex-populus-footer_content_categories_column"}>
            <ExPopulusFooterLabel className="ex-populus-footer_content_categories_column_label">
                {props.title}
            </ExPopulusFooterLabel>

            {props.links && Array.isArray(props.links) && props.links.length > 0 && props.links.map((link, index) => createLinkItems(link, index))}

            {props.children}
        </div>
    );
};

export default ExPopulusFooterNavColumn;
