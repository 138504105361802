import React, {ChangeEvent, useEffect, useMemo, useState,} from "react";
import classNames from "classnames";
import {connect} from "react-redux";
import {RouteProps} from "react-router";
import {Container, Input} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import {ApiError, VerificationsApi} from "client/cs";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";
import {decrementLoading, incrementLoading, toggleShowSuccessfulPasswordResetModal} from "../redux/meta/MetaActions";
import getCsConfig from "../utils/getCsConfig";
import XpLogoLink from "../components/XpLogoLink";
import Tooltip, {DisplayBehavior} from "../components/tooltips/TooltipContainer";
import formStateManager, {defaultFormField, IFormState} from "../utils/formState";
import TooltipErrorList from "../components/tooltips/TooltipErrorList";
import TooltipErrorMsg from "../components/tooltips/TooltipErrorMessage";
import InputPassword from "../components/inputs/InputPassword";
import FormFieldError from "../components/FormFieldError";

interface IProps extends RouteProps {
	dispatch?: any;
}

const defaultFormState: IFormState = {
	verificationCode: {
		...defaultFormField,
		errors: [{ isError: true, errorText: "" }],
		validate: (value) => ([{ isError: (value?.length === 0), errorText: "Reset code cannot be blank" }]),
	},
	newPassword: {
		...defaultFormField,
		displayBehavior: DisplayBehavior.ONFOCUS,
		errors: [{ isError: true, errorText: "" }],
		validate: (value) => ([
			{ isError: value?.length < 8, errorText: "Be at least 8 characters long" },
			{ isError: !(/[A-Z]+/g.test(value)), errorText: "Contain at least 1 uppercase letter" },
			{ isError: !(/[0-9]+/g.test(value)), errorText: "Contain at least 1 number" },
		]),
	},
	confirmPassword: {
		...defaultFormField,
		errors: [{ isError: true, errorText: "" }],
		validate: (value, currentState) => ([{ isError: !value || value !== currentState.newPassword.value, errorText: "Passwords do not match" }]),
	},
};

const defaultFormErrors: {[key: "unknown" | keyof IFormState]: { show: boolean, message?: string }} = {
	unknown: { show: false, message: "There was a problem. Please try again." },
	verificationCode: { show: false },
	newPassword: { show: false, message: "The passwords did not match!" },
}

const ResetPasswordPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const passwordResetRequestId: string = query.get("request");
	const [formOk, setFormOk] = useState(false);
	const [formState, setFormState] = useState<IFormState>(defaultFormState);
	const [formErrors, setFormErrors] = useState(defaultFormErrors);

	const stateManager = useMemo(() => formStateManager(defaultFormState), []);

	/**
	 * sets formOk true when no form fields are in error state
	 */
	 useEffect(() => {
		setFormOk(Object
			.keys(formState)
			.reduce((isOk, field) => isOk
				? !formState[field]?.errors?.some(({ isError }) => isError)
				: false, true));
	}, [formState]);

	/**
	 * Updates the local form state based on the output of the form state manager
	 * to accomodate error and tooltip states
	 * @param field the field to handle
	 * @returns function to handle the form state updates
	 */
	const onEvent = (field: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => setFormState(stateManager.setValue(field, e.target));

	async function handleFormError(formErrorResponse?: {json: () => Promise<ApiError>}) {
		const showError = (key) => setFormErrors({
			...formErrors,
			[key]: {
				...formErrors[key],
				show: true,
			},
		});

		try {
			const { errors } = await (formErrorResponse as {json: () => Promise<ApiError>}).json();
			if (!errors.length) {
				throw new Error("No errors messages found");
			}

			if (errors.some(({ message }) => message.includes("passwordResetRequestId"))) {
				showError("verificationCode");
			} else if (errors.some(({ message }) => message.includes("password fields did not match"))) {
				showError("newPassword");
			} else {
				throw new Error("Unknown error");
			}
		} catch (error) {
			showError("unknown");
		}
	}

	/**
	 * Used to reset form/form fields values
	 * @param fields Array of IFormState keys as strings whose values will be reset
	 */
	function resetForm(fields?: Array<keyof IFormState>) {
		if (!(fields?.length > 0)) {
			// reset entire form back to initial/default state
			return setFormState(stateManager.reset());
		}

		// reset specified field values
		fields.forEach((field) => stateManager.setValue(field, ""));
		return setFormState(stateManager.getState());
	}

	/**
	 * Submit the api to reset the user's password,
	 * toggling on the modal that tells them it's been successful,
	 * and sending them to the login page.
	 *
	 * @param e
	 */
	async function submitResetPasswordRequest(e?): Promise<void> {
		e?.preventDefault();
		if (!formOk) {
			return;
		}

		props.dispatch(incrementLoading());

		try {
			await new VerificationsApi(getCsConfig()).verifyPasswordReset({
				resetPasswordBody: {
					passwordResetRequestId: passwordResetRequestId || undefined,
					verificationCode: formState?.verificationCode.value as string || undefined,
					newPassword: formState?.newPassword.value as string || undefined,
					confirmPassword: formState?.confirmPassword.value as string || undefined,
				},
			});

			props.dispatch(toggleShowSuccessfulPasswordResetModal(true));
			history.push("/log-in");
		} catch (e) {
			resetForm(["newPassword", "confirmPassword"]);

			await handleFormError(e);
		}

		props.dispatch(decrementLoading());
	}

	return (
		<Container className="reset-password">
			<div className="reset-password_content">
				<XpLogoLink className={"logo"} />

				<div className="reset-password_content_form-container">
					<div className="reset-password_content_form-container_header">
						<div className="title">
							Reset password
						</div>

						<p className="subtitle">
							Enter your code you received via email
						</p>
					</div>

					<form onSubmit={submitResetPasswordRequest}>
						<FormFieldError { ...formErrors.unknown }></FormFieldError>

						<Tooltip
							position="top"
							trigger={() => formState.verificationCode.state.showTooltip}
							tooltip={<TooltipErrorMsg errors={formState.verificationCode.errors} position={"top"} />}
						>
							<Input
								name={"reset-code"}
								placeholder="Reset code"
								value={formState?.verificationCode.value as string}
								onChange={onEvent("verificationCode")}
								onBlur={onEvent("verificationCode")}
								onFocus={onEvent("verificationCode")}
								autoComplete={"new-password"}
							/>
						</Tooltip>
						<FormFieldError { ...formErrors.verificationCode }>
							<div>Invalid Reset code. Try again or <Link to={"/auth/forgot-password"}>restart the process</Link></div>
						</FormFieldError>

						<label className="reset-password_content_form-container_header-label">
							<p className="subtitle">
								Enter your new desired password
							</p>
						</label>

						<Tooltip
							position="top"
							trigger={() => formState.newPassword.state.showTooltip}
							tooltip={<TooltipErrorList title={"Your password must:"} errors={formState.newPassword.errors} position={"top"} />}
						>
							<InputPassword
								name="password"
								placeholder="Password"
								value={formState?.newPassword.value as string}
								onChange={onEvent("newPassword")}
								onBlur={onEvent("newPassword")}
								onFocus={onEvent("newPassword")}
								autoComplete={"off"}
								className={classNames({"invalid": formState.newPassword.state.showErrors && !formState.newPassword.state.isValid})}
							/>
						</Tooltip>
						<FormFieldError { ...formErrors.newPassword }></FormFieldError>

						<Tooltip
							position="bottom"
							trigger={() => formState.confirmPassword.state.showTooltip}
							offsets={{ bottom: -6 }}
							tooltip={<TooltipErrorMsg errors={formState.confirmPassword.errors} position={"bottom"} />}
						>
							<InputPassword
								name="confirmPassword"
								placeholder="Confirm password"
								value={formState?.confirmPassword.value as string}
								onChange={onEvent("confirmPassword")}
								onBlur={onEvent("confirmPassword")}
								onFocus={onEvent("confirmPassword")}
								autoComplete={"off"}
								className={classNames({"invalid": formState.confirmPassword.state.showErrors && !formState.confirmPassword.state.isValid})}
							/>
						</Tooltip>

						<ExPopulusButton
							<React.ButtonHTMLAttributes<HTMLButtonElement>>
							color="pink"
							forwardProps={{
								type: "submit",
								disabled: !formOk,
							}}
							className="reset-password_content_form-container_submit-button"
							onClick={submitResetPasswordRequest}
						>
							RESET PASSWORD
						</ExPopulusButton>

						<div className="reset-password_content_extras">
							<p className="reset-password_content_extras_signup-message">
								Remember your password?
								<br/>
								<Link to="/log-in">Sign in here</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
};

export default connect()(ResetPasswordPage);
