import {EVMChain, Listing} from "client/marketplace";

export function generateOpenSeaLink(listing: Listing): string {
	switch (listing?.chain) {
		case EVMChain.ETHEREUM_MAINNET:
		case EVMChain.POLYGON:
			return `https://opensea.io/collection/${listing.collection.project.name.toLowerCase()}?search[query]=${listing.name.replace(" ", "%20")}&search[sortAscending]=true&search[sortBy]=PRICE`;
		case EVMChain.RINKEBY:
			break;
		case EVMChain.ROPSTEN:
			break;
		case EVMChain.POLYGON_MUMBAI:
			break;
		case EVMChain.BINANCE_MAINNET:
			break;
		case EVMChain.BINANCE_TESTNET:
			break;
	}

	return "/";
}
