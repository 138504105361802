import { Asset } from "client/marketplace";
import { HardCodedGameDetails } from ".";

const gameDetailsLamoverse: HardCodedGameDetails = {
	id: "lamo-0",
	to: "/games/lamoverse",
	title: "LAMOverse",
	playFree: false,
	socialIcons: false,
	desc: "Chaos is the name of the game. In LAMO, everything, including the world itself, is destructible. Outlast your opponents by raining destruction from your personalized hovercar.",
	keyArtPath: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661464976/website/images/lamoThumbnail_vwhltr",
	logo: `${process.env.PUBLIC_URL}/images/games/lamo/lamo.png`,
	carouselAssets: [
		{
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/lamoverse/lamo-1.mp4",
			contentType: "video",
			cloudinary: {
				publicId: "website/videos/costly_blue_catshark",
				actions: {
					desktop: ["f_auto", "q_80:qmax_80"],
					mobile: ["f_auto", "q_80:qmax_50"]
				},
			},
		} as unknown as Asset,
		{
			url: "https://storage.googleapis.com/ex-populus-marketplace_static/lamoverse/lamo-2-cropped.png",
			contentType: "image",
			cloudinary: {
				publicId: "website/images/lamo-2_bg6gwl",
				actions: {
					desktop: ["f_auto", "q_auto"],
					mobile: ["f_auto", "q_auto"]
				},
			},
		} as unknown as Asset,
	],
	details: {
		description: "Chaos is the name of the game. In LAMO, everything, including the world itself, is destructible. Outlast your opponents by raining destruction from your personalized hovercar.",
		details: [
			{
				label: "DEVELOPER",
				value: {display: "LAMO"},
			},
			{
				label: "GENRE",
				value: {display: "Action / Shooter"},
			},
			{
				label: "STATUS",
				value: {display: "In Development"},
			},
			{
				label: "RELEASES",
				value: {display: "To Be Announced"},
			},
			{
				label: "PLATFORM",
				value: {display: "Windows"},
			},
		],
	},
	tags: [
		{ status: "In Development" },
		{ text: "Action/Shooter" },
	],
	moreGames: [
		"final-form",
		"carnivale",
	],
};

export default gameDetailsLamoverse;
