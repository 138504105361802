import React from "react";

export const ArbitrumComingSoon: React.FC = () => {
  return (
    <section className="arbitrum-coming-soon">
      <div className="arbitrum-coming-soon_container">
        <div className="arbitrum-coming-soon_container_label">
          <label>
            <strong>COMING SOON</strong>
          </label>
        </div>
        <div className="arbitrum-coming-soon_container_arbitrum">
          <img
            src={
              "https://res.cloudinary.com/ex-populus/image/upload/v1686786951/website/images/homepage/delta_pulse_navbar_tsi3nv.svg"
            }
            alt="Xai"
          />
        </div>
        <div className="arbitrum-coming-soon_container_content">
          <h4>Coming soon to Xai</h4>
          <span>
            A bridge will be made available soon that will allow you to bridge
            your assets from Solana to Arbitrum.
          </span>
        </div>
      </div>
    </section>
  );
};
