"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddCloudinaryInfoBodyToJSON = exports.AddCloudinaryInfoBodyFromJSONTyped = exports.AddCloudinaryInfoBodyFromJSON = exports.instanceOfAddCloudinaryInfoBody = void 0;
var CloudinaryInfo_1 = require("./CloudinaryInfo");
/**
 * Check if a given object implements the AddCloudinaryInfoBody interface.
 */
function instanceOfAddCloudinaryInfoBody(value) {
    var isInstance = true;
    isInstance = isInstance && "assetID" in value;
    isInstance = isInstance && "cloudinary" in value;
    return isInstance;
}
exports.instanceOfAddCloudinaryInfoBody = instanceOfAddCloudinaryInfoBody;
function AddCloudinaryInfoBodyFromJSON(json) {
    return AddCloudinaryInfoBodyFromJSONTyped(json, false);
}
exports.AddCloudinaryInfoBodyFromJSON = AddCloudinaryInfoBodyFromJSON;
function AddCloudinaryInfoBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'assetID': json['assetID'],
        'cloudinary': (0, CloudinaryInfo_1.CloudinaryInfoFromJSON)(json['cloudinary']),
    };
}
exports.AddCloudinaryInfoBodyFromJSONTyped = AddCloudinaryInfoBodyFromJSONTyped;
function AddCloudinaryInfoBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'assetID': value.assetID,
        'cloudinary': (0, CloudinaryInfo_1.CloudinaryInfoToJSON)(value.cloudinary),
    };
}
exports.AddCloudinaryInfoBodyToJSON = AddCloudinaryInfoBodyToJSON;
