import React, {useState} from "react";
import {Input} from "reactstrap";
import {InputProps} from "reactstrap/lib/Input";
import classNames from "classnames";
import {ReactComponent as VisibilityOn} from "../../svgs/visibility_on.svg";
import {ReactComponent as VisibilityOff} from "../../svgs/visibility_off.svg";

interface IProps extends InputProps {
	name?: string,
	className?: string;
}

const InputPassword: React.FC<IProps> = (props) => {

	const [passwordVisible, setPasswordVisible] = useState(false);

	/**
	 * changes the rendered image from visible on/off and changes input type to text/password
	 *
	 */
	function togglePasswordVisibility(): void {
		setPasswordVisible(!passwordVisible);
	}

	return (
		<div className="input-password">
			<Input
				type={passwordVisible ? "text" : "password"}
				className={classNames("input-password_input", props.className)}
				{...props}
			/>
			<span
				className={classNames("input-password_icon", {
					"input-password_icon_ios-styling": navigator.userAgent.indexOf("Safari") != -1,
				})}
				onClick={togglePasswordVisibility}
			>
				{!passwordVisible ? (
					<VisibilityOn/>
				) : (
					<VisibilityOff/>
				)}
			</span>
		</div>
	);
}

InputPassword.defaultProps = {
	name: "password",
	className: "",
};

export default InputPassword;
