import React from "react";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

export const HomepageHero: React.FC = () => {
	return (
		<section className="homepage-hero">
			<div className="homepage-hero_gradient"/>
			<div className="homepage-hero_video">
				<MediaAssetViewer
					asset={new URL("https://res.cloudinary.com/ex-populus/video/upload/f_auto/q_100/v1682966805/website/images/homepage/hero-video_t2kqn2.mp4")}
					contentType={"video"}
					videoAutoPlay={true}
					videoControls={false}
					videoMuted={true}
					videoLoop={true}
					elementWidth={{
						desktopWidth: 1920,
						mobileWidth: 800,
					}}
				/>
			</div>

			<div className="homepage-hero_container">
				<div className="homepage-hero_container_content">
					<h2 className="homepage-hero_container_content_title">Ex Populus Makes The Future Fun</h2>
					<span className="homepage-hero_container_content_subtitle">Ex Populus is bringing the next wave of indie hits to the next generation of hardcore gamers.</span>
					<ExPopulusButton
						color="dark-on-white"
						className="homepage-hero_container_content_button"
						to="/games/final-form"
					>
						Play Final Form
					</ExPopulusButton>
				</div>
			</div>
		</section>
	);
};
