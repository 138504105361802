"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllFeatureFlagsResponseToJSON = exports.GetAllFeatureFlagsResponseFromJSONTyped = exports.GetAllFeatureFlagsResponseFromJSON = exports.instanceOfGetAllFeatureFlagsResponse = void 0;
var FeatureFlag_1 = require("./FeatureFlag");
var PaginationInfo_1 = require("./PaginationInfo");
/**
 * Check if a given object implements the GetAllFeatureFlagsResponse interface.
 */
function instanceOfGetAllFeatureFlagsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "featureFlags" in value;
    return isInstance;
}
exports.instanceOfGetAllFeatureFlagsResponse = instanceOfGetAllFeatureFlagsResponse;
function GetAllFeatureFlagsResponseFromJSON(json) {
    return GetAllFeatureFlagsResponseFromJSONTyped(json, false);
}
exports.GetAllFeatureFlagsResponseFromJSON = GetAllFeatureFlagsResponseFromJSON;
function GetAllFeatureFlagsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoFromJSON)(json['paginationInfo']),
        'featureFlags': (json['featureFlags'].map(FeatureFlag_1.FeatureFlagFromJSON)),
    };
}
exports.GetAllFeatureFlagsResponseFromJSONTyped = GetAllFeatureFlagsResponseFromJSONTyped;
function GetAllFeatureFlagsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoToJSON)(value.paginationInfo),
        'featureFlags': (value.featureFlags.map(FeatureFlag_1.FeatureFlagToJSON)),
    };
}
exports.GetAllFeatureFlagsResponseToJSON = GetAllFeatureFlagsResponseToJSON;
