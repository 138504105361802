"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftInfoToJSON = exports.NftInfoFromJSONTyped = exports.NftInfoFromJSON = exports.instanceOfNftInfo = void 0;
var NftAttribute_1 = require("./NftAttribute");
var NftCollection_1 = require("./NftCollection");
var NftProperties_1 = require("./NftProperties");
/**
 * Check if a given object implements the NftInfo interface.
 */
function instanceOfNftInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "symbol" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "seller_fee_basis_points" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "properties" in value;
    isInstance = isInstance && "terms" in value;
    return isInstance;
}
exports.instanceOfNftInfo = instanceOfNftInfo;
function NftInfoFromJSON(json) {
    return NftInfoFromJSONTyped(json, false);
}
exports.NftInfoFromJSON = NftInfoFromJSON;
function NftInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'symbol': json['symbol'],
        'description': json['description'],
        'seller_fee_basis_points': json['seller_fee_basis_points'],
        'image': json['image'],
        'attributes': (json['attributes'].map(NftAttribute_1.NftAttributeFromJSON)),
        'collection': (0, NftCollection_1.NftCollectionFromJSON)(json['collection']),
        'properties': (0, NftProperties_1.NftPropertiesFromJSON)(json['properties']),
        'terms': json['terms'],
    };
}
exports.NftInfoFromJSONTyped = NftInfoFromJSONTyped;
function NftInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'symbol': value.symbol,
        'description': value.description,
        'seller_fee_basis_points': value.seller_fee_basis_points,
        'image': value.image,
        'attributes': (value.attributes.map(NftAttribute_1.NftAttributeToJSON)),
        'collection': (0, NftCollection_1.NftCollectionToJSON)(value.collection),
        'properties': (0, NftProperties_1.NftPropertiesToJSON)(value.properties),
        'terms': value.terms,
    };
}
exports.NftInfoToJSON = NftInfoToJSON;
