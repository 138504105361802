import React from "react";
import classNames from "classnames";
import { ReactComponent as SuccessCheckIcon } from "../svgs/success-check.svg";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

interface IModalProps {
	type: "success" | "failed";
	onOkClick?(): void;
}

const PasswordResetModal: React.FC<IModalProps> = (props) => {
	const classes = {
		"success": props.type === "success",
		"failed": props.type === "failed"
	};

	const success = {
		title: "Success!",
		icon: (<SuccessCheckIcon />),
		message: (<>
			<div>Your password has been reset successfully.</div>
			<div>You may use it to log in from now on.</div>
		</>),
	};

	// TODO: eventually we'll need an icon and real data
	const failed = {
		title: "Failed!",
		icon: (<SuccessCheckIcon />),
		message: (<>
			<div>Unable to reset your password.</div>
			<div>Please try again later.</div>
		</>),
	};

	const { title, icon, message } = props.type === "success" ? success : failed;

	return (
		<div className={"password-reset-modal"}>
			<div className={classNames("modal-icon", { ...classes })}>{icon}</div>
			<div className={"modal-title"}>{title}</div>
			<div className={"modal-message"}>{message}</div>

			<ExPopulusButton
				color={props.type === "success" ? "success" : "error"}
				onClick={props.onOkClick}
				className="modal-button"
			>
				OK
			</ExPopulusButton>
		</div>
	);
}

PasswordResetModal.defaultProps = {
	onOkClick: () => {},
};

export default PasswordResetModal;
