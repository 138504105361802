"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IGameCarouselAssetToJSON = exports.IGameCarouselAssetFromJSONTyped = exports.IGameCarouselAssetFromJSON = exports.instanceOfIGameCarouselAsset = void 0;
var runtime_1 = require("../runtime");
var Asset_1 = require("./Asset");
/**
 * Check if a given object implements the IGameCarouselAsset interface.
 */
function instanceOfIGameCarouselAsset(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfIGameCarouselAsset = instanceOfIGameCarouselAsset;
function IGameCarouselAssetFromJSON(json) {
    return IGameCarouselAssetFromJSONTyped(json, false);
}
exports.IGameCarouselAssetFromJSON = IGameCarouselAssetFromJSON;
function IGameCarouselAssetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'index': !(0, runtime_1.exists)(json, 'index') ? undefined : json['index'],
        'asset': !(0, runtime_1.exists)(json, 'asset') ? undefined : (0, Asset_1.AssetFromJSON)(json['asset']),
    };
}
exports.IGameCarouselAssetFromJSONTyped = IGameCarouselAssetFromJSONTyped;
function IGameCarouselAssetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'index': value.index,
        'asset': (0, Asset_1.AssetToJSON)(value.asset),
    };
}
exports.IGameCarouselAssetToJSON = IGameCarouselAssetToJSON;
