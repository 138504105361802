import React from "react";
import {Container} from "reactstrap";
import {User} from "client/cs";
import {GrCircleInformation} from "react-icons/all";
import {GAMESIGHT_EVENTS, SaleType} from "shared";
import {sendEvent} from "../../utils/event-tracking";
import navigateToURL from "../../utils/navigateToURL";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {TokenType} from "../../utils/TokenType";

export interface IGameContentProps {
	header: string;
	body: string;
	image: string;
	secondaryButton?: boolean;
	xpStoreBuyNowURL?: string;
	xpStoreURL: string;
	useSecondaryMarket?: boolean;
	secondaryMarketURL?: string;
	saleType: SaleType;
	tokenType?: TokenType;
}

interface IProps {
	id: string;
	gameContent?: Array<IGameContentProps>;
	currentUser?: User;
}

const GameDetailsInGameContent: React.FC<IProps> = (props) => {
	const navigateTo = (url: string) => {
		try {
			return navigateToURL(url);
		} catch (e) {
			// just log any errors to avoid breaking the page
			return () => console.error(e);
		}
	}

	const onClickHandler = (gameContentItem: IGameContentProps) => () => {
		// helper to distinguish which element is being rendered and attach analytics calls to button presses
		if (gameContentItem.tokenType === TokenType.DAG) {
			sendEvent({eventName: GAMESIGHT_EVENTS.FF_DAG_GET_CARDS, user: props.currentUser});
		}

		// primary buttons always send users to the XP store page
		if (!gameContentItem.secondaryButton) {
			return navigateTo(gameContentItem.xpStoreURL)();
		}

		// RARE/RARE_SOLD_OUT sales on secondary markets send
		// the user to the secondary market link
		if (gameContentItem.useSecondaryMarket && gameContentItem.saleType !== SaleType.COMMON) {
			return navigateTo(gameContentItem.secondaryMarketURL)();
		}

		// COMMON sales or sales without a secondary market send
		// users to the XP store buy now link
		return navigateTo(gameContentItem.xpStoreBuyNowURL)();
	}

	const getLearnMoreButton = (gameContentItem) => {
		if (!gameContentItem.secondaryButton) {
			return null;
		}

		return (
			<ExPopulusButton
				color="pink-on-gray"
				className="game-details-in-game-content_items_section_CTA_button-2"
				onClick={navigateTo(gameContentItem.xpStoreURL)}
			>
				Learn More
			</ExPopulusButton>
		);
	}

	return (
		<Container id={props.id} className="game-details-in-game-content">
			<div className="game-details-in-game-content_game-items">
				<div className="game-details-in-game-content_game-items_title">In-Game Items &amp; Content</div>
				<div className="game-details-in-game-content_game-items_count">
					{props.gameContent?.length || "0"} items
				</div>
			</div>

			<div className="game-details-in-game-content_banner">
				<div className="game-details-in-game-content_icon">
					<GrCircleInformation/>
				</div>

				<div className="game-details-in-game-content_text">
					<h3 className="game-details-in-game-content_text_header">
						Collect, trade, and complete card sets.
					</h3>
					<p className="game-details-in-game-content_text_body">
						Choose from the available Ex Populus Trading Card sets below to start building your deck!
					</p>
				</div>
			</div>

			{
				props.gameContent?.map((gameContentItem, index) => (
					<div key={`in-game-content-${index}`} className="game-details-in-game-content_items">
						<div className="game-details-in-game-content_items_image">
							<img
								src={gameContentItem.image}
								alt="In-Game Content"
							/>
						</div>

						<div className="game-details-in-game-content_items_text">
							<h3 className="game-details-in-game-content_items_text_header">
								{gameContentItem.header}
							</h3>
							<p className="game-details-in-game-content_items_section_label_mobile">Card Set</p>
							<p className="game-details-in-game-content_items_text_body">
								{gameContentItem.body}
							</p>

							<div className="game-details-in-game-content_items_section">
								<p className="game-details-in-game-content_items_section_label_desktop">Card Set</p>
								<div className="game-details-in-game-content_items_section_CTA">
									{getLearnMoreButton(gameContentItem)}

									<ExPopulusButton
										<React.ButtonHTMLAttributes<HTMLButtonElement>>
										color="pink"
										className="game-details-in-game-content_items_section_CTA_button"
										onClick={onClickHandler(gameContentItem)}
										forwardProps={{
											disabled: !gameContentItem.xpStoreBuyNowURL && !gameContentItem.secondaryMarketURL
										}}
									>
										{gameContentItem.secondaryButton ? "Get Cards" : "Learn More"}
									</ExPopulusButton>
								</div>
							</div>
						</div>
					</div>
				))
			}
		</Container>
	);
};

export default GameDetailsInGameContent;
