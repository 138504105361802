"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitSolanaPayTransactionBodyToJSON = exports.SubmitSolanaPayTransactionBodyFromJSONTyped = exports.SubmitSolanaPayTransactionBodyFromJSON = exports.instanceOfSubmitSolanaPayTransactionBody = void 0;
var runtime_1 = require("../runtime");
var SolanaPayAction_1 = require("./SolanaPayAction");
/**
 * Check if a given object implements the SubmitSolanaPayTransactionBody interface.
 */
function instanceOfSubmitSolanaPayTransactionBody(value) {
    var isInstance = true;
    isInstance = isInstance && "action" in value;
    return isInstance;
}
exports.instanceOfSubmitSolanaPayTransactionBody = instanceOfSubmitSolanaPayTransactionBody;
function SubmitSolanaPayTransactionBodyFromJSON(json) {
    return SubmitSolanaPayTransactionBodyFromJSONTyped(json, false);
}
exports.SubmitSolanaPayTransactionBodyFromJSON = SubmitSolanaPayTransactionBodyFromJSON;
function SubmitSolanaPayTransactionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'action': (0, SolanaPayAction_1.SolanaPayActionFromJSON)(json['action']),
        'account': !(0, runtime_1.exists)(json, 'account') ? undefined : json['account'],
        'mints': !(0, runtime_1.exists)(json, 'mints') ? undefined : json['mints'],
    };
}
exports.SubmitSolanaPayTransactionBodyFromJSONTyped = SubmitSolanaPayTransactionBodyFromJSONTyped;
function SubmitSolanaPayTransactionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action': (0, SolanaPayAction_1.SolanaPayActionToJSON)(value.action),
        'account': value.account,
        'mints': value.mints,
    };
}
exports.SubmitSolanaPayTransactionBodyToJSON = SubmitSolanaPayTransactionBodyToJSON;
