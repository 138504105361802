import React from "react";
import {connect} from "react-redux";
import {Modal} from "reactstrap";
import {IStore} from "../redux/defaultStore";
import {toggleShowSuccessfulPasswordResetModal} from "../redux/meta/MetaActions";
import PasswordResetModal from "./PasswordResetModal";

interface IProps {
	dispatch?: any;
	showSuccessfulPasswordResetModal?: boolean;
}

const PasswordResetSuccessModalManager: React.FC<IProps> = (props) => {

	function dismiss(): void {
		props.dispatch(toggleShowSuccessfulPasswordResetModal(false));
	}

	return (
		<Modal
			contentClassName={"dk-modal-content"}
			centered={true}
			fade={true}
			isOpen={props.showSuccessfulPasswordResetModal}
			toggle={dismiss}
		>
			<PasswordResetModal type={"success"} onOkClick={dismiss} />
		</Modal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		showSuccessfulPasswordResetModal: store.metaStore.showSuccessfulPasswordResetModal,
		...props,
	}
})(PasswordResetSuccessModalManager);
