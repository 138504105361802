"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradingCardHouseToJSON = exports.TradingCardHouseFromJSONTyped = exports.TradingCardHouseFromJSON = exports.TradingCardHouse = void 0;
/**
 *
 * @export
 */
exports.TradingCardHouse = {
    god: 'god',
    beast: 'beast',
    man: 'man',
    machine: 'machine'
};
function TradingCardHouseFromJSON(json) {
    return TradingCardHouseFromJSONTyped(json, false);
}
exports.TradingCardHouseFromJSON = TradingCardHouseFromJSON;
function TradingCardHouseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TradingCardHouseFromJSONTyped = TradingCardHouseFromJSONTyped;
function TradingCardHouseToJSON(value) {
    return value;
}
exports.TradingCardHouseToJSON = TradingCardHouseToJSON;
