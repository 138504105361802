import React from "react";

const GameDetailsFinalFormAboutSection: React.FC = (props) => (
  <div className={"game-details-about"}>

    <div className={"section-heading section-heading_no-top-margin"}>Final Form the Game</div>

    <div className={"heading"}>A Game for Collectors</div>
    <p className={"body-text"}>Final Form has been designed from the start as a card collector’s dream game.  The game focuses on the evolution of player cards which has been a central theme of the trading card genre for decades.  To replicate the real-world tendency of trading cards to be lost, damaged or destroyed, Final Form requires that players burn four cards of a lower tier to produce a single card of a higher tier.  Since all cards are NFTs with limited supplies, the burn-to-evolve requirement will result in the rarity of cards from a given card set increasing over time, thereby increasing that set’s desirability as a collector’s item.</p>

    <div className={"heading"}>Basic Game Rules</div>
    <p className={"body-text"}>The game will give players the opportunity to pledge and evolve trading cards. When players pledge their trading cards to The God’s Tooth they will receive a substance called Chromos.  Chromos is the substance required to evolve trading cards to a higher tier.  The amount of Chromos you receive will vary depending on the trading card you pledge and changes to the game over time by its developers.  To evolve your trading cards, you must burn one card from each house (Beast, Man, Machine, God) of the same tier along with the required amount of Chromos. Burning these resources will produce one trading card of the next higher tier.</p>

    <p className={"body-text"}>Burned digital collectibles and burned Chromos are gone forever and can’t be recreated or returned to you.  Evolving and burning digital collectibles is an irreversible, permanent action on a decentralized blockchain.</p>

    <div className={"heading"}>Incremental Game Elements</div>
    <p className={"body-text"}>In Final Form, the incremental experience centers around the production of Chromos, the fuel required for evolution. The first early access release of the game relies exclusively on Ex Populus trading cards (DAGs and Iron Pigeons soon after) to climb the increments of Chromos production that fuels your evolution. As you pledge your cards and evolve them, you climb the incremental tiers of the game until you eventually reach Legendary.</p>

    <p className={"body-text"}>Future expansions of the game are likely to expand on these incremental mechanics and bring an even more rich experience to players.</p>

    <div className={"heading"}>Gacha Mechanics</div>
    <p className={"body-text"}>Gacha is a known game mechanic that is leveraged in Final Form’s design. Gacha is a reference to many games that require players to complete sets of items to achieve a specific goal. In Final Form, the Gacha system is simple; players need to collect one card from each house, each of the same rarity tier in order to evolve into a card of the next tier up. When you evolve, your completed Gacha card set and Chromos evolutionary fuel are burned and gone forever.</p>

    <div className={"heading"}>Full Decentralization</div>
    <p className={"body-text"}>The game is fully decentralized. 100% of the game’s logic runs entirely on smart contracts deployed on the Solana Blockchain. We chose to do this because it provides access for other development teams to expand on the game without needing coordination or consent from Ex Populus. The cost of taking this fully decentralized approach is that some of the more complex features we discussed in our internal brainstorms will be too expensive or slow to include in the game. Ultimately we feel we can drive the most value to fans of Ex Populus trading cards by taking this approach and as the game experience becomes available — we think you will agree.</p>


    <div className={"section-heading"}>The Ex Populus Trading Card System (XPTCS)</div>

    <div className={"heading"}>About XPTCS</div>
    <p className={"body-text"}>There is an elegance in the design of the cards that is inspired by standard “decks” of playing cards. Standard playing cards have a suit and a number, which has been enough to inspire dozens of games for people to play with the cards. In the spirit of Web3, interoperability and decentralization, the Ex Populus Trading Card System (XPTCS) was designed as an open system that could be leveraged by 3rd party developers to make games with the cards.  This means eventually there may be games leveraging XPTCS  in addition to Final Form from other developers.</p>

    <div className={"heading"}>Card Metadata</div>
    <p className={"body-text"}>Each piece of information on the card is critical to understand. In the first part of this write-up, we will itemize and define each integer, symbol and label you see on the card above. Because the cards were designed as an open, decentralized system, not every piece of information is leveraged by every game, including Final Form.  Final Form may leverage more metadata in the future.  The early access release of Final Form focuses on the core functionality of evolution.</p>

    <p className={"body-text"}><span className={"strong"}>Abilities:</span> Each card comes equipped with 1,2 or 3 of over 30 special abilities. In the meta-data, the names of these abilities will be listed. The abilities are intended to be used in games to attack enemies or defend allies.</p>

    <p className={"body-text"}><span className={"strong"}>Health Traits:</span> Each card has one of five adjectives to describe the health ranking of the card.  These adjectives can be used in conjunction with the power rating to create health points/rating or similar.</p>

    <p className={"body-text"}><span className={"strong"}>Attack Traits:</span> Each card has one of five adjectives to describe the attack ranking of the card.  These adjectives can be used in conjunction with the power rating to create an attack points/rating or similar.</p>

    <p className={"body-text"}><span className={"strong"}>Power Rating:</span> The card’s power rating. When cards are used in games, attributes like health points and attack points can be derived from the card’s power, so bigger is better.</p>

    <p className={"body-text"}><span className={"strong"}>House:</span> Each XP trading card belongs to one of 4 houses. You can think of these almost like suits in a standard deck of cards. The houses are: Man, Machines, Beasts and Gods.</p>

    <p className={"body-text"}><span className={"strong"}>Card Number:</span> Each card set has numbered cards beginning at 0 and counting up as new cards are minted. That means the counting is mixed between the tiers: common, rare, epic and legendary. The quantity will never exceed the supply caps for a given set or edition (more below).</p>

    <p className={"body-text"}><span className={"strong"}>Set Name:</span> The name of the card set. Each universe, like Iron Pigeons, will have different names. It’s important to know that each set is completely compatible with the others.</p>

    <p className={"body-text"}><span className={"strong"}>Set Number:</span> DAGs is a card set and the first in the Ex Populus Trading Card System, so it will always be referred to as Set 1. Iron Pigeons will be Set 2. Subsequent sets will continue to count up.</p>

    <p className={"body-text"}><span className={"strong"}>Edition Number:</span>  Because games will heavily encourage burning of cards to evolve, the supply of Ex Populus trading cards is likely to decrease rapidly. Eventually, more cards in a given set may be minted to replenish the supply. These new mints will be 2nd, 3rd and 4th editions, continually counting up. Eventually, there will be very few 1st Editions left, so if you are a collector, keep your eyes peeled for those early editions.</p>

    <p className={"body-text"}><span className={"strong"}>Rarity Tier:</span> To start, there will be 4 rarity tiers: Common, Rare, Epic and Legendary.</p>

    <div className={"heading"}>Supply Caps</div>
    <p className={"body-text"}>Each Set of trading cards has an “active supply cap”.  This means that the active supply of a given card set can never exceed the amounts below.  However, because cards are burned and subsequent mints of second or third editions are possible, it's important to understand that the supply caps exclude the previously burned cards and only count the current “active” cards in circulation.</p>

    <div className={"heading"}>Active Supply Caps Per Set</div>
    <ul>
      <li>Common: 100,000</li>
      <li>Rare: 20,000</li>
      <li>Epic: 4,000</li>
      <li>Legendary: 800</li>
      <li>Active Set Total: 124,800</li>
    </ul>

    <p className={"body-text"}>There are only 800 Legendaries per Set / Edition. As cards in a given set are burned, it's possible that the remaining supply of cards in each set / edition may be equal to or below these supply caps</p>

    <div className={"heading"}>Flexibility & Scalability:</div>
    <p className={"body-text"}>The Ex Populus Trading Card System allows for scalability. Based on demand and engagement, Ex Populus can determine if new cards should be minted to replenish supply after large portions have been burned ( though never exceeding the supply cap) or if new sets should be made to create new supply beyond the caps within each set. This allows for scalability based on demand with levers in place to maintain an equilibrium.</p>

    <div className={"section-heading"}>The World of Final Form</div>

    <div className={"heading"}>A World for the Metaverse</div>
    <p className={"body-text"}>In Final Form, players are immersed in a world where mankind, beasts, gods and sentient machines arrive via portals from various dimensions.  Each come from universes with varying realities, laws of physics and visual appearances.  Because of this, some of the characters that you see in this world may look like they come from a different reality.  The idea of a multiverse with characters from different dimensions isn’t new and has become a recurring theme in some of today’s most popular games and movies.  In Final Form, each trading card set represents a different reality that has been brought to the world of The Gods’s Tooth. Here, each card and character can evolve and reach their Final Form with the help of players and collectors like you.</p>

    <div className={"heading"}>A Story for the Fandom</div>
    <p className={"body-text"}>You can read and stay updated on the expanding story, world and lore of Final Form by following along on Final Form’s Twitter and Instagram.  Read through a strange and rich history surrounding The God’s Tooth, where it came from and why it exists.  The more you learn about the world, its lore and history, the more entertainment you will receive from playing the game.</p>

  </div>
)

export default GameDetailsFinalFormAboutSection;
