import React, {HTMLAttributes} from "react";
import {ReactComponent as ArrowIcon} from "./../../../svgs/arrow-right.svg";

export interface IArticle {
	imageUrl: string;
	source: string;
	date: string;
	description: string;
	link: string;
}

interface IProps extends HTMLAttributes<HTMLElement> {
	article: IArticle;
	className?: string;
}

const PressArticle: React.FC<IProps> = (props) => {
	
	const {article} = props;

	return (
		<a
			href={article.link}
			target="_blank"
			rel="noopener noreferrer"
			className={"press-article"}
		>
			<img src={article.imageUrl}/>

			<div className={"info"}>
				<div className={"source"}>
					<span className={"name"}>{article.source}</span>
					<span>{"•"}</span>
					<span>{article.date}</span>
				</div>
				<div className={"desc"}>{article.description}</div>
			</div>

			<div className={"read-more"}>
				<p>Read More</p>
				<ArrowIcon/>
			</div>
		</a>
	);
}

export default PressArticle;

