import React from "react";
import classNames from "classnames";
import EthIconPurple from "./EthIconPurple";

interface IProps {
	price?: string | number;
	icon?: React.ReactNode;
	className?: string;
}

const EthIconPriceLabel: React.FC<IProps> = ({ price, icon, className }) => {
	return (
		<div className={"eth-icon-price-label"}>
			{icon} <span className={classNames("price", className)}>{price}</span>
		</div>
	);
}

EthIconPriceLabel.defaultProps = {
	price: "-",
	icon: (<EthIconPurple />),
	className: "",
}

export default EthIconPriceLabel;
