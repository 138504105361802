"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAllOfToJSON = exports.UserAllOfFromJSONTyped = exports.UserAllOfFromJSON = exports.instanceOfUserAllOf = void 0;
var runtime_1 = require("../runtime");
var UserType_1 = require("./UserType");
/**
 * Check if a given object implements the UserAllOf interface.
 */
function instanceOfUserAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
exports.instanceOfUserAllOf = instanceOfUserAllOf;
function UserAllOfFromJSON(json) {
    return UserAllOfFromJSONTyped(json, false);
}
exports.UserAllOfFromJSON = UserAllOfFromJSON;
function UserAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, UserType_1.UserTypeFromJSON)(json['type']),
        'disabled': json['disabled'],
        'email': json['email'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
exports.UserAllOfFromJSONTyped = UserAllOfFromJSONTyped;
function UserAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, UserType_1.UserTypeToJSON)(value.type),
        'disabled': value.disabled,
        'email': value.email,
        'name': value.name,
    };
}
exports.UserAllOfToJSON = UserAllOfToJSON;
