"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormErrorToJSON = exports.FormErrorFromJSONTyped = exports.FormErrorFromJSON = exports.instanceOfFormError = void 0;
var runtime_1 = require("../runtime");
var ApiError_1 = require("./ApiError");
var AppError_1 = require("./AppError");
var ErrorLevel_1 = require("./ErrorLevel");
var ErrorType_1 = require("./ErrorType");
/**
 * Check if a given object implements the FormError interface.
 */
function instanceOfFormError(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
exports.instanceOfFormError = instanceOfFormError;
function FormErrorFromJSON(json) {
    return FormErrorFromJSONTyped(json, false);
}
exports.FormErrorFromJSON = FormErrorFromJSON;
function FormErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ErrorType_1.ErrorTypeFromJSON)(json['type']),
        'level': !(0, runtime_1.exists)(json, 'level') ? undefined : (0, ErrorLevel_1.ErrorLevelFromJSON)(json['level']),
        'message': json['message'],
        'date': !(0, runtime_1.exists)(json, 'date') ? undefined : json['date'],
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : (json['errors'].map(AppError_1.AppErrorFromJSON)),
        'httpStatus': !(0, runtime_1.exists)(json, 'httpStatus') ? undefined : json['httpStatus'],
        'httpMethod': !(0, runtime_1.exists)(json, 'httpMethod') ? undefined : json['httpMethod'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'formFieldErrors': !(0, runtime_1.exists)(json, 'formFieldErrors') ? undefined : ((0, runtime_1.mapValues)(json['formFieldErrors'], ApiError_1.ApiErrorFromJSON)),
    };
}
exports.FormErrorFromJSONTyped = FormErrorFromJSONTyped;
function FormErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ErrorType_1.ErrorTypeToJSON)(value.type),
        'level': (0, ErrorLevel_1.ErrorLevelToJSON)(value.level),
        'message': value.message,
        'date': value.date,
        'errors': value.errors === undefined ? undefined : (value.errors.map(AppError_1.AppErrorToJSON)),
        'httpStatus': value.httpStatus,
        'httpMethod': value.httpMethod,
        'path': value.path,
        'formFieldErrors': value.formFieldErrors === undefined ? undefined : ((0, runtime_1.mapValues)(value.formFieldErrors, ApiError_1.ApiErrorToJSON)),
    };
}
exports.FormErrorToJSON = FormErrorToJSON;
