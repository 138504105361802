import React, {FC, ReactNode} from "react";
import classNames from "classnames";
import {MdKeyboardArrowDown} from "react-icons/all";
import {omit} from "lodash";
import {Link, useLocation} from "react-router-dom";
import {exPopulusUnifiedLinkDispatcher, IExPopulusUnifiedLinkDispatcher} from "./exPopulusUnifiedLinkDispatcher";
import ExPopulusHeaderLabel from "./ExPopulusHeaderLabel";


const APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || "https://expopulus.com";

export interface IExPopulusHeaderNavItemProps {
	implementingSiteURL?: string;
	children: string;
	icon?: FC; // Add icons to nav items with no sub menus, or use to override the default down chevron for dropdowns.
	to?: string; // adjusted link for us to implement react-router
	raw?: string; // unmodified link as passed from props way up
	onClick?: () => void; // Gets injected by the sidebar manager on items that appear in the sidebar, to help close it.
	targetBlank?: boolean; // Control if the link should open in a new tab or current tab. Ignored if "to" is set.
	extraLabel?: string;
	subMenu?: Array<IExPopulusHeaderNavItemProps>; // Adding a sub menu will automatically give a down chevron icon.
	className?: string;
}

const ExPopulusHeaderNavItem: React.FC<IExPopulusHeaderNavItemProps> = (props) => {

	const location = useLocation();

	// Determine if this nav item has a sub menu by checking the subMenu prop.
	const hasSubItems: boolean = props.subMenu && Array.isArray(props.subMenu) && props.subMenu.length > 0;

	/**
	 * Renderer for each sub navigation item.
	 *
	 * @param subItem
	 * @param i
	 */

	// todo: need to resolve validateDOMNesting: <a> cannot appear as a descendant of <a>.
	function createDropDownItems(subItem: IExPopulusHeaderNavItemProps, i: number): ReactNode {
		return (
			<ExPopulusHeaderNavItem
				key={`child-nav-item_${i}`}
				{...omit(subItem, "children")}
				implementingSiteURL={props.implementingSiteURL}
				className={"ex-populus-header-nav-item-positioner_drop-down_item"}
			>
				{subItem.children}
			</ExPopulusHeaderNavItem>
		);
	}

	/**
	 * Helper to render the icon.
	 *
	 * @param _icon
	 */
	function getIcon(_icon: FC): ReactNode {
		const Icon = _icon;

		return (
			<Icon/>
		);
	}

	const linkDeterminer: IExPopulusUnifiedLinkDispatcher = exPopulusUnifiedLinkDispatcher(props.implementingSiteURL, props.raw);

	// This needs to be cleaned up and clarified. I assume we are trying to overload this component
	// to be able to use it in more places but I'm unsure what all these conditions are supposed to represent
	// Way better to just make multiple focused components
	const isCurrentRoute: boolean =
		props.implementingSiteURL + location.pathname === props.raw ||

		// adding this line to support showing the selected state on subnav items
		location.pathname.replace("/", "").replace("#", "") === props.to?.replace("/", "").replace("#", "") ||

		props.implementingSiteURL === props.raw ||
		(props.implementingSiteURL === APP_WEBSITE_URL && props.to === "/account-details" && location.pathname === "/account-details");


	const linkContent = (
		<React.Fragment>
			{props.extraLabel && (
				<ExPopulusHeaderLabel>
					{props.extraLabel}
				</ExPopulusHeaderLabel>
			)}

			<div>
				{props.children}
				{props.icon ? getIcon(props.icon) : hasSubItems && getIcon(MdKeyboardArrowDown)}
			</div>

			{hasSubItems && (
				<div className={"ex-populus-header-nav-item-positioner_drop-down"}>
					{props.subMenu.map(createDropDownItems)}
				</div>
			)}
		</React.Fragment>
	);

	if (linkDeterminer.to) {
		return (
			<div
				className={classNames("ex-populus-header-nav-item-positioner", props.className, {
					"ex-populus-header-nav-item-positioner_selected": isCurrentRoute,
				})}
			>
				<Link
					to={linkDeterminer.to}
					className={classNames("ex-populus-header-nav-item")}
				>
					{linkContent}
				</Link>
			</div>
		);
	}

	return (
		<div
			className={classNames("ex-populus-header-nav-item-positioner", props.className, {
				"ex-populus-header-nav-item-positioner_selected": isCurrentRoute,
			})}
		>
			<a
				href={linkDeterminer.href}
				rel="noopener noreferrer"
				className={classNames("ex-populus-header-nav-item")}
			>
				{linkContent}
			</a>
		</div>
	);
};

ExPopulusHeaderNavItem.defaultProps = {
	targetBlank: false,
};

export default ExPopulusHeaderNavItem;
