import React, {ReactNode} from "react";
import GameDetailsCarouselGalleryPreview from "./GameDetailsCarouselGalleryPreview";
import {IAssetWithIndex} from "./GameDetailsCarousel";
import classNames from "classnames";

interface IProps {
	selectedIndex: number;
	setSelectedIndex: (index: number) => void;
	assets: Array<IAssetWithIndex>;
	onMouseEnter?(): void;
	onMouseLeave?(): void;
}

const GameDetailsCarouselThumbnailGroup: React.FC<IProps> = (props) => {

	/**
	 * Render the selected thumbnails. The JSX should only be calling this with an array of 3 or 4 based on screen size.
	 * User can click on one of the previews to switch to that slide (and if it's a video, start playing).
	 *
	 * @param assetWidthIndex
	 * @param i
	 */
	function renderThumbs(assetWidthIndex: IAssetWithIndex, i: number): ReactNode {
		function onSelectHelper(): void {
			props.setSelectedIndex(assetWidthIndex.index);
		}

		return (
			<GameDetailsCarouselGalleryPreview
				key={`gallery-preview_${i}`}
				asset={assetWidthIndex.asset}
				selected={props.selectedIndex === assetWidthIndex.index}
				onClick={onSelectHelper}
				className="game-details-carousel-thumbnail-group_item"
			/>
		);
	}

	return (
		<div
			className={classNames("game-details-carousel-thumbnail-group", {
				"game-details-carousel-thumbnail-group_visible": props.assets.findIndex(a => a.index === props.selectedIndex) > -1, // Thumbnail group "i" is visible if any of its thumbnails are the active slide.
			})}
		>
			{props.assets.map(renderThumbs)}
		</div>
	);
};

GameDetailsCarouselThumbnailGroup.defaultProps = {
	onMouseEnter: () => {},
	onMouseLeave: () => {},
}

export default GameDetailsCarouselThumbnailGroup;
