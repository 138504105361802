import React, {HTMLAttributes, ReactNode} from "react";
import classNames from "classnames";
import {Container} from "reactstrap";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

interface IProps extends HTMLAttributes<HTMLElement> {
    id: string;
    className?: string;
    nextSectionScroll?: ReactNode;
}

const AboutHero: React.FC<IProps> = (props) => {

    return (
        <section
            id={props.id}
            className={classNames("hero2", props.className)}
        >
            <Container>
                <div className="hero-bg-image">
                    <MediaAssetViewer
                        asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/about-hero-cutout_2x_t42yln")}
                        imageAltText="Hero Foreground"
                        contentType={"image"}
                    />
                </div>

                <header className="section-header">
                    <h2>
                        <span className={"bold"}>Ex Populus</span> is a <img className={"web3-logo"}
                                                                             src={"/images/about/web3-logo.png"}/> game
                        development studio.
                    </h2>

                    {props.nextSectionScroll}
                </header>

                <div className={"hero-bg-gradient"}>
                </div>
            </Container>

            <div className="hero-bg-image-2">

                <MediaAssetViewer
                    asset={new URL("https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1661293846/website/about-page/hero_bg_2x_icpdyh")}
                    contentType={"image"}
                    imageAltText="Hero Background"
                />
            </div>
        </section>
    );
}

AboutHero.defaultProps = {
    nextSectionScroll: null,
}

export default AboutHero;
