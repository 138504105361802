import React, {ReactNode} from "react";
import {PaginationInfo} from "client/marketplace";
import {isNil} from "lodash";
import classNames from "classnames";
import {Button} from "reactstrap";
import PaginationChevron from "./collectibles/PaginationChevron";

interface IProps {
	paginationInfo: PaginationInfo;
	onPageChangeHelper: (index: number) => void;
}

const CollectiblesPaginator: React.FC<IProps> = ({ paginationInfo, onPageChangeHelper }) => {

	/**
	 * Dynamic offset change function.
	 *
	 * @param offset
	 */
	function changePageHelper(offset: number): () => void {
		return () => onPageChangeHelper(offset);
	}

	// /**
	//  * Render each dynamically numbered pagination button.
	//  *
	//  * @param offset
	//  */
	const renderPaginatorButton = (offset: number): ReactNode => (
		<Button
			key={`marketplace-paginator-button_${offset}`}
			onClick={paginationInfo?.offset !== offset ? changePageHelper(offset) : undefined}
			className={classNames("collectibles-paginator_button", {
				"collectibles-paginator_button-active": paginationInfo?.offset === offset,
			})}
		>
			{offset + 1}
		</Button>
	);

	return (
		<div className="collectibles-paginator">
			<Button
				onClick={paginationInfo?.enablePrevious ? changePageHelper(paginationInfo?.offset - 1) : undefined}
				className={classNames("collectibles-paginator_button", "collectibles-paginator_button-fixed")}
			>
				<PaginationChevron direction={"left"} />
			</Button>

			{paginationInfo?.availableOffsets?.filter(i => !isNil(i)).map(renderPaginatorButton)}

			<Button
				onClick={paginationInfo?.enableNext ? changePageHelper(paginationInfo?.offset + 1) : undefined}
				className={classNames("collectibles-paginator_button", "collectibles-paginator_button-fixed")}
			>
				<PaginationChevron direction={"right"} />
			</Button>
		</div>
	);
};

export default CollectiblesPaginator;

