"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameAllOfToJSON = exports.GameAllOfFromJSONTyped = exports.GameAllOfFromJSON = exports.instanceOfGameAllOf = void 0;
var runtime_1 = require("../runtime");
var IGameCarouselAsset_1 = require("./IGameCarouselAsset");
/**
 * Check if a given object implements the GameAllOf interface.
 */
function instanceOfGameAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    return isInstance;
}
exports.instanceOfGameAllOf = instanceOfGameAllOf;
function GameAllOfFromJSON(json) {
    return GameAllOfFromJSONTyped(json, false);
}
exports.GameAllOfFromJSON = GameAllOfFromJSON;
function GameAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'slug': json['slug'],
        'carouselAssets': !(0, runtime_1.exists)(json, 'carouselAssets') ? undefined : (json['carouselAssets'].map(IGameCarouselAsset_1.IGameCarouselAssetFromJSON)),
    };
}
exports.GameAllOfFromJSONTyped = GameAllOfFromJSONTyped;
function GameAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'slug': value.slug,
        'carouselAssets': value.carouselAssets === undefined ? undefined : (value.carouselAssets.map(IGameCarouselAsset_1.IGameCarouselAssetToJSON)),
    };
}
exports.GameAllOfToJSON = GameAllOfToJSON;
