import * as React from "react";
import {ReactElement, ReactNode, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import {HiArrowNarrowLeft, HiArrowNarrowRight} from "react-icons/all";
import {useWindowDimensions} from "../../utils/hooks/useWindowDimensions";
import classNames from "classnames";
import GameDetailsTCSTabControl from "./GameDetailsTCSTabControl";

export interface IDataElementArrayElement {
	image?: string;
	alt: string;
	header: string;
	body: string | ReactNode;
}

const dataElementArray: Array<IDataElementArrayElement> = [
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/rarities_2x_mpcpzu",
		alt: "Rarity Tier",
		header: "Rarity Tier",
		body: "To start, there will be 4 rarity tiers: Common, Rare, Epic and Legendary.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/power_rating_2x_svr9ir",
		alt: "Power Rating",
		header: "Power Rating",
		body: "The card’s power rating. When cards are used in games, attributes like health points and attack points can be derived from the card’s power, so bigger is better.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/abilities_2x_oo6pnz",
		alt: "Abilities",
		header: "Abilities",
		body: "Each card comes equipped with 1,2, or 3 of over 30 special abilities. In the metadata, the names of these abilities will be listed. The abilities are intended to be used in games to attack enemies or defend allies.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/health_2x_myf0my",
		alt: "Health Traits",
		header: "Health Traits",
		body: "Each card has one of five adjectives to describe the health ranking of the card. These adjectives can be used in conjunction with the power rating to create health points/rating or similar.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/attack_2x_ewx3ai",
		alt: "Attack Traits",
		header: "Attack Traits",
		body: "Each card has one of five adjectives to describe the attack ranking of the card. These adjectives can be used in conjunction with the power rating to create an attack points/rating or similar.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/house_2x_tkf8oc",
		alt: "House",
		header: "House",
		body: "Each XP trading card belongs to one of 4 houses. You can think of these almost like suits in a standard deck of cards. The houses are: Man, Machines, Beasts and Gods.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/set_name_2x_bmictj",
		alt: "Set Name",
		header: "Set Name",
		body: "The name of the card set. Other sets, like Iron Pigeons, will have different names. It’s important to know that each set is completely compatible with the others.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/set_number_2x_yzmuzx",
		alt: "Set Number",
		header: "Set Number",
		body: "DAGs is a card set and the first in the Ex Populus Trading Card System, so it will always be referred to as Set 1. Iron Pigeons will be Set 2. Subsequent sets will continue to count up.",
	},
	{
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_scale,w_1920/f_auto/q_auto/v1668117068/website/images/final-form/TCS%20Learn/edition_number_2x_ys0ljg",
		alt: "Edition Number",
		header: "Edition Number",
		body: "Because games will heavily encourage burning of cards to evolve, the supply of Ex Populus trading cards is likely to decrease rapidly. Eventually, more cards in a given set may be minted to replenish the supply. These new mints will be 2nd, 3rd and 4th editions, continually counting up. Eventually, there will be very few 1st Editions left, so if you are a collector, keep your eyes peeled for those early editions.",
	},
	{
		image: "",
		alt: "Supply Caps",
		header: "Supply Caps",
		body: "Each Set of trading cards has an “active supply cap”. This means that the active supply of a given card set can never exceed the amounts below. However, because cards are burned and subsequent mints of second or third editions are possible, it’s important to understand that the supply caps exclude the previously burned cards and only count the current “active” cards in circulation.",
	},
	{
		image: "",
		alt: "Active Supply Caps Per Set",
		header: "Active Supply Caps Per Set",
		// body: "Common: 100,000 Rare: 20,000 Epic: 4,000 Legendary: 800 Active Set Total: 124,800 There are only 800 Legendaries per Set / Edition. As cards in a given set are burned, it’s possible that the remaining supply of cards in each set / edition may be equal to or below these supply caps",
		body: <p>
			Common: 100,000 <br/>
			Rare: 20,000 <br/>
			Epic: 4,000 <br/>
			Legendary: 800 <br/>
			Active Set Total: 124,800 <br/><br/>
			There are only 800 Legendaries per Set / Edition. As cards in a given set are burned, it’s possible that the
			remaining supply of cards in each set / edition may be equal to or below these supply caps
		</p>,
	},
	{
		image: "",
		alt: "Flexibility & Scaling",
		header: "Flexibility & Scaling",
		body: "The Ex Populus Trading Card System allows for scalability. Based on demand and engagement, Ex Populus can determine if new cards should be minted to replenish supply after large portions have been burned ( though never exceeding the supply cap) or if new sets should be made to create new supply beyond the caps within each set. This allows for scalability based on demand with levers in place to maintain an equilibrium.",
	},
]

export const FinalFormTCS: React.FC = () => {
	const windowDimensions = useWindowDimensions();
	const [selectedCarouselSlide, setSelectedCarouselSlide] = useState<number>(0);

	const tabControlScroller = (index) => {
		document.getElementById(dataElementArray[index].header).scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
			inline: 'start'
		});
		setSelectedCarouselSlide(index);
	}

	const createTCSCard = (item: IDataElementArrayElement, index: number): ReactNode => {
		return (
			<aside
				key={`tcs-card-${index}`}
				className="final-form-tcs_container_carousel_item"
			>
				<div className="final-form-tcs_container_carousel_item_container">

					{item.image && (
						<div className="final-form-tcs_container_carousel_item_container_image">
							<img src={item.image} alt={item.alt}/>
						</div>
					)}

					<div className={classNames("final-form-tcs_container_carousel_item_container_body", {
						"half": !item.image,
					})}>

						<h4>{item.header}</h4>
						<p>{item.body}</p>
					</div>
				</div>
			</aside>
		)
	}

	return (
		<section className="tcs-clip">
			<div className="final-form-tcs">
				<div className="container">
					<div className="final-form-tcs_container">
						<h2 className="final-form-tcs_container_title">
							<strong>The Final Form </strong><span>Trading card system</span>
						</h2>
						<p className="final-form-tcs_container_body">
							The system includes a standard rule-set to determine card rarity, health, attack, abilities
							and more. Inspired by traditional card decks, this thoughtfully designed system maximizes
							versatility, catering to a wide range of potential trading card games.
						</p>

						<div className="final-form-tcs_container_wrapper">
							<GameDetailsTCSTabControl
								selectedCarouselSlide={selectedCarouselSlide}
								setSelectedCarouselSlide={setSelectedCarouselSlide}
								data={dataElementArray}
							/>

							<div className="final-form-tcs_container_carousel">
								<Carousel
									showStatus={false}
									infiniteLoop={true}
									showArrows={windowDimensions.width > 991}
									selectedItem={selectedCarouselSlide}
									showThumbs={false}
									showIndicators={false}
									swipeScrollTolerance={32}
									onChange={tabControlScroller}
									renderArrowPrev={(onClickHandler, hasPrev, label) =>
										hasPrev && (
											<button
												type="button"
												onClick={onClickHandler}
												title={label}
												className="final-form-tcs_container_carousel_button final-form-tcs_container_carousel_button-prev"
											>
												<HiArrowNarrowLeft/>
											</button>
										)
									}
									renderArrowNext={(onClickHandler, hasNext, label) =>
										hasNext && (
											<button
												type="button"
												onClick={onClickHandler}
												title={label}
												className="final-form-tcs_container_carousel_button final-form-tcs_container_carousel_button-next"
											>
												<HiArrowNarrowRight/>
											</button>
										)
									}
								>
									{dataElementArray.map(createTCSCard) as Array<ReactElement>}
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
