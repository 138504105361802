"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetGeoBansResponseToJSON = exports.GetGeoBansResponseFromJSONTyped = exports.GetGeoBansResponseFromJSON = exports.instanceOfGetGeoBansResponse = void 0;
var GeoBan_1 = require("./GeoBan");
var PaginationInfo_1 = require("./PaginationInfo");
/**
 * Check if a given object implements the GetGeoBansResponse interface.
 */
function instanceOfGetGeoBansResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "paginationInfo" in value;
    isInstance = isInstance && "geoBans" in value;
    return isInstance;
}
exports.instanceOfGetGeoBansResponse = instanceOfGetGeoBansResponse;
function GetGeoBansResponseFromJSON(json) {
    return GetGeoBansResponseFromJSONTyped(json, false);
}
exports.GetGeoBansResponseFromJSON = GetGeoBansResponseFromJSON;
function GetGeoBansResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoFromJSON)(json['paginationInfo']),
        'geoBans': (json['geoBans'].map(GeoBan_1.GeoBanFromJSON)),
    };
}
exports.GetGeoBansResponseFromJSONTyped = GetGeoBansResponseFromJSONTyped;
function GetGeoBansResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': (0, PaginationInfo_1.PaginationInfoToJSON)(value.paginationInfo),
        'geoBans': (value.geoBans.map(GeoBan_1.GeoBanToJSON)),
    };
}
exports.GetGeoBansResponseToJSON = GetGeoBansResponseToJSON;
