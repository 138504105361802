import InitialWalletStore, {IWalletStore} from "./InitialWalletStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";

export enum WalletAction {
	PHANTOM_TOGGLE_AUTO_CONNECT = "PHANTOM_TOGGLE_AUTO_CONNECT",
}

export default function (store: IWalletStore = InitialWalletStore, action: IAction<WalletAction, any>): IWalletStore {
	const newStore: IWalletStore = cloneDeep(store);

	switch (action.type) {
		case WalletAction.PHANTOM_TOGGLE_AUTO_CONNECT:
			newStore.autoConnectPhantom = action.payload;
			break
		default:
			break;
	}

	return newStore;
}
