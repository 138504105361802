import React, { useEffect } from "react";
import { DialCode } from "client/marketplace";
import { CountryCode } from "libphonenumber-js";

interface IProps {
  show?: boolean;
	onChange: Function;
	dialCodesList: Array<DialCode>;
  defaultDialCode?: DialCode;
}

const CountryCodeDropDown: React.FC<IProps> = (props) => {
	const {
    show,
		onChange,
		dialCodesList,
    defaultDialCode,
	} = props;

  useEffect(() => {
    onChange(defaultDialCode);
  }, [defaultDialCode]);

  const makeListItem = (dc: DialCode, i: Number) => {
    const { name, dialCode, code } = dc;

    return (
      <li
        key={`country-code-${i}`}
        className={"country-code-list-item"}
        onClick={() => onChange(dc)}
      >
				<img src={`${process.env.PUBLIC_URL}/icons/flags/4x3/${code.toLowerCase()}.svg`} />
				<span>{name}</span>
				<span> ({dialCode})</span>
			</li>
    );
  }

  const makeDropDown = (dialCodes) => {
    return (
      <div className={"country-code-dropdown-list"}>
        <ul>
          {dialCodes?.map(makeListItem)}
        </ul>
      </div>
    );
  }

	return (
    <div className={"country-code-dropdown-container"}>
      { show && makeDropDown(dialCodesList) }
    </div>
	);
}

CountryCodeDropDown.defaultProps = {
  show: false,
};

export default CountryCodeDropDown;