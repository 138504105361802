"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartContractTypeToJSON = exports.SmartContractTypeFromJSONTyped = exports.SmartContractTypeFromJSON = exports.SmartContractType = void 0;
/**
 *
 * @export
 */
exports.SmartContractType = {
    ExPopulusERC721WithSingleMetadataIPFS: 'ExPopulusERC721WithSingleMetadataIPFS',
    HalloweenLamo: 'HalloweenLamo'
};
function SmartContractTypeFromJSON(json) {
    return SmartContractTypeFromJSONTyped(json, false);
}
exports.SmartContractTypeFromJSON = SmartContractTypeFromJSON;
function SmartContractTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SmartContractTypeFromJSONTyped = SmartContractTypeFromJSONTyped;
function SmartContractTypeToJSON(value) {
    return value;
}
exports.SmartContractTypeToJSON = SmartContractTypeToJSON;
