import ReactGA from "react-ga4";
import { User } from "client/cs";
import { gsghtSend, GAMESIGHT_EVENTS } from "shared";

export interface IEventInfo {
  eventName: GAMESIGHT_EVENTS;
  user?: User;
  sendToGA?: boolean;
  sendToGameSight?: boolean;
}

/**
 * Send the event to both GameSight and Google Analytics
 * @param {Object} eventInfo The event information
 * @param {GAMESIGHT_EVENTS} eventInfo.eventName The event name to send
 * @param {User} eventInfo.user Optional. The currently logged in user
 * @param {Boolean} eventInfo.sendToGA Optional. Defaults to FALSE. When true the Google Analytics event will NOT be sent.
 * @param {Boolean} eventInfo.sendToGameSight Optional. Defaults to FALSE. When true the GameSight event will NOT be sent.
 */
export const sendEvent = ({
  eventName,
  user = null,
  sendToGA = true,
  sendToGameSight = true,
}: IEventInfo) => {
  if (sendToGameSight) {
    gsghtSend(eventName, user);
  }

  if (sendToGA) {
    try {
      ReactGA.event(eventName);
    } catch (e) {
      console.error(e);
    }
  }
};

const marketingEvents = {
  sendEvent,
}

export default marketingEvents;
