import React from "react";
import {BsTwitter} from "react-icons/all";

interface IProps {
    twitterLink?: string;
}

const openNewWindow = (link) => () => window.open(link);

const TwitterButton: React.FC<IProps> = (props) => (
    <div className="twitter-button" onClick={openNewWindow(props.twitterLink)}>
        <div className="twitter-icon"><BsTwitter/></div>
    </div>
);

TwitterButton.defaultProps = {
    twitterLink: "https://twitter.com/xai_games",
}

export {TwitterButton};
