import React from "react";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";
import PlayFreeButton from "../../PlayFreeButton";
import {Carousel} from "react-responsive-carousel";

interface FinalFormMultiverseProps {
	onClick: () => void;
}

export const FinalFormMultiverse: React.FC<FinalFormMultiverseProps> = (props) => {

	const element1 = (
		<div className="final-form-multiverse_container_section">
			<div className="final-form-multiverse_container_section_image right">
				<img src="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967067/website/images/final-form/6._world_1_h8xhzl.png" alt="multiverse"/>
			</div>

			<div className="final-form-multiverse_container_section_body">
				<h2>A Trading Card Multiverse</h2>
				<p>At the center of Final Form is a mysterious obelisk known as the God’s Tooth on a distant alien planet. This “God’s Tooth” has created a dimensional rift that pulls in characters from many different dimensions to battle in an interdimensional arena. Only a few will reach their Final Form.</p>

				<div className="final-form-multiverse_container_section_body_button">
					<PlayFreeButton
						leftIcon={false}
						buttonColor="white"
					/>
				</div>
			</div>
		</div>
	);

	const element2 = (
		<div className="final-form-multiverse_container_section reverse">
			<div className="final-form-multiverse_container_section_body right">
				<h2>Entertainment Brand Crossover</h2>
				<p>Each limited edition card set has been made in collaboration with famous artists, celebrities and athletes including Oscar nominated Calabash, former World HeavyWeight Champ Mike Tyson and more to come.</p>

				<div className="final-form-multiverse_container_section_body_button right">
					<ExPopulusButton
						color="white"
						onClick={props.onClick}
					>
						GET CARDS
					</ExPopulusButton>
				</div>
			</div>

			<div className="final-form-multiverse_container_section_image">
				<img src="https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1682967067/website/images/final-form/6._world_2_hlxlam.png" alt="multiverse"/>
			</div>
		</div>
	);

	const mobileCarousel = (
		<Carousel
			showStatus={false}
			infiniteLoop={true}
			showArrows={false}
			showThumbs={false}
			swipeScrollTolerance={32}
			showIndicators={true}
			className="mobile_carousel"
		>
			{[element1, element2]}
		</Carousel>
	)

	return (
		<section className="final-form-multiverse-clip">
			<div className="final-form-multiverse">
				<div className="final-form-multiverse_container">

					<div className="final-form-multiverse_container_header">
						<h2 className="final-form-multiverse_container_header_title">
							<span>The World</span>
							of Final Form
						</h2>
					</div>

					<div className="desktop">
						{[element1, element2]}
					</div>

					<div className="mobile">
						{mobileCarousel}
					</div>
				</div>
			</div>
		</section>
	);
};
