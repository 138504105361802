"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = exports.instanceOfUser = void 0;
var runtime_1 = require("../runtime");
var UserType_1 = require("./UserType");
var _1 = require("./");
/**
 * Check if a given object implements the User interface.
 */
function instanceOfUser(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "verified" in value;
    return isInstance;
}
exports.instanceOfUser = instanceOfUser;
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['type'] === 'GOD_ADMIN') {
            return (0, _1.GodAdminFromJSONTyped)(json, true);
        }
        if (json['type'] === 'PUBLISHER') {
            return (0, _1.PublisherFromJSONTyped)(json, true);
        }
        if (json['type'] === 'STANDARD_ADMIN') {
            return (0, _1.StandardAdminFromJSONTyped)(json, true);
        }
        if (json['type'] === 'STANDARD_USER') {
            return (0, _1.StandardUserFromJSONTyped)(json, true);
        }
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, UserType_1.UserTypeFromJSON)(json['type']),
        'disabled': json['disabled'],
        'email': json['email'],
        'verified': json['verified'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, UserType_1.UserTypeToJSON)(value.type),
        'disabled': value.disabled,
        'email': value.email,
        'verified': value.verified,
        'name': value.name,
    };
}
exports.UserToJSON = UserToJSON;
