import {CTAItemType, ICTACommonRare, ICTASoldOut} from "../components/dag/DagBuyNowCTA";
import {SaleTypeEnum} from "./SaleTypeEnum";

export function isCTAItem(ctaValue: CTAItemType, saleType: SaleTypeEnum): ctaValue is ICTACommonRare {
	return saleType === SaleTypeEnum.RARE || saleType === SaleTypeEnum.COMMON;
}

export function isCTASoldOut(ctaValue: CTAItemType, saleType: SaleTypeEnum): ctaValue is ICTASoldOut {
	return saleType === SaleTypeEnum.RARE_SOLD_OUT;
}
