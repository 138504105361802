"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenAllOfToJSON = exports.TokenAllOfFromJSONTyped = exports.TokenAllOfFromJSON = exports.instanceOfTokenAllOf = void 0;
var UserType_1 = require("./UserType");
/**
 * Check if a given object implements the TokenAllOf interface.
 */
function instanceOfTokenAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
exports.instanceOfTokenAllOf = instanceOfTokenAllOf;
function TokenAllOfFromJSON(json) {
    return TokenAllOfFromJSONTyped(json, false);
}
exports.TokenAllOfFromJSON = TokenAllOfFromJSON;
function TokenAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, UserType_1.UserTypeFromJSON)(json['type']),
        'token': json['token'],
    };
}
exports.TokenAllOfFromJSONTyped = TokenAllOfFromJSONTyped;
function TokenAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, UserType_1.UserTypeToJSON)(value.type),
        'token': value.token,
    };
}
exports.TokenAllOfToJSON = TokenAllOfToJSON;
