"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardUserAllOfToJSON = exports.StandardUserAllOfFromJSONTyped = exports.StandardUserAllOfFromJSON = exports.instanceOfStandardUserAllOf = void 0;
var Wallet_1 = require("./Wallet");
/**
 * Check if a given object implements the StandardUserAllOf interface.
 */
function instanceOfStandardUserAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "agreeEmailUpdates" in value;
    isInstance = isInstance && "wallets" in value;
    return isInstance;
}
exports.instanceOfStandardUserAllOf = instanceOfStandardUserAllOf;
function StandardUserAllOfFromJSON(json) {
    return StandardUserAllOfFromJSONTyped(json, false);
}
exports.StandardUserAllOfFromJSON = StandardUserAllOfFromJSON;
function StandardUserAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreeEmailUpdates': json['agreeEmailUpdates'],
        'wallets': (json['wallets'].map(Wallet_1.WalletFromJSON)),
    };
}
exports.StandardUserAllOfFromJSONTyped = StandardUserAllOfFromJSONTyped;
function StandardUserAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreeEmailUpdates': value.agreeEmailUpdates,
        'wallets': (value.wallets.map(Wallet_1.WalletToJSON)),
    };
}
exports.StandardUserAllOfToJSON = StandardUserAllOfToJSON;
