"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiErrorToJSON = exports.ApiErrorFromJSONTyped = exports.ApiErrorFromJSON = exports.instanceOfApiError = void 0;
var runtime_1 = require("../runtime");
var AppError_1 = require("./AppError");
var ErrorLevel_1 = require("./ErrorLevel");
var ErrorType_1 = require("./ErrorType");
/**
 * Check if a given object implements the ApiError interface.
 */
function instanceOfApiError(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
exports.instanceOfApiError = instanceOfApiError;
function ApiErrorFromJSON(json) {
    return ApiErrorFromJSONTyped(json, false);
}
exports.ApiErrorFromJSON = ApiErrorFromJSON;
function ApiErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ErrorType_1.ErrorTypeFromJSON)(json['type']),
        'level': !(0, runtime_1.exists)(json, 'level') ? undefined : (0, ErrorLevel_1.ErrorLevelFromJSON)(json['level']),
        'message': json['message'],
        'date': !(0, runtime_1.exists)(json, 'date') ? undefined : json['date'],
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : (json['errors'].map(AppError_1.AppErrorFromJSON)),
        'httpStatus': !(0, runtime_1.exists)(json, 'httpStatus') ? undefined : json['httpStatus'],
        'httpMethod': !(0, runtime_1.exists)(json, 'httpMethod') ? undefined : json['httpMethod'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
    };
}
exports.ApiErrorFromJSONTyped = ApiErrorFromJSONTyped;
function ApiErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ErrorType_1.ErrorTypeToJSON)(value.type),
        'level': (0, ErrorLevel_1.ErrorLevelToJSON)(value.level),
        'message': value.message,
        'date': value.date,
        'errors': value.errors === undefined ? undefined : (value.errors.map(AppError_1.AppErrorToJSON)),
        'httpStatus': value.httpStatus,
        'httpMethod': value.httpMethod,
        'path': value.path,
    };
}
exports.ApiErrorToJSON = ApiErrorToJSON;
