"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckTokenExpirationResponseToJSON = exports.CheckTokenExpirationResponseFromJSONTyped = exports.CheckTokenExpirationResponseFromJSON = exports.instanceOfCheckTokenExpirationResponse = void 0;
/**
 * Check if a given object implements the CheckTokenExpirationResponse interface.
 */
function instanceOfCheckTokenExpirationResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "expired" in value;
    return isInstance;
}
exports.instanceOfCheckTokenExpirationResponse = instanceOfCheckTokenExpirationResponse;
function CheckTokenExpirationResponseFromJSON(json) {
    return CheckTokenExpirationResponseFromJSONTyped(json, false);
}
exports.CheckTokenExpirationResponseFromJSON = CheckTokenExpirationResponseFromJSON;
function CheckTokenExpirationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expired': json['expired'],
    };
}
exports.CheckTokenExpirationResponseFromJSONTyped = CheckTokenExpirationResponseFromJSONTyped;
function CheckTokenExpirationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expired': value.expired,
    };
}
exports.CheckTokenExpirationResponseToJSON = CheckTokenExpirationResponseToJSON;
