"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveCloudinaryInfoBodyToJSON = exports.RemoveCloudinaryInfoBodyFromJSONTyped = exports.RemoveCloudinaryInfoBodyFromJSON = exports.instanceOfRemoveCloudinaryInfoBody = void 0;
/**
 * Check if a given object implements the RemoveCloudinaryInfoBody interface.
 */
function instanceOfRemoveCloudinaryInfoBody(value) {
    var isInstance = true;
    isInstance = isInstance && "assetID" in value;
    return isInstance;
}
exports.instanceOfRemoveCloudinaryInfoBody = instanceOfRemoveCloudinaryInfoBody;
function RemoveCloudinaryInfoBodyFromJSON(json) {
    return RemoveCloudinaryInfoBodyFromJSONTyped(json, false);
}
exports.RemoveCloudinaryInfoBodyFromJSON = RemoveCloudinaryInfoBodyFromJSON;
function RemoveCloudinaryInfoBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'assetID': json['assetID'],
    };
}
exports.RemoveCloudinaryInfoBodyFromJSONTyped = RemoveCloudinaryInfoBodyFromJSONTyped;
function RemoveCloudinaryInfoBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'assetID': value.assetID,
    };
}
exports.RemoveCloudinaryInfoBodyToJSON = RemoveCloudinaryInfoBodyToJSON;
