"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatorBodyToJSON = exports.CreatorBodyFromJSONTyped = exports.CreatorBodyFromJSON = exports.instanceOfCreatorBody = void 0;
/**
 * Check if a given object implements the CreatorBody interface.
 */
function instanceOfCreatorBody(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfCreatorBody = instanceOfCreatorBody;
function CreatorBodyFromJSON(json) {
    return CreatorBodyFromJSONTyped(json, false);
}
exports.CreatorBodyFromJSON = CreatorBodyFromJSON;
function CreatorBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
    };
}
exports.CreatorBodyFromJSONTyped = CreatorBodyFromJSONTyped;
function CreatorBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
    };
}
exports.CreatorBodyToJSON = CreatorBodyToJSON;
