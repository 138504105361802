/**
 * Utils & a place for putting redirect mappings/strings here.
 * Chose to do it this way for initial implementation as it was faster & more straight forward than creating some sort
 * of parsing system. Also allows for calling the redirect string anything we want, irrespective of the associated page.
 *
 * Enum XP_REDIRECTS should map 1-1 with what the url should look like on redirect.
 * exPopulusRedirectMap object has instructions the receiving page can use to look up where to send in response.
 *
 */
var _a;
export var XP_REDIRECTS;
(function (XP_REDIRECTS) {
    XP_REDIRECTS["GET_DAGS"] = "get-dags";
    XP_REDIRECTS["GET_IRONPIGEONS"] = "get-iron-pigeons";
})(XP_REDIRECTS || (XP_REDIRECTS = {}));
export var exPopulusRedirectMap = (_a = {},
    _a[XP_REDIRECTS.GET_DAGS] = process.env.REACT_APP_DAG_URL + "/#homepage-buy-now",
    _a[XP_REDIRECTS.GET_IRONPIGEONS] = process.env.REACT_APP_IRON_PIGEONS_URL + "/#homepage-buy-now",
    _a);
/**
 * Validation to prevent malicious activities by unsavory individuals sending users to our site & hijacking the redirect query parameter.
 *
 * @param xpRedirect
 */
export function validateRedirectRoute(xpRedirect) {
    if (!XP_REDIRECTS || !exPopulusRedirectMap[xpRedirect]) {
        return false;
    }
    return true;
}
/**
 * Util you can use for Link tag "to" props on spots where we need to see if there was a redirect query param & verify
 * if it is a valid one (one of ours), and return the reconstructed link path.
 *
 * @param baseRoute
 * @param xpRedirect
 */
export function validateAndReturnRedirectQuery(baseRoute, xpRedirect) {
    if (!XP_REDIRECTS || !exPopulusRedirectMap[xpRedirect]) {
        return baseRoute;
    }
    return baseRoute + "?redirect=" + xpRedirect;
}
