import React from "react";
import {Container} from "reactstrap";
import {Asset} from "client/marketplace";
import {ExPopulusButton} from "../buttons/ExPopulusButton";
import {fileIsImage} from "../../utils/fileTypeChecks";
import openSecondaryMarketURL from "../../utils/openSecondaryMarketURL";

import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {TokenType} from "../../utils/TokenType";

const body: IAbout = {
    header: "About",
    subheader: "Mike Tyson has a passion for pigeons.",
    body: "His love for the birds began when he was only a child and has grown significantly over the years. Today, Tyson still loves his pigeons and maintains a collection of over 1,000 birds. The champ wanted to share his passion for collecting and raising Pigeons with you by making a unique set of pigeons for everyone to collect & enjoy in the Ex Populus trading card system.",
    // cardImage: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/about/ip-cards-example-hires-sized.png",
    cardImageAlt: "Iron Pigeons Cards Stack",
    ctaText: (
        <p>
            Iron Pigeons are now available to play in <a
            href={`${process.env.REACT_APP_WEBSITE_URL}/games/final-form`} target="_blank"
            rel="noopener noreferrer">Final Form™</a>
        </p>
    ),
    asset: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/about/ip-cards-example-hires-sized.png",
        contentType: "image",
        cloudinary: {
            publicId: "website/images/ip-cards-about_cgmjix",
            actions: {
                desktop: ["f_auto", "q_auto"],
                mobile: ["f_auto", "q_auto"]
            },
        },
    } as unknown as Asset,
}

interface IAbout {
    asset: Asset;
    cardImageAlt: string;
    header: string;
    subheader: string;
    body: string;
    ctaText?: string | JSX.Element;
}

interface IProps {
    tokenType: TokenType;
    onClickGetCardsCallback?: () => void;
}

const IronPigeonsHomepageAbout: React.FC<IProps> = (props) => {

    const onClickGetCards = () => {
        openSecondaryMarketURL(props.tokenType);

        props.onClickGetCardsCallback();
    }

    return (
        <section className={"homepage-template-about"}>
            <Container className={"container"}>
                <div className={"homepage-template-about_container"}>

                    <div className={"homepage-template-about_container_text"}>
                        <div className={"homepage-template-about_container_text_header"}>
                            {body.header}
                        </div>
                        <div className={"homepage-template-about_container_text_subheader"}>
                            {body.subheader}
                        </div>
                        <p className={"homepage-template-about_container_text_body"}>
                            {body.body}
                        </p>


                        {body.ctaText && (
                            <div className={"homepage-template-about_container_text_cta"}>
                                <div className={"homepage-template-about_container_text_cta_text"}>
                                    {body.ctaText}
                                </div>

                                <ExPopulusButton
                                    color="white-on-pink"
                                    to="#get-cards"
                                    onClick={onClickGetCards}
                                    className={"homepage-template-about_container_text_cta_button"}
                                >
                                    Get Cards
                                </ExPopulusButton>
                            </div>
                        )}
                    </div>

                    <div className={"homepage-template-about_container_card"}>
                        <div className={"homepage-template-about_container_card_size"}>
                            <MediaAssetViewer
                                asset={body.asset}
                                imageAltText={body.cardImageAlt}
                                contentType={fileIsImage(body.asset) ? "image" : "video"}
                                elementWidth={{
                                    desktopWidth: 520,
                                    mobileWidth: 520,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

IronPigeonsHomepageAbout.defaultProps = {
    onClickGetCardsCallback: () => {
    },
}

export {IronPigeonsHomepageAbout};
