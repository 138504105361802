"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateIronPigeonImagesBodyAllOfToJSON = exports.GenerateIronPigeonImagesBodyAllOfFromJSONTyped = exports.GenerateIronPigeonImagesBodyAllOfFromJSON = exports.instanceOfGenerateIronPigeonImagesBodyAllOf = void 0;
var IronPigeon_1 = require("./IronPigeon");
/**
 * Check if a given object implements the GenerateIronPigeonImagesBodyAllOf interface.
 */
function instanceOfGenerateIronPigeonImagesBodyAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "ironPigeon" in value;
    return isInstance;
}
exports.instanceOfGenerateIronPigeonImagesBodyAllOf = instanceOfGenerateIronPigeonImagesBodyAllOf;
function GenerateIronPigeonImagesBodyAllOfFromJSON(json) {
    return GenerateIronPigeonImagesBodyAllOfFromJSONTyped(json, false);
}
exports.GenerateIronPigeonImagesBodyAllOfFromJSON = GenerateIronPigeonImagesBodyAllOfFromJSON;
function GenerateIronPigeonImagesBodyAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ironPigeon': (0, IronPigeon_1.IronPigeonFromJSON)(json['ironPigeon']),
    };
}
exports.GenerateIronPigeonImagesBodyAllOfFromJSONTyped = GenerateIronPigeonImagesBodyAllOfFromJSONTyped;
function GenerateIronPigeonImagesBodyAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ironPigeon': (0, IronPigeon_1.IronPigeonToJSON)(value.ironPigeon),
    };
}
exports.GenerateIronPigeonImagesBodyAllOfToJSON = GenerateIronPigeonImagesBodyAllOfToJSON;
