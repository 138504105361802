import {IExPopulusHeaderNavItemProps} from "./ExPopulusHeaderNavItem";

export interface ExPopulusUniversalNavigationItem {
	title: string;
	href: string;
}

export interface IExPopulusUniversalNavigationItemFooterNav {
	title: string;
	to?: string;
	href?: string;
	local?: boolean;
}

export interface ExPopulusUniversalNavigationItemFooter extends IExPopulusUniversalNavigationItemFooterNav {
	extraLabel?: string;
	subLinks?: Array<ExPopulusUniversalNavigationItemFooter>;
}

export enum ExPopulusProject {
	FINAL_FORM,
	LAMOVERSE,
	COMING_SOON,
}

type ExPopulusProjectMap = {
	[key in ExPopulusProject]: ExPopulusUniversalNavigationItem;
};

export const exPopulusProjectDetails: ExPopulusProjectMap = {
	[ExPopulusProject.FINAL_FORM]: {
		title: "Final Form™",
		href: "/games/final-form",
	},
	[ExPopulusProject.LAMOVERSE]: {
		title: "LAMOverse",
		href: "/games/lamoverse",
	},
	[ExPopulusProject.COMING_SOON]: {
		title: "Coming Soon!",
		href: "/games/coming-soon",
	},
}

/**
 * Quicker checker to determine if the universal components are on the main exp site.
 *
 * @param url
 */
export function isMainEXPSite(url: string): boolean {
	return  url === process.env.REACT_APP_WEBSITE_URL;
}

/** Header Utils **/

/**
 * Determines if the links should be using "local" <Link/> tags instead of <a> tags
 * by comparing the to field against the url of the implementing xp site (marketplace, dag, ip, etc).
 *
 * Assumption is that most of the links will be local on the main site ("store", "games", "about", etc), while on sub domains
 * we expect most of the links to be external back to the main site, but how they're organised isn't important.
 *
 * When the JSX implements this data, the "to" field will result in a <Link to={props.to}> if determined to be local,
 * and otherwise you will get <a href={props.to}>. With this change it shouldn't be required to specify which links are local
 * in the implementing website anymore (Hooray).
 *
 * Also, to make the local "to" props work properly for the react-router-dom <Link/>, we have to remove the base part of the URL
 * (because react-router-dom will add it back)
 *
 * @param links
 * @param implementingSiteURL
 */
export function ascertainLocalLinks(links: Array<IExPopulusHeaderNavItemProps> = [], implementingSiteURL: string): Array<IExPopulusHeaderNavItemProps> {
	return links.map((link): IExPopulusHeaderNavItemProps => {
		const local: boolean = link.to && link.to.includes(implementingSiteURL);

		return {
			...link,
			to: implementingSiteURL === link.to ? "/" : ((local && link.to) ? (link.to.indexOf("/", 10) > -1 ? link.to.slice(link.to.indexOf("/", 10)) : link.to) : link.to),
			raw: link.to,
			subMenu: link.subMenu && Array.isArray(link.subMenu) && link.subMenu.length > 0 && ascertainLocalLinks(link.subMenu, implementingSiteURL),
		}
	});
}

/** Footer Utils **/

export function convertExPopulusProjectNameToNavFooterItem(project: ExPopulusProject): ExPopulusUniversalNavigationItemFooter {
	return {
		title: exPopulusProjectDetails[project].title,
		href: exPopulusProjectDetails[project].href,
	}
}

export function convertListOfExPopulusProjectNamesToNavFooterItem(projects: Array<ExPopulusProject>): Array<ExPopulusUniversalNavigationItemFooter> {
	return projects.map(convertExPopulusProjectNameToNavFooterItem);
}
