import classNames from "classnames";
import classnames from "classnames";
import React, { useState, useEffect } from "react";
import { useRect } from "../../utils/hooks/useRect";
import {
	arrowBorderWidth,
	commonArrowStyles,
	defaultArrowPositionalStyles,
	IArrowPositionalStyles,
	ITooltipError,
	TooltipPosition,
} from "./TooltipContainer";

interface IProps {
	title?: string | null;
	position?: TooltipPosition;
	errors?: Array<ITooltipError>;
	theme?: "dark" | "light",
}

const TooltipErrorList: React.FC<IProps> = ({ errors, title, position, theme }) => {
	const [arrowPositionalStyles, setArrowPositionalStyles] = useState<IArrowPositionalStyles>(defaultArrowPositionalStyles);

	const ref = React.createRef<HTMLDivElement>();
	const rect = useRect(ref);

	useEffect(() => {
		const vCenter = `${rect.height / 2 - arrowBorderWidth}px`;
		const hCenter = `${rect.width / 2 - arrowBorderWidth}px`;

		setArrowPositionalStyles({
			top: {
				...arrowPositionalStyles.top,
				top: `${rect.height}px`,
				left: hCenter
			},
			bottom: {
				...arrowPositionalStyles.bottom,
				left: hCenter
			},
			left: {
				...arrowPositionalStyles.left,
				top: vCenter
			},
			right: {
				...arrowPositionalStyles.right,
				top: vCenter
			}
		});
	}, [rect]);

	const tooltipStyles = {
		width: "300px",
		borderRadius: "4px"
	} as React.CSSProperties;

	const checkIcon = (
		<span className={"icon green"}>
			<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1Zm4.768 9.14a1 1 0 1 0-1.536-1.28l-4.3 5.159l-2.225-2.226a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.475-.067l5-6Z"
					clipRule="evenodd"></path>
			</svg>
		</span>
	);

	const errorIcon = (
		<span className={"icon red"}>
			<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
				<g fill="none" stroke="currentColor" strokeWidth="2">
					<circle cx="12" cy="12" r="10"></circle>
				</g>
			</svg>
		</span>
	);

	const textMessage = (isError, text) => (<span className={classnames("text", { "red": isError, "white": !isError })}>{text}</span>);

	const conditionsListItem = ({ isError, errorText }: ITooltipError, index) => {
		return (
			<li key={`error-${index}`}>
				<div className={"error-item"}>
					{ isError ? errorIcon : checkIcon }
					{ textMessage(isError, errorText) }
				</div>
			</li>
		);
	}

	const arrowClassnames = classNames("tt-arrow", {
		"tt-arrow_dark" : theme === "dark",
		"tt-arrow_light" : theme === "light",
		top: position.toLowerCase() === "top",
		bottom: position.toLowerCase() === "bottom",
		left: position.toLowerCase() === "left",
		right: position.toLowerCase() === "right",
	});

	return (
		<div ref={ref} className="list" style={{ ...tooltipStyles }}>
			<span className={"title"}>{title}</span>
			<ul>
				{ errors.map(conditionsListItem) }
			</ul>
			<span
				className={arrowClassnames}
				style={{ ...commonArrowStyles, ...arrowPositionalStyles[position] }}
			></span>
		</div>
	);
};

TooltipErrorList.defaultProps = {
	title: null,
	position: "top",
	errors: [],
	theme: "dark",
}

export default TooltipErrorList;