import React, { FC, ReactNode } from "react";
import {
  BsInstagram,
  BsTwitter,
  FaTelegramPlane,
  GrLinkedinOption,
  SiDiscord,
} from "react-icons/all";

const ExPopulusFooterSocials: React.FC = () => {
  function makeSocialIcon(icon: FC, href: string, name: string): ReactNode {
    const Icon = icon;

    return (
      <a
        href={href}
        aria-label={name}
        target="_blank"
        rel="noopener noreferrer"
        className={"ex-populus-footer-socials_item"}
      >
        <Icon />
      </a>
    );
  }

  return (
    <div className={"ex-populus-footer-socials"}>
      {makeSocialIcon(
        GrLinkedinOption,
        "https://www.linkedin.com/company/ex-populus/",
        "LinkedIn"
      )}
      {makeSocialIcon(
        BsInstagram,
        "https://www.instagram.com/expopulus/",
        "Instagram"
      )}
      {makeSocialIcon(BsTwitter, "https://twitter.com/xai_games", "Twitter")}
      {makeSocialIcon(SiDiscord, "https://discord.gg/xaigames", "Discord")}
      {makeSocialIcon(FaTelegramPlane, "https://t.me/expopulus", "Telegram")}
      {/*{makeSocialIcon(GrMedium, "https://expopulus.medium.com/decentralized-autonomous-gigaunits-42d947e517db")}*/}
    </div>
  );
};

export default ExPopulusFooterSocials;
