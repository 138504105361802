import * as React from "react";
import {GAMESIGHT_EVENTS} from "shared";
import {Container} from "reactstrap";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {Asset} from "client/marketplace";
import {sendEvent} from "../../utils/event-tracking";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

const body: IGetCards = {
    header: "Available Now on Magic Eden",
    collection: "Iron Pigeons",
    subheader: "Evolved Collection",
    image: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/iron-pigeons/pigeons-gradient.png",
        contentType: "image",
        cloudinary: {
            publicId: "website/images/iron-pigeons/hero/pigeons-gradient_rmvyr4",
            actions: {
                desktop: ["f_auto", "q_auto"],
                mobile: ["f_auto", "q_auto"]
            }
        }
    } as Asset,
    secondaryMarkets: [
        {
            url: "https://magiceden.io/marketplace/iron_pigeons",
            label: "Magic Eden",
            icon: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/buy%20now/MagicEden%402x.jpg",
            event: GAMESIGHT_EVENTS.IP_WEB_SECONDARY_MARKET_MAGICEDEN,
        },
        {
            url: "https://opensea.io/collection/iron-pigeons-sol",
            label: "OpenSea",
            icon: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/buy%20now/OpenSea%402x.jpg",
            event: GAMESIGHT_EVENTS.IP_WEB_SECONDARY_MARKET_OPENSEA,
        },
    ]
}

interface SecondaryLinkElement {
    icon: string,
    label: string,
    url: string,
    event: GAMESIGHT_EVENTS,
}

interface IGetCards {
    header: string;
    image: Asset;
    collection: string;
    subheader: string;
    secondaryMarkets: Array<SecondaryLinkElement>;
    onClickGetCardsCallback?: () => void;
}

interface IProps {
    onClickGetCardsCallback?: () => void;
}

const IronPigeonsGetCards: React.FC<IProps> = (props) => {
    function createSecondaryLink(item: SecondaryLinkElement, index: number): JSX.Element {

        const onClick = (marketEvent) => () => {
            // NOTE: sending the "generic" secondary market event for these buttons
            // is event is handled in the onClickGetCardsCallback code

            // send the market specific event as well
            sendEvent({eventName: marketEvent});

            window.open(item.url, "_blank", "noopener,noreferrer");
            props.onClickGetCardsCallback();
        }

        return (
            <aside className="get-cards-secondary-markets-banner_container_content_card_content_list_item">
                <div className="get-cards-secondary-markets-banner_container_content_card_content_list_item-container">
                    <div
                        className="get-cards-secondary-markets-banner_container_content_card_content_list_item-container_image">
                        <img src={item.icon} alt={`secondary-link-${index}`}/>
                    </div>

                    <div
                        className="get-cards-secondary-markets-banner_container_content_card_content_list_item-container_body">
                        {item.label}
                    </div>
                </div>

                <div className="get-cards-secondary-markets-banner_container_content_card_content_list_item-container">
                    <ExPopulusButton
                        <React.ButtonHTMLAttributes<HTMLAnchorElement>>
                        color="white-on-pink"
                        onClick={onClick(item.event)}
                        className="get-cards-secondary-markets-banner_container_content_card_content_list_item-container_button"
                    >
                        GET CARDS →
                    </ExPopulusButton>
                </div>
            </aside>
        );
    }

    return (
        <section className="get-cards-secondary-markets-banner">
            <Container className="get-cards-secondary-markets-banner_container">
                <label className="get-cards-secondary-markets-banner_container_label">GET CARDS</label>

                <h2><strong>{body.header}</strong></h2>

                <div className="get-cards-secondary-markets-banner_container_content">
                    <div className="get-cards-secondary-markets-banner_container_content_image">
                        <MediaAssetViewer
                            asset={body.image}
                            imageAltText={"placeholder"}
                            contentType={"image"}
                        />
                    </div>

                    <div className="get-cards-secondary-markets-banner_container_content_card">
                        <div className="get-cards-secondary-markets-banner_container_content_card_label">
                            <label><strong>EVOLVED</strong></label>
                        </div>

                        <div className="get-cards-secondary-markets-banner_container_content_card_content">
                            <div className="get-cards-secondary-markets-banner_container_content_card_content_headers">
                                <h5>{body.collection}</h5>
                                <h2><strong>{body.subheader}</strong></h2>
                            </div>

                            <div className="get-cards-secondary-markets-banner_container_content_card_content_list">
                                {body.secondaryMarkets.map(createSecondaryLink)}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

IronPigeonsGetCards.defaultProps = {
    onClickGetCardsCallback: () => console.warn("Missing onClickGetCardsCallback for IP!"),
}

export {IronPigeonsGetCards}
