import React, {ReactNode, useEffect, useLayoutEffect, useState,} from "react";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router-dom";
import {FaChevronDown} from "react-icons/all";
import {
    projectHeaderUtils,
    ProjectPageSection,
    ProjectPageSectionConfigMap,
    ProjectPageSectionRefMap,
} from "../../utils/projectHeaderUtils";
import {useAtom} from "jotai";
import {dismissBannerAtom} from "../banners/ArbitrumBanner";

export interface IHeaderItem {
    sectionId: string;
    label: string;
    route: string;
    ref: React.MutableRefObject<any>;
};

// Offset so that we can scroll to a position slightly higher than the specific section,
// as well as switching which section is "selected" in the top bar a bit earlier than needing to scroll all the way to it.
const scrollOffsetAdjuster: number = 125;

const projectRoutePrefix = "/dags";

const homePageLabels: ProjectPageSectionConfigMap = {
    [ProjectPageSection.HOME]: {
        label: "Home",
        route: `${projectRoutePrefix}/#homepage`,
    },
    [ProjectPageSection.PLAY_FREE]: {
        label: "Play Free",
        route: `${projectRoutePrefix}/#play-free`,
    },
    [ProjectPageSection.ABOUT]: {
        label: "About",
        route: `${projectRoutePrefix}/#about`,
    },
    [ProjectPageSection.GET_NOW]: {
        label: "Get Cards",
        route: `${projectRoutePrefix}/#get-cards`,
    },
    [ProjectPageSection.TRAITS_AND_RARITY]: {
        label: "Traits and Rarity",
        route: `${projectRoutePrefix}/#traits-and-rarity`,
    },
    [ProjectPageSection.SET]: {
        label: "Card Sets",
        route: `${projectRoutePrefix}/#card-sets`,
    },
    [ProjectPageSection.FAQ]: {
        label: "FAQ",
        route: `${projectRoutePrefix}/#faq`,
    },
}

interface IProps {
    refList?: ProjectPageSectionRefMap;
}

const DagHeader: React.FC<IProps> = (props) => {
    const history = useHistory();
    const location = useLocation();

    const [mobileOpen, setMobileOpen] = useState(false);
    const [activeSection, setActiveSection] = useState<ProjectPageSection>();
    const [scrollCheckInterval, setScrollCheckInterval] = useState(1);

    const [initialScroll, setInitialScroll] = useState(true);
    const [dismissBanner] = useAtom(dismissBannerAtom);

    /**
     * On initial load, set a timeout for the scroll so that the DOM can finish loading before scrolling to the appropriate DOM element.
     *
     */
    useLayoutEffect(() => {
        if (initialScroll) {
            setInitialScroll(false);
            setTimeout(() => {
                customScrollHelper();
            }, 1000);
        } else {
            customScrollHelper();
        }
    }, [location]);

    /**
     * Start another interval each time the state variable updates.
     *
     */
     useEffect(() => {
        scrollOffsetIntervalChecker().catch(console.error);
    }, [scrollCheckInterval]);

    /**
     * Helper to set specific scroll positions based on id list. - clean up this logic
     *
     */
    function customScrollHelper(): void {
        const sectionId = location.hash?.replace("#", "");
        if (!sectionId) {
            return;
        }

        const sectionElement = document.getElementById(sectionId);
        if (!sectionElement) {
            console.warn(`Unable to locate section "${sectionId}"!`);
            return;
        }

        const sectionOffset = sectionElement.offsetTop;
        window.scrollTo({ top: Math.max(0, sectionOffset - scrollOffsetAdjuster) });
    }

    /**
     * Handle running interval for checking the scroll position.
     *
     */
    async function scrollOffsetIntervalChecker(): Promise<void> {
        manualScrollCheck();
        await new Promise(r => setTimeout(r, 50));
        setScrollCheckInterval(scrollCheckInterval * -1);
    }

    /**
     * Open or close the mobile nav menu.
     *
     */
    function toggleMobileOpen(): void {
        setMobileOpen(!mobileOpen);
    }

    /**
     * Manual checking the scroll offset as we can't currently use a scroll listener and overflow: hidden at the same time.
     * Chrome and other browsers might have the offset accessible on different objects, so check document.body first,
     * otherwise use document.documentElement.
     *
     */
    function manualScrollCheck() {
        if (document.body.scrollTop) {
            setActiveSection(projectHeaderUtils(document.body.scrollTop + scrollOffsetAdjuster, props.refList));
        } else {
            setActiveSection(projectHeaderUtils(document.documentElement.scrollTop + scrollOffsetAdjuster, props.refList));
        }
    }

    /**
     * Dynamic util for assigning navigation to the buttons rendered in the JSX.
     * Also closes the mobile nav if it is open.
     *
     * @param _route
     */
    function navigateTo(_route: string): () => void {
        return () => {
            setMobileOpen(false);
            history.push(_route);
        }
    }

    /**
     * Generate nav links based on the specific HomePageSection, attach onClick to navigate to that section,
     * and check if it should be the "selected" section for changing the UI (border, color).
     *
     * @param section
     * @param mobile
     */
    function makeNavLink(section: ProjectPageSection, mobile: boolean): ReactNode {
        if (mobile) {
            return (
                <div
                    onClick={navigateTo(homePageLabels[section].route)}
                    className={classNames("project-header_mobile-nav_item", {
                        "project-header_mobile-nav_item-selected": activeSection === section,
                    })}
                >
                    <div className="project-header_mobile-nav_item_label">
                        {homePageLabels[section].label}
                    </div>
                </div>
            );
        }

        return (
            <div
                onClick={navigateTo(homePageLabels[section].route)}
                className={classNames("project-header_nav_navigation_item", {
                    "project-header_nav_navigation_item-selected": activeSection === section,
                })}
            >
                <div className="project-header_nav_navigation_item_label">
                    {homePageLabels[section].label}
                </div>
            </div>
        );
    }

    /**
     * Extra function for clicking the logo branding to close the mobile menu & scroll to top of page.
     *
     */
    function onBrandingClick(): void {
        setMobileOpen(false);
        history.push(homePageLabels[ProjectPageSection.HOME]?.route || "/#");
    }

    return (
        <header className={classNames("project-header", {"project-header_sticky-banner": !dismissBanner,})}>
            <div className="project-header-adjusted-container">
                <nav className="project-header_nav">
                    <div className="project-header_nav_branding-and-hamburger">
                        <div
                            onClick={onBrandingClick}
                            className="project-header_nav_branding-and-hamburger_branding"
                        >
                            <span>DAG</span>
                        </div>

                        <div className="project-header_nav_branding-and-hamburger_hamburger-container">
                            <div
                                className="project-header_nav_branding-and-hamburger_hamburger-container_inner"
                                onClick={toggleMobileOpen}
                            >
                                <FaChevronDown
                                    className={classNames("project-header_nav_branding-and-hamburger_hamburger-container_inner_mobile-chevron")}
                                    style={{transform: `rotate(${mobileOpen ? "180deg" : "0deg"})`}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="project-header_nav_navigation">
                        {makeNavLink(ProjectPageSection.HOME, false)}
                        {makeNavLink(ProjectPageSection.PLAY_FREE, false)}
                        {makeNavLink(ProjectPageSection.ABOUT, false)}
                        {makeNavLink(ProjectPageSection.GET_NOW, false)}
                        {makeNavLink(ProjectPageSection.TRAITS_AND_RARITY, false)}
                        {makeNavLink(ProjectPageSection.SET, false)}
                        {makeNavLink(ProjectPageSection.FAQ, false)}
                    </div>
                </nav>
            </div>

            <nav
                className={classNames("project-header_mobile-nav", {
                    "project-header_mobile-nav_open": mobileOpen,
                })}
            >
                {makeNavLink(ProjectPageSection.HOME, false)}
                {makeNavLink(ProjectPageSection.PLAY_FREE, false)}
                {makeNavLink(ProjectPageSection.ABOUT, false)}
                {makeNavLink(ProjectPageSection.GET_NOW, false)}
                {makeNavLink(ProjectPageSection.TRAITS_AND_RARITY, false)}
                {makeNavLink(ProjectPageSection.SET, false)}
                {makeNavLink(ProjectPageSection.FAQ, false)}
            </nav>
        </header>
    );
};

export {DagHeader};
