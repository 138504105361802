import React from "react";
import classNames from "classnames";

export interface ISocialProofAttribution {
	author: string;
	source: string;
}

export interface ISocialProof {
	score: number;
	quote: string;
	attribution: ISocialProofAttribution;
}

export const SocialProof: React.FC<ISocialProof> = (props) => {

	return (
		<div className="proof">
			<div className="info">
				<div className={"quote"}>&quot;{props.quote}&quot;</div>
				<div className={"attribution"}>
					<div className={"author"}>{props.attribution.author}</div>
					<div className={"source"}>{props.attribution.source}</div>
				</div>
			</div>
			<div className="score">
				<p>
					{props.score}/10
				</p>
			</div>
		</div>
	);
}
