import React from "react";
import {SystemRequirementsType, ISystemRequirement} from "../../utils/hardcoded";

export interface ISystemRequirementsProps {
    requirements: SystemRequirementsType;
}

const SystemRequirementsTitle: React.FC<SystemRequirementsType> = (props) => (
    <div className={"requirement-title"}>{props.title}</div>
);

const SystemRequirement: React.FC<ISystemRequirement> = (props) => (
    <div className={"system-requirement row"}>
        <div className={"cell requirement-label"}>{props.label}</div>
        <div className={"cell requirement-value"}>{props.value}</div>
    </div>
);

const SystemRequirementsList: React.FC<SystemRequirementsType> = (props) => {
    if (!props.requirements?.length) {
        return null;
    }

    return (
        <div className={"requirements-list"}>
            {props.requirements.map((req, i) => (<SystemRequirement key={`system-req-${i}`} {...req} />))}
        </div>
    );
};

const SystemRequirementsBox: React.FC<SystemRequirementsType> = (props) => (
    <div className={"requirements-box"}>
        <SystemRequirementsTitle {...props} />
        <SystemRequirementsList {...props} />
    </div>
);

const SystemRequirements: React.FC<{ requirements: Array<SystemRequirementsType> }> = (props) => (
    <div className={"container system-requirements"}>
        <div className={"section-title"}>System Requirements</div>
        <div className={"requirements-boxes"}>
            {props.requirements.map((req, i) => (<SystemRequirementsBox key={`system-req-box-${i}`}  {...req} />))}
        </div>
    </div>
);

export default SystemRequirements;
