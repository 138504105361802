import React from "react";
import {Carousel} from "react-responsive-carousel";
import {SocialProof} from "../SocialProof";
import {useWindowDimensions} from "../../utils/hooks/useWindowDimensions";

interface IProps {
    proof: any;
}

const GameDetailsSocialProofCarousel: React.FC<IProps> = (props) => {
    const windowDimensions = useWindowDimensions();

    /*   Used to calculate how many testimonials to display.   */
    const renderCarouselItems = (proof) => {
        const bucket = [];
        let chunkSize = 1;

        if (windowDimensions.width > 768) {
            chunkSize = 2;
        }
        if (windowDimensions.width > 1399) {
            chunkSize = 3;
        }

        for (let i = 0; i < proof.length; i += chunkSize) {
            bucket.push(proof.slice(i, i + chunkSize));
        }

        return bucket.map((item, index) => (
            <div key={`proof-container-${index}`} className={"proofs-container"}>
                {item.map((p, i) => <SocialProof key={`proof-${i}`} {...p} />)}
            </div>
        ));
    };

    const gameDetailsSocialProof = (proof) => {
        if (!proof?.length) {
            return null;
        }

        const slides = renderCarouselItems(proof);

        return (
            <div className={"container"}>
                <div className={"game-details-social-proof"}>
                    <Carousel
                        showStatus={false}
                        infiniteLoop={true}
                        showArrows={false}
                        showThumbs={false}
                        showIndicators={slides.length > 1}
                        swipeScrollTolerance={32}
                    >
                        {slides}
                    </Carousel>
                </div>
            </div>
        );
    };

   return (
       <>
           {gameDetailsSocialProof(props.proof)}
       </>
   )
};

export default GameDetailsSocialProofCarousel;
