import classNames from "classnames";
import React, {ReactNode, useState} from "react";
import {ReactComponent as ArrowIcon} from "../../svgs/arrow-right.svg";
import {Container} from "reactstrap";
import AnimateHeight from "react-animate-height";

export interface AboutDescription {
	subtitle?: string;
	description?: string;
	list?: string[];
}

export interface IProps {
	title?: string;
	showMore: boolean;
	content: React.ElementType;
}

const GameDetailsAbout: React.FC<IProps> = (props) => {

	const [aboutExpanded, setAboutExpanded] = useState(false);

	/*
	* The sectionElement function displays the subtitle, description, and list.
	*/
	function sectionElement(item: AboutDescription, index: number): ReactNode {
		return (
			<React.Fragment key={`game-details-section-element-${index}`}>
				{item.subtitle && <p className="game-details-about_content_subtitle">{item.subtitle}</p>}
				{item.description && <p className="game-details-about_content_description">{item.description}</p>}
				{item.list && <ul className="game-details-about_content_description">{item.list.map(listElement)}</ul>}
			</React.Fragment>
		);
	}

	function listElement(item: string, index: number): ReactNode {
		return (
			<li key={`game-details-section-element-${index}`}>{item}</li>
		);
	}

	/*
	 * Toggle the about section state from "Expanded" to "Collapsed."
	 */
	function onClickHandler(): void {
		setAboutExpanded(!aboutExpanded);
	}

	const AboutContentComponent = props.content || null;

	if (!AboutContentComponent) {
		return null;
	}

	return (
		<Container className="game-details-about">
			<article className="game-details-about_content">
				{props.title && (
					<h3 className="game-details-about_content_title">{props.title}</h3>
				)}
				<AnimateHeight
					duration={1000}
					height={aboutExpanded ? "auto" : 420}
				>
				<AboutContentComponent/>

				</AnimateHeight>
				<div
					className={classNames({
						"game-details-about_content_overlay": aboutExpanded === false,
					})}
				/>
			</article>

			{props.showMore &&
                <div className="anchor-link">
                    <div className="anchor-text">
					<span
                        className="anchor-text_button"
                        onClick={onClickHandler}>{aboutExpanded ? "Show Less" : "Show More"}
					</span>
                        <span
                            className={classNames("anchor-caret", {
								"anchor-caret": aboutExpanded === false,
								"anchor-caret_reversed": aboutExpanded === true,
							})}
                        >
						<ArrowIcon/>
					</span>
                    </div>
                </div>
			}
		</Container>
	);
};

export default GameDetailsAbout;