import { Asset, GamesApi } from "client/marketplace";
import { GameSlug } from "./hardcoded";

/**
 * Returns the game's carousel assets from the backend or the default assets if non are found
 * @param gameSlug The slug for the game
 * @param defaultCarouselAssets An array of Assets to use in case no assets are returned
 * @returns an array of assets from the backend or the default array
 */
export const getGameCarouselAssets = async (gameSlug: GameSlug, defaultCarouselAssets: Array<Asset>) => {
    const gameInfo = await new GamesApi().getGameBySlug({ slug: gameSlug });
    return gameInfo?.carouselAssets?.length
        // if there are carousel assets for this game on the backend, use them
        ? gameInfo.carouselAssets.sort((a, b) => a.index - b.index).map(({ asset }) => (asset))
        // otherise fallback to the hardcoded version
        : defaultCarouselAssets;
};
