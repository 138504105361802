"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradingCardAbilityToJSON = exports.TradingCardAbilityFromJSONTyped = exports.TradingCardAbilityFromJSON = exports.TradingCardAbility = void 0;
/**
 *
 * @export
 */
exports.TradingCardAbility = {
    one: 'one',
    two: 'two',
    three: 'three'
};
function TradingCardAbilityFromJSON(json) {
    return TradingCardAbilityFromJSONTyped(json, false);
}
exports.TradingCardAbilityFromJSON = TradingCardAbilityFromJSON;
function TradingCardAbilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TradingCardAbilityFromJSONTyped = TradingCardAbilityFromJSONTyped;
function TradingCardAbilityToJSON(value) {
    return value;
}
exports.TradingCardAbilityToJSON = TradingCardAbilityToJSON;
