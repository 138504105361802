"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortOrderToJSON = exports.SortOrderFromJSONTyped = exports.SortOrderFromJSON = exports.SortOrder = void 0;
/**
 *
 * @export
 */
exports.SortOrder = {
    asc: 'asc',
    desc: 'desc'
};
function SortOrderFromJSON(json) {
    return SortOrderFromJSONTyped(json, false);
}
exports.SortOrderFromJSON = SortOrderFromJSON;
function SortOrderFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SortOrderFromJSONTyped = SortOrderFromJSONTyped;
function SortOrderToJSON(value) {
    return value;
}
exports.SortOrderToJSON = SortOrderToJSON;
