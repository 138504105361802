import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useMediaQuery} from "react-responsive";
import {Token, User} from "client/cs";
import {Asset} from "client/marketplace";
import {useDispatch, useSelector} from "react-redux";
import {Carousel} from "react-responsive-carousel";
import {IStore} from "../redux/defaultStore";
import {useWindowDimensions} from "../utils/hooks/useWindowDimensions";
import {getGameCarouselAssets} from "../utils/getGameCarouselAssets";
import {decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import hardcodedGameDetails, {GameSlug} from "../utils/hardcoded";
import GameDetailsCarousel from "../components/gameDetails/GameDetailsCarousel";
import GameDetailsInfoCard from "../components/gameDetails/GameDetailsInfoCard";
import GameDetailsTitleBar from "../components/gameDetails/GameDetailsTitleBar";
import GameDetailsAbout from "../components/gameDetails/GameDetailsAbout";
import GameDetailsInGameContent from "../components/gameDetails/GameDetailsInGameContent";
import GameDetailsMoreGames from "../components/gameDetails/GameDetailsMoreGames";
import SystemRequirements from "../components/gameDetails/SystemRequirements";
import {SocialProof} from "../components/SocialProof";
import variables from "../style/variables";
import "react-responsive-carousel/lib/styles/carousel.min.css";// requires a loader
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";

interface IProps {
    gameSlug: GameSlug;
    dispatch?: any;
    fullToken?: Token;
    currentUser?: User;
}

const GameDetailsPage: React.FC<IProps> = (props) => {
    const windowDimensions = useWindowDimensions();

    const isMobile = useMediaQuery({query: `(max-width: ${variables.gameDetailsCarouselMobileBreakpoint})`});
    const isTablet = useMediaQuery({query: "(min-width: 992px) and (max-width: 1399px)"});
    const isDesktop = useMediaQuery({query: "(min-width: 1400px)"});

    const [isLoadingGameCarouselAssets, setIsLoadingGameCarouselAssets] = useState(true);
    const [gameCarouselAssets, setGameCarouselAssets] = useState<Array<Asset>>([]);

    const gameDetails = hardcodedGameDetails[props.gameSlug];

    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        const getGameInfo = async (gameSlug: GameSlug) => {
            try {
                setIsLoadingGameCarouselAssets(true);
                dispatch(incrementLoading());

                const carouselAssets = await getGameCarouselAssets(gameSlug, gameDetails.carouselAssets);

                setGameCarouselAssets(carouselAssets);
            } catch (e) {
                console.error(e);

                // if anything goes wrong on the asset call, just fallback to the hardcoded version
                setGameCarouselAssets(gameDetails.carouselAssets);
            } finally {
                dispatch(decrementLoading());
                setIsLoadingGameCarouselAssets(false);
            }
        };

        getGameInfo(props.gameSlug);
    }, [props.gameSlug]);

    const gameDetailsCarousel = (carouselAssets: Array<Asset>) => (
        <GameDetailsCarousel
            assets={carouselAssets}
            isMobile={isMobile}
        />
    );

    // Identifies if the game has Game Content and adds a button to scroll the user down to the section.
    const getCTAButton = (onClick: () => void) => (
        gameDetails.gameContent
            ? (
                <ExPopulusButton
                    color={fullToken ? "pink" : "pink-outline"}
                    className="game-details-title-bar_container_buttons_style"
                    onClick={onClick}
                >
                    Get Cards
                </ExPopulusButton>
            )
            : null
    );

    const getTitleBar = () => (
        <GameDetailsTitleBar
            id="game-details-title-bar"
            title={gameDetails.title}
            socialIcons={gameDetails.socialIcons}
            playFree={gameDetails.playFree}
        />
    );

    const gameDetailsAbout = gameDetails.about
        ? (<GameDetailsAbout {...gameDetails.about}/>)
        : null;

    const gameDetailsInfo = (
        <GameDetailsInfoCard {...gameDetails.details} />
    );

    const gameDetailsInGameContent = gameDetails.gameContent
        ? (<GameDetailsInGameContent gameContent={gameDetails.gameContent} id={"get-cards"}
                                     currentUser={currentUser}/>)
        : null;

    const gameDetailsMoreGames = gameDetails.moreGames.length
        ? (<GameDetailsMoreGames games={gameDetails.moreGames.map((gameSlug) => hardcodedGameDetails[gameSlug])}/>)
        : null;

    const renderCarouselItems = (proof) => {
        const bucket = [];
        let chunkSize = 1;

        if (windowDimensions.width > 991) {
            chunkSize = 2;
        }
        if (windowDimensions.width > 1399) {
            chunkSize = 3;
        }

        for (let i = 0; i < proof.length; i += chunkSize) {
            bucket.push(proof.slice(i, i + chunkSize));
        }

        return bucket.map((item, index) => (
            <div key={`proof-container-${index}`} className={"proofs-container"}>
                {item.map((p, i) => <SocialProof key={`proof-${i}`} {...p} />)}
            </div>
        ));
    };

    const gameDetailsSocialProof = (proof) => {
        if (!proof?.length) {
            return null;
        }

        const slides = renderCarouselItems(proof);

        return (
            <div className={"container"}>
                <div className={"game-details-social-proof"}>
                    <Carousel
                        showStatus={false}
                        infiniteLoop={true}
                        showArrows={false}
                        showThumbs={false}
                        showIndicators={slides.length > 1}
                    >
                        {slides}
                    </Carousel>
                </div>
            </div>
        );
    };

    const systemRequirements = gameDetails.systemRequirements
        ? (<SystemRequirements requirements={gameDetails.systemRequirements}/>)
        : null;

    function scrollToSection(sectionName: string): () => void {
        return () => {
            const section = document.getElementById(sectionName);
            if (section) {
                window.scrollTo(0, section.offsetTop);
            }
        }
    }

    if (isLoadingGameCarouselAssets) {
        return null;
    }

    return (
        <React.Fragment>
            {
                gameDetails.pageTitle && (
                    <Helmet>
                        <title>{gameDetails.pageTitle}</title>
                    </Helmet>
                )
            }

            <section className="game-details-page">
                <div className="game-details-page_content">

                    {isMobile && (
                        <div className="game-details-page_content_mobile">
                            <div className="container">
                                {gameDetailsCarousel(gameCarouselAssets)}
                            </div>
                            {getTitleBar()}

                            {gameDetailsSocialProof(gameDetails.social?.proof)}

                            <div className="container">
                                {gameDetailsInfo}
                                {gameDetailsAbout}
                                {gameDetailsInGameContent}
                                {systemRequirements}
                                {gameDetailsMoreGames}
                            </div>
                        </div>
                    )}

                    {isTablet && (
                        <div className="game-details-page_content_tablet">
                            <div className="container">
                                {gameDetailsCarousel(gameCarouselAssets)}
                            </div>
                            {getTitleBar()}

                            {gameDetailsSocialProof(gameDetails.social?.proof)}

                            <div className="container">
                                <div className="game-details-page_content_tablet_bottom">
                                    <div className="game-details-page_content_tablet_bottom_left">
                                        {gameDetailsAbout}
                                    </div>

                                    <div className="game-details-page_content_tablet_bottom_right">
                                        {gameDetailsInfo}
                                    </div>
                                </div>
                                <div>
                                    {gameDetailsInGameContent}
                                    {systemRequirements}
                                    {gameDetailsMoreGames}
                                </div>
                            </div>
                        </div>
                    )}

                    {isDesktop && (
                        <div className="game-details-page_content_desktop">
                            <div className="container">
                                {gameDetailsCarousel(gameCarouselAssets)}
                            </div>
                            {getTitleBar()}

                            {gameDetailsSocialProof(gameDetails.social?.proof)}

                            <div className="container">
                                <div className="game-details-page_content_desktop_bottom">
                                    <div className="game-details-page_content_desktop_bottom_left">
                                        {gameDetailsAbout}
                                        {gameDetailsInGameContent}
                                        {systemRequirements}
                                        {gameDetailsMoreGames}
                                    </div>

                                    <div className="game-details-page_content_desktop_bottom_right">
                                        {gameDetailsInfo}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </React.Fragment>
    );
};

export default GameDetailsPage;
