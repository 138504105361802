import React, {useState} from "react";
import {FaChevronDown} from "react-icons/all";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";

export interface IHomepageFAQ {
    question: string;
    answer: string | JSX.Element;
}

interface IProps {
    question: IHomepageFAQ;
}

const FAQElement: React.FC<IProps> = (props) => {

    const [isOpen, setIsOpen] = useState(false)

    function toggleIsOpen(): void {
        setIsOpen(!isOpen)
    }

    return (
        <div
            className="faq-container"
            onClick={toggleIsOpen}
        >
            <div className="faq-question">
                <div
                    className={classNames("text", {"open": isOpen})}>{props.question.question}</div>
                <div className={classNames("icon", {"open": isOpen})}><FaChevronDown/></div>
            </div>
            <div className={classNames("faq-answer", {"open": isOpen})}>
                <AnimateHeight
                    duration={200}
                    height={isOpen ? "auto" : 0}
                >
                    {props.question.answer}
                </AnimateHeight>
            </div>
        </div>
    );
};

export {FAQElement};
