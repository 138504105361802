import React from "react";
import {Asset} from "client/marketplace";
import {fileIsImage} from "../utils/fileTypeChecks";
import classNames from "classnames";
import MediaAssetViewer from "./mediaAssetViewer/MediaAssetViewer";

export interface IGalleryItem {
    src: string;
    alt?: string;
}

export interface IProps {
    asset: Asset;
    selected: boolean;
    onClick: () => void;
}

const GalleryItem: React.FC<IProps> = (props) => {

    return (
        <div
            className={classNames("gallery-item-container", {"selected": props.selected})}
            onClick={props.onClick}
        >
            <MediaAssetViewer
                asset={props.asset}
                contentType={fileIsImage(props.asset) ? "image" : "video"}
                imageAltText={props.asset?.name || "Preview"}
                videoAutoPlay={true}
                videoControls={false}
                videoLoop={true}
                videoMuted={true}
                videoPlaysInline={true}
            />
        </div>
    );
}

export default React.memo(GalleryItem, () => true);
