import React, {ReactNode} from "react";
import {useLocation} from "react-router-dom";
import Sidebar from "react-sidebar";
import ExPopulusSideBarInner from "./ExPopulusSideBarInner";
import {IProps as IExPopulusSideBarInnerProps} from "./ExPopulusSideBarInner";
import {baseHeaderLinks, mainSiteHeaderHeight} from "./ExPopulusHeader";

type SidebarManagerSideBarInnerProps = Omit<IExPopulusSideBarInnerProps, "onClose">;

interface IProps {
	sideBarOpen: boolean;
	toggleSideBar: () => void;
	children?: ReactNode;
	hiddenPages?: string[]; // Array of routes to not show the sidebar on
	sideBarInnerProps?: SidebarManagerSideBarInnerProps;
}

const ExPopulusSideBarManager: React.FC<IProps> = (props: IProps) => {

	let location = useLocation();

	/**
	 * Tells implementing parent to toggle the sidebar, where the parent should be communicating with that
	 * project's redux store.
	 *
	 */
	function toggleSideBarHelper(e?): void {
		if (e) {
			e.preventDefault();
		}

		props.toggleSideBar();
	}

	if (props.hiddenPages?.includes(location.pathname)) {
		return (<React.Fragment>{props.children}</React.Fragment>);
	}

	/**
	 * Help inject the functionality for closing the sidebar when interacting with the links.
	 * As of now, ignores sub-menus, as for initial release they're all external links.
	 *
	 * @param _sideBarInnerProps
	 */
	function injectCloseHelperToPropFunctions(_sideBarInnerProps: SidebarManagerSideBarInnerProps): SidebarManagerSideBarInnerProps {
		return {
			..._sideBarInnerProps,
			links: (_sideBarInnerProps.links && Array.isArray(_sideBarInnerProps.links) && _sideBarInnerProps.links.length > 0) ? _sideBarInnerProps.links.map((_link) => {
				return {
					..._link,
					to: _link.subMenu ? undefined : _link.to,
					onClick: () => {
						toggleSideBarHelper();
						if (_link.onClick) {
							_link.onClick();
						}
					}
				}
			}) : [],
		};
	}

	return (
		<Sidebar
			sidebar={(
				<ExPopulusSideBarInner
					onClose={toggleSideBarHelper}
					{...injectCloseHelperToPropFunctions(props.sideBarInnerProps)}
				/>
			)}
			open={props.sideBarOpen}
			docked={false}
			onSetOpen={toggleSideBarHelper}
			styles={{
				root: {
					// Position above overlays or other high z-index elements your app might use
					zIndex: 1000,
					// Detach from right and bottom screen edges as it blocks underlying content
					// This also has the effect of shrinking the component to a width and height of 0
					right: "auto",
					bottom: "auto",
					// Allow child sidebar elements to render now that element has collapsed
					overflow: "visible"
				},
				content: {
					// Detach from right and bottom screen edges as it blocks underlying content (collapses element)
					left: "auto",
					bottom: "auto",
					// The dragHandle is inside content element for some reason.
					// Allow it to render now that the parent is collapsed.
					overflow: "visible",
				},
				sidebar: {
					// Make sidebar fixed, like dragHandle is by default
					position: "fixed",
				},
				overlay: {
					// Enable/disable overlay interactivity based on open/closed state
					// pointer-events browser support: IE11+
					pointerEvents: props.sideBarOpen ? "auto" : "none"
				}
			}}
		/>
	);
};

ExPopulusSideBarManager.defaultProps = {
	hiddenPages: ["/404"],
	sideBarInnerProps: {
		implementingSiteURL: undefined,
		height: mainSiteHeaderHeight,
		links: baseHeaderLinks,
		showTwitter: true,
		showMedium: true,
		showDiscord: true,
		accountButtonConfig: undefined,
	} as IExPopulusSideBarInnerProps,
};

export {ExPopulusSideBarManager};
