import React from "react";
import {Asset} from "client/marketplace";
import {useMediaQuery} from "react-responsive";
import {Cloudinary} from "@cloudinary/url-gen";
import {AdvancedImage} from "@cloudinary/react";
import {fileIsImage, IFrontendFileType, isFileWithSRC} from "../../utils/fileTypeChecks";
import {FileWithSRC} from "../../utils/renderImageHelper";

const cloudName = "ex-populus";

interface IElementWidth {
    desktopWidth: number;
    mobileWidth: number;
}

interface IProps {
    asset: IFrontendFileType | URL,
    contentType?: "image" | "video",
    imageAltText?: string;
    videoPosterUrl?: string | null;
    videoControls?: boolean;
    videoAutoPlay?: boolean;
    videoMuted?: boolean;
    videoLoop?: boolean;
    videoPlaysInline?: boolean;
    videoOnEnded?: () => void;
    elementWidth?: IElementWidth;
    elementRef?: any;
}

export function buildAssetUrl(publicId: string, actions: Array<string>, contentType: "image" | "video") {
    const cld = new Cloudinary({cloud: {cloudName}});

    const cloudinaryAsset = contentType === "image"
        ? cld.image(publicId)
        : cld.video(publicId)

    actions?.forEach((action) => {
        cloudinaryAsset.addAction(action);
    });

    return cloudinaryAsset.toURL();
}

const hash = (str: string) => Array
    .from(str)
    .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);

function defaultImageElement(source: string, imageAltText: string) {
    return (
        <img
            src={source}
            alt={imageAltText || ""}
        />
    );
}

function defaultVideoElement(
    source: string,
    videoAutoPlay: boolean,
    videoControls: boolean,
    videoMuted: boolean,
    videoLoop: boolean,
    videoPlaysInline: boolean,
    videoOnEnded,
    elementRef: any,
) {
    return (
        <video
            key={hash(source)}
            src={`${source}#t=1`}
            autoPlay={videoAutoPlay}
            controls={videoControls}
            muted={videoMuted}
            loop={videoLoop}
            playsInline={videoPlaysInline}
            onEnded={videoOnEnded}
            ref={elementRef || undefined}
        />
    );
}

function renderNonCloudinaryAsset(
    asset: FileWithSRC,
    imageAltText: string,
    videoAutoPlay: boolean,
    videoControls: boolean,
    videoMuted: boolean,
    videoLoop: boolean,
    videoPlaysInline: boolean,
    videoOnEnded: () => void,
    elementRef: any,
) {
    return fileIsImage(asset)
        ? defaultImageElement(asset.imageSRC as string, imageAltText)
        : defaultVideoElement(asset.videoSRC as string, videoAutoPlay, videoControls, videoMuted, videoLoop, videoPlaysInline, videoOnEnded, elementRef);
}

function renderCloudinaryAsset(
    asset: Asset,
    isMobile,
    imageAltText: string,
    videoAutoPlay: boolean,
    videoControls: boolean,
    videoMuted: boolean,
    videoLoop: boolean,
    videoPlaysInline: boolean,
    videoOnEnded: () => void,
    elementWidth: IElementWidth,
    elementRef,
    videoPoster: string,
) {
    const cld = new Cloudinary({cloud: {cloudName}});
    const isImage = fileIsImage(asset);

    // handle the possibility that publicId is missing
    if (!asset.cloudinary?.publicId) {
        console.warn(`Missing Cloudinary info on asset "${asset.fileName}"; using Google bucket fallback ${asset.url}`);

        // just use the asset's google bucket url
        return fileIsImage(asset)
            ? defaultImageElement(asset.url, imageAltText)
            : defaultVideoElement(asset.url, videoAutoPlay, videoControls, videoMuted, videoLoop, videoPlaysInline, videoOnEnded, elementRef);
    }

    // determine the element's display width using provide elementWidth
    // or default values based on device
    const width = (isMobile ? elementWidth.mobileWidth : elementWidth.desktopWidth);

    // an "action" can be a pipe delimited string of separate actions
    // so flatten them all out here
    const cloudinaryActions = isMobile
        ? [`c_scale,w_${width}`].concat(asset.cloudinary.actions?.mobile).join("|").split("|")
        : [`c_scale,w_${width}`].concat(asset.cloudinary.actions?.desktop).join("|").split("|");


    // render IMAGE component
    if (isImage) {
        const cloudinaryImage = cld.image(asset.cloudinary.publicId);
        cloudinaryActions?.forEach((action) => cloudinaryImage.addAction(action));
        return (
            <AdvancedImage
                cldImg={cloudinaryImage}
                alt={imageAltText}
            />
        );
    }

    // render the VIDEO component

    // make a poster for the video
    const videoPosterUrl = videoPoster || cld.video(asset.cloudinary.publicId).addAction("f_jpg").toURL();

    // USE CLOUDINARY ADVANCED VIDEO COMPONENT
    // For some reason this reloads and restarts the videos every time the slide changes
    // const video = cld.video(asset.cloudinary.publicId);
    // cloudinaryActions.forEach((action) => video.addAction(action));
    // return (
    // 	<AdvancedVideo
    // 		cldVid={cld.video(asset.cloudinary.publicId)}
    // 		autoPlay={videoAutoPlay}
    // 		controls={videoControls}
    // 		poster={videoPosterUrl}
    // 		muted={videoMuted}
    // 		loop={videoLoop}
    // 		playsInline={videoPlaysInline}
    // 		innerRef={elementRef}
    // 	/>
    // );

    // MANUALLY BUILD VIDEO SOURCES
    // Even though this produces exactly the same html structures as the AdvancedVideo component
    // this code only loads the videos once and allows play/pause
    const sources = [
        {format: "f_webm", type: "video/webm"},
        {format: "f_mp4", type: "video/mp4"},
        {format: "f_ogv", type: "video/ogg"},
    ].map(({format, type}) => {
        const video = cld.video(asset.cloudinary.publicId);
        cloudinaryActions.concat(format).forEach((action) => video.addAction(action));
        return {video, type};
    });

    return (
        <video
            // need key so video gets re-rendered on navigation
            key={hash(asset.cloudinary.publicId)}
            poster={videoPosterUrl}
            ref={elementRef || undefined}
            autoPlay={videoAutoPlay}
            controls={videoControls}
            muted={videoMuted}
            loop={videoLoop}
            playsInline={videoPlaysInline}
            onEnded={videoOnEnded}
        >
            {sources.map(({video, type}, i) => (
                <source key={`${asset.id}-${i}`} src={`${video.toURL()}#t=1`} type={type}/>))}
        </video>
    );
}

const MediaAssetViewer: React.FC<IProps> = (props) => {
    const {
        asset,
        contentType,
        imageAltText,
        videoPosterUrl,
        videoControls,
        videoAutoPlay,
        videoMuted,
        videoLoop,
        videoPlaysInline,
        videoOnEnded,
        elementWidth,
        elementRef,
    } = props;

    const isMobile = useMediaQuery({query: "(max-width: 991px)"});

    // use URLs when they are provided
    if (asset instanceof URL) {
        return (
            <React.Fragment>
                {
                    contentType === "image"
                        ? defaultImageElement(asset.href, imageAltText)
                        : defaultVideoElement(asset.href, videoAutoPlay, videoControls, videoMuted, videoLoop, videoPlaysInline, videoOnEnded, elementRef)
                }
            </React.Fragment>
        );
    }

    // otherwise use asset/cloudinary info
    return (
        <React.Fragment>
            {
                isFileWithSRC(asset)
                    ? renderNonCloudinaryAsset(asset, imageAltText, videoAutoPlay, videoControls, videoMuted, videoLoop, videoPlaysInline, videoOnEnded, elementRef)
                    : renderCloudinaryAsset(asset, isMobile, imageAltText, videoAutoPlay, videoControls, videoMuted, videoLoop, videoPlaysInline, videoOnEnded, elementWidth, elementRef, videoPosterUrl)
            }
        </React.Fragment>
    )
}

MediaAssetViewer.defaultProps = {
    contentType: "video",
    imageAltText: "",
    videoPosterUrl: null,
    videoControls: false,
    videoAutoPlay: false,
    videoMuted: true,
    videoLoop: false,
    videoPlaysInline: true,
    // videoOnEnded: () => console.log("Default video ended!"),
    elementWidth: {
        desktopWidth: 1300,
        mobileWidth: 800,
    }, // default to larger, desktop width
    elementRef: undefined,
};

export default MediaAssetViewer;
