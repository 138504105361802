import {Listing} from "client/marketplace";

/**
 * Generates the "full" vanity url for the listing,
 * adding the creator, project, and collection names to the string.
 *
 * May be replaced in the future.
 *
 * @param listing
 */
export function generateFrontendFullVanityURL(listing: Listing): string {
	return `/${listing?.collection?.project?.creator?.name}/${listing?.collection?.project?.name}/${listing?.collection?.name}/${listing?.vanityUrl ? listing?.vanityUrl : listing?.id}`;
}
