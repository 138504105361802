"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckUserNftStatusBodyToJSON = exports.CheckUserNftStatusBodyFromJSONTyped = exports.CheckUserNftStatusBodyFromJSON = exports.instanceOfCheckUserNftStatusBody = void 0;
var runtime_1 = require("../runtime");
var DagDropType_1 = require("./DagDropType");
var NftType_1 = require("./NftType");
/**
 * Check if a given object implements the CheckUserNftStatusBody interface.
 */
function instanceOfCheckUserNftStatusBody(value) {
    var isInstance = true;
    isInstance = isInstance && "nftType" in value;
    isInstance = isInstance && "walletAddress" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
exports.instanceOfCheckUserNftStatusBody = instanceOfCheckUserNftStatusBody;
function CheckUserNftStatusBodyFromJSON(json) {
    return CheckUserNftStatusBodyFromJSONTyped(json, false);
}
exports.CheckUserNftStatusBodyFromJSON = CheckUserNftStatusBodyFromJSON;
function CheckUserNftStatusBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nftType': (0, NftType_1.NftTypeFromJSON)(json['nftType']),
        'dagDropType': !(0, runtime_1.exists)(json, 'dagDropType') ? undefined : (0, DagDropType_1.DagDropTypeFromJSON)(json['dagDropType']),
        'walletAddress': json['walletAddress'],
        'whitelistNftUuid': !(0, runtime_1.exists)(json, 'whitelistNftUuid') ? undefined : json['whitelistNftUuid'],
        'quantity': json['quantity'],
    };
}
exports.CheckUserNftStatusBodyFromJSONTyped = CheckUserNftStatusBodyFromJSONTyped;
function CheckUserNftStatusBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nftType': (0, NftType_1.NftTypeToJSON)(value.nftType),
        'dagDropType': (0, DagDropType_1.DagDropTypeToJSON)(value.dagDropType),
        'walletAddress': value.walletAddress,
        'whitelistNftUuid': value.whitelistNftUuid,
        'quantity': value.quantity,
    };
}
exports.CheckUserNftStatusBodyToJSON = CheckUserNftStatusBodyToJSON;
