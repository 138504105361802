"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialCodeToJSON = exports.DialCodeFromJSONTyped = exports.DialCodeFromJSON = exports.instanceOfDialCode = void 0;
/**
 * Check if a given object implements the DialCode interface.
 */
function instanceOfDialCode(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "dialCode" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "flag" in value;
    return isInstance;
}
exports.instanceOfDialCode = instanceOfDialCode;
function DialCodeFromJSON(json) {
    return DialCodeFromJSONTyped(json, false);
}
exports.DialCodeFromJSON = DialCodeFromJSON;
function DialCodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'dialCode': json['dialCode'],
        'code': json['code'],
        'flag': json['flag'],
    };
}
exports.DialCodeFromJSONTyped = DialCodeFromJSONTyped;
function DialCodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'dialCode': value.dialCode,
        'code': value.code,
        'flag': value.flag,
    };
}
exports.DialCodeToJSON = DialCodeToJSON;
