"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIErrorToJSON = exports.APIErrorFromJSONTyped = exports.APIErrorFromJSON = exports.instanceOfAPIError = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the APIError interface.
 */
function instanceOfAPIError(value) {
    var isInstance = true;
    isInstance = isInstance && "message" in value;
    return isInstance;
}
exports.instanceOfAPIError = instanceOfAPIError;
function APIErrorFromJSON(json) {
    return APIErrorFromJSONTyped(json, false);
}
exports.APIErrorFromJSON = APIErrorFromJSON;
function APIErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'message': json['message'],
        'date': !(0, runtime_1.exists)(json, 'date') ? undefined : json['date'],
        'httpStatus': !(0, runtime_1.exists)(json, 'httpStatus') ? undefined : json['httpStatus'],
        'httpMethod': !(0, runtime_1.exists)(json, 'httpMethod') ? undefined : json['httpMethod'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'errors': !(0, runtime_1.exists)(json, 'errors') ? undefined : (json['errors'].map(APIErrorFromJSON)),
    };
}
exports.APIErrorFromJSONTyped = APIErrorFromJSONTyped;
function APIErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'message': value.message,
        'date': value.date,
        'httpStatus': value.httpStatus,
        'httpMethod': value.httpMethod,
        'path': value.path,
        'errors': value.errors === undefined ? undefined : (value.errors.map(APIErrorToJSON)),
    };
}
exports.APIErrorToJSON = APIErrorToJSON;
