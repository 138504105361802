"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftStatusToJSON = exports.NftStatusFromJSONTyped = exports.NftStatusFromJSON = exports.instanceOfNftStatus = void 0;
/**
 * Check if a given object implements the NftStatus interface.
 */
function instanceOfNftStatus(value) {
    var isInstance = true;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
exports.instanceOfNftStatus = instanceOfNftStatus;
function NftStatusFromJSON(json) {
    return NftStatusFromJSONTyped(json, false);
}
exports.NftStatusFromJSON = NftStatusFromJSON;
function NftStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': json['status'],
    };
}
exports.NftStatusFromJSONTyped = NftStatusFromJSONTyped;
function NftStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
    };
}
exports.NftStatusToJSON = NftStatusToJSON;
