import React, { ChangeEvent, useState } from "react";
import { Input } from "reactstrap";
import { AsYouType, CountryCode } from "libphonenumber-js";

interface IProps {
	updateFormField: Function;
	selectedCountryCode: CountryCode;
	onFocus: React.FocusEventHandler<HTMLInputElement>;
	onBlur: React.FocusEventHandler<HTMLInputElement>;
}

const PhoneNumberFormatted: React.FC<IProps> = (props) => {
	const [phoneNumber, setPhoneNumber] = useState("");

	const {
		updateFormField,
		selectedCountryCode,
		onFocus,
		onBlur,
	} = props;

	const formatPhoneNumber = (rawPhoneNumber, countryCode) => rawPhoneNumber?.length > 4
		? new AsYouType(countryCode).input(rawPhoneNumber)
		: rawPhoneNumber;

	const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>, countryCode: CountryCode) => {
		const justNumbers = e.target.value.replace(/[^\d]/g, "");
		setPhoneNumber(formatPhoneNumber(justNumbers, countryCode));
		updateFormField(e.target.value);
	};

	return (
		<div style={{ flex: "1" }}>
			<Input
				placeholder="Phone (Optional)"
				value={formatPhoneNumber(phoneNumber?.replace(/[^\d]/g, ""), selectedCountryCode)}
				onChange={(e) => onPhoneNumberChange(e, selectedCountryCode)}
				onFocus={onFocus}
				onBlur={onBlur}
				style={{ borderWidth: "0", backgroundColor: "transparent" }}
			/>
		</div>
	);
}

export default PhoneNumberFormatted;