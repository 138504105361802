import { useEffect } from "react";
var gameSightAPIKey = process.env.REACT_APP_XP_MARKETPLACE_GAMESIGHT_WEB_SDK_API_KEY;
var InitGamesight = function (url) {
    useEffect(function () {
        var script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.innerHTML = "(function(G,m,s,g,h,t){G.gsght=G.gsght||function(){\n(G.gsght.q=G.gsght.q||[]).push(arguments)},G.gsght.q=[],G.gsght.h=1*new Date();\nh=m.createElement(s),t=m.getElementsByTagName(s)[0];h.async=1;h.src=g;t.parentNode.insertBefore(h,t)\n})(window,document,'script','".concat(url, "');\n\ngsght('init', '").concat(gameSightAPIKey, "')");
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, [url]);
};
export default InitGamesight;
