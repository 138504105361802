"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVMChainToJSON = exports.EVMChainFromJSONTyped = exports.EVMChainFromJSON = exports.EVMChain = void 0;
/**
 *
 * @export
 */
exports.EVMChain = {
    ETHEREUM_MAINNET: 'ETHEREUM_MAINNET',
    GOERLI: 'GOERLI',
    RINKEBY: 'RINKEBY',
    ROPSTEN: 'ROPSTEN',
    POLYGON: 'POLYGON',
    POLYGON_MUMBAI: 'POLYGON_MUMBAI',
    BINANCE_MAINNET: 'BINANCE_MAINNET',
    BINANCE_TESTNET: 'BINANCE_TESTNET',
    ARBITRUM_NOVA: 'ARBITRUM_NOVA',
    XAI_TESTNET: 'XAI_TESTNET',
    ARBITRUM_GOERLI: 'ARBITRUM_GOERLI'
};
function EVMChainFromJSON(json) {
    return EVMChainFromJSONTyped(json, false);
}
exports.EVMChainFromJSON = EVMChainFromJSON;
function EVMChainFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.EVMChainFromJSONTyped = EVMChainFromJSONTyped;
function EVMChainToJSON(value) {
    return value;
}
exports.EVMChainToJSON = EVMChainToJSON;
