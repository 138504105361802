import classNames from "classnames";
import { DialCode } from "client/marketplace";
import { CountryCode } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import CountryCodeDropDown from "./CountryCodeDropDown";
import CountryCodeLabel from "./CountryCodeLabel";
import PhoneNumberFormatted from "./PhoneNumberFormatted";

interface IProps {
	updateFormField: Function;
	dialCodeOnChange: Function;
	dialCodesList: Array<DialCode>;
	defaultCountryCode?: CountryCode;
}

const PhoneNumberWithCountryCode: React.FC<IProps> = (props) => {
		const {
		updateFormField,
		dialCodeOnChange,
		dialCodesList,
		defaultCountryCode,
	} = props;

	const [dialCode, setDialCode] = useState<DialCode>(undefined);
	const [showCountryCodeDropDown, setShowCountryCodeDropDown] = useState(false);
	const [isControlActive, setIsControlActive] = useState(false);

	const activateControl = () => setIsControlActive(true);
	const deactivateControl = () => setIsControlActive(false);

	const closeDropDown = () => {
		setShowCountryCodeDropDown(false);
		deactivateControl();
	}

	useEffect(() => {
		if (showCountryCodeDropDown) {
			document.addEventListener("keydown", closeDropDown);
			document.body.addEventListener("click", closeDropDown);
		} else {
			document.removeEventListener("keydown", closeDropDown);
			document.body.removeEventListener("click", closeDropDown);
		}
		
		return () => {
			document.removeEventListener("keydown", closeDropDown);
			document.body.removeEventListener("click", closeDropDown);
		};
	}, [closeDropDown, showCountryCodeDropDown]);

	useEffect(() => {
		if (!dialCode) {
			const dc = dialCodesList?.find(({ code }) => code.toLowerCase() === defaultCountryCode.toLowerCase());
			setDialCode(dc);
			dialCodeOnChange(dc);
		}
	}, [dialCode, dialCodesList])

	const onCountryCodeLabelClick = () => {
		setShowCountryCodeDropDown(!showCountryCodeDropDown);
		setIsControlActive(!showCountryCodeDropDown);
	};

	const countryCodeOnChange = (dialCode: DialCode) => {
		setDialCode(dialCode);
		dialCodeOnChange(dialCode);
		closeDropDown();
	}

	const selectedCountryCode = dialCode?.code as CountryCode;

	return (
		<>
			<div className={classNames("phone-number-container", { active: isControlActive })}>
				<CountryCodeLabel onClick={onCountryCodeLabelClick} countryCode={selectedCountryCode} dialCodeNumber={dialCode?.dialCode} />
				<PhoneNumberFormatted updateFormField={updateFormField} selectedCountryCode={selectedCountryCode} onFocus={activateControl} onBlur={deactivateControl} />
			</div>
			<CountryCodeDropDown show={showCountryCodeDropDown} onChange={countryCodeOnChange} dialCodesList={dialCodesList} defaultDialCode={dialCode} />
		</>
	);
}

PhoneNumberWithCountryCode.defaultProps = {
	defaultCountryCode: "US",
};

export default PhoneNumberWithCountryCode;