import React, {useMemo, useState} from "react";
import {GAMESIGHT_EVENTS} from "shared";
import {BsWindows} from "react-icons/all";
import {osName} from "react-device-detect";
import {User} from "client/cs";
import getGameDownloadURL from "../utils/getGameDownloadURL";
import FinalFormWindowDownloadManager from "./modals/FinalFormWindowDownload/FinalFormWindowDownloadManager";
import {sendEvent} from "../utils/event-tracking";
import {ExPopulusButton, ExPopulusButtonColor} from "./buttons/ExPopulusButton";

interface IProps {
  sendEvents?: boolean;
  leftIcon?: boolean,
  buttonColor?: ExPopulusButtonColor;
  forceEmailLink?: boolean;
  directDownloadUrl?: URL;
  className?: string;
  onClickCallback?: () => void;
  user?: User;
  buttonText?: string;
}

const PlayFreeButton: React.FC<IProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const downloadURLFromOs = useMemo(() => getGameDownloadURL(osName), [osName]);
  const downloadURL = props.forceEmailLink
    ? undefined
    : props.directDownloadUrl || downloadURLFromOs;

  const toggleModalOpen = () => setIsModalOpen(!isModalOpen);

  const onClick = () => {
    // no download url was provided or found for os
    // or we just want to force the send link email
    if (!downloadURL || props.forceEmailLink) {
      if (props.sendEvents) {
        sendEvent({ eventName: GAMESIGHT_EVENTS.FF_WEB_DOWNLOAD_LINK, user: props.user });
      }

      return setIsModalOpen(true);
    }

    // since we're sending the download also track the marketing events
    if (props.sendEvents) {
      sendEvent({ eventName: GAMESIGHT_EVENTS.FF_WEB_LAUNCHER_DOWNLOAD, user: props.user });
    }

    // run any provided onclick callback function
    props.onClickCallback();
  }

  return (
    <React.Fragment>
      <ExPopulusButton
        color={props.buttonColor}
        className={props.className}
        href={downloadURL ? downloadURL.href : undefined}
        leftIcon={props.leftIcon ? BsWindows : undefined}
        onClick={onClick}
      >
        {props.buttonText ? props.buttonText : "Download & Stake"}
      </ExPopulusButton>

      <FinalFormWindowDownloadManager
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        toggle={toggleModalOpen}
      />
    </React.Fragment>
  );
}

PlayFreeButton.defaultProps = {
  sendEvents: true,
  leftIcon: true,
  buttonColor: "white-on-pink",
  forceEmailLink: false,
  directDownloadUrl: undefined,
  className: "",
  user: undefined,
  onClickCallback: () => {},
};

export default PlayFreeButton;
