"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddGeoBanBodyToJSON = exports.AddGeoBanBodyFromJSONTyped = exports.AddGeoBanBodyFromJSON = exports.instanceOfAddGeoBanBody = void 0;
var runtime_1 = require("../runtime");
var GeoBanType_1 = require("./GeoBanType");
var Location_1 = require("./Location");
/**
 * Check if a given object implements the AddGeoBanBody interface.
 */
function instanceOfAddGeoBanBody(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "country" in value;
    return isInstance;
}
exports.instanceOfAddGeoBanBody = instanceOfAddGeoBanBody;
function AddGeoBanBodyFromJSON(json) {
    return AddGeoBanBodyFromJSONTyped(json, false);
}
exports.AddGeoBanBodyFromJSON = AddGeoBanBodyFromJSON;
function AddGeoBanBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, GeoBanType_1.GeoBanTypeFromJSON)(json['type']),
        'country': (0, Location_1.LocationFromJSON)(json['country']),
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : (0, Location_1.LocationFromJSON)(json['state']),
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.AddGeoBanBodyFromJSONTyped = AddGeoBanBodyFromJSONTyped;
function AddGeoBanBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, GeoBanType_1.GeoBanTypeToJSON)(value.type),
        'country': (0, Location_1.LocationToJSON)(value.country),
        'state': (0, Location_1.LocationToJSON)(value.state),
        'message': value.message,
    };
}
exports.AddGeoBanBodyToJSON = AddGeoBanBodyToJSON;
