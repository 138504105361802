import React from "react";
import {Input} from "reactstrap";
import {MdModeEditOutline} from "react-icons/all";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

interface IProps {
    email: string;
    className?: string;

    onEditPassword(): void;
    onEditEmailPreferences(): void;
    onLogoutClick(e?: React.MouseEvent): void;
}

export const AccountDetails: React.FC<IProps> = (props) => {
    const {email, onEditPassword, onEditEmailPreferences, onLogoutClick} = props;

    const formField = (label, field, onButtonClick) => (
        <div className={"form-field"}>
            <div className={"label"}>{label}</div>
            <div style={{display: "flex"}}>
                {field}

                <ExPopulusButton
                    color="pink"
                    leftIcon={MdModeEditOutline}
                    onClick={onButtonClick}
                    className="edit-button"
                />
            </div>
        </div>
    )

    return (
        <div className="account-details-container">
            <div className={"header"}>Account</div>
            <div className={"section"}>
                <div className={"title"}>Account Info</div>
                {formField(
                    "Email",
                    <Input
                        type="email"
                        placeholder="Email address"
                        value={email}
                    />,
                    () => {
                    }
                )}

                {formField(
                    "Password",
                    <Input
                        type="password"
                        placeholder="Password"
                        value={"************"}
                    />,
                    onEditPassword,
                )}
            </div>

            <div className={"section account-details-container_bottom"}>
                <div className={"title"}></div>

                <div className={"account-details-container_bottom_sign-out"}>
                    <ExPopulusButton
                        color="pink"
                        onClick={onLogoutClick}
                        className="account-details-container_bottom_sign-out_button"
                    >
                        Sign Out
                    </ExPopulusButton>
                </div>

                <div className={"account-details-container_bottom_email-preferences"}>
                    <ExPopulusButton
                        color="pink-on-gray"
                        onClick={onEditEmailPreferences}
                        className="account-details-container_bottom_email-preferences_button"
                    >
                        Email Preferences
                    </ExPopulusButton>
                </div>
            </div>
        </div>
    );
}
