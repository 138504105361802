import {Asset} from "client/marketplace";
import {HardCodedGameDetails} from ".";
import {SaleType} from "shared";
import GameDetailsFinalFormAboutSection from "../../components/gameDetails/GameDetailsFinalFormAboutSection";
import {TokenType} from "../TokenType";

const gameDetailsFinalForm: HardCodedGameDetails = {
    id: "xp-0",
    to: "/games/final-form",
    title: "Final Form™",
    pageTitle: "Final Form: A Free Web3 Trading Card Game",
    playFree: true,
    socialIcons: true,
    desc: "Final Form has been designed from the start as a card collector’s dream game. The game focuses on the evolution of player cards which has been a central theme of the trading card genre for decades.",
    keyArtPath: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_80/v1661190198/website/images/FFHero_lxezlq",
    logo: `${process.env.PUBLIC_URL}/images/games/xp/xpLogo.png`,
    social: {
        proof: [
            {
                score: 9,
                quote: "The most visually impressive web3 game I have ever seen.",
                attribution: {
                    author: "Lubov B.",
                    source: "San Francisco, CA",
                }
            },
            {
                score: 10,
                quote: "The Obelisk is the coolest digital card collector’s case ever!",
                attribution: {
                    author: "Justin S.",
                    source: "Dallas, TX",
                }
            },
            {
                score: 9,
                quote: "There is nothing more satisfying than evolving my cards.",
                attribution: {
                    author: "Mark H.",
                    source: "Schaumburg, IL",
                }
            },
        ],
    },
    carouselAssets: [
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/final-form/XP_A16_FF_1920x1080_v05.mp4",
            contentType: "video",
            cloudinary: {
                publicId: "website/videos/XP_A16_FF_1920x1080_v05_f3mrmw.mp4",
                actions: {
                    desktop: ["f_auto", "q_80:qmax_80"],
                    mobile: ["f_auto", "q_80:qmax_50"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/XP_cardGame_Blurred_HD_v29_1.mp4",
            contentType: "video",
            cloudinary: {
                publicId: "website/videos/unemployed_pink_swan",
                actions: {
                    desktop: ["f_auto", "q_80:qmax_80"],
                    mobile: ["f_auto", "q_80:qmax_50"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/iron-pigeons/XP_A17_IP_1920x1080v01.mp4",
            contentType: "video",
            cloudinary: {
                publicId: "website/videos/XP_A17_IP_1920x1080v01_mibz0s",
                actions: {
                    desktop: ["f_auto", "q_80:qmax_80"],
                    mobile: ["f_auto", "q_80:qmax_50"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/xp-card-game_dag-ChestBump.mp4",
            contentType: "video",
            cloudinary: {
                publicId: "website/videos/strange_purple_grasshopper",
                actions: {
                    desktop: ["f_auto", "q_80:qmax_80"],
                    mobile: ["f_auto", "q_80:qmax_50"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/dag_header.mp4",
            contentType: "video",
            cloudinary: {
                publicId: "website/videos/dag_header_wcanq5",
                actions: {
                    desktop: ["f_auto", "q_80:qmax_80"],
                    mobile: ["f_auto", "q_80:qmax_50"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/final-form/FFHero.jpg",
            contentType: "image",
            cloudinary: {
                publicId: "website/images/FFHero_lxezlq",
                actions: {
                    desktop: ["f_auto", "q_80"],
                    mobile: ["f_auto", "q_80"]
                },
            },
        } as unknown as Asset,
    ],
    about: {
        // This is a temporary solution to hide the Show More toggle on the Game Details page
        showMore: true,
        content: GameDetailsFinalFormAboutSection,
    },
    details: {
        description: "Final Form has been designed from the start as a card collector’s dream game. The game focuses on the evolution of player cards which has been a central theme of the trading card genre for decades.",
        playNow: false, // boolean value that enables/disables the "Play Now" button on the Final Form game page
        details: [
            {
                label: "DEVELOPER",
                value: {display: "Ex Populus"},
            },
            {
                label: "GENRE",
                value: {display: "Simulation"},
            },
            {
                label: "STATUS",
                value: {display: "In Development"},
            },
            {
                label: "RELEASES",
                value: {display: "Estimated October 2022"},
            },
            {
                label: "PLATFORM",
                value: {display: "Windows"},
            },
            {
                label: "NETWORK",
                value: {display: "Solana"},
            },
            {
                label: "DECENTRALIZED",
                value: {display: "Full"},
            },
        ]
    },
    tags: [
        {status: "In Development"},
        {text: "Simulation"},
    ],

    gameContent: [
        // DAGs
        {
            header: "Decentralized Autonomous GigaUnits",
            body: "DAGs are a collection of unique, hand-drawn traits that are combined using a generative algorithm to create thousands of completely unique trading cards.",
            image: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_80/v1661465751/website/images/xp-card-game_dag_pmsn3r",
            saleType: process.env.REACT_APP_DAG_SALE_TYPE as SaleType,

            //      If true, the DOM will render in the secondaryButton.
            secondaryButton: true,

            xpStoreBuyNowURL: `${process.env.REACT_APP_WEBSITE_URL}/dags/#get-cards`,
            xpStoreURL: `${process.env.REACT_APP_WEBSITE_URL}/dags/#`,

            //      If true, the onClickHandler will send the users to the secondaryMarketURL.
            useSecondaryMarket: false,
            secondaryMarketURL: "https://magiceden.io/marketplace/decentralized_autonomous_gigaunits_dag",
            tokenType: TokenType.DAG,
        },

        // IRON PIGEONS
        {
            header: "Iron Pigeons",
            body: "Mike Tyson brings his love for pigeons to the Metaverse by making them the second set in the Ex Populus trading card system!",
            image: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_80/v1661465751/website/images/xp-card-game_ip_hitrmx",
            saleType: process.env.REACT_APP_IRON_PIGEONS_SALE_TYPE as SaleType,

            // If true, the DOM will render in the secondaryButton.
            secondaryButton: true,

            xpStoreBuyNowURL: `${process.env.REACT_APP_WEBSITE_URL}/ironpigeons/#get-cards`,
            xpStoreURL: `${process.env.REACT_APP_WEBSITE_URL}/ironpigeons/#`,

            //  If true, the onClickHandler will send the users to the secondaryMarketURL.
            useSecondaryMarket: false,
            secondaryMarketURL: "https://magiceden.io/marketplace/iron_pigeons",
            tokenType: TokenType.IRON_PIGEONS,
        },
    ],

    systemRequirements: [
        {
            title: "Minimum",
            requirements: [
                { label: "OS", value: "Windows 10 (64-bit)" },
                { label: "Processor", value: "Intel Core i5-8300" },
                { label: "Memory", value: "8GB RAM" },
                { label: "Graphics", value: "Nvidia Geforce GTX 1050 2GB" },
            ],
        },
        {
            title: "Recommended",
            requirements: [
                { label: "OS", value: "Windows 10 (64-bit)" },
                { label: "Processor", value: "Intel Core i5-8300" },
                { label: "Memory", value: "16GB Ram" },
                { label: "Graphics", value: "Nvidia Geforce GTX 1070 8GB" },
            ],
        },
    ],

    moreGames: [
        "lamoverse",
        "carnivale",
    ],
};

export default gameDetailsFinalForm;
