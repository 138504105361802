import React, {DetailedHTMLProps, useEffect, useRef} from "react";
import {Asset} from "client/marketplace";
import {fileIsImage} from "../utils/fileTypeChecks";
import MediaAssetViewer from "./mediaAssetViewer/MediaAssetViewer";

interface IProps extends DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    asset: Asset;
    title: string;
    autoPlay?: boolean;
    controls?: boolean;
    muted?: boolean;
    containerClassName?: string;
}

const VideoCard: React.FC<IProps> = (props) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const forceAutoplay = async () => videoRef?.current.play();

        if (props.autoPlay) {
            forceAutoplay().catch(console.error);
        }
    }, [props.autoPlay]);

    return (
        // <aside className={classes["position-relative"]}>
        <aside className={"position-relative"}>
            <span className={`${props.containerClassName}`}>
                <MediaAssetViewer
                    asset={props.asset}
                    imageAltText={props.title}
                    contentType={fileIsImage(props.asset) ? "image" : "video"}
                    videoAutoPlay={props.autoPlay}
                    videoMuted={props.muted}
                    videoPlaysInline={true}
                    videoControls={props.controls}
                    videoLoop={false}
                    elementRef={fileIsImage(props.asset) ? undefined : videoRef}
                />
			</span>
        </aside>
    );
};

VideoCard.defaultProps = {
    autoPlay: true,
    controls: false,
    muted: true,
    containerClassName: "",
}

export {VideoCard};
