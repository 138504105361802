"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileBodyToJSON = exports.UpdateProfileBodyFromJSONTyped = exports.UpdateProfileBodyFromJSON = exports.instanceOfUpdateProfileBody = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the UpdateProfileBody interface.
 */
function instanceOfUpdateProfileBody(value) {
    var isInstance = true;
    isInstance = isInstance && "email" in value;
    return isInstance;
}
exports.instanceOfUpdateProfileBody = instanceOfUpdateProfileBody;
function UpdateProfileBodyFromJSON(json) {
    return UpdateProfileBodyFromJSONTyped(json, false);
}
exports.UpdateProfileBodyFromJSON = UpdateProfileBodyFromJSON;
function UpdateProfileBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
exports.UpdateProfileBodyFromJSONTyped = UpdateProfileBodyFromJSONTyped;
function UpdateProfileBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'name': value.name,
    };
}
exports.UpdateProfileBodyToJSON = UpdateProfileBodyToJSON;
