import React, {ChangeEvent} from "react";
import {BsWindows} from "react-icons/all";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";
import {Input, ModalBody, ModalFooter, ModalHeader,} from "reactstrap";
import classNames from "classnames";
import {IFormState} from "../../../utils/formState";
import {IFinalFormDownloadModalStep} from "./FinalFormWindowDownloadManager";
import Tooltip from "../../tooltips/TooltipContainer";
import TooltipErrorMsg from "../../tooltips/TooltipErrorMessage";
import {useSelector} from "react-redux";
import {IStore} from "../../../redux/defaultStore";

interface IProps {
    isOpen: boolean;
    setIsOpen: (v?: any) => void;
    formState: IFormState;
    formOk: boolean;
    closeHelper: () => void;
    onEvent: (field: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => void;
    toggle: () => void;
    onSubmit: (e?: IFinalFormDownloadModalStep) => void;
}

const FinalFormWindowDownloadModal: React.FC<IProps> = (props) => {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);

    function _onSubmitHelper(e?): void {
        e?.preventDefault();
        return props.onSubmit();
    }

    const currentUserEmail = (
        <span className="final-form-window-download-modal_email">{currentUser?.email}</span>
    )

    return (
        <form onSubmit={_onSubmitHelper}>
            <div>
                {currentUser && (
                    <>
                        <ModalHeader toggle={props.toggle as any}>
                            Get download link
                        </ModalHeader>

                        <ModalBody>
                            <p>Do you want to receive an email link at {currentUserEmail} to download Final Form?</p>
                        </ModalBody>

                        <ModalFooter>
                            <ExPopulusButton
                                <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                color="white-on-pink"
                                forwardProps={{
                                    type: "submit",
                                    onSubmit: _onSubmitHelper,
                                }}
                                className="final-form-window-download-modal_button"
                                leftIcon={BsWindows}
                            >
                                GET DOWNLOAD LINK
                            </ExPopulusButton>
                        </ModalFooter>
                    </>
                )}

                {!currentUser && (
                    <>
                        <ModalHeader toggle={props.toggle as any}>
                            Get download link
                        </ModalHeader>

                        <ModalBody>
                            <p>Enter your email below to receive a link to download Final Form for PC</p>

                            <Tooltip
                                position="top"
                                trigger={() => props.formState.email.state.showTooltip}
                                tooltip={
                                    <TooltipErrorMsg
                                        errors={props.formState.email.errors}
                                        position={"top"}
                                        theme="light"
                                    />
                                }
                            >
                                <Input
                                    type={"email"}
                                    placeholder={"Email address"}
                                    value={props.formState.email.value as string}
                                    onChange={props.onEvent("email")}
                                    onFocus={props.onEvent("email")}
                                    onBlur={props.onEvent("email")}
                                    className={classNames("final-form-window-download-modal_test", {"invalid": props.formState.email.state.showErrors && !props.formState.email.state.isValid})}
                                />
                            </Tooltip>
                        </ModalBody>

                        <ModalFooter>
                            <ExPopulusButton
                                <React.ButtonHTMLAttributes<HTMLButtonElement>>
                                color="white-on-pink"
                                forwardProps={{
                                    type: "submit",
                                    disabled: !props.formOk,
                                    onSubmit: _onSubmitHelper,
                                }}
                                className="final-form-window-download-modal_button"
                                leftIcon={BsWindows}
                            >
                                GET DOWNLOAD LINK
                            </ExPopulusButton>
                        </ModalFooter>
                    </>
                )}
            </div>
        </form>
    );
}

export default FinalFormWindowDownloadModal;
