import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {Asset} from "client/marketplace";
import {FaPlay} from "react-icons/all";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer, {buildAssetUrl} from "../mediaAssetViewer/MediaAssetViewer";

interface IProps {
    asset: Asset;
    selected: boolean;
    onClick: () => void;
    className?: string;

    onMouseEnter?(): void;

    onMouseLeave?(): void;
}

const GameDetailsCarouselGalleryPreview: React.FC<IProps> = (props) => {
    const [elementWidth, setElementWidth] = useState(0)
    const elementRef = useRef(null);

    useEffect(() => {
        setElementWidth(elementRef.current?.clientWidth || 0);
    }, []);

    /**
     *
     * @param asset Asset to build URL for
     * @param isImage Indicates is source asset is an image, otherwise it's treated as a video asset
     * @returns [string] The URL for the
     */
    function getAssetsUrl(asset: Asset, isImage) {
        if (!asset.cloudinary?.publicId) {
            return asset.url;
        }

        const actions = isImage ? [] : ["f_jpg"];
        const contentType = isImage ? "image" : "video";

        return buildAssetUrl(asset.cloudinary.publicId, actions, contentType);
    }

    function makeThumbnailPreview(asset: Asset, width: number) {
        const isImage = fileIsImage(asset);
        const assetUrl = getAssetsUrl(asset, isImage);
        const videoIconOverlay = (
            <div className="game-details-carousel-gallery-preview_video-container_play-icon-positioner">
                <div className="game-details-carousel-gallery-preview_video-container_play-icon-positioner_container">
                    <FaPlay/>
                </div>
            </div>
        );

        // when we have a non-cloudinary video asset we just have to use the default video tag
        if (!isImage && !asset.cloudinary?.publicId) {
            return (
                <React.Fragment>
                    <video
                        autoPlay={false}
                        controls={false}
                        loop={false}
                        muted={true}
                        playsInline={true}
                        src={`${assetUrl}#t=1`}
                    />

                    {videoIconOverlay}
                </React.Fragment>
            );
        }

        // otherwise we can use the image url generated by cloudinary
        // and avoid downloading the full video file
        return (
            <React.Fragment>
                <MediaAssetViewer
                    asset={new URL(assetUrl)}
                    contentType={"image"}
                    elementWidth={{
                        desktopWidth: width,
                        mobileWidth: width,
                    }}
                    imageAltText={asset.name || "Preview"}
                />

                {!isImage && videoIconOverlay}
            </React.Fragment>
        );
    }

    return (
        <div
            onClick={props.onClick}
            className={classNames("game-details-carousel-gallery-preview", props.className, {
                "game-details-carousel-gallery-preview_un-selected": !props.selected,
                "game-details-carousel-gallery-preview_selected": props.selected,
            })}
            ref={elementRef}
        >
            <div className="game-details-carousel-gallery-preview_video-container">
                {makeThumbnailPreview(props.asset, elementWidth)}
            </div>
        </div>
    );
};

GameDetailsCarouselGalleryPreview.defaultProps = {
    className: "",
    onMouseEnter: () => {
    },
    onMouseLeave: () => {
    },
}

export default React.memo(GameDetailsCarouselGalleryPreview);
