import React from "react";
import {Container} from "reactstrap";
import GameCard, {IGameCardProps} from "../../components/gameCard/GameCard";

interface IProps {
    games: Array<IGameCardProps>;
}

const GameDetailsMoreGames: React.FC<IProps> = (props) => {

	return (
		<Container className="game-details-more-games">
			<hr className="game-details-more-games_break"/>
			<div className="game-details-more-games_title">More Games</div>

			<div className="game-details-more-games_cards">
				{props.games.map((card) => (<GameCard key={card.id} {...card} />))}
			</div>
		</Container>
	);
}

GameDetailsMoreGames.defaultProps = {
    games: [],
}

export default GameDetailsMoreGames;
