import {Asset} from "client/marketplace";
import {FileWithSRC} from "./renderImageHelper";

export type IFrontendFileType = FileWithSRC | Asset;

/**
 * First check if the file is a FileWithSRC (frontend user-selected file) else assume Asset from client,
 * then check appropriate field for instance of "image" to determine file type.
 *
 * @param file
 */
export function fileIsImage(file: IFrontendFileType): boolean {
	return isFileWithSRC(file) ? file?.type?.includes("image") : file?.contentType?.includes("image");
}

/**
 * First check if the file is a FileWithSRC (frontend user-selected file) else assume Asset from client,
 * then check appropriate field for instance of "video" to determine file type.
 *
 * @param file
 */
export function fileIsVideo(file: IFrontendFileType): boolean {
	return isFileWithSRC(file) ? file?.type?.includes("video") : file?.contentType?.includes("video");
}

export function isFileWithSRC(file: IFrontendFileType): file is FileWithSRC {
	return file?.hasOwnProperty("isFileWithSRC");
}

