import React, {ReactNode} from "react";
import classNames from "classnames";
import {FaCheck} from "react-icons/all";

interface IProps {
	checked: boolean;
	onChange: () => void;
	children: ReactNode;
	className?: string;
}

const MarketplaceCheckbox: React.FC<IProps> = (props) => {
	const onKeyDown = (event) => {
		if (event.isComposing || event.keyCode !== 32) {
			return;
		}

		props.onChange();
	}
	return (
		<div className={classNames("marketplace-checkbox-container", props.className)}>
			<div className="marketplace-checkbox-container_adjuster">
				<div
					tabIndex={0}
					className={classNames("marketplace-checkbox-container_adjuster_box", {
						"marketplace-checkbox-container_adjuster_box-unchecked": !props.checked,
						"marketplace-checkbox-container_adjuster_box-checked": props.checked,
					})}
					onClick={props.onChange}
					onKeyDown={onKeyDown}
				>
					<FaCheck/>
				</div>
			</div>

			<div>
				{props.children}
			</div>
		</div>
	);
};

export default MarketplaceCheckbox;
