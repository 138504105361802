import React, {useEffect, useState} from "react";
import {Asset} from "client/marketplace";
import {fileIsImage} from "../utils/fileTypeChecks";
import GalleryItemList from "./GalleryItemList";
import MediaAssetViewer from "./mediaAssetViewer/MediaAssetViewer";

interface IProps {
    assets: Array<Asset>;
}

const Gallery: React.FC<IProps> = (props) => {

    const [selected, setSelected] = useState(0);
    const [renderKey, setRenderKey] = useState(1); // key used to force videos to play properly on initial render

    /**
     * useEffect that helps force videos to play properly,
     * both on initial render (due to checking props.assets,
     * which technically accounts for any changes, but this component
     * shouldn't be receiving new props.assets once it's rendered).
     *
     * Also listens for the selected index,
     * and updates the renderKey in either case to,
     * fixing video issues.
     *
     */
    useEffect(() => {
        setRenderKey(renderKey + 1);
    }, [JSON.stringify(props.assets), selected]);

    const selectedAsset: Asset = props.assets?.[selected];

    if (!selectedAsset) {
        return null;
    }

    return (
        <div className="gallery">
            <div
                className="gallery_selected-preview"
                key={renderKey}
            >
                <MediaAssetViewer
                    asset={selectedAsset}
                    contentType={fileIsImage(selectedAsset) ? "image" : "video"}
                    imageAltText={selectedAsset?.name || "Preview"}
                    videoAutoPlay={true}
                    videoControls={false}
                    videoLoop={true}
                    videoMuted={true}
                    videoPlaysInline={true}
                />
            </div>

            <div className="gallery_preview-list">
                <GalleryItemList
                    key={selected}
                    assets={props.assets}
                    selectedIndex={selected}
                    setSelected={setSelected}
                />
            </div>
        </div>
    );
}

export default Gallery;
