import React, {useMemo} from "react";
import {Container} from "reactstrap";
import {ScrollerLeftToRight, ScrollerRightToLeft,} from "../scrollers";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {Asset} from "client/marketplace";

const body: ITotalCount = {
    scrollerDuration: 84,
        scrollerItems: [
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-1.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-1_jnkose",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-2.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-2_btgkap",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-3.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-3_zk8rli",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-4.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-4_spnlej",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-5.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-5_ywkkkf",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-6.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-6_m6g36x",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-7.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-7_ccbwuo",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-8.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-8_x3epcp",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-9.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-9_mzvu9q",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-10.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-10_gnow3l",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-11.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-11_vvbwks",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-12.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-12_eviqrr",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-13.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-13_ylsh9z",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-14.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-14_nmaxsk",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-15.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-15_rihkl4",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-16.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-16_j0g1hl",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/total%20count/ip_scroller-17.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/iron-pigeons/ip_scroller-17_yz2uxh",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
    ]
}

interface ITotalCount {
    scrollerDuration: number;
    scrollerItems: Array<Asset>;
}

const randomizeList = (list) => list
    .map(value => ({value, sort: Math.random()}))
    .sort((a, b) => a.sort - b.sort)
    .map(({value}) => value);


const IronPigeonsHomepageTotalCount: React.FC = () => {
    const {scrollerDuration} = body;
    const scrollerItems = body.scrollerItems?.length ? body.scrollerItems : [];

    const randomizeListLeftResults = useMemo(() =>
        randomizeList(scrollerItems).map((source, index) => (
            <MediaAssetViewer
                key={`scroller-left-${index}`}
                asset={source}
                contentType={fileIsImage(source) ? "image" : "video"}
                elementWidth={{
                    desktopWidth: 336,
                    mobileWidth: 336,
                }}
            />
        )), [scrollerItems])

    const randomizeListRightResults = useMemo(() =>
        randomizeList(scrollerItems).map((source, index) => (
            <MediaAssetViewer
                key={`scroller-right-${index}`}
                asset={source}
                contentType={fileIsImage(source) ? "image" : "video"}
                elementWidth={{
                    desktopWidth: 336,
                    mobileWidth: 336,
                }}
            />
        )), [scrollerItems])


    return (
        <section className="homepage-template-total-count">
            <Container className="homepage-template-total-count_container">
                <div className="dag-scroller-container">
                    {
                        scrollerItems.length && <ScrollerRightToLeft duration={scrollerDuration}>
                            { randomizeListLeftResults }
                        </ScrollerRightToLeft>
                    }
                </div>
                <div className="homepage-template-total-count_container_text">
                    <div className="pink">Every Set</div>
                    <div>A Limited Edition</div>
                </div>
                <div className="dag-scroller-container">
                    {
                        scrollerItems.length && <ScrollerLeftToRight duration={scrollerDuration}>
                            { randomizeListRightResults }
                        </ScrollerLeftToRight>
                    }
                </div>
            </Container>
        </section>
    );
};

export {IronPigeonsHomepageTotalCount};
