import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import {GoogleTagManagerEvents} from "../utils/GoogleTagManagerEvents";
import TagManager from "react-gtm-module";

const UpdateTracking: React.FC = () => {

    const location = useLocation();

    useEffect(() => {
        try {
            ReactGA.send({hitType: "pageview", page: location.pathname});

        } catch (err) {
            console.error(err);
        }

        try {
            TagManager.dataLayer({
                dataLayer: {
                    event: GoogleTagManagerEvents.LOADED_URL,
                    url: location.pathname,
                },
            });
        } catch (err) {
            console.error(err);
        }
    }, [location.pathname])

    return null;
}

export default UpdateTracking;
