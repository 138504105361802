import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	steps: number;
	selected: number;
	onSelect: (stepperIndex: number) => void;
}

const GameDetailsCarouselPreviewStepper: React.FC<IProps> = (props) => {

	/**
	 * Render each step with dynamic className for styling based on if it's the "selected" step.
	 * Attach onClick functionality for selecting that "page" of the previews.
	 *
	 * @param v
	 * @param i
	 */
	function renderSteps(v: undefined, i: number): ReactNode {
		function onClickHelper(): void {
			if (props.selected === i) {
				return;
			}

			props.onSelect(i);
		}

		return (
			<div
				key={`gallery-preview-step_${i}`}
				className={classNames("game-details-carousel-preview-stepper_step", {
					"game-details-carousel-preview-stepper_step_selected": props.selected === i,
				})}
				onClick={onClickHelper}
			>
				<div className="game-details-carousel-preview-stepper_step_inner"/>
			</div>
		);
	}

	return (
		<div className="game-details-carousel-preview-stepper">
			{new Array(props.steps).fill(undefined).map(renderSteps)}
		</div>
	);
};

export default GameDetailsCarouselPreviewStepper;
