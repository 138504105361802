import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {ApiError} from "client/cs";
import ErrorModal from "./ErrorModal";

interface IProps {
    errors?: Array<ApiError>;
}

const ErrorModalManager: React.FC<ErrorModalManagerProps> = (props: ErrorModalManagerProps) => {

    function generateErrorModal(error: ApiError, index: number) {
        return (
            <ErrorModal
                key={`error-modal_${index}`}
                error={error}
                index={index}
            />
        )
    }

    return (
        <React.Fragment>
            {props.errors?.map(generateErrorModal)}
        </React.Fragment>
    );
};

const connector = connect((store: IStore, props: IProps) => {
    return {
        ...props,
        errors: store.metaStore.errors,
    }
});

type ErrorModalManagerProps = ConnectedProps<typeof connector>;

export default connector(ErrorModalManager);
