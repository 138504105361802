import InitialMetaStore, {IMetaStore} from "./meta/InitialMetaStore";
import InitialWalletStore, {IWalletStore} from "./wallets/InitialWalletStore";

export interface IStore {
	metaStore: IMetaStore;
	walletStore: IWalletStore;
}

export default {
	metaStore: InitialMetaStore,
	walletStore: InitialWalletStore,
} as IStore;
