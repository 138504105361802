"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionBodyToJSON = exports.CollectionBodyFromJSONTyped = exports.CollectionBodyFromJSON = exports.instanceOfCollectionBody = void 0;
/**
 * Check if a given object implements the CollectionBody interface.
 */
function instanceOfCollectionBody(value) {
    var isInstance = true;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfCollectionBody = instanceOfCollectionBody;
function CollectionBodyFromJSON(json) {
    return CollectionBodyFromJSONTyped(json, false);
}
exports.CollectionBodyFromJSON = CollectionBodyFromJSON;
function CollectionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'project': json['project'],
        'name': json['name'],
    };
}
exports.CollectionBodyFromJSONTyped = CollectionBodyFromJSONTyped;
function CollectionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'project': value.project,
        'name': value.name,
    };
}
exports.CollectionBodyToJSON = CollectionBodyToJSON;
