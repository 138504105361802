import React from "react";
import {Asset} from "client/marketplace";
import {Token, User} from "client/cs";
import {TemplateCollectionSoldOut} from "../TemplateCollectionSoldOut";
import {TemplateCollectionPreview} from "../TemplateCollectionPreview";
import {SaleTypeEnum} from "../../utils/SaleTypeEnum";
import {isCTASoldOut} from "../../utils/isCTAItem";
import {TokenType} from "../../utils/TokenType";

export interface IBullet {
    iconName?: string;
    text: string | React.ReactNode;
    header?: string;
}

export interface ICTAProperties {
    saleType: SaleTypeEnum;
    rarity: string;
    header: string;
    subheader?: string;
    alt?: string;
    collectionName: string;
    collectionNamePlural: string;
    buttonText?: string;
    disabled?: boolean;
}

export interface ICTACommonRare extends ICTAProperties {
    price: number | string;
    priceType: string;
    cryptoIcon: string;
    buttonText: string;
    bullets: Array<IBullet>;
}

export interface ICTASoldOut extends ICTAProperties {
    marketButtons: Array<{ marketName: string, url: string }>,
}

export type CTAItemType = ICTACommonRare | ICTASoldOut;

export interface IBuyNowProperties {
    header: string;
    subheader: string;
    body1: string;
    body2?: string;
    backgroundImage: Asset;
    backgroundImageAlt: string;
    CTACommonValue: ICTACommonRare;
    CTARareValue: ICTACommonRare;
    CTARareSoldOut: ICTASoldOut;
}

interface IProps {
    saleType?: SaleTypeEnum;
    fullToken?: Token;
    currentUser?: User;
    tokenType: TokenType;
    ctaValue: CTAItemType;
    redirect: string;
    selectionHelper: () => void;
    onClickMarketButton?: () => void;
}

const HomepageTemplateBuyNowCTA: React.FC<IProps> = (props: IProps) => {
    const {
        ctaValue,
        saleType,
        tokenType,
        currentUser,
        onClickMarketButton,
        // selectionHelper,
    } = props;

    const startBuyFlow = () => {
        // We do not currently support a buy flow in either DAGs or IPs
        // selectionHelper();

        // So just run any provided callback code (like for tracking)
        onClickMarketButton();
    }

    const showSoldOut = (soldoutCTAValue: ICTASoldOut) => (
        <TemplateCollectionSoldOut
            tokenType={tokenType}
            onClickMarketButton={onClickMarketButton}
            currentUser={currentUser}
            {...soldoutCTAValue}
        />
    )

    const showNotSoldOut = (commonRareCTAValue: ICTACommonRare) => (
            <TemplateCollectionPreview
                {...commonRareCTAValue}
                tokenType={tokenType}
                onClick={startBuyFlow}
            />
    );

    return (
        <section className={"homepage-template-buy-now_container_CTA"}>
            {
                isCTASoldOut(ctaValue, saleType)
                    ? showSoldOut(ctaValue)
                    : showNotSoldOut(ctaValue)
            }
        </section>
    );
};

HomepageTemplateBuyNowCTA.defaultProps = {
    onClickMarketButton: () => {},
}

export { HomepageTemplateBuyNowCTA };
