import React from "react";
import { Container } from "reactstrap";
import { User } from "client/cs";
import classNames from "classnames";
import { useAtom } from "jotai";
import { IoSparklesSharp } from "react-icons/all";
import { dismissBannerAtom } from "../../banners/ArbitrumBanner";
import PlayFreeButton from "../../PlayFreeButton";

export interface IProps {
  id?: string;
  className?: string;
  user?: User;
  onClick?: () => void;
}

export const FinalFormTitleBar: React.FC<IProps> = (props) => {
  const [dismissBanner] = useAtom(dismissBannerAtom);

  return (
    <div
      id={props.id}
      className={classNames("final-form-title-bar", {
        "final-form-title-bar_sticky-banner": !dismissBanner,
      })}
    >
      <Container className="final-form-title-bar_container">
        <div className="final-form-title-bar_container_header">
          <h1 className="final-form-title-bar_container_header_title">
            Final Form™
          </h1>
          <div className="final-form-title-bar_container_header_arbitrum">
            <IoSparklesSharp />
            <p>Coming soon to Xai</p>
          </div>
        </div>

        <div className="final-form-title-bar_container_align">
          <div className="final-form-title-bar_container_buttons">
            <PlayFreeButton
              className={"final-form-title-bar_container_buttons_style"}
              user={props.user}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

FinalFormTitleBar.defaultProps = {
  className: "",
  id: null,
};
