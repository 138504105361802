import React, {ReactNode, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError} from "../redux/meta/MetaActions";
import {Listing, ListingsApi} from "client/marketplace";
import getConfig from "../utils/getConfig";
import Gallery from "../components/Gallery";
import {generateOpenSeaLink} from "../utils/generateOpenSeaLink";
import {Link, useHistory} from "react-router-dom";
import {generateEtherscanLink} from "../utils/generateEtherscanLink";
import LocalLoader from "../components/LocalLoader";
import {ReactComponent as DescriptionIcon} from "../svgs/description-icon.svg";
import {ReactComponent as NewWindowIcon} from "../svgs/new-window.svg";
import EthIconPriceLabel from "../components/EthIconPriceLabel";
import {ExPopulusButton} from "../components/buttons/ExPopulusButton";
import {generateSupplyMessage} from "../utils/generateSupplyMessage";

interface IProps extends RouteComponentProps {
	dispatch?: any;
}

const ListingPageDK: React.FC<IProps> = (props) => {

	const history = useHistory();
	const listingVanity: string = props.match.params["listingName"];

	const [listing, setListing] = useState<Listing>(undefined);
	const [localLoading, setLocalLoading] = useState(false);

	// TODO: remove once the container template implements the dark theme
	useEffect(() => {
		document.body.classList.add("page-gradient");

		return () => {
			document.body.classList.remove("page-gradient");
		};
	});

	useEffect(() => {
		getCollectible().then().catch();
	}, []);

	/**
	 * Call the api to get the listing.
	 *
	 */
	async function getCollectible(): Promise<void> {
		setLocalLoading(true);

		try {
			const res = await new ListingsApi(getConfig()).getListing({
				urlExtension: listingVanity,
			});

			setListing(res);
		} catch (e) {
			history.push("/collectibles");
			props.dispatch(addError(e));
		}

		setLocalLoading(false);
	}

	const shortenEtherscanAddress = ({smartContractAddress}: Listing) => smartContractAddress
		? `${smartContractAddress.slice(0, 16)}...${smartContractAddress.slice(-4)}`
		: "Unavailable";

	const infoValueLink = (uri: string, value: string, isExternal = false, isNewWindow = true) => {
		if (!isExternal) {
			return (<Link to={uri}>{value}</Link>)
		}

		const attrs = {
			target: isNewWindow ? "_blank" : undefined,
			rel: isNewWindow ? "noopener noreferrer" : undefined,
		};

		return (<a href={uri} {...attrs}>{value}</a>);
	};

	const makeInfoLabel = (label: string, value: string, uri?: string, isExternal = false, isNewWindow = true) => (
		<div className={"info-label"}>
			<div className={"label"}>{label}</div>
			{
				uri
					? (infoValueLink(uri, value, isExternal, isNewWindow))
					: (<div className={"value"}>{value}</div>)
			}
		</div>
	);

	const makePricingLabel = ({totalSupply, maximumSupply, price}: Listing) => {
		if (totalSupply == null || maximumSupply == null || totalSupply >= maximumSupply) {
			return (<div className={"price-sold-out"}>Sold Out</div>);
		}

		return (
			<div className={"current-price"}>
				<EthIconPriceLabel price={price} className={"current-price"}/>
			</div>
		);
	}

	if (localLoading || !listing) {
		return (
			<Container>
				<LocalLoader/>
			</Container>
		);
	}

	return (
		<Container className="listing-page">
			<div className={"collectible-name mobile"}>{listing.name}</div>

			<div className="box">
				<Gallery assets={listing?.assets}/>
			</div>

			<div className="box">
				<div className={"collectible-name"}>{listing.name}</div>
				<div className={"description-label"}>{generateSupplyMessage(listing)}</div>

				<div className={"price-container"}>
					<div>
						<div className={"current-price-label"}>Current Price</div>
						{makePricingLabel(listing)}
					</div>
					<div className={"view-button"}>
						<ExPopulusButton
							<React.AnchorHTMLAttributes<HTMLAnchorElement>>
							color="pink"
							rightIcon={NewWindowIcon}
							href={listing?.customLink?.url ?? generateOpenSeaLink(listing)}
							forwardProps={{
								target: "_blank",
							}}
						>
							{listing?.customLink?.text ?? "VIEW ON OPENSEA"}
						</ExPopulusButton>
					</div>
				</div>

				<div className="description-label">
					<DescriptionIcon/>
					<div>Description</div>
				</div>

				<p className="description">
					{listing?.description}
				</p>

				<div className="info-container">
					{makeInfoLabel("Creator", listing?.collection?.project?.creator?.name)}
					{makeInfoLabel("Collection", listing?.collection?.name)}
					{makeInfoLabel("Rarity", listing?.supplyPrefix)}
					{makeInfoLabel("Evolutive", "No")}
					{makeInfoLabel("Type", "Playable Avatar")}
					{
						listing?.smartContractAddress
							? makeInfoLabel("Track", shortenEtherscanAddress(listing), generateEtherscanLink(listing), true)
							: makeInfoLabel("Type", "Unavailable")
					}
				</div>

			</div>
		</Container>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
	}
})(ListingPageDK);
