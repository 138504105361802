"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SolanaPayActionToJSON = exports.SolanaPayActionFromJSONTyped = exports.SolanaPayActionFromJSON = exports.SolanaPayAction = void 0;
/**
 *
 * @export
 */
exports.SolanaPayAction = {
    LOGIN: 'LOGIN',
    STAKE: 'STAKE',
    UNSTAKE: 'UNSTAKE',
    EVOLVE: 'EVOLVE',
    BURN: 'BURN',
    MINT: 'MINT',
    CLAIM: 'CLAIM',
    CREATE_POOL: 'CREATE_POOL'
};
function SolanaPayActionFromJSON(json) {
    return SolanaPayActionFromJSONTyped(json, false);
}
exports.SolanaPayActionFromJSON = SolanaPayActionFromJSON;
function SolanaPayActionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SolanaPayActionFromJSONTyped = SolanaPayActionFromJSONTyped;
function SolanaPayActionToJSON(value) {
    return value;
}
exports.SolanaPayActionToJSON = SolanaPayActionToJSON;
