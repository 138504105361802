"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradingCardRarityToJSON = exports.TradingCardRarityFromJSONTyped = exports.TradingCardRarityFromJSON = exports.TradingCardRarity = void 0;
/**
 *
 * @export
 */
exports.TradingCardRarity = {
    legendary: 'legendary',
    epic: 'epic',
    rare: 'rare',
    common: 'common'
};
function TradingCardRarityFromJSON(json) {
    return TradingCardRarityFromJSONTyped(json, false);
}
exports.TradingCardRarityFromJSON = TradingCardRarityFromJSON;
function TradingCardRarityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TradingCardRarityFromJSONTyped = TradingCardRarityFromJSONTyped;
function TradingCardRarityToJSON(value) {
    return value;
}
exports.TradingCardRarityToJSON = TradingCardRarityToJSON;
