import React from "react";

const TermsOfServicePage: React.FC = () => {

    return (
        <section className={"legal-text"}>
            <div className="text-container">

                <div className={"page-content"}>
                    <div className="page-title">
                        <h1 className="text-center">Ex Populus Digital Collectibles Store and Gaming Platform Terms of Service</h1>
                        <p className="upcase text-center">Last Updated: 08/23/2022</p>
                    </div>

                    <p>
                        Welcome to the Ex Populus Digital Collectibles Store and Gaming Platform (the “<span
                        className={"bold-white-text"}>Platform</span>”). The Platform is an online store through which
                        users can purchase unique limited-edition blockchain-based non-fungible tokens (each, a "
                        <span className={"bold-white-text"}>Digital Collectible</span>") sold by Ex Populus, Inc.
                        (“<span className={"bold-white-text"}>Ex Populus</span>” or “<span
                        className={"bold-white-text"}>we</span>”) and access blockchain-enabled video games published by
                        Ex Populus (the “<span className={"bold-white-text"}>Games</span>”), and access other features
                        available via Ex Populus’ website, mobile app, or other available medium. The Games available
                        through the Platform may incorporate Digital Collectibles and blockchain-based fungible tokens
                        (collectively, “<span className={"bold-white-text"}>Web3 Game Items</span>”). These Terms of
                        Service (“<span className={"bold-white-text"}>Terms</span>”) apply to your access to and use of
                        the Platform and all services made available on the Platform (collectively, the “<span
                        className={"bold-white-text"}>Services</span>”). By using our Services, you agree to these
                        Terms, including the mandatory arbitration provision and class action waiver in Section 17. If
                        you do not agree to these Terms, you may not access or use our Services.
                    </p>
                    <p>
                        PLEASE NOTE THE ARBITRATION PROVISION SET FORTH BELOW, WHICH REQUIRES YOU TO, EXCEPT WHERE AND
                        TO THE EXTENT PROHIBITED BY LAW, ARBITRATE ANY CLAIMS YOU MAY HAVE AGAINST EX POPULUS ON AN
                        INDIVIDUAL BASIS. ARBITRATION ON AN INDIVIDUAL BASIS MEANS THAT YOU WILL NOT HAVE, AND YOU
                        WAIVE, THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, AND THAT YOU MAY NOT PROCEED IN A
                        CLASS, CONSOLIDATED, OR REPRESENTATIVE CAPACITY.
                    </p>
                    <p>
                        THE DIGITAL COLLECTIBLES AVAILABLE ON THE PLATFORM PROVIDE A LICENSE TO ACCESS AND USE DIGITIZED
                        CONTENT AND MATERIAL CREATED AND PROVIDED BY EX POPULUS AND OTHER THIRD PARTY DEVELOPERS (EACH,
                        A “<span className={"bold-white-text"}>DEVELOPER</span>”), SUBJECT TO THE TERMS AND CONDITIONS
                        OF THE DEVELOPER (“<span className={"bold-white-text"}>DEVELOPER TERMS</span>”) THAT ARE BY AND
                        BETWEEN YOU AND THE DEVELOPER. WE WILL PRESENT THE DEVELOPER TERMS TO YOU AT THE TIME OF
                        PURCHASE AND A LINK TO THE DEVELOPER TERMS WILL BE EMBEDDED IN THE DIGITAL COLLECTIBLE’S
                        METADATA.
                    </p>
                    <p>
                        OWNERSHIP OF A DIGITAL COLLECTIBLE MIGHT ENTITLE YOU TO A LICENSE TO ACCESS OR USE CERTAIN
                        OFF-BLOCKCHAIN BENEFITS, SUCH AS SPECIAL FEATURES, SERVICES OR GOODS (“<span
                        className={"bold-white-text"}>BENEFITS</span>”) PURSUANT TO THE DEVELOPER TERMS. UNLESS THE
                        DEVELOPER TERMS EXPRESSLY STATE OTHERWISE, EX POPULUS IS NOT A PARTY TO THE DEVELOPER TERMS AND
                        WILL NOT BE RESPONSIBLE OR LIABLE TO YOU IN THE EVENT THAT A DEVELOPER IS OBLIGATED TO YOU
                        PURSUANT TO THE DEVELOPER TERMS. YOU AGREE THAT WE WILL NOT BE A PARTY TO OR HAVE ANY
                        RESPONSIBILITY OR LIABILITY FOR, ARISING OUT OF, RELATING TO, ASSOCIATED WITH OR RESULTING FROM
                        ANY DISPUTES BETWEEN YOU AND ANY DEVELOPER ARISING OUT OF THE FUNCTIONALITY, USE OR MISUSE OF
                        ANY DIGITAL COLLECTIBLE OR THE DEVELOPER’S PROVISION OF OR FAILURE TO PROVIDE ANY BENEFITS IN
                        CONNECTION WITH ANY DIGITAL COLLECTIBLE.
                    </p>
                    <p>
                        We may supply different or additional terms in relation to some of our Services, and those
                        different or additional terms become part of your agreement with Ex Populus if you use those
                        Services. If there is a conflict between these Terms and the additional terms, the additional
                        terms will control for that conflict.
                    </p>
                    <p>
                        We may make changes to these Terms from time to time. If we make changes, we will provide you
                        with notice of such changes, such as by sending an email, providing a notice through our
                        Services or updating the date at the top of these Terms. Unless we say otherwise in our notice,
                        the amended Terms will be effective immediately, and your continued use of our Services after we
                        provide such notice will confirm your acceptance of the changes. If you do not agree to the
                        amended Terms, you must stop using our Services.
                    </p>
                    <p>
                        If you have any questions about these Terms or our Services, please contact us at <a
                        className="text-blue-600 underline" href="mailto:legal@expopulus.com" target="_blank"
                        rel="noreferrer">legal@expopulus.com</a>.
                    </p>

                    <h4>1. Privacy</h4>
                    <p>
                        For information about how we collect, use, share or otherwise process information about you,
                        please see our Privacy Policy <a className="text-blue-600 underline"
                                                         href="https://expopulus.com/privacy">https://expopulus.com/privacy</a>.
                    </p>

                    <h4>2. Eligibility</h4>
                    <p>
                        Unless otherwise indicated (e.g., as part of the official rules for a Game), in order to use our
                        Services, you must meet the following eligibility criteria:
                    </p>
                    <ul>
                        <li>
                            You must not be located in a country that is subject to the United States government
                            embargo, or
                            that has been designated by the United States government as a "terrorist
                            supporting "country.
                        </li>
                        <li>
                            You must not be listed on any United States government list of prohibited or restricted
                            parties.
                        </li>
                        <li>
                            You must be at least 13 years of age.
                        </li>
                    </ul>
                    <p>
                        If you are under 18 years of age (or the age of legal majority where you live), you may use our
                        Services only under the supervision of a parent or legal guardian who agrees to be bound by
                        these Terms. If you are a parent or legal guardian of a user under the age of 18 (or the age of
                        legal majority), you agree to be fully responsible for the acts or omissions of such user in
                        relation to our Services.
                    </p>
                    <p>
                        If you use our Services on behalf of another person or entity, (a) all references
                        to "you"
                        throughout these Terms will include that person or entity, (b) you represent that you are
                        authorized to accept these Terms on that person’s or entity’s behalf, and (c) in the event you
                        or the person or entity violates these Terms, the person or entity agrees to be responsible to
                        us.
                    </p>
                    <p>
                        The Services may refer to or give opportunities to participate in contests of skill,
                        sweepstakes, or other events that offer prizes or require you to send in material or information
                        about yourself. Each contest, sweepstakes or other such event may have its own rules and
                        additional terms, which you may be required to read and agree to before you enter.

                    </p>

                    <h4>3. Your User Account and Account Security</h4>
                    <p>
                        You may need to register for an account to access certain of our Services. When you register for
                        an account, you must provide accurate account information and promptly update this information
                        if it changes. You also must maintain the security of your account and promptly notify us if you
                        discover or suspect that someone has accessed your account without your permission. You must not
                        permit others to use your account credentials. You are responsible for the activities of any
                        users that occur in connection with your account. We reserve the right to reclaim usernames,
                        including on behalf of businesses or individuals that hold legal claim, including trademark
                        rights, in those usernames.
                    </p>
                    <p>
                        Access to your account is limited solely to you. You will not sell, rent, lease, or grant access
                        to your account to any person without our prior written permission.
                    </p>
                    <p>
                        You are solely responsible for maintaining the security of your account and control over any
                        usernames, passwords, or any other codes that you use to access our Services. You will not hold
                        us responsible for managing and maintaining the security of your account. We are not responsible
                        (and you will not hold us responsible) for any unauthorized access to or use of your account.
                        You are responsible for monitoring your account. If you notice any unauthorized or suspicious
                        activity in your account, please notify us immediately.
                    </p>

                    <h4>4. Purchase and Safekeeping of Web3 Game Items</h4>
                    <p>
                        We do not offer a hosted digital wallet on the Platform or otherwise custody Digital
                        Collectibles or Web3 Game Items for our users as part of the Services. You will be required to
                        link a Web3 blockchain digital wallet (“<span className={"bold-white-text"}>Web3 Wallet</span>”)
                        that is compatible with the relevant Digital Collectible or Game to the Platform before you will
                        be able to purchase a Digital Collectible and play certain of the Games that incorporate Web3
                        Game Items. You can find more information about the Web3 Wallet compatibility requirements for
                        each type of Digital Collectible and Game available on the Platform here: <a
                        className="text-blue-600 underline" href="https://metamask.io/faqs/">Metamask</a> or <a
                        className="text-blue-600 underline" href="https://help.phantom.app/hc/en-us">Phantom</a> wallet.
                        We will deliver any Digital Collectibles that you purchase from us directly to your Web3
                        Wallet and any Web3 Game Items that you earn during gameplay will be transferred via smart
                        contract to your Web3 Wallet. You will be responsible for safekeeping the passwords and keys
                        associated with your Web3 Wallet. We will not be able to recover Digital Collectible or Web3
                        Game Items for you in the event that you lose access to your Web3 Wallet account or under any
                        other circumstances.
                    </p>
                    <p>
                        We currently only accept payments in the supported cryptocurrencies listed on our website
                        here: <a className="text-blue-600 underline"
                                 href="https://expopulus.com/">https://expopulus.com/</a>. You cannot maintain a balance
                        of cryptocurrency with us for purposes of making purchases through our Services. You must link
                        your Web3 Wallet to the Services and use a supported type of cryptocurrency from your Web3
                        Wallet to complete your purchase.
                    </p>
                    <p>
                        ALL PRICING AND PAYMENT TERMS ARE AS SET FORTH ON THE PLATFORM, AND ANY PAYMENT OBLIGATIONS YOU
                        INCUR ARE BINDING AT THE TIME OF PURCHASE. ALL DIGITAL COLLECTIBLE SALES, AND ANY PAYMENTS MADE
                        IN CONNECTION WITH THE GAME, ARE FINAL. UNLESS OTHERWISE REQUIRED BY LAW, WE WILL NOT PROVIDE A
                        REFUND ON ANY DIGITAL COLLECTIBLE PURCHASE OR PAYMENT MADE IN CONNECTION WITH THE GAME.
                    </p>
                    <p>
                        ALL TRANSACTIONS (E.G., TRANSFERS, SALES) THAT YOU ENGAGE IN INVOLVING WEB3 GAME ITEMS ARE
                        FINAL. ALL SUCH TRANSACTIONS OCCUR THROUGH YOUR PERSONAL WEB3 WALLET ON A BLOCKCHAIN NETWORK. WE
                        HAVE NO ABILITY TO REVERSE OR MODIFY ANY SUCH TRANSACTIONS. WE WILL HAVE NO LIABILITY TO YOU OR
                        TO ANY THIRD PARTY FOR ANY CLAIMS OR DAMAGES THAT MAY ARISE AS A RESULT OF ANY TRANSACTIONS THAT
                        YOU ENGAGE IN INVOLVING WEB3 IN-GAME ITEMS.
                    </p>
                    <p>
                        PLEASE CAREFULLY REVIEW ANY ADDITIONAL DEVELOPER TERMS PRESENTED TO YOU AT THE TIME OF SALE.
                        UNLESS THE DEVELOPER TERMS EXPRESSLY PROVIDE OTHERWISE, THESE DEVELOPER TERMS ARE BY AND BETWEEN
                        YOU AND THE DEVELOPER AND WE ARE NOT A PARTY TO OR LIABLE TO YOU UNDER SUCH TERMS.
                    </p>
                    <p>
                        YOU ARE COLLECTING, TRADING, PLAYING, OR PURCHASING DIGITAL COLLECTIBLES AND WEB3 GAME ITEMS
                        SOLELY FOR YOUR OWN COLLECTION, USE AND ENJOYMENT, AND NOT FOR SPECULATIVE OR INVESTMENT
                        PURPOSES, FOR USE AS A SUBSTITUTE FOR CURRENCY OR MEDIUM OF EXCHANGE, RESALE OR REDISTRIBUTION
                        AND YOU ARE NOT ACQUIRING ANY EQUITY OR OTHER OWNERSHIP OR PROFIT-SHARING INTEREST IN EX
                        POPULUS, ITS AFFILIATES OR ANY BRAND AS A RESULT OF YOUR ACQUISITION OF DIGITAL COLLECTIBLES OR
                        WEB3 GAME ITEMS.
                    </p>
                    <p>
                        YOU WILL NOT PORTRAY DIGITAL COLLECTIBLES OR WEB3 GAME ITEMS AS AN OPPORTUNITY TO GAIN AN
                        ECONOMIC BENEFIT OR PROFIT, OR AS AN INVESTMENT, EQUITY, OR ANY OTHER OWNERSHIP OR
                        PROFIT-SHARING INTEREST IN EX POPULUS, ITS AFFILIATES, OR ANY VIDEO GAME.
                    </p>
                    <p>
                        LEGISLATIVE OR REGULATORY CHANGES AT THE STATE, FEDERAL OR INTERNATIONAL LEVEL MAY ADVERSELY
                        AFFECT THE USE, TRANSFER, OR EXCHANGE OF DIGITAL COLLECTIBLES AND WEB3 GAME ITEMS.
                    </p>

                    <h4>5. Suspension of Access to Services</h4>
                    <p>
                        We have the right to suspend or terminate your account or access to our Services, with or
                        without notice, and refuse any current or future use of the Services at any time and for any
                        reason. We may immediately suspend your account, pause or cancel your access to our Services, or
                        close your account if we suspect, in our sole discretion, that (a) your Web3 Wallet is being
                        used for money laundering, to evade sanctions or to engage in illegal activity, (b) you have
                        concealed or provided false identification information or other details, (c) you have engaged in
                        fraudulent activity, or (d) you have engaged in transactions in violation of these Terms.
                    </p>

                    <h4>6. License to Services and Ownership</h4>
                    <p>
                        Our Services, including the text, graphics, images, photographs, videos, illustrations and other
                        content contained therein, are owned by Ex Populus or our licensors and are protected under both
                        United States and foreign laws. Except as explicitly stated in these Terms, all rights in and to
                        our Services are reserved by us or our licensors. Subject to your compliance with these Terms,
                        you are hereby granted a limited, nonexclusive, nontransferable, non-sublicensable, revocable
                        license to access and use our Services for your own personal, noncommercial use. Any use of our
                        Services other than as specifically authorized herein, without our prior written permission, is
                        strictly prohibited, will terminate the license granted herein and violate our intellectual
                        property rights.
                    </p>

                    <h4>7. Creative Work in Digital Collectibles</h4>
                    <p>
                        <span className="bold-text">7.1 Creative Work.</span> Each Digital Collectible is a
                        limited-edition blockchain-based token that
                        evidences ownership of a limited license to use one or more works of authorship, subject to the
                        Developer Terms, and may include copyrighted materials, trade names, trademarks or service
                        marks, and names, likenesses, or images of Ex Populus, individuals or entities associated with
                        Ex Populus or Developers (the "<span
                        className={"bold-white-text"}>Creative Work</span>"). It is important to understand, and
                        you hereby acknowledge, that the Digital Collectible is separate from the Creative Work.
                        Although the Digital Collectible itself is sold or otherwise transferred to you, the Creative
                        Work associated with the Digital Collectible is licensed to you, and not sold or otherwise
                        transferred to you. The Creative Work is neither stored nor embedded in the Digital Collectible,
                        but is accessible through the Digital Collectible, subject to compliance with these Terms and
                        the Developer Terms.
                    </p>
                    <p>
                        <span className="bold-text">7.2 Restrictions.</span> You will not, attempt to, or permit or
                        enable any third party to: (a) separate
                        the Creative Work from the Digital Collectible; (b) modify the Creative Work, unless expressly
                        permitted to do so pursuant to the Developer Terms; (c) register or attempt to register any
                        trademark or copyright or otherwise acquire additional intellectual property rights in or to the
                        Creative Work; (d) use the Creative Work in connection with images, videos or other content,
                        including content that is hateful, intolerant, violent, cruel or that could reasonably be found
                        to constitute hate speech or infringe upon the rights of others; (e) commercialize the Creative
                        Work or use the Creative Work in connection with any business, message, product, or service, or
                        in any manner that may imply endorsement of any business, message, product, or service; (f) use
                        the Creative Work in any manner that is likely to cause confusion or dilute, blur, or tarnish
                        the Creative Work or any intellectual property rights in the Creative Work; or (h) use the
                        Creative Work in any manner that violates the these Terms or the Developer Terms.
                    </p>
                    <p>
                        <span className="bold-text">7.3 Royalties.</span> Ex Populus and Developers, as well as their
                        affiliates, licensors and designee(s), may be entitled to receive royalties from your purchase
                        and subsequent sales of a Digital Collectible, which defray costs associated with licensing the
                        Creative Work and providing the Benefits to you.
                    </p>

                    <h4>8. Prohibited Conduct and Content</h4>
                    <p>
                        You will not violate any applicable law, contract, intellectual property right or other
                        third-party right or commit a tort, and you are solely responsible for your conduct while using
                        our Services. You will not:
                    </p>
                    <ul>
                        <li>
                            Use or attempt to use another user’s account without authorization from that user and Ex
                            Populus;
                        </li>
                        <li>
                            Impersonate or otherwise misrepresent your affiliation with a person or entity;
                        </li>
                        <li>Sell, resell or commercially use our Services;</li>
                        <li>
                            Copy, reproduce, distribute, publicly perform or publicly display all or portions of our
                            Services, except as expressly permitted by us or our licensors;
                        </li>
                        <li>
                            Modify our Services, remove any proprietary rights notices or markings, or otherwise make
                            any derivative works based upon our Services;
                        </li>
                        <li>
                            Use our Services other than for their intended purpose and in any manner that could
                            interfere with, disrupt, negatively affect or inhibit other users from fully enjoying our
                            Services or that could damage, disable, overburden or impair the functioning of our Services
                            in any manner;
                        </li>
                        <li>
                            Reverse engineer any aspect of our Services or do anything that might discover source code
                            or bypass or circumvent measures employed to prevent or limit access to any part of our
                            Services;
                        </li>
                        <li>
                            Attempt to circumvent any content-filtering techniques we employ or attempt to access any
                            feature or area of our Services that you are not authorized to access;
                        </li>
                        <li>
                            Use any data mining, robots or similar data gathering or extraction methods designed to
                            scrape or extract data from our Services;
                        </li>
                        <li>
                            Use the Services or content of the Services made available to you in any manner that: (a)
                            suggests an association with any other products, services, or brands (b) is likely to cause
                            confusion among customers, (c) is otherwise exploitative for any commercial purpose, or (d)
                            otherwise infringes on our intellectual property rights.
                        </li>
                        <li>
                            Make any offer to trade or pay any Digital Collectible, Web3 Game Item, cryptocurrency, or
                            money where the trade or payment is contingent upon an event such as the outcome of a
                            digital match or battle within the Game.
                        </li>
                        <li>
                            Develop or use any applications that interact with our Services without our prior written
                            consent; or
                        </li>
                        <li>
                            Use our Services for any illegal or unauthorized purpose, or engage in, encourage or promote
                            any activity that violates these Terms.
                        </li>
                    </ul>
                    <p>
                        Enforcement of this Section 8 is solely at Ex Populus 's discretion, and failure to enforce this
                        section in some instances does not constitute a waiver of our right to enforce it in other
                        instances. In addition, this Section 8 does not create any private right of action on the part
                        of any third party or any reasonable expectation that our Services will not contain any content
                        that is prohibited by such rules.
                    </p>

                    <h4>9. Trademarks</h4>
                    <p>
                        Ex Populus and our logos, our product or service names, our slogans and the look and feel of our
                        Services are trademarks of Ex Populus and may not be copied, imitated or used, in whole or in
                        part, without our prior written permission. All other trademarks, registered trademarks, product
                        names and company names or logos mentioned on our Services are the property of their respective
                        owners. Reference to any products, services, processes or other information by trade name,
                        trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement,
                        sponsorship or recommendation by us.
                    </p>

                    <h4>10. Feedback and User Content</h4>
                    <p>
                        <span className="bold-text">10.1. Feedback.</span> You may voluntarily post, submit or otherwise
                        communicate to us any questions,
                        comments, suggestions, ideas, original or creative materials or other information about Ex
                        Populus or our Services (collectively, "<span
                        className={"bold-white-text"}>Feedback</span>"). We may use such Feedback for any purpose,
                        commercial or otherwise, without acknowledgment or compensation to you, including, without
                        limitation, to develop, copy, publish, or improve the Feedback in Ex Populus 's sole discretion.
                        You understand that Ex Populus may treat Feedback as nonconfidential.
                    </p>
                    <p>
                        <span className="bold-text">10.2. User Content.</span> Subject to any limitations under
                        applicable law, you acknowledge and accept
                        that you have no property or other rights in any content on the Services, including but not
                        limited to any content that you may have created or developed and submitted to the Platform,
                        Game, Services, or Ex Populus (“<span className={"bold-white-text"}>User Content</span>”). By
                        submitting any User Content, and subject to any limitations under applicable law, you give up
                        any claims that the use of User Content violates any of your rights, including moral rights,
                        privacy rights, proprietary rights, publicity rights, rights to credit for material or ideas or
                        any other right, including the right to approve the way such User Content is used. Additionally,
                        you grant us and any successors and assigns a perpetual, royalty-free, worldwide license to use,
                        transmit, copy and display such submitted information and material in any and all media now
                        known or hereinafter devised and represent that you have all necessary rights to grant the
                        foregoing license. We may change, edit, or remove any User Content that we deem abusive,
                        illegal, indecent, obscene, offensive, threatening or otherwise violating our policies in any
                        way.
                    </p>

                    <h4>11. Third-Party Content</h4>
                    <p>
                        We may provide information about third-party products, services, activities or events, or we may
                        allow third parties to make their content and information available on or through our Services
                        (in each case, excluding all Creative Work) (collectively, "<span
                        className={"bold-white-text"}>Third-Party Content</span>"). We provide Third-Party Content
                        as a service to those interested in such content. Your dealings or correspondence with third
                        parties and your use of or interaction with any Third-Party Content are solely between you and
                        the third party. Ex Populus does not control or endorse, and makes no representations or
                        warranties regarding, any Third-Party Content, and your access to and use of such Third-Party
                        Content is at your own risk.
                    </p>

                    <h4>12. Indemnification</h4>
                    <p>
                        To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless
                        Ex Populus and our subsidiaries and affiliates, and each of our respective officers, directors,
                        agents, partners and employees (individually and collectively, the "<span
                        className={"bold-white-text"}>Ex Populus Parties</span>") from and against any losses,
                        liabilities, claims, demands, damages, expenses or costs ("<span
                        className={"bold-white-text"}>Claims</span>") arising out of or related to (a) your access
                        to or use of our Services (including, without limitation, Digital Collectibles, Web3 Game Items,
                        Benefits and Creative Works); (b) your User Content or Feedback; (c) your violation of these
                        Terms (including, without limitation, the terms and conditions with respect to Digital
                        Collectibles, Web3 Game Items, Benefits and Creative Works) or the Developer Terms; (d) your
                        violation, misappropriation or infringement of any intellectual property, privacy, or other
                        rights of another; or (e) your conduct in connection with our Services. You agree to cooperate
                        with the Ex Populus Parties in defending such Claims and pay all fees, costs and expenses
                        associated with defending such Claims (including, but not limited to, attorneys’ fees). Ex
                        Populus Parties will have control of the defense or settlement, at Ex Populus 's sole option, of
                        any third-party Claims.
                    </p>

                    <h4>13. Disclaimers</h4>
                    <p>
                        YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. EXCEPT AS OTHERWISE PROVIDED IN A WRITING BY US,
                        THE SERVICES AND ANY CONTENT IN THE SERVICES, ALL CREATIVE WORKS, BENEFITS, DIGITAL
                        COLLECTIBLES, AND WEB3 GAME ITEMS ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF
                        ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, EX
                        POPULUS DOES NOT REPRESENT OR WARRANT THAT OUR SERVICES, ANY CREATIVE WORKS, BENEFITS, DIGITAL
                        COLLECTIBLES, OR WEB3 GAME ITEMS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WHILE
                        EX POPULUS ATTEMPTS TO MAKE YOUR USE OF OUR SERVICES AND ANY CONTENT THEREIN, ALL CREATIVE
                        WORKS, BENEFITS, DIGITAL COLLECTIBLES, AND WEB3 GAME ITEMS SAFE, WE CANNOT AND DO NOT REPRESENT
                        OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU
                        ASSUME THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF OUR SERVICES.
                    </p>
                    <p>
                        EX POPULUS PARTIES WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
                        RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY ACCESS TO OR USE OF CREATIVE WORKS,
                        BENEFITS, DIGITAL COLLECTIBLES, OR WEB3 GAME ITEMS, INCLUDING BUT NOT LIMITED TO ANY LOSSES,
                        DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY
                        CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED
                        OR THIRD-PARTY COMPROMISED WALLET FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS; OR (E) ANY
                        UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING,
                        BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE, THE APPLICABLE BLOCKCHAIN, A THIRD
                        PARTY, OR YOU. EX POPULUS PARTIES WILL ALSO NOT BE RESPONSIBLE FOR: (A) ANY LATE, LOST,
                        MISROUTED, GARBLED OR DISTORTED OR DAMAGED TRANSMISSIONS, OR REGISTRATIONS; (B) PHONE,
                        ELECTRONIC, HARDWARE, SOFTWARE, NETWORK, INTERNET, OR OTHER COMPUTER OR COMMUNICATIONS-RELATED
                        MALFUNCTIONS OR FAILURES; (C) ANY SERVICES DISRUPTIONS, INJURIES, LOSSES OR DAMAGES; OR (D) ANY
                        TYPOGRAPHICAL ERRORS IN ANY MATERIALS ASSOCIATED WITH THE SERVICES.
                    </p>
                    <p>
                        DIGITAL COLLECTIBLES AND WEB3 GAME ITEMS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY
                        VIRTUE OF THE OWNERSHIP RECORD MAINTAINED ON THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSFER OF
                        CONTROL THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE APPLICABLE BLOCKCHAIN
                        NETWORK. YOU ACKNOWLEDGE THAT YOUR DECISION TO ENTER INTO ANY TRADE OF A DIGITAL COLLECTIBLE OR
                        WEB3 GAME ITEM, OR TO USE ANY DIGITAL COLLECTIBLE OR WEB3 GAME ITEM ON THE PLATFORM OR IN THE
                        GAMES, IS AT YOUR SOLE DISCRETION AND YOUR OWN RISK.
                    </p>
                    <p>
                        Digital Collectibles and Web3 Game Items are made available to you through the Platform on the
                        digital asset’s native blockchain. You will not be able to transfer your Digital Collectibles to
                        any digital wallet that is not compatible with this native blockchain, unless you use a
                        third-party cross-chain bridge that supports such transfers. In the event that you inadvertently
                        transfer a Digital Collectible or Web3 Game Item to a digital wallet that is not compatible with
                        the applicable blockchain, your Digital Collectible or Web3 Game Item may be permanently lost or
                        destroyed.
                    </p>
                    <p>
                        Digital Collectibles are transferable blockchain-based non-fungible tokens that evidence
                        ownership of a limited license to one or more Creative Works, and, in some cases, to access
                        Benefits, that is exclusive to the owner and thereby collectible as a form of alienable digital
                        property. Like physical collectibles, the price of non-fungible tokens may be subject to
                        fluctuation based upon broader industry trends and sentiment. Unlike cryptocurrencies and other
                        fungible digital assets, non-fungible tokens are not used to make payments. Non-fungible tokens
                        are not mutually interchangeable and cannot be divided into smaller parts. These design features
                        limit the usefulness of non-fungible tokens as a form of payment or substitute for currency.
                        Instead, non-fungible tokens are enjoyed as digital collectibles.
                    </p>
                    <p>
                        The legal framework for the regulation of blockchain-based digital assets in the U.S. and
                        internationally is rapidly evolving and subject to change. In the U.S., Congress has not
                        expressly granted authority to any regulator to regulate digital assets or adopted legislation
                        clarifying the application of the U.S. federal laws, including securities, commodity and money
                        services business laws, to digital assets. Legislative and regulatory changes or actions at the
                        state, federal, or international level may adversely affect the use, transfer, exchange, and
                        value of Digital Collectibles and Web3 Game Items.
                    </p>
                    <p>
                        There is no guaranteed future value for Digital Collectibles or Web3 Game Items. Any future
                        value of a Digital Collectible or Web3 Game Item is based solely on consumer interest and demand
                        for that Digital Collectible or Web3 Game Item and not something that Ex Populus or the
                        Developer can control or will attempt to control or influence.
                    </p>
                    <p>
                        We are not responsible for sustained losses due to vulnerability or any kind of failure,
                        abnormal behavior of software (e.g., wallet, smart contract), the applicable blockchain, or any
                        other features of Digital Collectibles or Web3 Game Items. We are not responsible for sustained
                        losses due any issues with the applicable blockchain supporting Digital Collectibles or Web3
                        Game Items including forks, technical node issues or any other issues having fund losses as a
                        result.
                    </p>
                    <p>
                        In some cases, Ex Populus integrates directly with third parties, including but not limited to,
                        online merchant platforms, mailing list platforms, and social media platforms ("<span
                        className={"bold-white-text"}>Third Party APIs</span>"). Ex Populus has no control over the
                        uptime and functionality made available through Third Party APIs and as such certain aspects of
                        the Platform could incur an outage outside of Ex Populus’s control, and certain aspects of the
                        Platform’s functionality could be impacted by changes of features made available through Third
                        Party APIs.
                    </p>
                    <p>
                        The Ex Populus Platform is subject to flaws and acknowledge that you are solely responsible for
                        evaluating any code provided by the Platform. Ex Populus may experience cyber-attacks,
                        unexpected surges in activity, or other operational or technical difficulties that may cause
                        interruptions to or delays on the Platform. You accept the risk of the Platform failure
                        resulting from unanticipated or heightened technical difficulties, including those resulting
                        from sophisticated attacks, and you agree not to hold us accountable for any related losses.
                    </p>

                    <h4>14. Limitation of Liability</h4>
                    <p>
                        To the fullest extent permitted by applicable law, Ex Populus and the other Ex Populus Parties
                        will not be liable to you under any theory of liability—whether based in contract, tort,
                        negligence, strict liability, warranty, or otherwise—for any indirect, consequential, exemplary,
                        incidental, punitive or special damages or lost profits, even if Ex Populus or the other Ex
                        Populus Parties have been advised of the possibility of such damages.
                    </p>
                    <p>
                        The total liability of Ex Populus and the other Ex Populus Parties for any claim arising out of
                        or relating to these Terms or our Services, regardless of the form of the action, is limited to
                        $100.
                    </p>
                    <p>
                        The limitations set forth in this Section 14 will not limit or exclude liability for the gross
                        negligence, fraud or intentional misconduct of Ex Populus or the other Ex Populus Parties or for
                        any other matters in which liability cannot be excluded or limited under applicable law.
                        Additionally, some jurisdictions do not allow the exclusion or limitation of incidental or
                        consequential damages, so the above limitations or exclusions may not apply to you.
                    </p>

                    <h4>15. Release</h4>
                    <p>
                        To the fullest extent permitted by applicable law, you release Ex Populus and the other Ex
                        Populus Parties from responsibility, liability, claims, demands and/or damages (actual and
                        consequential) of every kind and nature, known and unknown (including, but not limited to,
                        claims of negligence), arising out of or related to disputes between users or the acts or
                        omissions of third parties. If you are a consumer who resides in California, you hereby waive
                        your rights under California Civil Code § 1542, which provides: “A general release does not
                        extend to claims that the creditor or releasing party does not know or suspect to exist in his
                        or her favor at the time of executing the release and that, if known by him or her, would have
                        materially affected his or her settlement with the debtor or released party.”
                    </p>

                    <h4>16. Transfer and Processing Data</h4>
                    <p>
                        In order for us to provide our Services, we may process, transfer and store information about
                        you in the United States and other countries, where you may not have the same rights and
                        protections as you do under local law.
                    </p>

                    <h4>17. Dispute Resolution; Binding Arbitration</h4>
                    <p>
                        Please read the following section carefully because it requires you to arbitrate certain
                        disputes and claims with Ex Populus and limits the manner in which you can seek relief from us,
                        unless you opt out of arbitration by following the instructions set forth below. No class or
                        representative actions or arbitrations are allowed under this arbitration provision. In
                        addition, arbitration precludes you from suing in court or having a jury trial.
                    </p>
                    <p>
                        <span className="bold-text">17.1 No Representative Actions.</span> You and Ex Populus agree that
                        any dispute arising out of or
                        related to these Terms or our Services is personal to you and Ex Populus and that any dispute
                        will be resolved solely through individual action, and will not be brought as a class
                        arbitration, class action or any other type of representative proceeding.
                    </p>
                    <p>
                        <span className="bold-text">17.2 Arbitration of Disputes.</span> Except for small claims
                        disputes in which you or Ex Populus seeks
                        to bring an individual action in small claims court located in the county of your billing
                        address or disputes in which you or Ex Populus seeks injunctive or other equitable relief for
                        the alleged infringement or misappropriation of intellectual property, you and Ex Populus waive
                        your rights to a jury trial and to have any other dispute arising out of or related to these
                        Terms or our Services, including claims related to privacy and data security, (collectively,
                        “<span className={"bold-white-text"}>Disputes</span>”) resolved in court. Instead, for any
                        Dispute that you have against Ex Populus you agree to first contact Ex Populus and attempt to
                        resolve the claim informally by sending a written notice of your claim (“<span
                        className={"bold-white-text"}>Notice</span>”) to Ex Populus by email at legal@expopulus.com or
                        by certified mail addressed to 2120 University Ave, Unit 607, Berkeley CA 94704. The Notice must
                        (a) include your name, residence address, email address, and telephone number; (b) describe the
                        nature and basis of the Dispute; and (c) set forth the specific relief sought. Our notice to you
                        will be similar in form to that described above. If you and Ex Populus cannot reach an agreement
                        to resolve the Dispute within thirty (30) days after such Notice is received, then either party
                        may submit the Dispute to binding arbitration administered by JAMS or, under the limited
                        circumstances set forth above, in court. All Disputes submitted to JAMS will be resolved through
                        confidential, binding arbitration before one arbitrator. Arbitration proceedings will be held in
                        Alameda County, California unless you are a consumer, in which case you may elect to hold the
                        arbitration in your county of residence. For purposes of this Section 17, a “consumer” means a
                        person using the Services for personal, family or household purposes. You and Ex Populus agree
                        that Disputes will be held in accordance with the JAMS Streamlined Arbitration Rules and
                        Procedures (“<span className={"bold-white-text"}>JAMS Rules</span>”). The most recent version of
                        the JAMS Rules are available on the <a className="text-blue-600 underline"
                                                               href="https://www.jamsadr.com/rules-streamlined-arbitration/">JAMS
                        website</a> and are hereby incorporated by reference. You either acknowledge and agree that you
                        have read and understand the JAMS Rules or waive your opportunity to read the JAMS Rules and
                        waive any claim that the JAMS Rules are unfair or should not apply for any reason.
                    </p>
                    <p>
                        <span className="bold-text">17.3</span> You and Ex Populus agree that these Terms affect
                        interstate commerce and that the
                        enforceability of this Section 17 will be substantively and procedurally governed by the Federal
                        Arbitration Act, 9 U.S.C. § 1, et seq. (the “<span className={"bold-white-text"}>FAA</span>”),
                        to the maximum extent permitted by
                        applicable law. As limited by the FAA, these Terms and the JAMS Rules, the arbitrator will have
                        exclusive authority to make all procedural and substantive decisions regarding any Dispute and
                        to grant any remedy that would otherwise be available in court, including the power to determine
                        the question of arbitrability. The arbitrator may conduct only an individual arbitration and may
                        not consolidate more than one individual’s claims, preside over any type of class or
                        representative proceeding or preside over any proceeding involving more than one individual.

                    </p>
                    <p>
                        <span className="bold-text">17.4</span> The arbitration will allow for the discovery or exchange
                        of non-privileged information
                        relevant to the Dispute. The arbitrator, Ex Populus, and you will maintain the confidentiality
                        of any arbitration proceedings, judgments and awards, including information gathered, prepared
                        and presented for purposes of the arbitration or related to the Dispute(s) therein. The
                        arbitrator will have the authority to make appropriate rulings to safeguard confidentiality,
                        unless the law provides to the contrary. The duty of confidentiality does not apply to the
                        extent that disclosure is necessary to prepare for or conduct the arbitration hearing on the
                        merits, in connection with a court application for a preliminary remedy or in connection with a
                        judicial challenge to an arbitration award or its enforcement, or to the extent that disclosure
                        is otherwise required by law or judicial decision.
                    </p>
                    <p>
                        <span className="bold-text">17.5</span> You and Ex Populus agree that for any arbitration you
                        initiate, you will pay the filing fee
                        (up to a maximum of $250 if you are a consumer), and Ex Populus will pay the remaining JAMS fees
                        and costs. For any arbitration initiated by Ex Populus, Ex Populus will pay all JAMS fees and
                        costs. You and Ex Populus agree that the state or federal courts of the State of California and
                        the United States sitting in Alameda County, California have exclusive jurisdiction over any
                        appeals and the enforcement of an arbitration award.
                    </p>
                    <p>
                        <span className="bold-text">17.6</span> Any Dispute must be filed within one year after the
                        relevant claim arose; otherwise, the
                        Dispute is permanently barred, which means that you and Ex Populus will not have the right to
                        assert the claim.
                    </p>
                    <p>
                        <span className="bold-text">17.7</span> You have the right to opt out of binding arbitration
                        within 30 days of the date you first
                        accepted the terms of this Section 17 by emailing us at legal@expopulus.com. In order to be
                        effective, the opt-out notice must include your full name and address and clearly indicate your
                        intent to opt out of binding arbitration. By opting out of binding arbitration, you are agreeing
                        to resolve Disputes in accordance with Section 18.
                    </p>
                    <p>
                        <span className="bold-text">17.8</span> If any portion of this Section 17 is found to be
                        unenforceable or unlawful for any reason,
                        (a) the unenforceable or unlawful provision shall be severed from these Terms; (b) severance of
                        the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this
                        Section 17 or the parties’ ability to compel arbitration of any remaining claims on an
                        individual basis pursuant to this Section 17; and (c) to the extent that any claims must
                        therefore proceed on a class, collective, consolidated, or representative basis, such claims
                        must be litigated in a civil court of competent jurisdiction and not in arbitration, and the
                        parties agree that litigation of those claims shall be stayed pending the outcome of any
                        individual claims in arbitration. Further, if any part of this Section 17 is found to prohibit
                        an individual claim seeking public injunctive relief, that provision will have no effect to the
                        extent such relief is allowed to be sought out of arbitration, and the remainder of this Section
                        17 will be enforceable.
                    </p>

                    <h4>18. Governing Law and Venue</h4>
                    <p>
                        Any dispute arising from these Terms and your use of our Services will be governed by and
                        construed and enforced in accordance with the laws of the State of California, except to the
                        extent preempted by U.S. federal law, without regard to conflict of law rules or principles
                        (whether of the State of California or any other jurisdiction) that would cause the application
                        of the laws of any other jurisdiction. Any dispute between the parties that is not subject to
                        arbitration or cannot be heard in small claims court will be resolved in the state or federal
                        courts of the State of California and the United States, respectively, sitting in San Francisco,
                        California.
                    </p>

                    <h4>19. Modifying and Terminating our Services</h4>
                    <p>
                        We reserve the right to modify our Services or to suspend or stop providing all or portions of
                        our Services at any time. We may also, from time to time, issue patches or updates for portions
                        of the Services. You also have the right to stop using our Services at any time. We are not
                        responsible for any loss or harm related to your inability to access or use our Services.
                    </p>

                    <h4>20. Severability</h4>
                    <p>
                        If any provision or part of a provision of these Terms is unlawful, void or unenforceable, that
                        provision or part of the provision is deemed severable from these Terms and does not affect the
                        validity and enforceability of any remaining provisions.
                    </p>

                    <h4>21. Miscellaneous</h4>
                    <p>
                        These Terms constitute the entire agreement between you and Ex Populus relating to your access
                        to and use of our Services. Notwithstanding any other provisions of these Terms, Sections 6
                        through 21 survive any expiration or termination of these terms. The failure of Ex Populus to
                        exercise or enforce any right or provision of these Terms will not operate as a waiver of such
                        right or provision. The section titles in these Terms are for convenience only and have no legal
                        or contractual effect. Except as otherwise provided herein, these Terms are intended solely for
                        the benefit of the parties and are not intended to confer third-party beneficiary rights upon
                        any other person or entity. You agree that communications and transactions between us may be
                        conducted electronically. These Terms may not be transferred, assigned or delegated by you, by
                        operation of law or otherwise, without our prior written consent, and any attempted transfer,
                        assignment or delegation without such consent will be void and without effect. We may freely
                        transfer, assign or delegate these Terms or our Services, in whole or in part, without your
                        prior written consent.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TermsOfServicePage;
