"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCountResponseToJSON = exports.ErrorCountResponseFromJSONTyped = exports.ErrorCountResponseFromJSON = exports.instanceOfErrorCountResponse = void 0;
/**
 * Check if a given object implements the ErrorCountResponse interface.
 */
function instanceOfErrorCountResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "errorCount" in value;
    isInstance = isInstance && "errors" in value;
    return isInstance;
}
exports.instanceOfErrorCountResponse = instanceOfErrorCountResponse;
function ErrorCountResponseFromJSON(json) {
    return ErrorCountResponseFromJSONTyped(json, false);
}
exports.ErrorCountResponseFromJSON = ErrorCountResponseFromJSON;
function ErrorCountResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errorCount': json['errorCount'],
        'errors': json['errors'],
    };
}
exports.ErrorCountResponseFromJSONTyped = ErrorCountResponseFromJSONTyped;
function ErrorCountResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errorCount': value.errorCount,
        'errors': value.errors,
    };
}
exports.ErrorCountResponseToJSON = ErrorCountResponseToJSON;
