import React from "react";
import {TwitterButton} from "./TwitterButton";
import {Asset} from "client/marketplace";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

const body: ITeam = {
    header: "Team",
    subheader: "Meet the Team",
    teamMembers: [
        {
            name: "Mike Tyson",
            twitterLink: "https://twitter.com/MikeTyson",
            asset: {
                url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/team/team_mike.png",
                contentType: "image",
                cloudinary: {
                    publicId: "website/images/team_mike_h4gvsg",
                    actions: {
                        desktop: ["f_auto", "q_auto"],
                        mobile: ["f_auto", "q_auto"]
                    },
                },
            } as unknown as Asset,
        },
        {
            name: "Jeff",
            twitterLink: "https://twitter.com/jeffrhood",
            asset: {
                url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/team/team_jeff.png",
                contentType: "image",
                cloudinary: {
                    publicId: "website/images/team_jeff_zhclj7",
                    actions: {
                        desktop: ["f_auto", "q_auto"],
                        mobile: ["f_auto", "q_auto"]
                    },
                },
            } as unknown as Asset,
        },
        {
            name: "Soby",
            twitterLink: "https://twitter.com/sobylife",
            asset: {
                url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/team/team_soby.png",
                contentType: "image",
                cloudinary: {
                    publicId: "website/images/team_soby_cifoba",
                    actions: {
                        desktop: ["f_auto", "q_auto"],
                        mobile: ["f_auto", "q_auto"]
                    },
                },
            } as unknown as Asset,
        },
        {
            name: "Toby",
            twitterLink: "https://twitter.com/tobyonek",
            asset: {
                url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/Iron%20Pigeons/team/team_tobias.png",
                contentType: "image",
                cloudinary: {
                    publicId: "website/images/team_tobias_nvi2nv",
                    actions: {
                        desktop: ["f_auto", "q_auto"],
                        mobile: ["f_auto", "q_auto"]
                    },
                },
            } as unknown as Asset,
        },
    ],
}

export interface ITeamMember {
    name: string;
    asset: Asset;
    twitterLink?: string;
}

export interface ITeam {
    header: string;
    subheader: string;
    teamMembers?: Array<ITeamMember>;
}

const TeamMember: React.FC<ITeamMember> = ({name, asset, twitterLink}) => (
    <div className="team-member">
        <MediaAssetViewer
            asset={asset}
            contentType={fileIsImage(asset) ? "image" : "video"}
            elementWidth={{
                desktopWidth: 382,
                mobileWidth: 382,
            }}
        />
        <div className="name">{name}</div>
        <TwitterButton twitterLink={twitterLink}/>
    </div>
);

const IronPigeonsHomepageTeam: React.FC = () => {
    const {header, subheader, teamMembers} = body;

    if (!teamMembers?.length) {
        return null;
    }

    return (
        <section className="homepage-template-team">
            <h2 className="header">{header}</h2>
            <h3 className="subheader">{subheader}</h3>
            <div className="team-members">
                {teamMembers.map((teamMember, index) => (<TeamMember key={`team-member_${index}`} {...teamMember} />))}
            </div>
        </section>
    )
};

export {IronPigeonsHomepageTeam};
