"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignCloudinaryUploadBodyToJSON = exports.SignCloudinaryUploadBodyFromJSONTyped = exports.SignCloudinaryUploadBodyFromJSON = exports.instanceOfSignCloudinaryUploadBody = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SignCloudinaryUploadBody interface.
 */
function instanceOfSignCloudinaryUploadBody(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSignCloudinaryUploadBody = instanceOfSignCloudinaryUploadBody;
function SignCloudinaryUploadBodyFromJSON(json) {
    return SignCloudinaryUploadBodyFromJSONTyped(json, false);
}
exports.SignCloudinaryUploadBodyFromJSON = SignCloudinaryUploadBodyFromJSON;
function SignCloudinaryUploadBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'public_id': !(0, runtime_1.exists)(json, 'public_id') ? undefined : json['public_id'],
        'folder': !(0, runtime_1.exists)(json, 'folder') ? undefined : json['folder'],
        'eager_async': !(0, runtime_1.exists)(json, 'eager_async') ? undefined : json['eager_async'],
        'eager': !(0, runtime_1.exists)(json, 'eager') ? undefined : json['eager'],
    };
}
exports.SignCloudinaryUploadBodyFromJSONTyped = SignCloudinaryUploadBodyFromJSONTyped;
function SignCloudinaryUploadBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'public_id': value.public_id,
        'folder': value.folder,
        'eager_async': value.eager_async,
        'eager': value.eager,
    };
}
exports.SignCloudinaryUploadBodyToJSON = SignCloudinaryUploadBodyToJSON;
