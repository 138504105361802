"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginBodyToJSON = exports.LoginBodyFromJSONTyped = exports.LoginBodyFromJSON = exports.instanceOfLoginBody = void 0;
/**
 * Check if a given object implements the LoginBody interface.
 */
function instanceOfLoginBody(value) {
    var isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    return isInstance;
}
exports.instanceOfLoginBody = instanceOfLoginBody;
function LoginBodyFromJSON(json) {
    return LoginBodyFromJSONTyped(json, false);
}
exports.LoginBodyFromJSON = LoginBodyFromJSON;
function LoginBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
    };
}
exports.LoginBodyFromJSONTyped = LoginBodyFromJSONTyped;
function LoginBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
    };
}
exports.LoginBodyToJSON = LoginBodyToJSON;
