import React from "react";
import { Container } from "reactstrap";
import { FAQElement, IHomepageFAQ } from "../FAQElement";

const body: Array<IHomepageFAQ> = [
  {
    question: "What can I do with Iron Pigeons?",
    answer:
      "Mike Tyson's Iron Pigeons trading cards are collectible NFT cards playable within the game Final Form. In the game, Iron Pigeons may be pledged to extract Chromos - the lifeblood of evolution - which you can then use to evolve your cards to higher tiers of rarity.",
  },
  {
    question: "What is an NFT?",
    answer:
      "An NFT is a unique and non-interchangeable unit of data stored on a digital ledger. In short, it proves you own something digitally and identifies authentic copies and owners.",
  },
  {
    question:
      "I want to use an Iron Pigeon in creative media. Who do I contact?",
    answer: "Please reach out to Ex Populus at hello@expopulus.com",
  },
  {
    question: "When will I be able to mint new Iron Pigeons?",
    answer: (
      <p>
        Iron Pigeons are available on Magic Eden here:
        <a
          href="https://magiceden.io/marketplace/iron_pigeons"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          https://magiceden.io/marketplace/iron_pigeons
        </a>
        <br />
        <br />
        Follow our
        <a
          href="https://twitter.com/xai_games"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Twitter
        </a>{" "}
        and join our
        <a
          href="https://discord.gg/xaigames"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Discord
        </a>{" "}
        server for alerts when new Iron Pigeons are available to mint. In the
        meantime, you can purchase minted Iron Pigeons from Magic Eden, OpenSea,
        Solsea, and Solanart.
      </p>
    ),
  },
];

const IronPigeonsHomepageFAQ: React.FC = () => {
  return (
    <section className="homepage-template-faq">
      <Container className="container">
        <div className="homepage-template-faq_container">
          <div className="title-section">
            <div className="heading">FAQ</div>
            <div className="title">Frequently Asked Questions</div>
          </div>

          <div className="faq-section">
            {body?.map((question, index) => (
              <FAQElement key={index} question={question} />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export { IronPigeonsHomepageFAQ };
