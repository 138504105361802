import {ApiError, ErrorLevel, ErrorType, FormError} from "client/cs";

// centralized error handler encapsulates error-handling related logic
export default class ErrorHandler {
    private static isApiErrorLike(error: unknown): error is Record<keyof ApiError, any> {
        if (!error || typeof error !== "object") {
            return false;
        }

        if (!error.hasOwnProperty("type") || !error.hasOwnProperty("message")) {
            return false;
        }

        return true;
    }

    public static isApiError(error: unknown): error is ApiError {
        if (!ErrorHandler.isApiErrorLike(error)) {
            return false;
        }

        const {type, level, message, date, errors, httpStatus, httpMethod, path} = error;

        if (!Object.values(ErrorType).includes(type)) {
            return false;
        }

        if (level && !Object.values(ErrorLevel).includes(level)) {
            return false;
        }

        if (typeof message !== "string") {
            return false;
        }

        if (date != null && typeof date !== "number") {
            return false;
        }

        if (errors && (!Array.isArray(errors) || errors.some(e => !ErrorHandler.isApiError(e)))) {
            return false;
        }

        if (httpStatus != null && typeof httpStatus !== "number") {
            return false;
        }

        if (httpMethod != null && typeof httpMethod !== "string") {
            return false;
        }

        if (path != null && typeof path !== "string") {
            return false;
        }

        return true;
    }

    private static isFormErrorLike(error: unknown): error is ApiError & {formError?: any} {
        if (!ErrorHandler.isApiError(error)) {
            return false;
        }

        return error.hasOwnProperty("formError");
    }

    private static isFormError(error: unknown): error is FormError {
        if (!ErrorHandler.isFormErrorLike(error)) {
            return false;
        }

        if (error.formError && (typeof error.formError !== "object" || Object.values(error.formError).some(e => !ErrorHandler.isApiError(e)))) {
            return false;
        }

        return true;
    }

    public static makeDefaultError(): ApiError {
        return {
            type: ErrorType.APP,
            level: ErrorLevel.OPERATIONAL,
            message: "An unknown error has occurred. Please contact support or try again.",
            date: Date.now(),
        }
    }

    public static async handleError(error: unknown): Promise<ApiError> {
        if (ErrorHandler.isApiError(error)) {
            return error;
        }

        if (error instanceof Response) {
            const fetchError =  await error.json();
            if (ErrorHandler.isApiError(fetchError)) {
                return fetchError;
            }
        }

        return ErrorHandler.makeDefaultError();
    }
}