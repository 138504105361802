import {InjectedConnector} from "@web3-react/injected-connector";
import {Web3Provider} from '@ethersproject/providers';

export const injectedConnector = new InjectedConnector({
	supportedChainIds: [
		1, // Mainnet
		3, // Ropsten
		4, // Rinkeby
		5, // Goerli
		42, // Kovan
		56, // Binance Smart Chain
		97, // Binance Smart Chain Testnet
	],
})

export function getLibrary(provider: any): Web3Provider {
	const library = new Web3Provider(provider)
	library.pollingInterval = 12000
	return library
}

// to use the hook you use...
// const { chainId, account, library, activate, active } = useWeb3React<Web3Provider>();
//
// /**
//  * Connects to metamask and grabs the network
//  */
// async function activateMetamask() {
// 	props.dispatch(incrementLoading());
// 	if (active) {
// 		return;
// 	}
// 	try {
// 		await activate(injectedConnector) // import from the util
// 	} catch (err) {
// 		props.dispatch(addError({messages: ["You must connect a web3 provider in order to submit a note. Try googling Metamask!"]}))
// 	}
// 	props.dispatch(decrementLoading());
// }
//
// /**
//  * Starts the purchase transaction using metamask
//  */
// async function purchaseWithProvider() {
// 	props.dispatch(incrementLoading());
//
// 	try {
// 		const contract = <ContractURI> new Contract("0x2eDE40032ddEca24bF3454d5b4C42B6C8a88d2CB", frameOneSoftwareIdentifierArtifact.abi, library.getSigner())
// 		const price = await contract.getPrice();
// 		await contract.submitNote(BigNumber.from(123), note, {value: price});
// 	} catch (err) {
// 		if (err?.data?.message) {
// 			props.dispatch(addError({messages: [err?.data?.message]}));
// 		} else {
// 			props.dispatch(addError({messages: ["There was an error in performing the transaction."]}));
// 		}
// 		console.error(err);
// 	}
// 	props.dispatch(decrementLoading());
// }
