"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadSolanaPayTransactionGetResponseToJSON = exports.ReadSolanaPayTransactionGetResponseFromJSONTyped = exports.ReadSolanaPayTransactionGetResponseFromJSON = exports.instanceOfReadSolanaPayTransactionGetResponse = void 0;
/**
 * Check if a given object implements the ReadSolanaPayTransactionGetResponse interface.
 */
function instanceOfReadSolanaPayTransactionGetResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "label" in value;
    isInstance = isInstance && "icon" in value;
    return isInstance;
}
exports.instanceOfReadSolanaPayTransactionGetResponse = instanceOfReadSolanaPayTransactionGetResponse;
function ReadSolanaPayTransactionGetResponseFromJSON(json) {
    return ReadSolanaPayTransactionGetResponseFromJSONTyped(json, false);
}
exports.ReadSolanaPayTransactionGetResponseFromJSON = ReadSolanaPayTransactionGetResponseFromJSON;
function ReadSolanaPayTransactionGetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': json['label'],
        'icon': json['icon'],
    };
}
exports.ReadSolanaPayTransactionGetResponseFromJSONTyped = ReadSolanaPayTransactionGetResponseFromJSONTyped;
function ReadSolanaPayTransactionGetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'icon': value.icon,
    };
}
exports.ReadSolanaPayTransactionGetResponseToJSON = ReadSolanaPayTransactionGetResponseToJSON;
