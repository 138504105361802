import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";

export enum MetaAction {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    LOADING = "LOADING",
    ADD_ERROR = "ADD_ERROR",
    REMOVE_ERROR = "REMOVE_ERROR",
    SIDEBAR = "SIDEBAR",
    UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
    AGREE_COOKIES = "AGREE_COOKIES",
    PASSWORD_RESET_MODAL_SUCCESS = "PASSWORD_RESET_MODAL_SUCCESS",
}

export default function (store: IMetaStore = InitialMetaStore, action: IAction<MetaAction, any>): IMetaStore {
    const newStore: IMetaStore = cloneDeep(store);

    switch (action.type) {
        case MetaAction.LOGIN:
            newStore.fullToken = action.payload;
            break;
        case MetaAction.LOGOUT:
            delete newStore.fullToken;
            delete newStore.currentUser;
            break;
        case MetaAction.LOADING:
            if (newStore.loadingIncrement + action.payload >= 0) {
                newStore.loadingIncrement += action.payload;
            } else {
                newStore.loadingIncrement = 0;
            }
            break;
        case MetaAction.ADD_ERROR:
            newStore.errors.push(action.payload);
            break;
        case MetaAction.REMOVE_ERROR:
            newStore.errors.splice(action.payload, 1);
            break;
        case MetaAction.SIDEBAR:
            newStore.sidebarVisible = action.payload;
            break;
        case MetaAction.UPDATE_CURRENT_USER:
            newStore.currentUser = action.payload;
            break;
        case MetaAction.AGREE_COOKIES:
            newStore.agreedCookies = true;
            break;
        case MetaAction.PASSWORD_RESET_MODAL_SUCCESS:
            newStore.showSuccessfulPasswordResetModal = action.payload;
            break;
        default:
            break;
    }

    return newStore;
}

