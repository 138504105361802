"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftFileToJSON = exports.NftFileFromJSONTyped = exports.NftFileFromJSON = exports.instanceOfNftFile = void 0;
/**
 * Check if a given object implements the NftFile interface.
 */
function instanceOfNftFile(value) {
    var isInstance = true;
    isInstance = isInstance && "uri" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
exports.instanceOfNftFile = instanceOfNftFile;
function NftFileFromJSON(json) {
    return NftFileFromJSONTyped(json, false);
}
exports.NftFileFromJSON = NftFileFromJSON;
function NftFileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'uri': json['uri'],
        'type': json['type'],
    };
}
exports.NftFileFromJSONTyped = NftFileFromJSONTyped;
function NftFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'uri': value.uri,
        'type': value.type,
    };
}
exports.NftFileToJSON = NftFileToJSON;
