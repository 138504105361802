import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { GAMESIGHT_EVENTS } from "shared";
import { ProjectPageSection } from "../../utils/projectHeaderUtils";
import { IronPigeonsGetCards } from "./IronPigeonsGetCards";
import { sendEvent } from "../../utils/event-tracking";
import { IronPigeonsHeader } from "./IronPigeonsHeader";
import { IronPigeonsHomepageHero } from "./IronPigeonsHomepageHero";
import { GetFinalFormCardsBanner } from "../GetFinalFormCardsBanner";
import { IronPigeonsHomepageAbout } from "./IronPigeonsHomepageAbout";
import { IronPigeonsHomepageVideo } from "./IronPigeonsHomepageVideo";
import { IronPigeonsHomepagePlayFree } from "./IronPigeonsHomepagePlayFree";
import { IronPigeonsHomepageTotalCount } from "./IronPigeonsHomepageTotalCount";
import { IronPigeonsHomepageTraitsAndRarity } from "./IronPigeonsHomepageTraitsAndRarity";
import { IronPigeonsHomepageTeam } from "./IronPigeonsHomepageTeam";
import { IronPigeonsHomepageSet } from "./IronPigeonsHomepageSet";
import { IronPigeonsHomepageFAQ } from "./IronPigeonsHomepageFAQ";
import { TokenType } from "../../utils/TokenType";
import { useSelector } from "react-redux";
import { IStore } from "../../redux/defaultStore";
import { ArbitrumComingSoon } from "../dag/ArbitrumComingSoon";

const IronPigeonsHomepage: React.FC = () => {
  const homepageHeroRef = useRef(null);
  const homepagePlayFreeRef = useRef(null);
  const homepageAboutRef = useRef(null);
  const homepageGetNowRef = useRef(null);
  const homepageTraitsAndRarityRef = useRef(null);
  const homepageSetRef = useRef(null);
  const homepageTeamRef = useRef(null);
  const homepageFAQRef = useRef(null);

  const history = useHistory();
  const currentUser = useSelector(
    (store: IStore) => store.metaStore.currentUser
  );

  useEffect(() => {
    sendEvent({
      eventName: GAMESIGHT_EVENTS.IP_WEB_PAGEVIEW,
      user: currentUser,
    });

    window.onpageshow = function (event) {
      if (event.persisted) {
        sendEvent({
          eventName: GAMESIGHT_EVENTS.IP_WEB_PAGEVIEW,
          user: currentUser,
        });
      }
    };

    return () => {
      window.onpageshow = undefined;
    };
  }, [currentUser]);

  useEffect(() => {
    if (!history.location.pathname.endsWith("/")) {
      history.push(`${history.location.pathname}/#`);
    }
  }, []);

  const trackGetCardsButtonClick = () =>
    sendEvent({
      eventName: GAMESIGHT_EVENTS.IP_WEB_GET_CARDS,
      sendToGameSight: false,
    });

  const trackSecondaryMarketClick = () => {
    sendEvent({
      eventName: GAMESIGHT_EVENTS.IP_WEB_SECONDARY_MARKET,
      user: currentUser,
    });
  };

  return (
    <section>
      <IronPigeonsHeader
        refList={{
          [ProjectPageSection.HOME]: homepageHeroRef,
          [ProjectPageSection.PLAY_FREE]: homepagePlayFreeRef,
          [ProjectPageSection.ABOUT]: homepageAboutRef,
          [ProjectPageSection.GET_NOW]: homepageGetNowRef,
          [ProjectPageSection.TRAITS_AND_RARITY]: homepageTraitsAndRarityRef,
          [ProjectPageSection.SET]: homepageSetRef,
          [ProjectPageSection.TEAM]: homepageTeamRef,
          [ProjectPageSection.FAQ]: homepageFAQRef,
        }}
      />

      <div id="homepage" ref={homepageHeroRef}>
        <IronPigeonsHomepageHero />
      </div>

      <GetFinalFormCardsBanner
        title="Iron Pigeons"
        tokenType={TokenType.IRON_PIGEONS}
        onClickGetCardsCallback={trackGetCardsButtonClick}
      />

      <IronPigeonsHomepageVideo />

      <div id="play-free" ref={homepagePlayFreeRef}>
        <IronPigeonsHomepagePlayFree />
      </div>

      <div id="about" ref={homepageAboutRef}>
        <IronPigeonsHomepageAbout
          tokenType={TokenType.IRON_PIGEONS}
          onClickGetCardsCallback={trackGetCardsButtonClick}
        />
      </div>

      <div id="get-cards" ref={homepageGetNowRef}>
        <ArbitrumComingSoon />

        <IronPigeonsGetCards
          onClickGetCardsCallback={trackSecondaryMarketClick}
        />
      </div>

      <IronPigeonsHomepageTotalCount />

      <div id="traits-and-rarity" ref={homepageTraitsAndRarityRef}>
        <IronPigeonsHomepageTraitsAndRarity
          onClickGetCardsCallback={trackGetCardsButtonClick}
        />
      </div>

      <div id="card-sets" ref={homepageSetRef}>
        <IronPigeonsHomepageSet />
      </div>

      <div id="team" ref={homepageTeamRef}>
        <IronPigeonsHomepageTeam />
      </div>

      <div id="faq" ref={homepageFAQRef}>
        <IronPigeonsHomepageFAQ />
      </div>
    </section>
  );
};

export { IronPigeonsHomepage };
