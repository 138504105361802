import React, {useEffect, useRef} from "react";
import {Asset} from "client/marketplace";
import classNames from "classnames";
import variables from "../../style/variables";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";

interface IProps {
    asset: Asset;
    selected: boolean;
    isHovered: boolean;
    onVideoEnd: () => void;
}

const GameDetailsCarouselSlide: React.FC<IProps> = (props) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    /**
     * As long as the video ref exists, when the selected prop changes, try to toggle the video playback state.
     *
     */
    useEffect(() => {
        if (videoRef.current) {
            toggleVideoPlaybackState();
        }
    }, [videoRef.current, props.selected]);

    /**
     * Handle toggling video playback, if this slide is a video.
     * When the slide comes into focus, start/resume playback,
     * and when exiting, pause on a timeout (so the video still plays until it finishes fading when "selected" changes)
     *
     */
    function toggleVideoPlaybackState(): void {
        if (props.selected) {
            videoRef.current.play().catch(console.error);
        } else {
            setTimeout(() => {
                if (videoRef?.current?.pause) {
                    videoRef.current.pause();
                }
            }, parseInt(variables.gameDetailsCarouselSlideAnimationTiming));
        }
    }

    return (
        <div
            // ref={hoverRef}
            className={classNames("game-details-carousel-slide", {
                "game-details-carousel-slide_selected": props.selected
            })}
        >
            <MediaAssetViewer
                asset={props.asset}
                imageAltText={"Preview"}
                videoControls={props.isHovered}
                videoAutoPlay={false}
                videoMuted={true}
                videoPlaysInline={true}
                elementWidth={{
                    desktopWidth: 1920,
                    mobileWidth: 800,
                }}
                videoOnEnded={props.onVideoEnd}
                elementRef={videoRef}
            />
        </div>
    );
};

export default GameDetailsCarouselSlide;
