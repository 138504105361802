"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserTotalsResponseToJSON = exports.GetUserTotalsResponseFromJSONTyped = exports.GetUserTotalsResponseFromJSON = exports.instanceOfGetUserTotalsResponse = void 0;
/**
 * Check if a given object implements the GetUserTotalsResponse interface.
 */
function instanceOfGetUserTotalsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "totalUsers" in value;
    isInstance = isInstance && "subscribers" in value;
    isInstance = isInstance && "verifiedSubscribers" in value;
    isInstance = isInstance && "solanaAddressUsers" in value;
    isInstance = isInstance && "evmAddressUsers" in value;
    isInstance = isInstance && "yearlyTotalUsers" in value;
    isInstance = isInstance && "monthlyTotalUsers" in value;
    isInstance = isInstance && "weeklyTotalUsers" in value;
    isInstance = isInstance && "hourlyTotalUsers" in value;
    return isInstance;
}
exports.instanceOfGetUserTotalsResponse = instanceOfGetUserTotalsResponse;
function GetUserTotalsResponseFromJSON(json) {
    return GetUserTotalsResponseFromJSONTyped(json, false);
}
exports.GetUserTotalsResponseFromJSON = GetUserTotalsResponseFromJSON;
function GetUserTotalsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalUsers': json['totalUsers'],
        'subscribers': json['subscribers'],
        'verifiedSubscribers': json['verifiedSubscribers'],
        'solanaAddressUsers': json['solanaAddressUsers'],
        'evmAddressUsers': json['evmAddressUsers'],
        'yearlyTotalUsers': json['yearlyTotalUsers'],
        'monthlyTotalUsers': json['monthlyTotalUsers'],
        'weeklyTotalUsers': json['weeklyTotalUsers'],
        'hourlyTotalUsers': json['hourlyTotalUsers'],
    };
}
exports.GetUserTotalsResponseFromJSONTyped = GetUserTotalsResponseFromJSONTyped;
function GetUserTotalsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalUsers': value.totalUsers,
        'subscribers': value.subscribers,
        'verifiedSubscribers': value.verifiedSubscribers,
        'solanaAddressUsers': value.solanaAddressUsers,
        'evmAddressUsers': value.evmAddressUsers,
        'yearlyTotalUsers': value.yearlyTotalUsers,
        'monthlyTotalUsers': value.monthlyTotalUsers,
        'weeklyTotalUsers': value.weeklyTotalUsers,
        'hourlyTotalUsers': value.hourlyTotalUsers,
    };
}
exports.GetUserTotalsResponseToJSON = GetUserTotalsResponseToJSON;
