import React, {FC} from "react";
import classNames from "classnames";

interface IProps {
	icon: FC;
	href: string;
	ariaLabel?: string;
	className?: string;
}

const ExPopulusHeaderSocialLink: React.FC<IProps> = (props) => {

	const Icon = props.icon;
	const ariaLabel = props.ariaLabel;

	return (
		<a
			href={props.href}
			target="_blank"
			aria-label={props.ariaLabel}
			rel="noopener noreferrer"
			className={classNames("ex-populus-header-social-link", props.className)}
		>
			<Icon/>
		</a>
	);
};

export default ExPopulusHeaderSocialLink;
