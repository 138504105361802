import React from "react";
import {ExPopulusButton} from "../../buttons/ExPopulusButton";

export interface gameContentData {
	header: string,
	body: string,
	label: string,
	image: string,
	getCardsUrl: string,
	viewDetailsUrl: string,
}

const gameContent = [
	{
		header: "Decentralized Autonomous GigaUnits",
		body: "DAGs are a collection of unique, hand-drawn traits that are combined using a generative algorithm to create thousands of completely unique trading cards.",
		label: "Card Set",
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1661465751/website/images/xp-card-game_dag_pmsn3r",
		getCardsUrl: "/dags/#get-cards",
		viewDetailsUrl: "/dags/#",
	},
	{
		header: "Iron Pigeons",
		body: "Mike Tyson brings his love for pigeons to the Metaverse by making them the second set in the Ex Populus trading card system!",
		label: "Card Set",
		image: "https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1661465751/website/images/xp-card-game_ip_hitrmx",
		getCardsUrl: "/ironpigeons/#get-cards",
		viewDetailsUrl: "/ironpigeons/#",
	},
];

interface IProps {
	id: string;
}

export const FinalFormInGameContent: React.FC<IProps> = (props) => {
	const getInGameContent = (item: gameContentData, index: number) => {
		return (
			<div className="final-form-in-game-content_container_card" key={`final-form-content-${index}`}>
				<div className="final-form-in-game-content_container_card_image">
					<img
						src={item.image}
						alt="In Game Content image"
					/>
				</div>

				<div className="final-form-in-game-content_container_card_details">
					<div className="final-form-in-game-content_container_card_details_label">
						<span>{item.label}</span>
					</div>
					<span className="final-form-in-game-content_container_card_details_header">
						{item.header}
					</span>
					<span className="final-form-in-game-content_container_card_details_body">
						{item.body}
					</span>

					<div className="final-form-in-game-content_container_card_details_cta">
						<ExPopulusButton
							color="white-on-transparent"
							className="final-form-in-game-content_container_card_details_cta_button"
							to={item.viewDetailsUrl}
						>
							View details
						</ExPopulusButton>
						<ExPopulusButton
							color="dark-on-white"
							className="final-form-in-game-content_container_card_details_cta_button"
							to={item.getCardsUrl}
						>
							Get Cards
						</ExPopulusButton>
					</div>
				</div>
			</div>
		);
	}

	return (
		<section className="final-form-in-game-content" id={props.id}>
			<div className="final-form-in-game-content_container">
				<hr/>
				<h2 className="final-form-in-game-content_container_title">
					In-Game Items and Content
				</h2>
				<div className="final-form-in-game-content_container_banner">
					<div className="final-form-in-game-content_container_banner_content">
						<h6>
							Ready to begin playing Final Form?
						</h6>
						<span>
						Choose from the available Final Form sets below to start building your deck!
					</span>
					</div>
				</div>

				{gameContent.map(getInGameContent)}

			</div>
		</section>
	);
};
