"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAdminBodyToJSON = exports.CreateAdminBodyFromJSONTyped = exports.CreateAdminBodyFromJSON = exports.instanceOfCreateAdminBody = void 0;
var runtime_1 = require("../runtime");
var UserType_1 = require("./UserType");
/**
 * Check if a given object implements the CreateAdminBody interface.
 */
function instanceOfCreateAdminBody(value) {
    var isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "confirmPassword" in value;
    isInstance = isInstance && "userType" in value;
    isInstance = isInstance && "username" in value;
    return isInstance;
}
exports.instanceOfCreateAdminBody = instanceOfCreateAdminBody;
function CreateAdminBodyFromJSON(json) {
    return CreateAdminBodyFromJSONTyped(json, false);
}
exports.CreateAdminBodyFromJSON = CreateAdminBodyFromJSON;
function CreateAdminBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'userType': (0, UserType_1.UserTypeFromJSON)(json['userType']),
        'username': json['username'],
    };
}
exports.CreateAdminBodyFromJSONTyped = CreateAdminBodyFromJSONTyped;
function CreateAdminBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'name': value.name,
        'userType': (0, UserType_1.UserTypeToJSON)(value.userType),
        'username': value.username,
    };
}
exports.CreateAdminBodyToJSON = CreateAdminBodyToJSON;
