"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LamoOwnerResponseToJSON = exports.LamoOwnerResponseFromJSONTyped = exports.LamoOwnerResponseFromJSON = exports.instanceOfLamoOwnerResponse = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the LamoOwnerResponse interface.
 */
function instanceOfLamoOwnerResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfLamoOwnerResponse = instanceOfLamoOwnerResponse;
function LamoOwnerResponseFromJSON(json) {
    return LamoOwnerResponseFromJSONTyped(json, false);
}
exports.LamoOwnerResponseFromJSON = LamoOwnerResponseFromJSON;
function LamoOwnerResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ownsLAMO': !(0, runtime_1.exists)(json, 'ownsLAMO') ? undefined : json['ownsLAMO'],
    };
}
exports.LamoOwnerResponseFromJSONTyped = LamoOwnerResponseFromJSONTyped;
function LamoOwnerResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ownsLAMO': value.ownsLAMO,
    };
}
exports.LamoOwnerResponseToJSON = LamoOwnerResponseToJSON;
