import React, {ChangeEvent, useEffect, useMemo, useState,} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "reactstrap";
import {ModalProps} from "reactstrap/lib/Modal";
import {IStore} from "../../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../../redux/meta/MetaActions";
import {VerificationsApi} from "client/marketplace";
import formStateManager, {defaultFormField, IFormState} from "../../../utils/formState";
import FinalFormWindowDownloadModal from "./FinalFormWindowDownloadModal";
import FinalFormWindowDownloadConfirmationModal from "./FinalFormWindowDownloadConfirmationModal";
import FinalFormWindowResendModal from "./FinalFormWindowResendModal";
import isValidEmail from "../../../utils/isValidEmail";
import getConfig from "../../../utils/getConfig";

export enum IFinalFormDownloadModalStep {
    EMAIL,
    CHECK_EMAIL
}

const defaultFormState: IFormState = {
    email: {
        ...defaultFormField,
        errors: [{isError: true, errorText: ""}],
        validate: (value) => ([{isError: !isValidEmail(value), errorText: "This is not a valid email address"}]),
    },
};

interface IProps extends ModalProps {
    toggle?: (e?: any) => void;
    isOpen?: boolean;
    setIsOpen: (e: boolean) => void;
}

const FinalFormWindowDownloadManager: React.FC<IProps> = (props) => {

    const [resendConfirmation, setResendConfirmation] = useState<boolean>(false);
    const [modalStep, setModalStep] = useState<IFinalFormDownloadModalStep>(IFinalFormDownloadModalStep.EMAIL);
    const [formState, setFormState] = useState<IFormState>(defaultFormState);
    const [formOk, setFormOk] = useState<boolean>(false);
    const stateManager = useMemo(() => formStateManager(defaultFormState), []);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const dispatch = useDispatch();

    /**
     * sets formOk true when agreeTerms is true and
     * no form fields are in error state
     */
    useEffect(() => {
        setFormOk(Object
            .keys(formState)
            .reduce((isOk, field) => isOk
                ? !formState[field]?.errors?.some(({isError}) => isError)
                : false, true));
    }, [formState]);

    useEffect(() => {
        if (currentUser && currentUser?.email) {
            setModalStep(IFinalFormDownloadModalStep.CHECK_EMAIL);
            setFormState(stateManager.setValue("email", currentUser?.email));
        }
    }, []);

    useEffect(() => {
        return () => {
            if (!props.isOpen) {
                setFormState(defaultFormState);
                setModalStep(IFinalFormDownloadModalStep.EMAIL);
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (resendConfirmation) {
            setTimeout(() => {
                setResendConfirmation(false);
            }, 1500);
        }
    }, [resendConfirmation]);

    const closeHelper = (e?): void => {
        if (e) {
            e.preventDefault();
        }
        props.setIsOpen(false);
    }

    /**
     * Updates the local form state based on the output of the form state manager
     * to accommodate error and tooltip states
     * @param field the field to handle
     * @returns function to handle the form state updates
     */
    const onEvent = (field: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => setFormState(stateManager.setValue(field, e.target));

    function sendEmail(resend: boolean): () => Promise<void> {
        return async () => {
            dispatch(incrementLoading());
            try {
                await new VerificationsApi(getConfig()).sendDownloadLink({
                    requestSendDownloadLinkBody: {
                        email: currentUser?.email || formState.email?.value as string,
                    }
                });
            } catch (err) {
                dispatch(await addError(err));
            } finally {
                setModalStep(IFinalFormDownloadModalStep.CHECK_EMAIL);
                if (resend) {
                    setResendConfirmation(!resendConfirmation);
                }
            }
            dispatch(decrementLoading());
        }
    }

    return (
        <Modal
            {...props}
            centered={true}
            className="final-form-window-download-modal"
        >
            {modalStep === IFinalFormDownloadModalStep.EMAIL &&
                <FinalFormWindowDownloadModal
                    isOpen={props.isOpen}
                    setIsOpen={props.setIsOpen}
                    formState={formState}
                    formOk={formOk}
                    closeHelper={closeHelper}
                    onEvent={onEvent}
                    toggle={props.toggle}
                    onSubmit={sendEmail(false)}
                />
            }

            {modalStep === IFinalFormDownloadModalStep.CHECK_EMAIL &&
                <React.Fragment>
                    <FinalFormWindowResendModal resendConfirmation={resendConfirmation}/>
                    <FinalFormWindowDownloadConfirmationModal
                        formState={formState}
                        toggle={props.toggle}
                        onSubmit={props.toggle}
                        resend={sendEmail(true)}
                    />
                </React.Fragment>
            }
        </Modal>
    );
}

export default FinalFormWindowDownloadManager;
