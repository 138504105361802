import React from "react";
import {Col, Container, Row} from "reactstrap";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

interface IProps {
	onClick: () => void;
}

const ContactHero: React.FC<IProps> = (props) => {

	return (
		<section className="contact-hero">

			<div
				className="contact-hero__image"
				style={{
					backgroundImage: `url("/images/contact/contact-hero.jpg")`,
				}}
			>
				<div className="contact-hero__image-overlay" />
			</div>


			<Container>
				<Row>
					<Col className="contact-hero__body">
						<div className="contact-hero__body-container">
							<span className="contact-hero__body-container-subheader">contact us</span>

							<h2 className="contact-hero__body-container-header">Get in touch</h2>

							<p className="contact-hero__body-container-body">
								{"See an opportunity to partner? Send a note to let us know your thoughts."}
								<br className="contact-hero__body-container-body__line-break"/>
								{"We currently have offices in Berkeley, Vancouver, and Singapore."}
							</p>

							<div className="contact-hero__body-container-cta">
								<ExPopulusButton
									color="pink"
									onClick={props.onClick}
								>
									SEND A MESSAGE
								</ExPopulusButton>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default ContactHero;
