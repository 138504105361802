import React, {ReactNode, useEffect, useRef, useState} from "react";
import {Asset} from "client/marketplace";
import classNames from "classnames";
import {chunk} from "lodash";
import {fileIsImage} from "../../utils/fileTypeChecks";
import {ReactComponent as CarouselArrow} from "../../svgs/carousel-controls_arrow.svg";
import GameDetailsCarouselSlide from "./GameDetailsCarouselSlide";
import GameDetailsCarouselPreviewStepper from "./GameDetailsCarouselPreviewStepper";
import GameDetailsCarouselThumbnailGroup from "./GameDetailsCarouselThumbnailGroup";

// Automatic timing for advancing carousel slides. Ignored by video slides.
const slideTimer: number = 7000;

// Interface for us to pass around asset details along with a reference to that asset's index relative to the unaltered array.
// Sounds redundant at first, but it's done this way because we chunk the asset array, while wanting the original index reference for controlling ui.
export interface IAssetWithIndex {
    asset: Asset;
    index: number;
}

interface IProps {
    assets: Array<Asset>;
    isMobile?: boolean;
}

const GameDetailsCarousel: React.FC<IProps> = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [looped, setLooped] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isThumbsHovered, setIsThumbsHovered] = useState(false);

    const timer = useRef<ReturnType<typeof setTimeout>>(null); // Used for automatically switching slides, will be ignored if only 1 slide is present.
    const visibleGalleryPreviews: number = props.isMobile ? 3 : 4;

    useEffect(() => {
        setLooped(false);
        setSelectedIndex(0);
    }, [JSON.stringify(props.assets)]);

    /**
     * When assets are received from props, begin an artificial interval (repeating timeouts) to update the selectedIndex.
     * Since this useEffect listens for selectedIndex, no logic needs to be written to re-start the timeout, as the timeout
     * updates the selectedIndex, thus prompting the useEffect to repeat. Brilliant! Also, this kills 2 birds with 1 stone
     * by resetting the timeout when/if the user manually changes the selectedIndex.
     *
     * selectedIndex will increment, and reset back to 0 if it's at the end.
     * Nothing happens if there's only 1 slide.
     *
     */
    useEffect(() => {
        startTimer();

        return () => {
            clearTimeout(timer.current);
        }
    }, [JSON.stringify(props.assets), selectedIndex]);

    /**
     * Handler for stopping & resetting the automatic timer when user hovers mouse over the carousel, if the current slide
     * is an image; hovering shouldn't impact how videos behave.
     *
     */
    useEffect(() => {
        if (isHovering && fileIsImage(props.assets[selectedIndex])) {
            clearTimeout(timer.current);
        } else if (!isHovering && fileIsImage(props.assets[selectedIndex])) {
            startTimer();
        }
    }, [isHovering]);

    const onMouseEnter = () => setIsHovered(true);
    const onMouseLeave = () => setIsHovered(false);
    const onMouseEnterThumbs = () => setIsThumbsHovered(true);
    const onMouseLeaveThumbs = () => setIsThumbsHovered(false);
    
    /**
     * Logic used for setting the timeout/interval. Leveraged by the 2 use effects that listen for selectedIndex & isHover changing.
     *
     */
    function startTimer(): void {
        if (props.assets?.length > 1) {
            if (fileIsImage(props.assets[selectedIndex])) {
                timer.current = setTimeout(() => {
                    setSelectedIndex(getNextAvailableSlide());
                }, slideTimer) as ReturnType<typeof setTimeout>;
            }
        }
    }

    /**
     * Calculates the next asset we should choose to automatically rotate to.
     * Logic included to differentiate between whether the carousel has "looped" once,
     * which becomes true by:
     * - carousel automatically reaches the end and resets
     * - user clicks the right-arrow on the final carousel slide
     *
     * When looped is true, we look for the next image asset, and skip returning to videos.
     *
     */
    function getNextAvailableSlide(): number {
        if (!looped) {
            // If the carousel hasn't done a full pass yet...

            // Increment the selectedIndex if not at the end
            if (selectedIndex < props.assets.length - 1) {
                return selectedIndex + 1;
            } else {
                // if we're at the end of the list, setLooped to true & find the first image asset
                setLooped(true);

                const foundIndex = props.assets.findIndex(fileIsImage);
                return foundIndex > -1 ? foundIndex : 0;
            }
        } else {
            // The index we want to start our search at
            const adjustedIndexOffset: number = selectedIndex + 1;

            // See if we should reset to 0 if we're greater than the length of the assets array
            let baseIndex: number = adjustedIndexOffset >= props.assets.length ? 0 : adjustedIndexOffset;

            // Look for index of the next image asset starting at the baseIndex, if nothing found start from 0 again.
            let foundIndex = props.assets.slice(baseIndex).findIndex(fileIsImage);
            if (foundIndex + baseIndex < 0) {
                foundIndex = props.assets.findIndex(fileIsImage);
            }

            // Return the next image's index if found
            if (foundIndex + baseIndex > -1) {
                return foundIndex + baseIndex
            }

            // Else, stay on the selectedIndex
            return selectedIndex;
        }
    }

    /**
     * When clicking the left arrow, attempt to decrement the selected index by 1 if possible.
     * If it so happens that the selected index is 0, go to the highest index available (slides length - 1).
     *
     */
    function onPrevious(): void {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        } else {
            setSelectedIndex(props.assets.length - 1);
        }
    }

    /**
     * When clicking the right arrow, attempt to increment the selected index  by 1 if possible.
     * If it so happens that the selected index is equal to the amount of slides - 1, go back to index 0.
     *
     */
    function onNext(): void {
        if (selectedIndex < props.assets.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        } else {
            if (!looped) {
                setLooped(true);
            }
            setSelectedIndex(0);
        }
    }

    /**
     * Renders each "slide" of the carousel, controls if the slide is selected based on the index, which the slide
     * uses to know if it should be visible or hidden (and whether videos should be playing or not).
     *
     * @param asset
     * @param i
     */
    // function renderSlides(asset: Asset, i: number): ReactNode {
    //     const onVideoEndHelper = () => setSelectedIndex(getNextAvailableSlide());
    //     return (
    //         <GameDetailsCarouselSlide
    //             key={`game-details-slide_${i}`}
    //             asset={asset}
    //             selected={selectedIndex === i}
    //             isHovered={isHovered || isThumbsHovered}
    //             onVideoEnd={onVideoEndHelper}
    //         />
    //     );
    // }

    const renderSlides = (assets: Array<Asset>, hovering: boolean) => {
        const onVideoEndHelper = () => setSelectedIndex(getNextAvailableSlide());

        return assets.map((asset, i) => (
            <GameDetailsCarouselSlide
                key={`game-details-slide_${i}`}
                asset={asset}
                selected={selectedIndex === i}
                isHovered={isHovered || isThumbsHovered}
                onVideoEnd={onVideoEndHelper}
            />
        ));
    }

    /**
     * Update the selected index based on which of the "steps" the user clicked on.
     *
     * @param stepperIndex
     */
    function handleStepperSelect(stepperIndex: number): void {
        setSelectedIndex(stepperIndex * visibleGalleryPreviews);
    }

    /**
     * Render each group of 3/4 thumbnails in a now separated component, and just like the "active" slide,
     * every paginated group of thumbnails is actually always rendered in the DOM, but we used opacity & pointer-events
     * css to essentially hide them / disable them. This is done to prevent video thumbnails from flickering when their
     * paginated group comes back into focus, and allow us to do a nicer / fade animation instead.
     *
     * @param thumbs
     * @param i
     */
    function renderThumbGroups(thumbs: Array<Asset>, i: number): ReactNode {
        return (
            <GameDetailsCarouselThumbnailGroup
                key={`thumbs-group_${i}`}
                onMouseEnter={onMouseEnterThumbs}
                onMouseLeave={onMouseLeaveThumbs}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                assets={thumbs.map((a, j: number): IAssetWithIndex => {
                    return {
                        asset: a,
                        index: (i * visibleGalleryPreviews) + j, // Calculate this asset's index relative to the original array from props.
                    }
                })}
            />
        );
    }

    return (
        <div className="game-details-carousel">
            <div className="game-details-carousel_main" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
                {/* {props.assets?.map(renderSlides)} */}
                {renderSlides(props.assets, isHovered || isThumbsHovered)}
            </div>

            {props.assets.length > 0 && (
                <React.Fragment>
                    <div
                        onMouseEnter={onMouseEnterThumbs}
                        onMouseLeave={onMouseLeaveThumbs}
                        className={classNames("game-details-carousel_gallery", {
                            "game-details-carousel_gallery game-details-carousel_gallery_padded": props.assets.length > visibleGalleryPreviews,
                        })}
                    >
                        <div
                            className="carousel-controls_arrow-container"
                            onClick={onPrevious}
                        >
                            <CarouselArrow className="carousel-controls_arrow-rotated"/>
                        </div>

                        <div className="game-details-carousel_gallery_previews">
                            {chunk(props.assets, visibleGalleryPreviews).map(renderThumbGroups)}
                        </div>

                        <div
                            className="carousel-controls_arrow-container"
                            onClick={onNext}
                        >
                            <CarouselArrow/>
                        </div>

                        {props.assets.length > visibleGalleryPreviews && (
                            <div className="game-details-carousel_stepper">
                                <GameDetailsCarouselPreviewStepper
                                    steps={Math.ceil(props.assets.length / visibleGalleryPreviews)}
                                    selected={(selectedIndex - (selectedIndex % visibleGalleryPreviews)) / visibleGalleryPreviews}
                                    onSelect={handleStepperSelect}
                                />
                            </div>
                        )}

                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default GameDetailsCarousel;
