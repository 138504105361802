import React, {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import {WalletsApi, WalletType} from "client/cs";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {injectedConnector} from "../utils/web3Connection";
import getCsConfig from "../utils/getCsConfig";
import classNames from "classnames";
import TagManager from "react-gtm-module";
import {GoogleTagManagerEvents} from "../utils/GoogleTagManagerEvents";
import HeaderContainer from "./containers/HeaderContainer";
import {ExPopulusButton} from "./buttons/ExPopulusButton";

interface IProps {}

const MetaMaskLinkedIndicator: React.FC<IProps> = (props) => {

	const {chainId, account, library, activate, active} = useWeb3React<Web3Provider>();
	const [walletIsLinked, setWalletIsLinked] = useState<{ [key: string]: boolean }>({});
	const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
	const dispatch = useDispatch();

	useEffect(() => {
		if (account && typeof account === "string" && account?.length > 0) {
			validateWalletIsConnected().then().catch();
		}
	}, [account]);

	/**
	 * Connects to metamask and grabs the network.
	 *
	 */
	async function activateMetamask(): Promise<void> {
		if (active) {
			return;
		}

		try {
			await activate(injectedConnector);

			TagManager.dataLayer({
				dataLayer: {
					event: GoogleTagManagerEvents.CONNECT_METAMASK,
				},
			});
		} catch (e) {
			await dispatch(addError({type: "API", message: "You must connect a web3 provider in order to do this. Try googling Metamask!"}));
		}
	}

	/**
	 * Call our api to determine if their wallet is linked,
	 * save result to state variable.
	 *
	 */
	async function validateWalletIsConnected(): Promise<void> {
		dispatch(incrementLoading());

		try {
			const res = await new WalletsApi(getCsConfig(fullToken)).isWalletLinked({
				isWalletLinkedBody: {
					address: account,
				},
			});

			setWalletIsLinked({
				...walletIsLinked,
				[account]: res.linked,
			});
		} catch (e) {
			await dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	/**
	 * Call api to link wallet.
	 *
	 */
	async function linkWallet(): Promise<void> {
		dispatch(incrementLoading());

		try {
			await new WalletsApi(getCsConfig(fullToken)).addWallet({
				addWalletBody: {
					type: WalletType.EVM,
					address: account,
				},
			});

			TagManager.dataLayer({
				dataLayer: {
					event: GoogleTagManagerEvents.LINK_METAMASK,
					address: account,
				},
			});

			await validateWalletIsConnected();
		} catch (e) {
			await dispatch(addError(e));
		}

		dispatch(decrementLoading());
	}

	function statusDisplayHelper(): "pending" | "ready" {
		if (!active) {
			return "pending"
		} else if (active && !walletIsLinked[account]) {
			return "pending"
		} else if (active && walletIsLinked[account]) {
			return "ready"
		} else {
			return "pending"
		}
	}

	return (
		<HeaderContainer
			title="Metamask"
			rounded={true}
			bgColor="darkGrey"
			status={statusDisplayHelper()}
		>
			<div className="account-page-linked">
				<div className="account-page-linked-indicator">
					<div className="account-page-linked-indicator_left">
						<div>
							<div className={classNames("account-page-linked-indicator_left_crypto-icon", {
								"account-page-linked-indicator_left_crypto-icon_linked": active,
								"account-page-linked-indicator_left_crypto-icon_unlinked": !active,
							})}>
								<img
									src={process.env.PUBLIC_URL + "/images/MetaMask_Fox.svg"}
									alt="metamask"
								/>
							</div>
						</div>
					</div>

					<div className="account-page-linked-indicator_right">
						<div>
							<span className="account-page-linked-indicator_right_title">
								Display Name
							</span>

							<p className="account-page-linked-indicator_right_address">
								{account || "(unknown)"}
							</p>
						</div>
					</div>
				</div>

				<hr/>

				<div className="account-page-linked-indicator_subheader">
					<span>Metamask is required for the following items:</span>
					<ul>
						<li>LAMOverse</li>
					</ul>
				</div>

				<div className="account-page-linked-indicator_button-container">
					<div/>
					<div>
						{!active && (
							<ExPopulusButton
								color="pink"
								onClick={activateMetamask}
							>
								CONNECT METAMASK
							</ExPopulusButton>
						)}

						{active && !walletIsLinked[account] && (
							<ExPopulusButton
								color="pink"
								onClick={linkWallet}
							>
								Metamask connected - wallet unlinked
							</ExPopulusButton>
						)}

						{active && walletIsLinked[account] && (
							<ExPopulusButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="pink"
								forwardProps={{
									disabled: true
								}}
							>
								LINKED
							</ExPopulusButton>
						)}
					</div>
				</div>
			</div>
		</HeaderContainer>
	);
};

export default MetaMaskLinkedIndicator;
