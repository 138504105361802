// default list of domains considered "internal"
const defaultDomains = [
  "expopulus.com",
  "localhost",
];

/**
 * Used to see if a url's host is considered "external"
 * @param url {URL} Url to examine
 * @returns {boolean} True if url's host is not in the internal domains list
 */
 const isExternal = (url: URL, domains: Array<string>) => !(!!domains.find((domain) => url.host.includes(domain)));

 /**
  * Navigate the browser to the URL. URLs from domains in the domainsList are considered
  * to be "internal" and are opened in the same window. External URLs are opened in new windows
  * @param url {string} The url to navigate to
  * @param forceNewWindow {boolean} [forceNewWindow=false] Forces all URLs to open in a new window when true
  * @param domainsList {Array<string>} List of "internal" domains. Defaults to "expopulus.com" and "localhost"
  */
const navigateToURL = (url: string, forceNewWindow: boolean = false, domainsList: Array<string> = defaultDomains) => {
  const navigateURL = new URL(url);
  const windowTarget = isExternal(navigateURL, domainsList) ? "_blank" : "_self";
  return () => window.open(url, forceNewWindow ? "_blank" : windowTarget, "noreferrer");
}

export default navigateToURL;
