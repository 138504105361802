import React from "react";
import { ReactComponent as Chevron } from "../../svgs/pagination-chevron.svg";

type Direction = "left" | "right" | "up" | "down";

interface IProps { direction?: Direction };

const PaginationChevron = ({ direction }: IProps) => (<div className={direction}><Chevron /></div>);

PaginationChevron.defaultProps = {
	direction: "left",
}

export default PaginationChevron;
