"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckIpResponseToJSON = exports.CheckIpResponseFromJSONTyped = exports.CheckIpResponseFromJSON = exports.instanceOfCheckIpResponse = void 0;
var runtime_1 = require("../runtime");
var IpBanReason_1 = require("./IpBanReason");
/**
 * Check if a given object implements the CheckIpResponse interface.
 */
function instanceOfCheckIpResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "banned" in value;
    return isInstance;
}
exports.instanceOfCheckIpResponse = instanceOfCheckIpResponse;
function CheckIpResponseFromJSON(json) {
    return CheckIpResponseFromJSONTyped(json, false);
}
exports.CheckIpResponseFromJSON = CheckIpResponseFromJSON;
function CheckIpResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'ip': !(0, runtime_1.exists)(json, 'ip') ? undefined : json['ip'],
        'banned': json['banned'],
        'isAnonymous': !(0, runtime_1.exists)(json, 'isAnonymous') ? undefined : json['isAnonymous'],
        'isAnonymousVpn': !(0, runtime_1.exists)(json, 'isAnonymousVpn') ? undefined : json['isAnonymousVpn'],
        'country': !(0, runtime_1.exists)(json, 'country') ? undefined : json['country'],
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : json['state'],
        'reasons': !(0, runtime_1.exists)(json, 'reasons') ? undefined : (json['reasons'].map(IpBanReason_1.IpBanReasonFromJSON)),
    };
}
exports.CheckIpResponseFromJSONTyped = CheckIpResponseFromJSONTyped;
function CheckIpResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ip': value.ip,
        'banned': value.banned,
        'isAnonymous': value.isAnonymous,
        'isAnonymousVpn': value.isAnonymousVpn,
        'country': value.country,
        'state': value.state,
        'reasons': value.reasons === undefined ? undefined : (value.reasons.map(IpBanReason_1.IpBanReasonToJSON)),
    };
}
exports.CheckIpResponseToJSON = CheckIpResponseToJSON;
