import React from "react";
import {User} from "client/cs";
import {BsStar, CgInfo, RiTrophyLine} from "react-icons/all";
import {GAMESIGHT_EVENTS, SaleType} from "shared";
import {sendEvent} from "../utils/event-tracking";
import {ExPopulusButton} from "./buttons/ExPopulusButton";
import {ICTACommonRare} from "./dag/DagBuyNowCTA";
import {TokenType} from "../utils/TokenType";

const bulletIcons = {
    star: BsStar,
    info: CgInfo,
    trophy: RiTrophyLine,
}

interface IProps extends ICTACommonRare {
    onClick: () => void;
    tokenType: TokenType;
    currentUser?: User;
}

const TemplateCollectionPreview: React.FC<IProps> = (props) => {
    const onClick = () => {
        const events = {
            [TokenType.DAG]: GAMESIGHT_EVENTS.DAG_WEB_GET_CARDS,
            [TokenType.IRON_PIGEONS]: GAMESIGHT_EVENTS.IP_WEB_GET_PIGEONS,
        };

        const event = events[props.tokenType];
        if (event) {
            sendEvent({
                eventName: event,
                user: props.currentUser,
            });
        }

        props.onClick();
    }

    return (
        <>
            <h4 className={"homepage-template-buy-now_container_CTA_header"}>
                {props.header}
            </h4>

            <div className={"homepage-template-buy-now_container_CTA_price"}>
                <img
                    src={props.cryptoIcon}
                    alt={props.alt}
                    className={"homepage-template-buy-now_container_CTA_price_solana"}
                />
                <h4 className={"homepage-template-buy-now_container_CTA_price_subheader"}>
                    {props.price}
                </h4>
                <span>{props.priceType}</span>
            </div>

            <span className={"homepage-template-buy-now_container_CTA_body"}>
				{props.subheader}
			</span>

            <div>
                {
                    props.bullets.map(({iconName, text, header}, index) => {
                        const Icon = bulletIcons[iconName] || undefined;

                        return (
                            <div key={`list-item-${index}`}
                                 className={"homepage-template-buy-now_container_CTA_body"}>

                                {Icon && (
                                    <Icon className={"homepage-template-buy-now_container_CTA_body_icon"}/>
                                )}


                                <span className={"homepage-template-buy-now_container_CTA_body_list"}>
                                    {header && (<strong>{header}</strong>)}
                                    {text}
								</span>
                            </div>
                        )
                    })
                }
            </div>

            <ExPopulusButton
                color="white-on-pink"
                onClick={onClick}
                forwardProps={{type: "button"}}
                className={"homepage-template-buy-now_container_button"}
            >
                {props.buttonText}
            </ExPopulusButton>
        </>
    );
};

export {TemplateCollectionPreview};
