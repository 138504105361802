"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBodyToJSON = exports.RegisterBodyFromJSONTyped = exports.RegisterBodyFromJSON = exports.instanceOfRegisterBody = void 0;
var runtime_1 = require("../runtime");
var PhoneNumberBody_1 = require("./PhoneNumberBody");
/**
 * Check if a given object implements the RegisterBody interface.
 */
function instanceOfRegisterBody(value) {
    var isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "confirmPassword" in value;
    isInstance = isInstance && "agreeEmailUpdates" in value;
    return isInstance;
}
exports.instanceOfRegisterBody = instanceOfRegisterBody;
function RegisterBodyFromJSON(json) {
    return RegisterBodyFromJSONTyped(json, false);
}
exports.RegisterBodyFromJSON = RegisterBodyFromJSON;
function RegisterBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': json['email'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, PhoneNumberBody_1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'solanaWalletAddress': !(0, runtime_1.exists)(json, 'solanaWalletAddress') ? undefined : json['solanaWalletAddress'],
        'agreeEmailUpdates': json['agreeEmailUpdates'],
    };
}
exports.RegisterBodyFromJSONTyped = RegisterBodyFromJSONTyped;
function RegisterBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'name': value.name,
        'phoneNumber': (0, PhoneNumberBody_1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'solanaWalletAddress': value.solanaWalletAddress,
        'agreeEmailUpdates': value.agreeEmailUpdates,
    };
}
exports.RegisterBodyToJSON = RegisterBodyToJSON;
