"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameBodyToJSON = exports.GameBodyFromJSONTyped = exports.GameBodyFromJSON = exports.instanceOfGameBody = void 0;
var Game_1 = require("./Game");
/**
 * Check if a given object implements the GameBody interface.
 */
function instanceOfGameBody(value) {
    var isInstance = true;
    isInstance = isInstance && "game" in value;
    return isInstance;
}
exports.instanceOfGameBody = instanceOfGameBody;
function GameBodyFromJSON(json) {
    return GameBodyFromJSONTyped(json, false);
}
exports.GameBodyFromJSON = GameBodyFromJSON;
function GameBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'game': (0, Game_1.GameFromJSON)(json['game']),
    };
}
exports.GameBodyFromJSONTyped = GameBodyFromJSONTyped;
function GameBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game': (0, Game_1.GameToJSON)(value.game),
    };
}
exports.GameBodyToJSON = GameBodyToJSON;
