import React, {ChangeEventHandler, useState} from "react";
import {Spinner} from "reactstrap";
import {APIError, PhoneNumberBody, SubscribersApi} from "client/marketplace";
import getConfig from "../../utils/getConfig";
import {generateFrontendError} from "../../redux/meta/MetaActions";
import ExPopulusFooterSubscribeCountryCodeInput from "./ExPopulusFooterSubscribeCountryCodeInput";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

const defaultPhoneNumberBody: PhoneNumberBody = {
	countryCode: undefined,
	nationalNumber: "",
};

const ExPopulusFooterSubscribe: React.FC = () => {

	const [form, setForm] = useState<PhoneNumberBody>(defaultPhoneNumberBody);
	const [loading, setLoading] = useState(false);
	const [localError, setLocalError] = useState<APIError>(undefined);

	/**
	 * Dynamically on change for the inputs.
	 *
	 */
	function dynamicOnChange(key: keyof PhoneNumberBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Dynamically on change for the dial code inputs.
	 *
	 */
	function dynamicDialCodeOnChange(key: keyof PhoneNumberBody): (dialCodeField: string) => void {
		return (dialCodeField) => {
			setForm({
				...form,
				[key]: dialCodeField,
			});
		}
	}

	/**
	 * Call the api to submit the user's phone number to the system.
	 * Don't send the user anywhere after or show a modal (as per existing website).
	 *
	 */
	async function submitPhoneNumber(e?): Promise<void> {
		e?.preventDefault();
		setLocalError(undefined);
		setLoading(true);

		try {
			await new SubscribersApi(getConfig()).subscribe({
				phoneNumberBody: {
					countryCode: form.countryCode || undefined,
					nationalNumber: form.nationalNumber || undefined,
				},
			});

			setForm({
				...defaultPhoneNumberBody,
				countryCode: form.countryCode,
			});
		} catch (e) {
			setLocalError(await generateFrontendError(e));
		}

		setLoading(false);
	}

	return (
		<div className={"ex-populus-footer-subscribe"}>
			<div className={"ex-populus-footer-subscribe_spacer"}>
				<div className={"ex-populus-footer-subscribe_spacer_content"}>
					<div className={"ex-populus-footer-subscribe_spacer_content_text-container"}>
						<h5 className="ex-populus-footer-subscribe_spacer_content_text-container_title">
							Ping me about new drops!
						</h5>

						<p className={"ex-populus-footer-subscribe_spacer_content_text-container_sub-title"}>
							Enter your number and we will send you an SMS every time there's a new drop!
						</p>
					</div>

					<div className="ex-populus-footer-subscribe_spacer_content_form-container">
						<form
							onSubmit={submitPhoneNumber}
							className={"ex-populus-footer-subscribe_spacer_content_form-container_form"}
						>
							<div
								className="ex-populus-footer-subscribe_spacer_content_form-container_form_input-container">
								<ExPopulusFooterSubscribeCountryCodeInput
									value={form.countryCode}
									valueKey="code"
									onChange={dynamicDialCodeOnChange("countryCode")}
									disabled={loading}
									className={"ex-populus-footer-subscribe_spacer_content_form-container_form_input-container_selector"}
								/>

								<input
									placeholder="(888) 123-4567"
									value={form.nationalNumber}
									onChange={dynamicOnChange("nationalNumber")}
									disabled={loading}
									className="ex-populus-footer-subscribe_spacer_content_form-container_form_input-container_input"
								/>
							</div>

							<ExPopulusButton
								<React.ButtonHTMLAttributes<HTMLButtonElement>>
								color="white-on-pink"
								onClick={submitPhoneNumber}
								className="ex-populus-footer-subscribe_spacer_content_form-container_form_button"
								forwardProps={{
									type: "submit",
									disabled: loading,
								}}
							>
								{loading && (
									<div className={"ex-populus-footer-subscribe_spacer_content_form-container_form_button_loader-container"}>
										<Spinner className={"ex-populus-footer-subscribe_spacer_content_form-container_form_button_loader-container_spinner"}/>
									</div>
								)}

								SUBMIT
							</ExPopulusButton>
						</form>

						{localError && (
							<div className={"ex-populus-footer-subscribe_spacer_content_form-container_error-container"}>
								<p className={"ex-populus-footer-subscribe_spacer_content_form-container_error-container_message"}>
									{localError.message}
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExPopulusFooterSubscribe;
