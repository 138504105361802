export enum GoogleTagManagerEvents {
	LOADED_URL = "LOADED_URL",
	WHITELIST_SUCCESSFUL = "WHITELIST_SUCCESSFUL",
	WHITELIST_FAILURE = "WHITELIST_FAILURE",
	// Connected Wallet so website can read
	CONNECT_METAMASK = "CONNECT_METAMASK",
	CONNECT_PHANTOM = "CONNECT_PHANTOM",
	// Actually sent an address to the backend
	LINK_METAMASK = "LINK_METAMASK",
	LINK_PHANTOM = "LINK_PHANTOM",
	PLAY_FREE = "PLAY_FREE",
}
