"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetCarouselSlidePriorityBodyToJSON = exports.SetCarouselSlidePriorityBodyFromJSONTyped = exports.SetCarouselSlidePriorityBodyFromJSON = exports.instanceOfSetCarouselSlidePriorityBody = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the SetCarouselSlidePriorityBody interface.
 */
function instanceOfSetCarouselSlidePriorityBody(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSetCarouselSlidePriorityBody = instanceOfSetCarouselSlidePriorityBody;
function SetCarouselSlidePriorityBodyFromJSON(json) {
    return SetCarouselSlidePriorityBodyFromJSONTyped(json, false);
}
exports.SetCarouselSlidePriorityBodyFromJSON = SetCarouselSlidePriorityBodyFromJSON;
function SetCarouselSlidePriorityBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'priority': !(0, runtime_1.exists)(json, 'priority') ? undefined : json['priority'],
    };
}
exports.SetCarouselSlidePriorityBodyFromJSONTyped = SetCarouselSlidePriorityBodyFromJSONTyped;
function SetCarouselSlidePriorityBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'priority': value.priority,
    };
}
exports.SetCarouselSlidePriorityBodyToJSON = SetCarouselSlidePriorityBodyToJSON;
