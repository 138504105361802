import React, {useMemo} from "react";
import {Container} from "reactstrap";
import {ScrollerLeftToRight, ScrollerRightToLeft,} from "../scrollers";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {Asset} from "client/marketplace";

const body: ITotalCount = {
    scrollerDuration: 84,
        scrollerItems: [
        {
            url: "images/scoller-images/dag-scroll-5390.png",
            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5390_wudxyw",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5391.png",
            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5391_lhxjat",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5392.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5392_htvv2x",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5393.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5393_ocojws",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5394.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5394_pl00av",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5395.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5395_v6lha3",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5396.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5396_hdmltf",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5397.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5397_rcy5m3",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5398.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5398_ht9yco",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5399.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5399_k4vo7n",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5400.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5400_hhrtjy",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5401.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5401_pehuju",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
        {
            url: "images/scoller-images/dag-scroll-5402.png",

            contentType: "image",
            cloudinary: {
                publicId: "website/images/scrollers/dags/dag-scroll-5402_lwufqq",
                actions: {
                    desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
                },
            },
        } as unknown as Asset,
    ]
}

interface ITotalCount {
    scrollerDuration: number;
    scrollerItems: Array<Asset>;
}

const randomizeList = (list) => list
    .map(value => ({value, sort: Math.random()}))
    .sort((a, b) => a.sort - b.sort)
    .map(({value}) => value);


const DagHomepageTotalCount: React.FC = () => {
    const {scrollerDuration} = body;
    const scrollerItems = body.scrollerItems?.length ? body.scrollerItems : [];

    const randomizeListLeftResults = useMemo(() =>
        randomizeList(scrollerItems).map((source, index) => (
            <MediaAssetViewer
                key={`scroller-left-${index}`}
                asset={source}
                contentType={fileIsImage(source) ? "image" : "video"}
                elementWidth={{
                    desktopWidth: 336,
                    mobileWidth: 336,
                }}
            />
        )), [scrollerItems])

    const randomizeListRightResults = useMemo(() =>
        randomizeList(scrollerItems).map((source, index) => (
            <MediaAssetViewer
                key={`scroller-right-${index}`}
                asset={source}
                contentType={fileIsImage(source) ? "image" : "video"}
                elementWidth={{
                    desktopWidth: 336,
                    mobileWidth: 336,
                }}
            />
        )), [scrollerItems])


    return (
        <section className="homepage-template-total-count">
            <Container className="homepage-template-total-count_container">
                <div className="dag-scroller-container">
                    {
                        scrollerItems.length && <ScrollerRightToLeft duration={scrollerDuration}>
                            { randomizeListLeftResults }
                        </ScrollerRightToLeft>
                    }
                </div>
                <div className="homepage-template-total-count_container_text">
                    <div className="pink">Every Set</div>
                    <div>A Limited Edition</div>
                </div>
                <div className="dag-scroller-container">
                    {
                        scrollerItems.length && <ScrollerLeftToRight duration={scrollerDuration}>
                            { randomizeListRightResults }
                        </ScrollerLeftToRight>
                    }
                </div>
            </Container>
        </section>
    );
};

export {DagHomepageTotalCount};
