"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUserResponseToJSON = exports.GetUserResponseFromJSONTyped = exports.GetUserResponseFromJSON = exports.instanceOfGetUserResponse = void 0;
var User_1 = require("./User");
/**
 * Check if a given object implements the GetUserResponse interface.
 */
function instanceOfGetUserResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "user" in value;
    return isInstance;
}
exports.instanceOfGetUserResponse = instanceOfGetUserResponse;
function GetUserResponseFromJSON(json) {
    return GetUserResponseFromJSONTyped(json, false);
}
exports.GetUserResponseFromJSON = GetUserResponseFromJSON;
function GetUserResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': (0, User_1.UserFromJSON)(json['user']),
    };
}
exports.GetUserResponseFromJSONTyped = GetUserResponseFromJSONTyped;
function GetUserResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, User_1.UserToJSON)(value.user),
    };
}
exports.GetUserResponseToJSON = GetUserResponseToJSON;
