import React, {HTMLAttributes} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";

const brandLogos = [
    {
        order: 0,
        name: "Pixar",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/pixar-logo_nhfhts",

    },
    {
        order: 1,
        name: "BlueSky",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/blue-sky-logo_k5qhqz",

    },
    {
        order: 2,
        name: "DreamWorks",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/dreamworks-logo_efewzv",

    },
    {
        order: 3,
        name: "Baobab",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/baobab-logo_2x_unvkrt",
    },
    {
        order: 4,
        name: "Electronic Arts",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/ea-logo_2x_lkv0yz",
    },
    {
        order: 5,
        name: "Activision",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/activision-logo_2x_txwu8e",
    },
    {
        order: 6,
        name: "Ubisoft",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/ubisoft-logo_2x_htssir",
    },
    {
        order: 7,
        name: "Blizzard",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/blizzard-logo_2x_nei723",
    },
    {
        order: 8,
        name: "Wargaming.net",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/wargaming-logo_2x_wi63mt",
    },
    {
        order: 9,
        name: "Love, Death & Robots",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/love-death-robots-logo_2x_n7cwuj",
    },
    {
        order: 10,
        name: "Disney Pixar Soul",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/pixar-soul-logo_2x_eemqmc",
    },
    {
        order: 11,
        name: "Disney Pixar Cars",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/pixar-cars-logo_2x_obds4r",
    },
    {
        order: 12,
        name: "Disney Pixar Brave",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/pixar-brave-logo_2x_ccj7kh",
    },
    {
        order: 13,
        name: "Disney Pixar The Good Dinosaur",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/pixar-good-dinosaur-logo_2x_ze7hyg",
    },
    {
        order: 14,
        name: "Disney +",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/disney-plus-logo_2x_dfzkci",
    },
    {
        order: 15,
        name: "IGN",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/ign-logo_2x_id93of",
    },
    {
        order: 16,
        name: "Netflix",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/netflix-logo_2x_ygs9o8",
    },
    {
        order: 17,
        name: "Marvel",
        imageUrl: "https://res.cloudinary.com/ex-populus/image/upload/f_auto/q_auto/v1661361001/website/about-page/marvel-logo_2x_hztaxm",
    },
];

interface IProps extends HTMLAttributes<HTMLElement> {
    id?: string;
    className?: string;
}

const OurBackground: React.FC<IProps> = (props) => {

    return (
        <section
            className={classNames("our-background", props.className)}
            id={props.id}
        >
            <Container>
                <header>
                    <h3>What We&apos;ve Worked On</h3>
                    <h2>Background</h2>
                </header>

                <p>Ex Populus is staffed and led by industry veterans who come from some of the biggest brands in
                    entertainment and have worked on some of the most successful games and movies in history.</p>

                <div className={"logos"}>
                    {
                        brandLogos
                            .sort((a, b) => (a.order > b.order) ? 1 : -1)
                            .map(({name, imageUrl}) => (<div className={"logo"}><img src={imageUrl} alt={name}/></div>))
                    }
                </div>
            </Container>
        </section>
    );
}

export default OurBackground;
