import React from "react";
import SocialMediaIcon, {IconType} from "../aboutUs/SocialMediaIcon";

export const HomepageCommunity: React.FC = () => {
	return (
		<section className="homepage-community-clip">
			<div className="homepage-community">
				<div className="homepage-community_container">
					<div className="homepage-community_container_content">
						<h2 className="homepage-community_container_content_title">
							<span>Join</span> our community
						</h2>
						<span className="homepage-community_container_content_subtitle">
							Join our community of thousands of loyal and passionate fans!
						</span>

						<div className={"homepage-community_container_content_social-media"}>
							<SocialMediaIcon iconType={IconType.DISCORD}/>
							<SocialMediaIcon iconType={IconType.TWITTER}/>
						</div>
					</div>

					<div className="homepage-community_container_image">
						<img
							src={"https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683670340/website/images/homepage/community_section_2_imnc4v.png"}
							alt={"Community"}
						/>
					</div>
				</div>

				<div className="homepage-community_container_image larger">
					<img
						src={"https://res.cloudinary.com/ex-populus/image/upload/c_mfit/f_auto/q_100/v1683670340/website/images/homepage/community_section_2_imnc4v.png"}
						alt={"Community"}
					/>
				</div>
			</div>
		</section>
	)
}
