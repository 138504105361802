"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterBodyAllOfToJSON = exports.RegisterBodyAllOfFromJSONTyped = exports.RegisterBodyAllOfFromJSON = exports.instanceOfRegisterBodyAllOf = void 0;
var runtime_1 = require("../runtime");
var PhoneNumberBody_1 = require("./PhoneNumberBody");
/**
 * Check if a given object implements the RegisterBodyAllOf interface.
 */
function instanceOfRegisterBodyAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "agreeEmailUpdates" in value;
    return isInstance;
}
exports.instanceOfRegisterBodyAllOf = instanceOfRegisterBodyAllOf;
function RegisterBodyAllOfFromJSON(json) {
    return RegisterBodyAllOfFromJSONTyped(json, false);
}
exports.RegisterBodyAllOfFromJSON = RegisterBodyAllOfFromJSON;
function RegisterBodyAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, PhoneNumberBody_1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'solanaWalletAddress': !(0, runtime_1.exists)(json, 'solanaWalletAddress') ? undefined : json['solanaWalletAddress'],
        'agreeEmailUpdates': json['agreeEmailUpdates'],
    };
}
exports.RegisterBodyAllOfFromJSONTyped = RegisterBodyAllOfFromJSONTyped;
function RegisterBodyAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'phoneNumber': (0, PhoneNumberBody_1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'solanaWalletAddress': value.solanaWalletAddress,
        'agreeEmailUpdates': value.agreeEmailUpdates,
    };
}
exports.RegisterBodyAllOfToJSON = RegisterBodyAllOfToJSON;
