"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionToJSON = exports.CollectionFromJSONTyped = exports.CollectionFromJSON = exports.instanceOfCollection = void 0;
var Project_1 = require("./Project");
/**
 * Check if a given object implements the Collection interface.
 */
function instanceOfCollection(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
exports.instanceOfCollection = instanceOfCollection;
function CollectionFromJSON(json) {
    return CollectionFromJSONTyped(json, false);
}
exports.CollectionFromJSON = CollectionFromJSON;
function CollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'project': (0, Project_1.ProjectFromJSON)(json['project']),
        'name': json['name'],
    };
}
exports.CollectionFromJSONTyped = CollectionFromJSONTyped;
function CollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'project': (0, Project_1.ProjectToJSON)(value.project),
        'name': value.name,
    };
}
exports.CollectionToJSON = CollectionToJSON;
