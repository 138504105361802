import React from "react";
import {Container} from "reactstrap";
import {fileIsImage} from "../../utils/fileTypeChecks";
import MediaAssetViewer from "../mediaAssetViewer/MediaAssetViewer";
import {Asset} from "client/marketplace";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

const body: ISets = {
    logoImage: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/sets/xp_logo%402x.png",
        logoImageAlt: "XP Logo",
        header: "Trading Card Set",
        subheader: "Collect, trade, and complete sets",
        body: "The Ex Populus Trading Card System (XPTCS) opens a whole new world in the Metaverse where you can collect, trade, and complete sets. The best collectors can show off their decks to their friends and peers.",
        cardImageAlt: "DAG Sets",
        backgroundImage: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/sets/xpstore_bg%402x.png",
            contentType: "image",
            cloudinary: {
            publicId: "website/images/xpstore_bg_2x_difto4",
                actions: {
                desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
            },
        },
    } as unknown as Asset,
        backgroundImageAlt: "Sets Background",
        asset: {
        url: "https://storage.googleapis.com/ex-populus-marketplace_static/template-homepage/DAGs/sets/dag-card-reverse-3x.png",
            contentType: "image",
            cloudinary: {
            publicId: "website/images/homepage-sets-card_ljfuqr",
                actions: {
                desktop: ["f_auto", "q_auto"],
                    mobile: ["f_auto", "q_auto"]
            },
        },
    } as unknown as Asset,
}

interface ISets {
    asset: Asset;
    logoImage: string;
    logoImageAlt: string;
    header: string;
    subheader: string;
    body: string;
    cardImageAlt: string;
    backgroundImage: Asset;
    backgroundImageAlt: string;
}

const DagHomepageSet: React.FC = () => {
    return (
        <section className="homepage-template-set">
            <div className="homepage-template-set_overlay"/>

            <Container className="container">
                <div className="homepage-template-set_container">
                    <div className="homepage-template-set_container_text">
                        <div className="homepage-template-set_container_text_header">
                            <img
                                src={body.logoImage}
                                alt={body.logoImageAlt}
                            />
                            <div>{body.header}</div>
                        </div>
                        <div className="homepage-template-set_container_text_subheader">
                            {body.subheader}
                        </div>
                        <p className="homepage-template-set_container_text_body">
                            {body.body}
                        </p>

                        <ExPopulusButton
                            color="white-on-pink"
                            href={`${process.env.REACT_APP_WEBSITE_URL}/games/final-form`}
                            className="homepage-template-set_container_button"
                        >
                            Learn More
                        </ExPopulusButton>
                    </div>

                    <div className="homepage-template-set_container_card">
                        <div className="homepage-template-set_container_card_size">
                            <MediaAssetViewer
                                asset={body.asset}
                                imageAltText={body.cardImageAlt}
                                contentType={fileIsImage(body.asset) ? "image" : "video"}
                                elementWidth={{
                                    desktopWidth: 505,
                                    mobileWidth: 385,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
            <div className="homepage-template-set_background">
                <MediaAssetViewer
                    asset={body.backgroundImage}
                    imageAltText={body.backgroundImageAlt}
                    contentType={fileIsImage(body.backgroundImage) ? "image" : "video"}
                />
            </div>
        </section>
    );
};

export {DagHomepageSet};
