"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoBanTypeToJSON = exports.GeoBanTypeFromJSONTyped = exports.GeoBanTypeFromJSON = exports.GeoBanType = void 0;
/**
 *
 * @export
 */
exports.GeoBanType = {
    OFAC_SANCTIONS: 'OFAC_SANCTIONS',
    GAMBLING: 'GAMBLING',
    SECURITIES: 'SECURITIES'
};
function GeoBanTypeFromJSON(json) {
    return GeoBanTypeFromJSONTyped(json, false);
}
exports.GeoBanTypeFromJSON = GeoBanTypeFromJSON;
function GeoBanTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GeoBanTypeFromJSONTyped = GeoBanTypeFromJSONTyped;
function GeoBanTypeToJSON(value) {
    return value;
}
exports.GeoBanTypeToJSON = GeoBanTypeToJSON;
