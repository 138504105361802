"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoBanToJSON = exports.GeoBanFromJSONTyped = exports.GeoBanFromJSON = exports.instanceOfGeoBan = void 0;
var runtime_1 = require("../runtime");
var GeoBanType_1 = require("./GeoBanType");
var GodAdmin_1 = require("./GodAdmin");
var Location_1 = require("./Location");
/**
 * Check if a given object implements the GeoBan interface.
 */
function instanceOfGeoBan(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "country" in value;
    return isInstance;
}
exports.instanceOfGeoBan = instanceOfGeoBan;
function GeoBanFromJSON(json) {
    return GeoBanFromJSONTyped(json, false);
}
exports.GeoBanFromJSON = GeoBanFromJSON;
function GeoBanFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, GeoBanType_1.GeoBanTypeFromJSON)(json['type']),
        'creator': (0, GodAdmin_1.GodAdminFromJSON)(json['creator']),
        'country': (0, Location_1.LocationFromJSON)(json['country']),
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : (0, Location_1.LocationFromJSON)(json['state']),
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.GeoBanFromJSONTyped = GeoBanFromJSONTyped;
function GeoBanToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, GeoBanType_1.GeoBanTypeToJSON)(value.type),
        'creator': (0, GodAdmin_1.GodAdminToJSON)(value.creator),
        'country': (0, Location_1.LocationToJSON)(value.country),
        'state': (0, Location_1.LocationToJSON)(value.state),
        'message': value.message,
    };
}
exports.GeoBanToJSON = GeoBanToJSON;
