"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardAdminToJSON = exports.StandardAdminFromJSONTyped = exports.StandardAdminFromJSON = exports.instanceOfStandardAdmin = void 0;
/**
 * Check if a given object implements the StandardAdmin interface.
 */
function instanceOfStandardAdmin(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfStandardAdmin = instanceOfStandardAdmin;
function StandardAdminFromJSON(json) {
    return StandardAdminFromJSONTyped(json, false);
}
exports.StandardAdminFromJSON = StandardAdminFromJSON;
function StandardAdminFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.StandardAdminFromJSONTyped = StandardAdminFromJSONTyped;
function StandardAdminToJSON(value) {
    return value;
}
exports.StandardAdminToJSON = StandardAdminToJSON;
