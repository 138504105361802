"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Centralized Services OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the CS backend.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpBanTypeToJSON = exports.IpBanTypeFromJSONTyped = exports.IpBanTypeFromJSON = exports.IpBanType = void 0;
/**
 *
 * @export
 */
exports.IpBanType = {
    INVALID_IP: 'INVALID_IP',
    GEO: 'GEO'
};
function IpBanTypeFromJSON(json) {
    return IpBanTypeFromJSONTyped(json, false);
}
exports.IpBanTypeFromJSON = IpBanTypeFromJSON;
function IpBanTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.IpBanTypeFromJSONTyped = IpBanTypeFromJSONTyped;
function IpBanTypeToJSON(value) {
    return value;
}
exports.IpBanTypeToJSON = IpBanTypeToJSON;
