import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import {ModalProps} from "reactstrap/lib/Modal";
import {FiCheck} from "react-icons/all";
import colors from "../../style/colors";
import {ExPopulusButton} from "../buttons/ExPopulusButton";

interface IProps extends ModalProps {

}

const ContactUsSuccessModal: React.FC<IProps> = (props) => {

	function closeHelper(): void {
		props.onClose(false);
	}

	return (
		<Modal
			{...props}
			centered={true}
		>
			<ModalHeader toggle={closeHelper}>Success</ModalHeader>


			<ModalBody className="text-center">
				<FiCheck size={"4rem"} color={colors.raz}/>
				<p>Email sent.</p>
			</ModalBody>

			<ModalFooter>
				<ExPopulusButton
					color="pink"
					onClick={closeHelper}
					className="w-100"
				>
					CLOSE
				</ExPopulusButton>
			</ModalFooter>
		</Modal>
	);
}

export default ContactUsSuccessModal;
