import React from "react";
import classNames from "classnames";
import { ReactComponent as XpIcon } from "../../svgs/social-media-icons/xp-no-color.svg";
import { ReactComponent as DiscordIcon } from "../../svgs/social-media-icons/discord.svg";
import { ReactComponent as TwitterIcon } from "../../svgs/social-media-icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../svgs/social-media-icons/instagram.svg";
import { AiFillInstagram } from "react-icons/all";

export enum IconType {
  DISCORD = "discord",
  TWITTER = "twitter",
  INSTAGRAM = "instagram",
  FF_TWITTER = "ff_twitter",
  FF_INSTAGRAM = "ff_instagram",
  EXPOPULUS = "expopulus",
}

interface ISocialMediaIcon {
  href: string;
  className: string;
  icon: JSX.Element;
}

const socialMediaIcons: { [key in IconType]: ISocialMediaIcon } = {
  [IconType.DISCORD]: {
    href: "https://discord.gg/xaigames",
    className: "discord",
    icon: <DiscordIcon />,
  },
  [IconType.TWITTER]: {
    href: "https://twitter.com/xai_games",
    className: "twitter",
    icon: <TwitterIcon />,
  },
  [IconType.INSTAGRAM]: {
    href: "https://www.instagram.com/expopulus/",
    className: "instagram",
    icon: <InstagramIcon />,
  },
  [IconType.EXPOPULUS]: {
    href: "https://www.expopulus.com/about",
    className: "",
    icon: <XpIcon />,
  },
  [IconType.FF_TWITTER]: {
    href: "https://twitter.com/FinalFormXP/",
    className: "twitter",
    icon: <TwitterIcon />,
  },
  [IconType.FF_INSTAGRAM]: {
    href: " https://www.instagram.com/finalformxp/",
    className: "instagram",
    icon: <AiFillInstagram />,
  },
};

interface IProps {
  iconType?: IconType;
  className?: string;
}

const SocialMediaIcon: React.FC<IProps> = (props) => {
  const socialMediaIcon = socialMediaIcons[props.iconType];
  return (
    <a
      className={classNames("icon", socialMediaIcon.className, props.className)}
      href={socialMediaIcon.href}
      target={"_blank"}
      rel={"noopener noreferrer"}
    >
      {socialMediaIcon.icon}
    </a>
  );
};

SocialMediaIcon.defaultProps = {
  iconType: IconType.EXPOPULUS,
};

export default SocialMediaIcon;
