"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingBodyToJSON = exports.ListingBodyFromJSONTyped = exports.ListingBodyFromJSON = exports.instanceOfListingBody = void 0;
var runtime_1 = require("../runtime");
var CustomLink_1 = require("./CustomLink");
var EVMChain_1 = require("./EVMChain");
var SmartContractType_1 = require("./SmartContractType");
/**
 * Check if a given object implements the ListingBody interface.
 */
function instanceOfListingBody(value) {
    var isInstance = true;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "chain" in value;
    isInstance = isInstance && "smartContractType" in value;
    isInstance = isInstance && "smartContractAddress" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "assets" in value;
    return isInstance;
}
exports.instanceOfListingBody = instanceOfListingBody;
function ListingBodyFromJSON(json) {
    return ListingBodyFromJSONTyped(json, false);
}
exports.ListingBodyFromJSON = ListingBodyFromJSON;
function ListingBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'collection': json['collection'],
        'chain': (0, EVMChain_1.EVMChainFromJSON)(json['chain']),
        'smartContractType': (0, SmartContractType_1.SmartContractTypeFromJSON)(json['smartContractType']),
        'smartContractAddress': json['smartContractAddress'],
        'name': json['name'],
        'description': json['description'],
        'supplyPrefix': !(0, runtime_1.exists)(json, 'supplyPrefix') ? undefined : json['supplyPrefix'],
        'priority': !(0, runtime_1.exists)(json, 'priority') ? undefined : json['priority'],
        'vanityUrl': !(0, runtime_1.exists)(json, 'vanityUrl') ? undefined : json['vanityUrl'],
        'customLink': !(0, runtime_1.exists)(json, 'customLink') ? undefined : (0, CustomLink_1.CustomLinkFromJSON)(json['customLink']),
        'verificationImage': !(0, runtime_1.exists)(json, 'verificationImage') ? undefined : json['verificationImage'],
        'assets': json['assets'],
    };
}
exports.ListingBodyFromJSONTyped = ListingBodyFromJSONTyped;
function ListingBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'collection': value.collection,
        'chain': (0, EVMChain_1.EVMChainToJSON)(value.chain),
        'smartContractType': (0, SmartContractType_1.SmartContractTypeToJSON)(value.smartContractType),
        'smartContractAddress': value.smartContractAddress,
        'name': value.name,
        'description': value.description,
        'supplyPrefix': value.supplyPrefix,
        'priority': value.priority,
        'vanityUrl': value.vanityUrl,
        'customLink': (0, CustomLink_1.CustomLinkToJSON)(value.customLink),
        'verificationImage': value.verificationImage,
        'assets': value.assets,
    };
}
exports.ListingBodyToJSON = ListingBodyToJSON;
