import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import HowCanWeHelpSection from "../components/contact/HowCanWeHelpSection";
import ContactHero from "../components/contact/ContactHero";
import {useHistory} from "react-router-dom";
import OurLocations from "../components/contact/OurLocations";
import WeAreHiring from "../components/contact/WeAreHiring";

enum ContactHash {
	FORM = "#how-can-we-help-section"
}

interface IProps {
	dispatch?: any;
}

const ContactPage: React.FC<IProps> = (props) => {

	const history = useHistory();
	const HowCanWeHelpSectionRef = useRef(null);

	// check the hash of the url if it matches an anchor on the page, scroll to the section if the anchor exists.
	useEffect(() => {
		if (location?.hash === ContactHash.FORM) {
			scrollHelper();
		}
	}, [location?.hash]);

	// update url hash and scroll form into view
	function scrollHelper(): void {
		if (location?.hash !== ContactHash.FORM) {
			history.push(`/contact/${ContactHash.FORM}`);
		}
		HowCanWeHelpSectionRef.current.scrollIntoView({
			block: "start",
			behavior: "smooth"
		});
	}

	return (
		<div className="contact-page">
			<ContactHero onClick={scrollHelper}/>

				<OurLocations/>

				<WeAreHiring/>

				<div
					id="how-can-we-help-section"
					ref={HowCanWeHelpSectionRef}
				>
					<HowCanWeHelpSection/>
				</div>
		</div>
	);
}

export default connect()(ContactPage);
