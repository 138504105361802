"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Ex Populus Marketplace OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: Local Development
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingAllOfToJSON = exports.ListingAllOfFromJSONTyped = exports.ListingAllOfFromJSON = exports.instanceOfListingAllOf = void 0;
var runtime_1 = require("../runtime");
var Asset_1 = require("./Asset");
var Collection_1 = require("./Collection");
var CustomLink_1 = require("./CustomLink");
var EVMChain_1 = require("./EVMChain");
var SmartContractType_1 = require("./SmartContractType");
/**
 * Check if a given object implements the ListingAllOf interface.
 */
function instanceOfListingAllOf(value) {
    var isInstance = true;
    isInstance = isInstance && "smartContractType" in value;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "chain" in value;
    isInstance = isInstance && "smartContractAddress" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
exports.instanceOfListingAllOf = instanceOfListingAllOf;
function ListingAllOfFromJSON(json) {
    return ListingAllOfFromJSONTyped(json, false);
}
exports.ListingAllOfFromJSON = ListingAllOfFromJSON;
function ListingAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'smartContractType': (0, SmartContractType_1.SmartContractTypeFromJSON)(json['smartContractType']),
        'collection': (0, Collection_1.CollectionFromJSON)(json['collection']),
        'chain': (0, EVMChain_1.EVMChainFromJSON)(json['chain']),
        'smartContractAddress': json['smartContractAddress'],
        'name': json['name'],
        'description': json['description'],
        'supplyPrefix': !(0, runtime_1.exists)(json, 'supplyPrefix') ? undefined : json['supplyPrefix'],
        'priority': !(0, runtime_1.exists)(json, 'priority') ? undefined : json['priority'],
        'vanityUrl': !(0, runtime_1.exists)(json, 'vanityUrl') ? undefined : json['vanityUrl'],
        'customLink': !(0, runtime_1.exists)(json, 'customLink') ? undefined : (0, CustomLink_1.CustomLinkFromJSON)(json['customLink']),
        'verificationImage': !(0, runtime_1.exists)(json, 'verificationImage') ? undefined : (0, Asset_1.AssetFromJSON)(json['verificationImage']),
        'assets': !(0, runtime_1.exists)(json, 'assets') ? undefined : (json['assets'].map(Asset_1.AssetFromJSON)),
        'price': !(0, runtime_1.exists)(json, 'price') ? undefined : json['price'],
        'totalSupply': !(0, runtime_1.exists)(json, 'totalSupply') ? undefined : json['totalSupply'],
        'maximumSupply': !(0, runtime_1.exists)(json, 'maximumSupply') ? undefined : json['maximumSupply'],
    };
}
exports.ListingAllOfFromJSONTyped = ListingAllOfFromJSONTyped;
function ListingAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'smartContractType': (0, SmartContractType_1.SmartContractTypeToJSON)(value.smartContractType),
        'collection': (0, Collection_1.CollectionToJSON)(value.collection),
        'chain': (0, EVMChain_1.EVMChainToJSON)(value.chain),
        'smartContractAddress': value.smartContractAddress,
        'name': value.name,
        'description': value.description,
        'supplyPrefix': value.supplyPrefix,
        'priority': value.priority,
        'vanityUrl': value.vanityUrl,
        'customLink': (0, CustomLink_1.CustomLinkToJSON)(value.customLink),
        'verificationImage': (0, Asset_1.AssetToJSON)(value.verificationImage),
        'assets': value.assets === undefined ? undefined : (value.assets.map(Asset_1.AssetToJSON)),
        'price': value.price,
        'totalSupply': value.totalSupply,
        'maximumSupply': value.maximumSupply,
    };
}
exports.ListingAllOfToJSON = ListingAllOfToJSON;
