import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {logout} from "../redux/meta/MetaActions";
import {TokensApi} from "client/cs";
import getCsConfig from "../utils/getCsConfig";

const tokenCheckInterval: number = 60000;

const TokenManager: React.FC = () => {
    const fullToken = useSelector((store: IStore) => store.metaStore.fullToken);
    const dispatch = useDispatch();

    useEffect(() => {
        checkTokenValid().catch(console.error);
        const interval = setInterval(checkTokenValid, tokenCheckInterval);
        return () => clearInterval(interval);
    }, [JSON.stringify(fullToken)]);

    async function checkTokenValid(): Promise<void> {
        // don't need to check if token doesn't exist
        if (!fullToken?.token) {
            return;
        }

        try {
            const res = await new TokensApi(getCsConfig(fullToken)).checkTokenExpiration({tokenBody: {token: fullToken?.token}});

            if (res.expired === true) {
                dispatch(logout());
            }
        } catch (e) {
            console.error(e);
        }
    }

    return null;
};

export default TokenManager;
